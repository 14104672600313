import React from 'react';
import autoBind from 'react-autobind';
import { Result, Button, message } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//props are: app, isVisible, certificationProcess, certificationSpecs, onUpdate
export default class CommonCertificationViewLockCourseTabView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Actions
  handleUnlockCourse() {
    const lastCourse = this.props.certificationProcess.courses[this.props.certificationProcess.courses?.length - 1];
    if (lastCourse) this._unlockCourse(lastCourse);
  }

  //UI
  render() {
    if (!this.props.isVisible) return <></>;
    //
    const subtitle = "Certification is locked due to a course failure";
    const resultProps = !this.props.app.isAdmin()
      ? {}
      : {
        extra: [
          <Button type="primary" key="1" icon={<UnlockOutlined />} onClick={this.handleUnlockCourse}>
            Unlock
          </Button>,
        ],
      };
    return (
      console.log("certificationSpecs++++++++++++", this.props.certificationSpecs),
      console.log("certProc++++++++++++", this.props.certificationProcess),
      <>
        <CommonLoadingView isLoading={this.state.isLoading} />
        <Result status="error" title="Locked" subTitle={subtitle} {...resultProps} />
      </>
    );
  }

  //Private
  //API
  async _unlockCourse(currentCourse) {
    this.startLoading();
    const resp = await this.props.app.api.course.unlock(
      currentCourse.userID,
      currentCourse.certProcID,
      currentCourse.id,
      { comments: '' }
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Course unlocked with success!');
      await this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading();
  }
}

CommonCertificationViewLockCourseTabView.GetTabTitleView = function (certSpecs, certProc) {
  if (!certSpecs || !certProc) return '';
  if (certProc.state == Globals.CertificationProcess_State.COURSE_LOCKED)
    return `${Utils.camelizeString(certSpecs.uiTerm)} is Locked`;
  return '';
};
