import React from 'react';
import autoBind from 'react-autobind';
import { Form, DatePicker, Input, Select, InputNumber, Typography, Checkbox, Button, message } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//
import CustomComponent from '../../../components/CustomComponent';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import '../../../assets/stylesheets/CommonVoucherForm.less';
//
export default class CommonVoucherForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      autoGenerate: true,
      expires: false,
      unlimitedActs: true,
      maxDiscountEnabled: false,
      voucherID: null,
      codeCopied: false,
    };
  }
  //Public
  setFormData(data) {
    this.setState(
      {
        autoGenerate: false,
        expires: data.expDate > 0,
        unlimitedActs: !(data.maxActivations > 0),
        voucherID: data.id,
        codeCopied: false,
        maxDiscountEnabled: data.maxQuantity > 0 || data.maxDiscount > 0,
      },
      () => {
        this.form.setFieldsValue({
          voucherCode: data.id,
          description: data.description,
          productIDs: data.productID || this.props.app.sharedCache().getAllProductsID(),
          discAmount: data.discAmount || 0,
          discPercent: (data.discPercent || 0) * 100,
          ...(data.expDate ? { expDate: Utils.getDateOnMomentFormatByTimestamp(data.expDate) } : { expDate: null }),
          ...(data.maxActivations ? { maxActivations: data.maxActivations } : { maxActivations: null }),
          expires: this.state.expires,
          unlimitedActs: this.state.unlimitedActs,
          maxQuantity: this.state.maxDiscountEnabled && data.maxQuantity >= 0 ? data.maxQuantity : 0,
          maxDiscount:
            this.state.maxDiscountEnabled && data.maxQuantity <= 0 && data.maxDiscount != 0 ? data.maxDiscount : 0,
          maxDiscountEnabled: this.state.maxDiscountEnabled,
        });
      }
    );
  }

  reset() {
    this.form.resetFields();
    this.setState({ autoGenerate: true, expires: false, unlimitedActs: true, voucherID: null, codeCopied: false });
  }
  //Actions
  handleCheckChange(id, val) {
    this.setState({ [id]: val.target.checked });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onSubmit({
      id: this.state.autoGenerate ? null : formData.voucherCode,
      description: formData.description,
      ...(formData.discPercent && formData.discPercent > 0
        ? { discPercent: formData.discPercent / 100, discAmount: 0 }
        : { discPercent: 0, discAmount: formData.discAmount }),
      productID: formData.productIDs || this.props.app.sharedCache().getAllProductsID(),
      expDate: this.state.expires ? Utils.getTimestampFromMoment(formData.expDate) : null,
      maxActivations: this.state.unlimitedActs ? null : formData.maxActivations,
      maxQuantity: this.state.maxDiscountEnabled && formData.maxQuantity >= 0 ? formData.maxQuantity : -1,
      maxDiscount:
        this.state.maxDiscountEnabled && formData.maxQuantity <= 0 && formData.maxDiscount >= 0
          ? formData.maxDiscount
          : -1,
    });
  }
  handleCodeCopy() {
    this.setState({ codeCopied: true }, () => {
      message.success('Copied to clipboard');
      setTimeout(() => {
        if (this._isMounted) this.setState({ codeCopied: false });
      }, 3500);
    });
  }

  //UI
  render() {
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
    };
    return (
      <Form {...layout} {...Utils.propagateRef(this, 'form')}>
        {this.props.app.sharedCache().getAllProductsID().length > 1 && (
          <Form.Item
            name="productIDs"
            label={'Allowed products'}
            rules={[{ required: true, message: 'Please, select at least one product!' }]}
            initialValue={this.props.app.sharedCache().getAllProductsID()}
          >
            <Select mode="multiple" disabled={this.props.editMode}>
              {this.props.app
                .sharedCache()
                .getAllProductsID()
                .map((id) => {
                  const pr = this.props.app.sharedCache().getProductByID(id);
                  return (
                    <Select.Option value={pr.id} key={pr.id}>
                      {pr.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}
        {!this.props.editMode ? (
          <>
            <Form.Item
              label="Auto generate code?"
              name="autoGenerate"
              colon={false}
              valuePropName="checked"
              initialValue={this.state.autoGenerate}
            >
              <Checkbox id="autoGenerate" onChange={this.handleCheckChange.bind(this, 'autoGenerate')} />
            </Form.Item>
            <Form.Item
              label="Voucher Code"
              name="voucherCode"
              rules={[{ required: !this.state.autoGenerate, message: 'Please, type the voucher code!' }]}
            >
              <Input type="text" allowClear disabled={this.state.autoGenerate} minLength={10} />
            </Form.Item>
          </>
        ) : (
          <Form.Item label="Voucher Code" name="voucherCode">
            <Input
              type="text"
              disabled
              suffix={
                <CopyToClipboard text={this.state.voucherID} onCopy={this.handleCodeCopy}>
                  <Button>
                    {' '}
                    Copy Code
                    {!this.state.codeCopied ? <CopyOutlined /> : <CheckOutlined />}
                  </Button>
                </CopyToClipboard>
              }
            />
          </Form.Item>
        )}

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please, type the voucher description!' }]}
        >
          <Input type="text" allowClear />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          extra="*Discount per license, percentage amount takes precedence."
          label="Discount"
        >
          <Form.Item
            extra="Fixed Amount"
            initialValue={0}
            name="discAmount"
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <InputNumber
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Typography.Text className="discountDivider">OR</Typography.Text>
          <Form.Item
            extra="Percentage"
            initialValue={0}
            name="discPercent"
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              min={0}
              max={100}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Max discount enabled?"
          name="maxDiscountEnabled"
          colon={false}
          valuePropName="checked"
          initialValue={this.state.maxDiscountEnabled}
        >
          <Checkbox id="maxDiscountEnabled" onChange={this.handleCheckChange.bind(this, 'maxDiscountEnabled')} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          extra="*Optional max discount. Quantity takes precedence."
          label="Max discount"
        >
          <Form.Item
            extra="Value ($)"
            initialValue={0}
            name="maxDiscount"
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <InputNumber
              disabled={!this.state.maxDiscountEnabled}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Typography.Text className="discountDivider">OR</Typography.Text>
          <Form.Item
            extra="Quantity (UN)"
            initialValue={0}
            name="maxQuantity"
            style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
          >
            <InputNumber disabled={!this.state.maxDiscountEnabled} />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Time limited?"
          name="expires"
          colon={false}
          valuePropName="checked"
          initialValue={this.state.expires}
        >
          <Checkbox id="expires" onChange={this.handleCheckChange.bind(this, 'expires')} />
        </Form.Item>
        <Form.Item
          label="Expiration"
          name="expDate"
          rules={[{ required: this.state.expires, message: 'Please, type the voucher code!' }]}
        >
          <DatePicker
            allowClear
            disabled={!this.state.expires}
            showTime={{ format: 'HH:mm:' }}
            format={Globals.DefaultUIDateTimeFormat}
          />
        </Form.Item>

        <Form.Item
          label="Unlimited activations?"
          name="unlimitedActs"
          colon={false}
          valuePropName="checked"
          initialValue={this.state.unlimitedActs}
        >
          <Checkbox id="unlimitedActs" onChange={this.handleCheckChange.bind(this, 'unlimitedActs')} />
        </Form.Item>
        <Form.Item
          label="Max Activations"
          name="maxActivations"
          rules={[{ required: !this.state.unlimitedActs, message: 'Please, type the voucher code!' }]}
        >
          <InputNumber min={-1} allowClear disabled={this.state.unlimitedActs} />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.props.isLoading}
            disabled={this.props.isLoading}
          >
            {this.props.editMode ? 'Save' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
