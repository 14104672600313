import React from 'react';
import autoBind from 'react-autobind';
import { CaretDownOutlined } from '@ant-design/icons';

import '../../assets/stylesheets/CustomCollapse.less';

export default class CustomCollapse extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: !!this.props.visible,
    };
  }

  handleToggleVisibility(event) {
    event.stopPropagation();

    const classes = Object.entries(Object.fromEntries(event.target.classList.entries()));
    const { tagName } = event.target;
    const validElement =
      classes.find(([, className]) => ['collapse-header', 'description', 'title'].includes(className)) ||
      ['svg', 'path'].includes(tagName?.toLowerCase());

    if (!validElement) return;

    this.setState((prevState) => ({
      ...prevState,
      isVisible: !prevState.isVisible,
    }));
  }

  render() {
    const { isVisible } = this.state;
    const { title, description, children, extraActions, type } = this.props;

    return (
      <div className={`collapse-item ${type ? type : ''}`}>
        <header
          onClick={this.handleToggleVisibility}
          className={isVisible ? 'collapse-header visible' : 'collapse-header'}
        >
          <div className="info">
            <strong className="title">{title}</strong>
            <span className="description">{description}</span>
          </div>

          <div className="right">
            {extraActions && <div className="extra-actions">{extraActions(this.state.isVisible)}</div>}
            <CaretDownOutlined className={`arrow ${isVisible ? 'visible' : ''}`} />
          </div>
        </header>

        <div className={`body ${isVisible ? 'visible' : ''}`}>{children}</div>
      </div>
    );
  }
}
