import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Empty } from 'antd';

export default class ApplicationsChart extends React.Component {
  render() {
    let data,
      leftMargin = null;
    if (typeof this.props.renewalStarted != 'undefined') {
      data = [
        { id: 'Renewal Submitted', label: 'Submitted', Total: this.props.renewalSubmitted },
        { id: 'Renewal Started', label: 'Started', Total: this.props.renewalStarted },
        { id: 'New Submitted', label: 'Submitted', Total: this.props.submitted },
        { id: 'New Started', label: 'Started', Total: this.props.started },
      ];
      leftMargin = 132;
    } else {
      data = [
        { id: 'Submitted', label: 'Submitted', Total: this.props.submitted },
        { id: 'Started', label: 'Started', Total: this.props.started },
      ];
      leftMargin = 82;
    }

    const allEmpty = data.every((item) => item.Total === 0);
    if (allEmpty) {
      return <Empty description="No data to be displayed here" style={{ marginTop: 56 }} />;
    }

    return (
      <ResponsiveBar
        data={data}
        keys={['Total']}
        layout="horizontal"
        colors={{ scheme: 'orange_red' }}
        colorBy="id"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        margin={{ top: 0, right: 0, bottom: 70, left: leftMargin }}
        axisLeft={{ tickSize: 0, tickPadding: 20, tickRotation: 0 }}
        enableGridY={false}
        theme={{
          legends: { text: { fontSize: 13 } },
          axis: { ticks: { text: { fontSize: 13 } } },
          labels: { text: { fontSize: 13 } },
        }}
      />
    );
  }
}
