import React from 'react';
import autoBind from 'react-autobind';
import { Result, Spin } from 'antd';
//
export default class CommonCoursePurchaseModalSubStep_LoadingView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    let content = {
      status: 'info',
      title: 'Loading.',
      subTitle: 'Loading available sessions..',
      extra: <Spin spinning />,
    };
    return <Result {...content} />;
  }
}
