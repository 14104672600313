import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Tag, Button, DatePicker, Select, Row, Col } from 'antd';
import { DownloadOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
//
import CustomComponent from '../../../components/CustomComponent';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import config from '../../../config/config';
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
//
import '../../../assets/stylesheets/AdminSearchSessionsView.less';
import '../../../assets/stylesheets/AdminReports.less';
//
export default class AdminSessionResultsReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      firstLoad: true,
      sessionResults: [],
      courseIDs: [],
      ...this._getInitialState(),
    };
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
    // if (this.state.firstLoad) this.fetchData();
  }

  //API
  async fetchData() {
    this.setState({ sessionResults: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const { dateFrom, dateTo, courseIDs } = this.state.filters;
    const from = Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1);
    const resp = await this.props.app.classroom.report.sessionResults(
      dateFrom,
      dateTo,
      courseIDs ? courseIDs : null,
      from,
      true
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.sessionResults) {
      const sessionResults = resp.body.sessionResults;
      this.setState({ sessionResults, total: resp.body.total, firstLoad: true, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  //Actions
  //Main actions
  async handleExportXLSX() {
    const { dateFrom, dateTo, courseIDs } = this.state.filters;
    this.startLoading();
    //Make request
    const resp = await this.props.app.classroom.report.sessionResults(dateFrom, dateTo, courseIDs ? courseIDs : null);
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `SessionResults-${dateFrom}-${dateTo}`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading();
  }
  handleSearch() {
    if (this.state.filters.dateFrom || this.state.filters.dateTo) {
      this.setState({ sortedInfo: null, currentPage: 1 }, () => {
        this.fetchData();
      });
    }
  }
  //Table
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo });
  }
  onRowSelection(val) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.sessionsHome, {}, val.id);
  } // TODO: Validate this behaviour
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => {
      this.fetchData();
    });
  }
  //Custom filter
  handleChangeFilter = (type) => (value) => {
    // validating by type so we can set multiple filter types with only one state change
    // and encapsulate filters change logic in only one method
    // this is useful today only for registered date range
    const filter = !Array.isArray(value) && typeof value == 'object' ? value : { [type]: value };
    this.setState((prevState) => ({
      filters: { ...prevState.filters, ...filter },
    }));
  };
  handleRangeDateChange(date) {
    let [from, to] = date || [];
    from = from ? from.format('YYYY-MM-DD') : null;
    to = to ? to.format('YYYY-MM-DD') : null;

    this.setState({ momentRange: date }); // used to populate when state is cached
    this.handleChangeFilter(null)({ dateFrom: from, dateTo: to });
  }
  //UI
  render() {
    const props = {
      rowKey: (record) => record.sessionID + record.userID,
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      scroll: { x: true },
      locale: { emptyText: this.state.firstLoad ? 'Search session results' : 'No session results found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        showSizeChanger: false,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
        total: this.state.total,
        onChange: this.handlePagination,
        current: this.state.currentPage,
      },
    };
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Session Results Report" />
          <Layout.Content>
            {this._renderFilters()}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={this.handleExportXLSX}
                disabled={this.state.sessionResults.length < 1}
                style={{ margin: '15px 0' }}
              >
                {' '}
                Export to xlsx{' '}
              </Button>
            </div>
            <Table
              className="adminSearchSessionsTable"
              onRow={this._onRow}
              columns={this._getTableColumns()}
              dataSource={this.state.sessionResults}
              {...props}
            />
            <Row className="sessionSearchTotalRow" type="flex" align="center">
              <Tag className="sessionSearchTotalLabel" icon={<SearchOutlined />}>
                {this.state.total} session results found
              </Tag>
            </Row>
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* UI privates renders */
  _renderFilters() {
    return (
      <div className="sessionsFilters">
        <Row type="flex">
          <Col className="filterItem">
            <strong>Courses:</strong>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              value={this.state.filters.courseIDs}
              size="large"
              onChange={this.handleChangeFilter('courseIDs')}
            >
              {this.props.app
                .sharedCache()
                .getAllUniqueCourses()
                .map((course) => (
                  <Select.Option key={course.id} value={course.id}>
                    {course.displayName}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col className="filterItem">
            <strong>Date Range:</strong>
            <DatePicker.RangePicker
              defaultValue={this.state.momentRange}
              size="large"
              onChange={this.handleRangeDateChange}
            />
          </Col>
          <Col>
            <Button type="primary" size="large" style={{ marginLeft: 4 }} onClick={this.handleSearch.bind(this)}>
              Filter
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  /* UI Helpers */
  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Course',
        key: 'courseID',
        width: '10%',
        render: (session) => this.props.app.sharedCache().getCourseByID(session.courseID).displayName,
      },
      {
        title: 'Session Name',
        key: 'name',
        width: '15%',
        render: (session) => session.name,
      },
      {
        title: 'User Name',
        key: 'name',
        width: '15%',
        render: (session) => `${session.firstName} ${session.lastName}`,
      },
      {
        title: 'Email',
        key: 'email',
        width: '15%',
        render: (session) => session.email,
      },
      {
        title: 'Venue',
        key: 'venueName',
        width: '10%',
        render: (session) => session.venueName,
      },
      {
        title: 'City',
        key: 'city',
        width: '10%',
        render: (session) => session.city,
      },
      {
        title: 'Result Status',
        key: 'resultStatus',
        width: '5%',
        render: (session) => Globals.Result_Status[session.resultStatus],
      },
      {
        title: 'Result Grade',
        key: 'resultGrade',
        width: '5%',
        render: (session) => session.resultGrade,
      },
      {
        title: 'Result Date',
        key: 'resultDate',
        width: '15%',
        render: (session) => Utils.getDateOnUIFormatByTimestamp(session.resultDate),
      },
    ];
    return columns;
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.onRowSelection(record);
      }, // click row
      onDoubleClick: () => {
        this.onRowSelection(record);
      }, // double click row
    };
  }

  //Filters and URL support
  _getInitialState() {
    let pState = {};
    try {
      pState = JSON.parse(
        Buffer.from(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Filters), 'base64').toString('ascii') ||
          '{}'
      );
    } catch (e) {}
    const dateFrom = pState?.filters?.dateFrom || null;
    const dateTo = pState?.filters?.dateTo || null;
    const courseIDs = pState?.filters?.courseIDs || [];
    const currentPage = pState?.currentPage || 1;

    const momentRange = [];
    if (dateFrom) {
      momentRange.push(moment(new Date(dateFrom)));
    }
    if (dateTo) {
      momentRange.push(moment(new Date(dateTo)));
    }

    return {
      momentRange,
      filters: {
        dateFrom: dateFrom || null,
        dateTo: dateTo || null,
        courseIDs,
      },
      currentPage,
      sortedInfo: null,
      total: 0,
    };
  }
  _setSearchQueryParams() {
    const cleanState = this.state;
    delete cleanState.sessionResults;
    this.props.app.urlManager.updateQueryStringParam(
      Globals.URL_Path_Filters,
      Buffer.from(JSON.stringify(cleanState)).toString('base64')
    );
  }
}
