import React from 'react';
import autoBind from 'react-autobind';
import CommonDynamicForm from '../../../Forms/CommonDynamicForm';
import Utils from '../../../../../components/Utils';
import { Button, Col, Form, Input, Result, Row, Spin, Alert, Popconfirm } from 'antd';
import Globals from '../../../../../config/Globals';
import moment from 'moment';
import { add } from 'date-fns';

export default class CommonProductPurchaseModalStep_InfoConfirmation extends React.Component {
  /**
   * Creates an instance of CommonProductPurchaseModalStep_InfoConfirmation.
   *
   * @constructor
   * @param { { app, onNext, onCancel, onPrevious, user } } props
   */
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      selectedOrg: null,
      isLoading: false,
      isSubmitting: false,
      hasChanged: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const user = await this._getUser();
      const address = {
        unit: user?.['address.unit'],
        street0: user?.['address.street.0'],
        street1: user?.['address.street.1'],
        city: user?.['address.city'],
        province: user?.['address.province'],
        country: user?.['address.country'],
        postalCode: user?.['address.postalCode'],
      };
      this.setState({ selectedOrg: user?.employementInfo?.org, isLoading: false }, async () => {
        this.form.setFieldsValue(address);
        await this.form.validateFields();
      });
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }

  async handleValidation() {
    try {
      if (!this.state.hasChanged) {
        this.setState({ hasChanged: true });
      }

      const resp = await this.form.validateFields();

      return { ...resp, ...this.state };
    } catch (err) {
      console.error('error ', err);

      return null;
    }
  }

  async handleSubmit() {
    const materialShippingInfo = await this.form.validateFields();
    this.props.onNext({ materialShippingInfo });
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')} style={{ marginTop: 20 }}>
        {this.state.isLoading && (
          <Result status="info" title="Loading" subTitle="Loading your account data..." extra={<Spin />} />
        )}

        {!this.state.isLoading && (
          <>
            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col offset={1} span={3}>
                <Form.Item name="unit" label="Apt, Suite or Unit">
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={19}>
                <Form.Item
                  name="street0"
                  label="Mailing address or PO box"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col offset={1} span={5}>
                <Form.Item name="city" label="City" rules={[{ required: true, message: 'This field is required!' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={5}>
                <Form.Item
                  name="province"
                  label="Province"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={5}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={5}>
                <Form.Item
                  name="postalCode"
                  label="Postal Code"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 24 }}>
              <Col span={24}>
                <Alert
                  type="warning"
                  showIcon
                  message={
                    <>
                      <strong>ATTENTION:</strong> Review the accuracy of your shipping information before finalizing
                      your order. Please note that if incorrect details are provided, any additional costs associated
                      with reshipping or resolving delivery errors will be your responsibility.
                    </>
                  }
                />
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: 16 }}>
              <Col>
                <Button
                  className="purchaseModalCancelButton"
                  disabled={this.state.isSubmitting}
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>

                <Button disabled={this.state.isSubmitting} onClick={this.props.onPrevious}>
                  Previous
                </Button>
                <Popconfirm
                  placement="bottom"
                  title={'Have you verified that your shipping address is correct?'}
                  onConfirm={this.handleSubmit}
                  okText="Yes"
                  cancelText="No"
                  zIndex={99999}
                >
                  <Button className="purchaseModalConfirmationButton" type="primary" loading={this.state.isSubmitting}>
                    Next
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }

  _renderForm() {
    return <></>;
  }

  /* API */
  async _getUser() {
    let userID = null;
    if (this.props.user?.userID) userID = this.props.user.userID;
    else if (this.props.user?.id) userID = this.props.user.id;
    else userID = this.props.app.getAuthorizedUserID();
    const promises = [];
    //get user and check for failure
    let userObj = null;
    promises.push(
      new Promise(async (resolve) => {
        userObj = await this.props.app.idm.api.user.read(userID);
        if (userObj.statusCode == 200) {
          userObj = userObj.body;
          resolve(true);
        } else {
          this.props.app.alertController.showAPIErrorAlert(null, userObj);
          resolve(false);
        }
      })
    );

    //Load employment info
    let employementInfo = null;
    const additionalInputs = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
    if (Object.values(additionalInputs || {}).find((f) => f.type == Globals.RegOrgEmploymentSelectionType)) {
      promises.push(
        new Promise(async (resolve) => {
          let employmentResp = await this.props.app.organization.employee.getEmployeeByEmployeeID(userID);
          if (employmentResp.statusCode == 200) employementInfo = employmentResp.body;
          resolve(true);
        })
      );
    }

    //get partitions data
    const wantedParts = this._getAvailablePartitions(true);
    let partData = null;
    if (wantedParts.length > 0) {
      promises.push(
        new Promise(async (resolve) => {
          partData = await this.props.app.idm.api.userPartition.readSome(wantedParts, userID);
          partData = partData?.body?.parts || {};
          resolve(true);
        })
      );
    }

    //Resolve alll, continue if all true
    const resolveAll = await Promise.all(promises);
    if (!resolveAll || resolveAll.find((r) => !r)) return;
    //Return parsed info
    return this._parseUserResponses(userObj, partData, employementInfo);
  }

  _parseUserResponses(userObj, partData, employementInfo) {
    //
    try {
      //Buildup form data
      const formData = {};
      const fields = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
      for (let fieldKey of Object.keys(fields || {})) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0) {
          const part = partData.find((part) => part.partID == field.partitionID);
          formData[field.id] = Utils.getNestedObject(part ? part.value : null, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (field.saveOnUser && !formData[field.id]) {
          formData[field.id] = Utils.getNestedObject(userObj, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (!formData[field.id]) {
          formData[field.id] = ''; /* unknown source */
        }
        if (field.type == 'boolean' || field.type == 'switch') formData[field.id] = !!formData[field.id];
      }
      //Set ship to main address
      const partition = this.props.app.sharedCache().getTenantRegistrationConfig().fields
        ?.shipToMainAddress?.partitionID;
      const shipToMainAddress = !!partition
        ? !!partData?.find((part) => part.partID == partition).value?.shipToMainAddress
        : false;
      //Set form data
      return { ...formData, ...userObj, partData, employementInfo, shipToMainAddress };
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  _getAvailablePartitions(noSanitize) {
    let parts = [];
    const additionalInputs = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
    for (let input of Object.values(additionalInputs || {})) {
      const partID = noSanitize ? input.partitionID : input.partitionID.replace(/\./g, '_');
      if (parts.indexOf(partID) == -1) parts.push(partID);
    }
    return parts;
  }
}
