import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message } from 'antd';
//
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//
import CommonTrainingProviderForm from '../Forms/CommonTrainingProviderForm';
//props are: app, onUpdate
export default class CommonTrainingProviderDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, trainingProviderID: null, data: null };
  }
  //Public
  show(trainingProviderID) {
    this.setState({ isVisible: true, trainingProviderID, data: null }, this._fetch);
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isLoading: false, isVisible: false, trainingProviderID: null, data: null });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.trainingProviderID) this._update(formData);
    else this._create(formData);
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.trainingProviderID ? 'Edit' : 'Create'} Training Provider`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Row type="flex" justify="end">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleSubmit}>
              {this.state.trainingProviderID ? 'Save' : 'Create'}
            </Button>{' '}
          </Col>
        </Row>
        <CommonTrainingProviderForm
          app={this.props.app}
          {...Utils.propagateRef(this, 'form')}
          isEdit={!!this.state.trainingProviderID}
        />
      </Drawer>
    );
  }

  // API Calls
  async _fetch() {
    if (!this.state.trainingProviderID) return; //avoid loading empty TPs
    this.setState({ isLoading: true });
    const resp = await this.props.app.config.trainingProvider.getTrainingProvider(this.state.trainingProviderID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ data: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _update(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.config.trainingProvider.updateTrainingProvider({
      id: this.state.data.id,
      customerID: this.state.data.customerID,
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Training Provider successfully updated!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
  async _create(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.config.trainingProvider.createTrainingProvider({
      ...data,
      ...(this.props.app.isSysAdmin() && data.id
        ? {
            id: data.id,
          }
        : {
            id: `TrainingProvider-${this.props.app.sharedCache().getCustomerID()}-${this.props.app.sharedCache().getTenantConfig().id}-${config.Stage}-${data.name.toLowerCase().replace(/[ ?&/]/g, '')}-${Date.now()}`,
          }),
      customerID: this.props.app.sharedCache().getCustomerID(),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Training Provider successfully created!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
