import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Drawer, Divider, Button, Table, Tag } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
import Globals from '../../../config/Globals';
//
//props are: app, session
export default class CommonSessionChargesDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sessions: [],
      userFullName: null,
      isLoading: false,
      isVisible: false,
    };
  }
  //Public actions
  async show(sessionID, userID) {
    await this._fetch(sessionID, userID);
  }
  //Private actions
  async _fetchUserName(usersID) {
    const resp = await this.props.app.api.user.searchUsersByIDs(usersID);
    if (resp.statusCode == 200 && resp.body) {
      return resp.body.users;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    return null;
  }
  handleClose() {
    this.setState({ isVisible: false, isLoading: false });
  }
  // API Calls
  async _fetch(sessionID, userID) {
    this.setState({ isLoading: true });
    const resp = await this.props.app.classroom.sessionCharge.getSessionChargesByUserID(sessionID, userID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const user = await this._fetchUserName([userID]);
      this.setState({
        sessions: resp.body.financialCharges,
        userFullName: user[0]._source.fullName,
        isLoading: false,
        isVisible: true,
      });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
  }
  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`Session Charges from Student: ${this.state.userFullName}`}
        placement="right"
        onClose={this.handleClose}
        width={650}
        footer={this._renderDrawerFooter()}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        {this.state.sessions.length > 0 && <>{this._renderSessionsChargesTable()}</>}
      </Drawer>
    );
  }

  /* private UI */
  _renderSessionsChargesTable() {
    const columns = [
      {
        title: 'Date',
        key: 'timestamp',
        width: '25%',
        render: (props) => Utils.getDateOnUIFormatByTimestamp(props.timestamp),
      },
      {
        title: 'Status',
        key: 'chargeResult',
        width: '25%',
        render: (props) => {
          return (
            <Tag color={props.chargeResult == Globals.SessionEnrolment_ChargeResult.SUCCESS ? 'green' : 'red'}>
              {props.chargeResult}
            </Tag>
          );
        },
      },
      { title: 'Transaction', key: 'transactionID', dataIndex: 'transactionID', width: '25%' },
      {
        title: 'Amount',
        key: 'amount',
        width: '25%',
        render: (props) => `$${Utils.toCurrencyFormat(props.amount)}`,
      },
    ];
    const props = {
      rowKey: 'transactionID',
      loading: this.state.isLoading,
      locale: { emptyText: 'No charges found!' },
    };
    return (
      <Layout.Content>
        <Table columns={columns} dataSource={this.state.sessions || []} {...props} pagination={false} />
      </Layout.Content>
    );
  }
  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleClose} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    );
  }
}
