import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Button, message, Modal } from 'antd';
//
import CustomComponent from '../../../components/CustomComponent';
//
import Utils from '../../../components/Utils';
// import Globals from '../../config/Globals';
//
//import config from '../../config/config';
import '../../../assets/stylesheets/CommonContactModal.less';
//
const { TextArea } = Input;
//props handleSubmissionComplete, handleCancel
class CommonContactModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false };
  }
  async loadAuthorizedInfo() {
    const user = await this.props.app.idm.session.data.getUserObject();
    if (user) {
      setTimeout(() => {
        if (this.form) {
          this.form.setFieldsValue({
            name: user.firstName + '' + user.lastName,
            email: user.email,
          });
        }
      }, 250);
    }
  }
  //Actions
  handleCancel() {
    this.props.handleCancel();
  }
  async handleSubmit() {
    const resp = await this.form.validateFields();
    if (resp) this._submitContactForm(resp);
  }
  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        closable={false}
        title="Contact Us"
        footer={null}
        className="contactUsModal"
        open={this.props.isVisible}
        confirmLoading={this.state.isLoading}
      >
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* subcomponents */
  _renderForm() {
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };

    return (
      <Form {...layout} key="contactUs" className="contactUsForm" {...Utils.propagateRef(this, 'form')}>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item
              label="Full name"
              name="name"
              rules={[{ required: true, message: 'Please, type your full name!' }]}
            >
              <Input id="name" name="name" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item
              label="Contact Email"
              name="email"
              rules={[{ required: true, message: 'Please, type your email!' }]}
            >
              <Input id="email" name="email" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Form.Item
              label="Contact Text"
              name="comments"
              rules={[{ required: true, message: 'Please, type your contact text' }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          style={{ border: 0 }}
          key="submit"
          type="primary"
          loading={this.state.isLoading}
          onClick={this.handleSubmit}
          disabled={this.state.isLoading}
          className="contactUsSubmitButton"
        >
          {' '}
          Submit{' '}
        </Button>
      </Row>
    );
  }
  /* private methods */
  async _submitContactForm(data) {
    this.setState({ isLoading: true });
    const contactResp = await this.props.app.api.tenant.sendContactForm({
      name: data.name,
      email: data.email,
      comments: data.comments,
    });
    if (!this._isMounted) return;
    console.debug('Contact form resp:', contactResp, data);
    if (contactResp.statusCode == 200) {
      this.setState({ isLoading: false });
      message.success('Contact form submitted with success!');
      this.form.resetFields();
      this.props.handleSubmissionComplete();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, contactResp);
    }
  }
}

export default CommonContactModal;
