import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonSearchBar from '../../commonComponents/CommonSearchBar';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminOrgLicensesReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const { currentPage, searchTerm, sortedInfo } = this._getInitialState();

    this.state = {
      isLoading: false,
      isDownloading: false,
      searchTerm,
      sortedInfo,
      preview: [],
      orgsTotal: 0,
      currentPage,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  handleSearch(term) {
    this.setState({ searchTerm: term }, this._fetchData);
  }

  handlePagination(currentPage) {
    this.setState({ currentPage }, this._fetchData);
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter }, this._fetchData);
  }

  async generateReportXLSX() {
    this.setState({ isDownloading: true });

    const { searchTerm } = this.state;
    const { sortOrder, sortField } = this._getSearchFilter();

    const resp = await this.props.app.api.organization.organizationLicensesReportFile(searchTerm, sortOrder, sortField);

    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, 'org-licenses-report', 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isDownloading: false });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Org Name',
        key: 'name.keyword',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Students',
        key: 'numMembers',
        dataIndex: 'numMembers',
        sorter: true,
      },
      {
        title: 'Licenses Owned',
        key: 'licensesOwned',
        dataIndex: 'licensesOwned',
      },
      {
        title: 'Licenses Used',
        key: 'licensesUsed',
        dataIndex: 'licensesUsed',
      },
      {
        title: 'Available',
        key: 'licensesAvailable',
        dataIndex: 'licensesAvailable',
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No items found' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
        total: this.state.orgsTotal,
        onChange: this.handlePagination,
        current: this.state.currentPage,
        showSizeChanger: false,
      },
    };

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader className="pageHeader" title="Org Licenses Report" />

        <Layout.Content>
          <CommonSearchBar
            alwaysEnabled
            defaultValue={this.state.searchTerm}
            handleSearch={this.handleSearch}
            disabled={this.state.isLoading}
          />
          <Button
            type="primary"
            style={{ marginBottom: 10 }}
            icon={<DownloadOutlined />}
            onClick={this.generateReportXLSX}
            disabled={this.state.preview.length < 1 || this.state.isDownloading}
            loading={this.state.isDownloading}
          >
            Export to xlsx
          </Button>

          <Table columns={columns} dataSource={this.state.preview} scroll={{ x: true }} sticky {...props} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _fetchData() {
    this.setState({ isLoading: true });

    const { from, sortOrder, sortField } = this._getSearchFilter();

    const resp = await this.props.app.api.organization.organizationLicensesReportPreview(
      this.state.searchTerm,
      from,
      sortOrder,
      sortField
    );

    if (resp.statusCode == 200 && resp.body) {
      const { orgs, total } = resp.body;
      this.setState({ preview: orgs, orgsTotal: total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }

    this._reloadURLParams();
  }

  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(
      Globals.URL_Path_SortField,
      this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null
    );
    this.props.app.urlManager.updateQueryStringParam(
      Globals.URL_Path_SortOrder,
      this.state.sortedInfo ? this.state.sortedInfo.order : null
    );
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_SearchTerm, this.state.searchTerm);
  }

  _getSearchFilter() {
    const from = Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1);
    const sortField =
      this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey
        ? this.state.sortedInfo.columnKey
        : null;
    const sortOrder = this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null;
    return { from, sortField, sortOrder: sortOrder == 'descend' ? 'desc' : 'asc' };
  }

  _getInitialState() {
    const currentPage = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Page) || 1;
    const searchTerm = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_SearchTerm) || '';
    const sortField = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_SortField) || null;
    const sortOrder = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_SortOrder) || null;

    const sortedInfo = {};
    if (sortField) sortedInfo.columnKey = sortField;
    if (sortOrder) {
      sortedInfo.order = sortOrder == 'desc' ? 'descend' : 'ascend';
    }

    return { currentPage, searchTerm, sortField, sortOrder };
  }
}
