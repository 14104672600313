import React from 'react';
import autoBind from 'react-autobind';
import { message, Layout, PageHeader, Select, Row, Col, Button, Switch, Upload, Tag } from 'antd';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CustomComponent from '../../components/CustomComponent';
import { GrDocumentCsv } from 'react-icons/gr';
//
import Papa from 'papaparse';
//
import CommonEmailCertificatesTable from '../commonComponents/CommonEmailCertificatesTable';
//
export default class AdminEmailCertificates extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      courseID: this.props.app.sharedCache().getAllUniqueCourses()[0].id,
      ccAdmins: false,
      file: null,
      parsedCSV: null,
      errs: [],
      isLoading: false,
    };
  }
  //Actions
  handleCCAdmins() {
    this.setState({ ccAdmins: !this.state.ccAdmins });
  }
  handleSelectCourse(courseID) {
    this.setState({ courseID });
  }
  handleUpload({ file }) {
    Papa.parse(file, {
      header: true,
      transformHeader: this.handleTransformHeader,
      complete: this.handleFileValidation,
    });
    this.setState({ file });
  }
  handleTransformHeader(header) {
    if (header.toLowerCase() == 'lrnworkmail') return 'lrnmail';
    else return header.toLowerCase();
  }
  handleFileValidation(parsedCSV) {
    const errs = [];
    //Validate columns by name
    if (!parsedCSV.meta?.fields?.some((header) => header === 'certno')) errs.push("Missing column 'certno'");
    if (!parsedCSV.meta?.fields?.some((header) => header === 'completiondate'))
      errs.push("Missing column 'completiondate'");
    if (!parsedCSV.meta?.fields?.some((header) => header === 'lrnname')) errs.push("Missing column 'lrnname'");
    if (!parsedCSV.meta?.fields?.some((header) => header === 'lrnmail'))
      errs.push("Missing column 'lrnmail' or 'lrnworkmail'");

    parsedCSV.data = parsedCSV.data.filter((row) => {
      return !Object.values(row).every((value) => value.trim() === '');
    });

    //TODO: validate dates and emails
    this.setState({ parsedCSV, errs });
  }
  async handleEmailCertificates() {
    this.startLoading();
    const { parsedCSV, ccAdmins, courseID } = this.state;
    //Sanitize keys
    const recipients = parsedCSV.data.map((r) => {
      for (const key in r) {
        if (key == 'certno') r.certNum = r[key];
        if (key == 'completiondate') r.completionDate = r[key];
        if (key == 'lrnname') r.name = r[key];
        if (key == 'lrnmail') r.emailAddress = r[key];
        delete r[key];
      }
      return r;
    });
    //
    const emailCertsObj = {
      ccAdmins,
      certSpecsID: this.props.app.sharedCache().getCourseByID(courseID).certificationID,
      courseID,
      recipients,
    };
    console.log(emailCertsObj);
    const resp = await this.props.app.api.tenant.emailCertificates(emailCertsObj);
    if (resp.statusCode == 200) {
      message.success('Mail certificates request created successfully');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState(this.getInitialState());
    this.stopLoading();
  }

  render() {
    const courses = this.props.app.sharedCache().getAllUniqueCourses();

    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Email Certificates" />

          <Layout.Content>
            <div className="usersFilters">
              <Row type="flex">
                <Col className="filterItem">
                  <strong>Course:</strong>
                  <Select
                    style={{ minWidth: 200 }}
                    value={this.state.courseID}
                    size="large"
                    onChange={this.handleSelectCourse}
                  >
                    {courses.map((course) => (
                      <Select.Option key={course.id} value={course.id}>
                        {course.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col className="filterItem">
                  <strong>Send Copies to Admins:</strong>
                  <Switch checked={this.state.ccAdmins} onChange={this.handleCCAdmins} />
                </Col>
                <Col className="filterItem">
                  <Button
                    type="primary"
                    size="large"
                    onClick={this.handleEmailCertificates}
                    disabled={!(this.state.parsedCSV && this.state.errs.length == 0)}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 24 }}>
                <Col className="filterItem">
                  <Upload.Dragger
                    multiple={false}
                    maxCount={1}
                    beforeUpload={() => false}
                    showUploadList={false}
                    fileList={this.state.file ? [this.state.file] : []}
                    onChange={this.handleUpload}
                    disabled={this.state.isLoading}
                    style={{ opacity: this.state.isLoading ? 0.5 : 1 }}
                  >
                    <p className="ant-upload-drag-icon">
                      <GrDocumentCsv size={40} color={'#106D37'} />
                    </p>
                    {this.state.file && <p className="ant-upload-text">{this.state.file?.name}</p>}
                    {!this.state.file && <p className="ant-upload-text">Click or drag the csv to this area</p>}
                    <p className="ant-upload-hint">
                      {this.state.file ? (
                        <>
                          Click or drag on this area to replace the selected file.
                          <br />
                        </>
                      ) : (
                        <>File must be on csv format and the standard required import columns.</>
                      )}
                    </p>
                  </Upload.Dragger>
                </Col>
              </Row>
              {this.state.parsedCSV && this.state.errs.length > 0 && (
                <Row type="flex" style={{ marginTop: 24 }}>
                  <div>
                    <h1>Errors were found on the CSV uploaded:</h1>
                    <ul>
                      {this.state.errs.map((item) => (
                        <li key={item} style={{ marginTop: 10 }}>
                          <Tag color="red">{item}</Tag>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Row>
              )}
              {this.state.parsedCSV && this.state.errs.length == 0 && (
                <Row type="flex" style={{ marginTop: 24 }}>
                  <CommonEmailCertificatesTable
                    emailCertificates={this.state.parsedCSV.data || []}
                    isLoading={this.state.isLoading}
                  />
                </Row>
              )}
            </div>
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }
}
