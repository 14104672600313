import React from 'react';
import autoBind from 'react-autobind';
import { Button, Modal, Divider, Row, message } from 'antd';
//
import Utils from '../../../components/Utils';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
import CommonOrganizationMemberProfileForm from '../Forms/CommonOrganizationMemberProfileForm';
//
import '../../../assets/stylesheets/OrganizationMemberProfileModal.less';
//
const RequestMask = { ORG: 2, MEMBER: 4, ALL: 2 | 4 };
//
export default class OrganizationMemberProfileModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      orgID: null,
      userID: null,
      orgData: null,
      userData: null,
      isVisible: false,
    };
  }
  //Public
  show(orgID, userID) {
    if (this.state.isVisible) return; //precaution
    this.setState({ isVisible: true, orgID, userID, ordData: null, userData: null }, () => {
      this._fetchData();
    });
  }
  //Actions
  handleCancel() {
    this.setState({ isVisible: false });
  }
  async handleSave() {
    const data = await this.form.validateFields();
    if (!data) return;
    if (data.email) delete data.email;
    this._saveOrganizationMemberMetadata(data);
  }
  handleAfterClose() {
    this.setState({ isVisible: false, isLoading: false, orgID: null, userID: null, ordData: null, userData: null });
  }
  //UI
  render() {
    const title = this.state.isLoading
      ? 'Organization Student Settings'
      : `${this.state.orgData?.name} Student Settings`;
    return (
      <Modal
        maskClosable={false}
        title={title}
        afterClose={this.handleAfterClose}
        open={this.state.isVisible}
        closable
        footer={null}
        className="organizationMemberProfileModal"
      >
        <CommonLoadingView isLoading={this.state.isLoading} />
        {this.state.userData && (
          <CommonOrganizationMemberProfileForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
        )}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* private UI */
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ border: 0, marginLeft: 20 }} type="primary" onClick={this.handleSave}>
          {' '}
          Save{' '}
        </Button>
      </Row>
    );
  }

  /* private API */
  async _fetchData() {
    this.startLoading();
    const data = await this._loadData(RequestMask.ALL);
    if (data) {
      this.setState({ ...data, isLoading: false }, () => {
        if (this.form && data.userData) this.form.setFields(data.userData);
      });
    } else this.setState({ isLoading: false, isVisible: false });
  }

  /* API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.ORG) === RequestMask.ORG
        ? this.props.app.organization.organizationApp.getOrganizationApp(this.state.orgID)
        : null,
      (mask & RequestMask.MEMBER) === RequestMask.MEMBER
        ? this.props.app.organization.organizationAppMember.getOrganizationAppMember(
            this.state.orgID,
            this.state.userID
          )
        : null,
    ]);
    if (
      (mask & RequestMask.ORG) === RequestMask.ORG &&
      !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.orgID)
    ) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if (
      (mask & RequestMask.MEMBER) === RequestMask.MEMBER &&
      !(resp[1].statusCode == 200 && resp[1].body && resp[1].body.userID)
    ) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[1]);
      return false;
    }
    return {
      ...((mask & RequestMask.ORG) === RequestMask.ORG ? { orgData: resp[0].body } : {}),
      ...((mask & RequestMask.MEMBER) === RequestMask.MEMBER ? { userData: resp[1].body } : {}),
    };
  }
  async _saveOrganizationMemberMetadata(metadata) {
    this.startLoading();
    const resp = await this.props.app.organization.organizationAppMember.updateOrganizationAppMember(
      this.state.orgData.orgID,
      this.state.userData.userID,
      {
        metadata: metadata || {},
      }
    );
    if (resp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    } else {
      message.success('Member updated with success!');
      this.setState({ isVisible: false, isLoading: false });
    }
  }
}
