import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Form, Select, Menu, Button, Dropdown } from 'antd';
import moment from 'moment';

import Utils from '../../../components/Utils';

export default class AdminAppStatsFilters extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const { currentYear, currentMonth, currentQuarter, availableMonths, availableQuarters, availableYears } =
      Utils.getReportDateRange();

    const { certifications } = this.props.app.sharedCache().getTenantConfig();
    certifications.sort((a, b) => a.description.localeCompare(b.description));

    this.state = {
      // Data
      certifications,
      // Selected filters
      selectedProgram: certifications[0]?.id ?? null,
      selectedYear: currentYear,
      selectedPeriod: 'lastQuarter',
      // Filter options
      currentMonth,
      currentYear,
      currentQuarter,
      availableMonths,
      availableQuarters,
      availableYears,
    };
  }

  handleChangePeriod({ key: selectedPeriod }) {
    this.setState({ selectedPeriod });
  }

  handleChangeYear(selectedYear) {
    const { currentYear, currentMonth, currentQuarter, availableMonths, availableQuarters, availableYears } =
      selectedYear == new Date().getFullYear() ? Utils.getReportDateRange() : Utils.getReportDateRange(selectedYear);
    this.setState({
      selectedYear: currentYear,
      selectedPeriod: 'lastQuarter',
      // Filter options
      currentMonth,
      currentYear,
      currentQuarter,
      availableMonths,
      availableQuarters,
      availableYears,
    });
  }

  handleFilter() {
    const { selectedPeriod, selectedYear, selectedProgram } = this.state;

    let filterMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    if (selectedPeriod === 'lastQuarter') {
      filterMonths = Utils.getQuarterMonths(this._getLastQuarter());
    }

    if (selectedPeriod.startsWith('quarter-')) {
      const [, quarter] = selectedPeriod.split('quarter-');
      filterMonths = Utils.getQuarterMonths(Number(quarter));
    }

    if (selectedPeriod === 'lastMonth') {
      filterMonths = [this._getLastMonth() < 10 ? `0${this._getLastMonth()}` : this._getLastMonth()];
    }

    if (selectedPeriod.includes('month-')) {
      const [, month] = selectedPeriod.split('month-');
      filterMonths = [month];
    }

    const firstMonth = filterMonths.at(0);
    const lastMonth = filterMonths.at(-1) || firstMonth;

    const startDate = moment(`${selectedYear}-${firstMonth}-01`).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(`${selectedYear}-${lastMonth}-01`).endOf('month').format('YYYY-MM-DD');

    this.props.onFilter({
      programID: selectedProgram,
      fromDate: startDate,
      toDate: endDate,
    });
  }

  render() {
    return (
      <>
        <Form layout="vertical" colon={false}>
          <Row gutter={24} justify="end">
            <Col span={4}>
              <Form.Item label="Program">
                <Select
                  value={this.state.selectedProgram}
                  onChange={(selectedProgram) => this.setState({ selectedProgram })}
                  placeholder="Select..."
                >
                  {this.state.certifications.map((certification) => (
                    <Select.Option key={certification.id} value={certification.id}>
                      {certification.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item label="Year">
                <Select value={this.state.selectedYear} onChange={this.handleChangeYear}>
                  {this.state.availableYears.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item label="Period">{this._renderPeriodDropdown()}</Form.Item>
            </Col>

            <Col>
              <Form.Item label=" ">
                <Button type="primary" onClick={this.handleFilter} disabled={!this.state.selectedProgram}>
                  Apply
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  // Private
  _renderPeriodDropdown() {
    const { availableMonths, availableQuarters } = this.state;

    const menu = (
      <Menu placement="bottomCenter" onClick={this.handleChangePeriod}>
        <Menu.Item key="fullYear">Full year</Menu.Item>
        <Menu.Item key="lastQuarter">Last quarter</Menu.Item>
        <Menu.Item key="lastMonth">Last month</Menu.Item>
        <Menu.SubMenu key="byMonth" title="By month">
          {availableMonths.map((month) => (
            <Menu.Item key={`month-${month}`}>{Utils.getMonthName(month)}</Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.SubMenu key="byQuarter" title="By quarter">
          {availableQuarters.map((quarter) => (
            <Menu.Item key={`quarter-${quarter}`}>Q{quarter}</Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button>{this._getPeriodTitle()}</Button>
      </Dropdown>
    );
  }

  _getPeriodTitle() {
    const { selectedPeriod, selectedYear } = this.state;

    if (selectedPeriod === 'fullYear') {
      return 'Full year';
    }

    if (selectedPeriod === 'lastQuarter') {
      return `Last quarter (Q${this._getLastQuarter()} of ${selectedYear})`;
    }

    if (selectedPeriod === 'lastMonth') {
      return `Last month (${Utils.getMonthDescription(this._getLastMonth())}) of ${selectedYear}`;
    }

    if (selectedPeriod.startsWith('quarter-')) {
      const [, quarter] = selectedPeriod.split('quarter-');
      return `Q${quarter} of ${selectedYear}`;
    }

    if (selectedPeriod.startsWith('month-')) {
      const [, month] = selectedPeriod.split('month-');
      return `${Utils.getMonthDescription(month)} of ${selectedYear}`;
    }

    return 'Period';
  }

  _getLastQuarter() {
    if (this.state.selectedYear == new Date().getFullYear()) return this.state.currentQuarter - 1;
    else return this.state.currentQuarter;
  }

  _getLastMonth() {
    if (this.state.selectedYear == new Date().getFullYear()) return this.state.currentMonth - 1;
    else return this.state.currentMonth;
  }
}
