import React from 'react';
import autoBind from 'react-autobind';
import { Table, Tag, Row, Button, Tooltip, message, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
//
import Utils from '../../../../components/Utils';
import Globals from '../../../../config/Globals';
import config from '@/config/config';
//
import CourseTabWrapper from './CourseTabWrapper';

//props are: app, isVisible, coursesIDs, certificationProcess, user
export default class CourseTabPaymentsTab extends CourseTabWrapper {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { consumptions: [], firstLoad: true, isLoading: false, downloadingReceipts: {}, sessionID: [] };
  }

  //Life cycle
  componentDidMount() {
    if (this.state.firstLoad) this._fetch();
  }

  //Actions
  async handleDownload(props) {
    this._downloadReceipt(props);
  }

  _handleOpenSession = (sessionID) => {
    const { app } = this.props;

    this.setState({ sessionID: [sessionID] }, () => {
      app.urlManager.openExternalPageWithParams(config.ApplicationRoutes.session, null, this.state.sessionID);
    });
  };

  //UI
  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return <></>;
    const hasActions = !!this.state.consumptions?.find((c) =>
      Object.values(Globals.LicenseOrder_ReceiptEnabled_Providers).includes(c?.order?.providerID)
    );
    //
    const columns = [
      {
        title: 'Activation Date',
        dataIndex: ['consumption', '0', 'createdOn'],
        render: (date) => Utils.getDateAndTimeOnUIFormatByTimestamp(date),
      },
      {
        title: 'Order Type',
        dataIndex: ['order', 'type'],
        render: (type) => (
          <Tag icon={Globals.getIconByOrderType(type)} color={Globals.getColorByOrderType(type)}>
            {type || ''}
          </Tag>
        ),
      },
      {
        title: 'Order Amount ($)',
        dataIndex: ['order', 'valueTotal'],
        align: 'center',
        render: (value) => Utils.toCurrencyFormat(value),
      },
      {
        title: 'Order Status',
        dataIndex: ['order', 'status'],
        render: (status) => <Tag color={Globals.getColorByOrderState(status)}>{status}</Tag>,
      },
      ...(hasActions
        ? [
            {
              title: 'Actions',
              width: 'auto',
              key: 'Actions',
              width: '15%',
              render: (props) => {
                return (
                  <span className="tableButtonContainer">
                    <Tooltip placement="bottomLeft" title="Download Receipt">
                      <Button
                        variant="none"
                        loading={this.state.downloadingReceipts[props.license.id]}
                        icon={<DownloadOutlined />}
                        shape="circle"
                        onClick={this.handleDownload.bind(this, props)}
                      />
                    </Tooltip>
                  </span>
                );
              },
            },
          ]
        : []),
    ];

    return super.render(
      <Table
        rowKey={(props) => props.license.id}
        columns={columns}
        loading={this.state.isLoading}
        dataSource={this.state.consumptions}
        expandable={{ expandedRowRender: this._renderExpandedRow }}
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
        scroll={{ x: true }}
      />
    );
  }

  // Private UI
  _renderExpandedRow({ order, key, consumption, license, user }) {
    return (
      <div className="data-card">
        <h2>Details</h2>
        <Row type="flex" justify="space-between">
          <Col className="data-item">
            <small>License Status</small>
            <div className="value">
              <span>
                <Tag color={Globals.getColorByLicenseStatus(license.status)}>{license.status}</Tag>
              </span>
            </div>
          </Col>
          <Col className="data-item">
            <small>Order Status</small>
            <div className="value">
              {' '}
              <span>
                <Tag color={Globals.getColorByOrderState(order.status)}>{order.status}</Tag>
              </span>{' '}
            </div>
          </Col>
          <Col className="data-item">
            <small>Order Type</small>
            <div className="value">
              <span>
                <Tag icon={Globals.getIconByOrderType(order.type)} color={Globals.getColorByOrderType(order.type)}>
                  {order.type || ''}
                </Tag>
              </span>
            </div>
          </Col>
          <Col className="data-item">
            <small>Order Date</small>{' '}
            <div className="value">
              {' '}
              <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(license.createdOn)}</span>{' '}
            </div>
          </Col>
          <Col className="data-item">
            <small>Purchaser</small>{' '}
            <div className="value">
              {' '}
              <span>{order.name}</span>{' '}
            </div>
          </Col>
          <Col className="data-item">
            <small>License #</small>{' '}
            <div className="value">
              {' '}
              <span>{license.id}</span>{' '}
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col className="data-item">
            <small>License Key</small>{' '}
            <div className="value">
              {' '}
              <span>{key[0].id}</span>{' '}
            </div>
          </Col>
          <Col className="data-item">
            <small>Activation Date</small>{' '}
            <div className="value">
              {' '}
              <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(consumption[0].createdOn)}</span>{' '}
            </div>
          </Col>
          {consumption[0].expDate && consumption[0].expDate > 0 && (
            <Col className="data-item">
              <small>Expiration Date</small>
              <div className="value">
                <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(consumption[0].expDate)}</span>
              </div>
            </Col>
          )}
          <Col className="data-item">
            <small>Key Usage</small>{' '}
            <div className="value">
              {' '}
              <span>
                {key[0].numActivations} activations of {key[0].maxActivations}
              </span>{' '}
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col className="data-item">
            <small>Session ID</small>
            <div className="value">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this._handleOpenSession(consumption[0].sessionID);
                }}
              >
                {consumption[0].sessionID}
              </a>
            </div>
          </Col>
          {consumption[0].invalidatedOn && consumption[0].invalidatedOn > 0 && (
            <>
              <Col className="data-item">
                <small>License Invalidated On</small>
                <div className="value">
                  <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(consumption[0].invalidatedOn)}</span>
                </div>
              </Col>
              <Col className="data-item">
                <small>License Invalidation</small>
                <div className="value">
                  <span>{consumption[0].invalidatedBy == this.props.user?.id ? 'User' : 'Admin'}</span>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }

  //Private API
  async _fetch() {
    this.setState({ isLoading: true, firstLoad: false });
    //
    const ids =
      this.props.certificationProcess.courses
        ?.filter((c) => (this.props.coursesIDs || []).includes(c.courseID))
        ?.filter((c) => c.licenseID)
        .map((course) => course.licenseID)
        ?.filter((c) => c != 'FAKE-BY-IMPORT') ||
      []?.filter((c) => !!c) ||
      [];
    const resp =
      ids.length > 0 ? await this.props.app.license.licenseConsumption.getLicenseConsumptionsByLicenseID(ids) : null;
    if (!this._isMounted) return;

    if (ids.length === 0) {
      this.setState({ consumptions: [], isLoading: false, firstLoad: false });
    } else if (resp && resp.statusCode === 200) {
      this.setState({
        consumptions: resp.body.consumptions,
        isLoading: false,
        firstLoad: false,
        downloadingReceipts: {},
      });
    } else {
      console.debug('Error while fetching license consumption', resp);
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ consumptions: [], isLoading: false, firstLoad: false });
    }
  }

  _getSessionIDByCourseID(licenseID) {
    const course = this.props.certificationProcess.courses.find((c) => c.licenseID === licenseID);
    console.log('course------', this.props.certificationProcess.courses);
    return course ? course.sessionID : 'N/A';
  }

  async _downloadReceipt(props) {
    this.setState({ downloadingReceipts: { ...this.state.downloadingReceipts, [props.license.id]: true } });
    const resp = await this.props.app.license.order.getOrderReceipt(props.order.externalID, props.order.id);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${props.order.id}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.setState({ downloadingReceipts: { ...this.state.downloadingReceipts, [props.license.id]: false } });
  }
}
