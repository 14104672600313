import { identifyUserLog } from '../../logRocketSetup';
import React from 'react';
import autoBind from 'react-autobind';
import IDM from '@ikonintegration/idmclient';
import SMLicense from '@ikonintegration/mod-license-client';
import SMAuditing from '@ikonintegration/mod-auditing-client';
import SMOrganization from '@ikonintegration/mod-organization-client';
import SMConfig from '@ikonintegration/mod-config-client';
import SMClassroom from '@ikonintegration/mod-classroom-client';
import LMSAPI from '@ikonintegration/lms-api-client';
//
import Cache from './Cache';
import Utils from './Utils';

import ThemeManager from './ThemeManager';
import URLManager from './URLManager';
import PaymentManager from './PaymentManager';
import AlertController from './AlertController';
//
import config from '../config/config';
import packageJSON from '../../../package.json';
import Globals from '../config/Globals';

//
export default class Authenticator extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    console.debug(`BCCSA Program FE - version: ${packageJSON.version}@${process.env.COMMIT_HASH}`);
    //
    this.urlManager = new URLManager(this);
    this.themeManager = new ThemeManager(this);
    this.paymentManager = new PaymentManager(this);
    this.tenantID = this.themeManager.theme.config.tenantID;
    this._sharedCache = null; //will be set
    this.openContactModal = null;
    //IDM
    this.isAuthenticating = false;
    this.sessionWillLoadHandler = null;
    this.sessionDidLoadHandler = null;
    this.sessionDidFailLoadHandler = null;
    this.err = null;
    this.idm = new IDM(this.themeManager.theme.config.idmKey, this.themeManager.theme.config.idmSecret, {
      ...config.IDMClientOptions,
      autoEnrollRole: [this.themeManager.theme.config.userRole, this.themeManager.theme.config.portalUserRole],
      roles: {
        ...config.IDMClientOptions.roles,
        ADMIN: this.themeManager.theme.config.adminRole,
        USER: this.themeManager.theme.config.userRole,
      },
      externalAuthDomain: this.themeManager.theme.config.idmExternalAuthDomain,
      cookiesEndpoint: this.themeManager.theme.config.idmExternalCookiesDomain,
    });
    this.idm.sessionLoadedHandler = this.sessionLoadedHandler.bind(this);
    //Shared modules & APIs
    this.api = new LMSAPI({
      endpoint: config.ApplicationAPIEndpoint,
      statsEndpoint: config.APIStatsEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: this.tenantID,
    });
    this.auditing = new SMAuditing({
      endpoint: config.SMAuditingEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      namespace: this.tenantID,
    });
    this.license = new SMLicense({
      endpoint: config.SMLicenseEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: this.tenantID,
    });
    this.organization = new SMOrganization({
      endpoint: this.themeManager.theme.config.orgModEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: this.tenantID,
    });
    this.config = new SMConfig({
      endpoint: config.SMConfigEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: this.tenantID,
    });
    this.classroom = new SMClassroom({
      endpoint: config.SMClassroomEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: this.tenantID,
    });
  }
  async componentDidMount() {
    this.startLoading(true);
    if (!this.idm.isLogged()) await this.idm.load(null, { tenantID: this.tenantID }); // ask for revalidation if have session - will redirect
    if (!this.idm.isLogged()) {
      await this.redirectToAuth();
      this.endLoading(true); //if we kept logged out, stop loading
    }
  }
  // Shortcuts on application levels
  isAdmin() {
    return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('ADMIN') : false;
  }
  isSysAdmin() {
    return this.idm.session.authorization ? this.idm.session.authorization.hasClaim(Globals.SysAdminRole) : false;
  }
  isUser() {
    if (this.isOrgManager()) {
      return false;
    }
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim('USER') && !this.isAdmin() && !this.isSysAdmin()
      : false;
  }
  isOrgManager(orgID = null) {
    const userObj = this.sharedCache().getProgramUser();
    if (orgID) return userObj?.managerOf?.includes(orgID);
    return userObj?.managerOf?.length > 0;
  }
  isOrgAdmin() {
    const customer = this.sharedCache().getTenantConfig()?.customer;
    return this.idm.session.authorization && customer
      ? this.idm.session.authorization.hasClaim(customer.orgAdminRole)
      : false;
  }
  getAuthorizedUserID() {
    return this.idm.session.authorization.getUserID();
  }

  // Loading
  startLoading(reload) {
    this.isAuthenticating = true;
    if (reload) this.forceUpdate();
  }
  endLoading(reload) {
    this.isAuthenticating = false;
    if (reload) this.forceUpdate();
  }

  // Singleton
  sharedCache() {
    if (this._sharedCache == null) {
      this._sharedCache = new Cache(this);
    }
    return this._sharedCache;
  }

  // Authentication entrypoint
  async redirectToAuth() {
    const isRegistering = this.props.history.location.pathname == config.ApplicationRoutes.register;
    if (isRegistering)
      this.idm.registration.register(
        [this.themeManager.theme.config.userRole, this.themeManager.theme.config.portalUserRole],
        false,
        this.urlManager.getLoginPage(),
        { tenantID: this.tenantID, customError: this.err }
      );
    await this.idm.authenticator.login(
      null,
      null,
      [this.themeManager.theme.config.userRole, this.themeManager.theme.config.portalUserRole],
      window.location,
      { tenantID: this.tenantID, customError: this.err }
    );
  }
  // IDM delegate calls
  async sessionLoadedHandler() {
    //Todo prevent double session load!
    if (this.isLoadingSession) return;
    this.isLoadingSession = true;
    if (this.sessionWillLoadHandler) this.sessionWillLoadHandler();
    //session will be loaded by this.idm.load or by login call, both should be
    //loading while this is called, so we dont update state when starting loading for session load
    //reloading state will cause authorized area to be called while we haven't fully loaded the session
    this.startLoading(false);

    //Identify user to RUM
    this._identifyUser();
    //Load cache
    await this._loadCache();
    //recognize affiliate if any
    this._recognizeAffiliate();

    //End loading, unblock other session load
    this.isLoadingSession = false;
    this.endLoading(true);
  }

  //UI
  render() {
    return (
      <>
        <AlertController {...Utils.propagateRef(this, 'alertController')} />
        {this.idm.isLogged() && !this.isAuthenticating ? this.renderAuthorizedView() : this.renderUnauthorizedView()}
      </>
    );
  }

  async reloadPendingOrders() {
    await this.sharedCache().loadPendingOrders();
    this.forceUpdate();
  }

  async reloadProducts() {
    await this.sharedCache().loadProducts();
    this.forceUpdate();
  }

  async reloadPendingApplications() {
    await this.sharedCache().loadPendingApplications();
    this.forceUpdate();
  }

  async reloadCache(forceUserConsistency) {
    return await this._loadCache(forceUserConsistency);
  }

  async reloadPendingApprovals() {
    await this.sharedCache().loadPendingApprovals();
    this.forceUpdate();
  }

  /* private */
  async _loadCache(forceUserConsistency) {
    //Check if could load cache properly, if not we can't proceed :/
    //Since the user has already logged it worth trying 2/3 times so
    //we dont loose user retetion :p
    let retries = 0;
    const maxAttempts = 3;
    while (retries < maxAttempts) {
      if (await this.sharedCache().loadCache(forceUserConsistency)) break;
      await Utils.shortRandSleep();
      retries++;
    }
    //Inject customer ID into config API as it comes available at tenant load
    this.config.config.customerID = this.sharedCache().getCustomerID();
    //Check
    if (maxAttempts == retries) {
      /* FAILED - MAX ATTEMPTS */
      await this.idm.session.clearSession(true); //light logout
      this.sharedCache().clearCache();
      if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler();
    } else {
      //Check for server time
      const timeDiff = Math.abs(Date.now() - this.sharedCache().getTenantConfig().serverTime);
      console.debug('System time diff:', timeDiff);
      if (timeDiff > Globals.API_MaxEpochDiscrepancy) {
        this.err =
          "Connection with the server can't be established because there is a discrepancy between your time and the server time! Please, enable automatic system date synchronization.";
        if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler(this.err);
        await this.idm.session.clearSession(true); //light logout
        this.sharedCache().clearCache();
      } else if (this.sessionDidLoadHandler) this.sessionDidLoadHandler();
    }
  }
  _recognizeAffiliate() {
    this.affiliateID = this.idm.urlmanager.getParam(Globals.URL_Path_Affiliate_ID);
    if (this.affiliateID) {
      console.debug('Using affiliate with ID:', this.affiliateID);
      this.urlManager.updateQueryStringParam(Globals.URL_Path_Affiliate_ID, null);
    }
  }
  /* helpers */
  async _getIDMToken() {
    if (this.idm.session.authorization) {
      const token = await this.idm.session.getToken(true);
      return `Bearer ${token}`;
    }
    return null;
  }
  async _identifyUser() {
    if (process.env.REACT_APP_OFFLINE) return;

    const userObj = await this.idm.session.data.getUserObject();

    // Identify the user with LogRocket
    identifyUserLog({
      userID: this.idm?.session?.authorization?.getUserID(),
      userType: this.isAdmin() ? 'Admin' : 'User',
      tenantID: this.tenantID,
      firstName: userObj.firstName,
      lastName: userObj.lastName,
      email: userObj.email,
    });
  }
}
