import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLicenseOrdersTable from '../commonComponents/CommonLicenseOrdersTable';
//
// import config from '../../config/config';
// import Globals from '../../config/Globals';
//
export default class AdminPendingOrdersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      pendingOrders: [],
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const pendingOrders = this.props.app.sharedCache().getPendingOrders();
    this.setState({ pendingOrders });
  }

  async reloadCache() {
    this.setState({ isLoading: true });
    await this.props.app.reloadPendingOrders();
    const pendingOrders = this.props.app.sharedCache().getPendingOrders();
    this.setState({ pendingOrders, isLoading: false });
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <PageHeader className="pageHeader" title="Pending Orders" />
        <Layout.Content>
          <CommonLicenseOrdersTable
            isLoading={this.state.isLoading}
            orders={this.state.pendingOrders}
            app={this.props.app}
            purchaserVisible
            onApproval={this.reloadCache}
          />
        </Layout.Content>
      </Layout.Content>
    );
  }
}
