import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Form, Select, InputNumber, Col, Button } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
//
//props are: onUpdate, courses
export default class CommonCertificationExpirationRuleDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, rule: null, index: -1 };
  }
  //Public
  show(rule, index) {
    this.setState({ isVisible: true, rule, index: index != undefined ? index : -1 }, () => {
      if (rule) this.form.setFieldsValue({ ...rule });
    });
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isVisible: false, rule: null, index: -1 });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onUpdate(formData, this.state.index);
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.rule ? 'Edit' : 'Create'} Expiration Rule`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="forceRetakerequirementsIDs"
                label="Force Retake Requirement(s)"
                extra="List of requirements that will be reenvalidated if days rule matches."
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Select mode="multiple" style={{ minWidth: 300 }}>
                  {' '}
                  {(this.props.courses || []).map((opt) => (
                    <Select.Option key={opt.id} value={opt.id}>
                      {opt.name}
                    </Select.Option>
                  ))}{' '}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                name="untilDaysAfterExpiration"
                label="Until Days After Expiration"
                extra="Number of days after the expiration this rule applies for the renewal"
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={-1} />
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item
                name="priceOverride"
                label="Price Override"
                extra="The price override when this rules applies (optional)."
              >
                <InputNumber style={{ width: '100%' }} precision={2} min={-1} />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              {' '}
              <Button type="primary" onClick={this.handleSubmit}>
                Done
              </Button>{' '}
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}
