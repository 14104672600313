import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Form, Button, Input, Tag, Checkbox, message } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
//
import CommonStudentSearchTable from '../StudentSelection/CommonStudentSearchTable';
import CommonStudentReplaceConfirmation from '../StudentSelection/CommonStudentReplaceConfirmation';
import CommonLoadingView from '../CommonLoadingView';
//props are: app, session, onReplace
export default class CommonSessionReplaceStudentDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, selectedStudent: null, isLoading: false };
  }
  show(student) {
    this.setState({ isVisible: true, student });
  }
  handleClose() {
    this.setState({ isVisible: false, selectedStudent: null }, () => {
      this.props.onReplace();
    });
  }
  handleSelect(selectedStudent) {
    this.setState({ selectedStudent });
  }
  async handleSubmit() {
    this.startLoading();
    const replaceResp = await this.props.app.api.course.replace(
      this.state.student.userID,
      this.state.student.externalID.split('::')[0], //cp id
      this.state.student.externalID, //ex id
      this.props.session.id,
      this.state.selectedStudent.id
    );
    if (replaceResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', 'Student replaced successfully!');
      this.stopLoading();
      this.handleClose();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, replaceResp);
      this.stopLoading();
      this.handleClose();
    }
  }
  //UI
  render() {
    return (
      <Drawer
        placement="right"
        title="Replace Student in Session"
        width={800}
        onClose={this.handleClose}
        open={this.state.isVisible}
        bodyStyle={{ paddingBottom: 20 }}
        footer={this._renderDrawerFooter()}
      >
        <CommonLoadingView isLoading={this.state.isLoading} />
        {!this.state.selectedStudent && (
          <CommonStudentSearchTable
            {...Utils.propagateRef(this, 'StudentSearchTable')}
            app={this.props.app}
            onSelect={this.handleSelect}
            student={this.state.student}
          />
        )}
        {this.state.selectedStudent && (
          <CommonStudentReplaceConfirmation
            {...Utils.propagateRef(this, 'StudentReplaceConfirmation')}
            app={this.props.app}
            student={this.state.student}
            selectedStudent={this.state.selectedStudent}
            session={this.props.session}
          />
        )}
      </Drawer>
    );
  }
  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        {this.state.selectedStudent && (
          <Button onClick={this.handleSubmit} type="primary" style={{ marginRight: 8 }}>
            Confirm
          </Button>
        )}
      </div>
    );
  }
}
