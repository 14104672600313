import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import config from './app/config/config';

// LogRocket init
export const logRocketInit = () => {
  if (!process.env.REACT_APP_OFFLINE) {
    console.log('LogRocketOptions', config.LogRocketOptions);

    LogRocket.init(config.LogRocketOptions.applicationId);

    setupLogRocketReact(LogRocket);
  }
};

// Logger service message
export const logMessage = (message, extra) => {
  if (process.env.NODE_ENV === 'production' && !process.env.REACT_APP_OFFLINE) {
    LogRocket.captureMessage(message, {
      tags: { type: 'Error Message' },
      extra,
    });
  } else {
    console.log('[LogRocket Mock]', message, extra);
  }
};

// Logger service event
export const identifyUserLog = (params) => {
  const { userID, userType, tenantID, firstName, lastName, email } = params;

  if (process.env.NODE_ENV === 'production' && !process.env.REACT_APP_OFFLINE) {
    LogRocket.identify(userID, {
      userType,
      tenantID,
      firstName,
      lastName,
      email,
      application: 'BCCSA LMS',
    });
  } else {
    console.log('[LogRocket Identify Mock]', {
      userID,
      userType,
      tenantID,
      firstName,
      lastName,
      email,
      application: 'BCCSA LMS',
    });
  }
};
