import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Alert, InputNumber, Button, Tooltip, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
//
import { PAYMENT_TYPE } from './CommonLicensePurchaseModalStep_PaymentView_Federated';
import Utils from '../../../../components/Utils';
//
const styleHidden = { style: { display: 'none' } };
//props are: app, user, onCancel, onNext, product, payload, selectedSession
//chargingAmount, quantity, taxAmount, totalAmount, productObject, autoRedeem
export default class CommonLicensePurchaseModalStep_SummaryView_Federated extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      firstName: '',
      lastName: '',
      purchaserName: 'loading...',
      purchaseMethod: '',
      purchaseMethodSuffix: 'loading...',
      paymentMethod: PAYMENT_TYPE.CARD,
    };
  }
  setSensitiveInfo(data) {
    const safe = data.ccNumber + '';
    this.setState(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        paymentMethod: data.paymentMethod,
        purchaserName: `${data.firstName} ${data.lastName}`,
        purchaseMethod: data.paymentMethod == PAYMENT_TYPE.CARD ? '(Credit Card)' : 'Invoice',
        purchaseMethodSuffix:
          data.paymentMethod == PAYMENT_TYPE.CARD ? `XXXX-XXXX-XXXX-${safe.substr(safe.length - 4, 4)}` : null,
      },
      () => {
        if (data.paymentMethod == PAYMENT_TYPE.CARD) {
          this.form.setFieldsValue({
            'billing-first-name': data.firstName,
            'billing-last-name': data.lastName,
            'billing-email': this.props.user.email,
            'billing-cc-exp': Utils.getCardExpirationFromMoment(data.expiration),
            'billing-cc-number': data.ccNumber,
            cvv: data.cvc,
          });
        }
      }
    );
  }
  //Validation
  async validateFields() {
    return this.form.validateFields();
  }
  //Actions
  handleSubmit() {
    this.props.onNext(this.state);
    //If is not invoice, submit the secure form
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) document.getElementById('summary').submit();
  }
  async handlePreviousAction() {
    const resp = await this.form.validateFields();
    if (resp) this.props.onPrevious({ ...resp, ...this.state });
  }
  //UI
  render() {
    const discount = this.props.discountAmount > 0 ? this.props.discountAmount * this.props.quantity * -1 : 0;
    return (
      <Form {...Utils.propagateRef(this, 'form')} action={this.props.payload?.gatewayUrl} key="summary" id="summary">
        {
          /* show expiration alert */
          this.props.autoRedeem && this.props.selectedSession && this.props.selectedSession.onlineAccessPeriod > 0 && (
            <Alert
              style={{ marginTop: 10 }}
              type="warning"
              showIcon
              message={`Once you complete this operation, you will have ${Utils.getIntervalString(this.props.selectedSession.onlineAccessPeriod)} to complete ${this.props.productObject.description}.
                           Your license will expire on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(Utils.timestampAfterMinutesAtEOD(this.props.selectedSession.onlineAccessPeriod))}.`}
            />
          )
        }
        <Divider orientation="left">Order Summary</Divider>
        <Row type="flex" justify="start">
          <Typography.Text strong>Please, confirm the order information carefully:</Typography.Text>
        </Row>
        <Divider orientation="left" className="subdividers">
          Order
        </Divider>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 8}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Product </Typography.Text>
            </div>
            <Form.Item>
              <Input value={this.props.product.name} disabled className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Price </Typography.Text>
            </div>
            <Form.Item>
              <Tooltip title={'The original price of the product.'} placement="topLeft">
                <InputNumber
                  name="amount"
                  value={this.props.chargingAmount}
                  decimalSeparator="."
                  precision={2}
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 2}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 2}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Quantity </Typography.Text>
            </div>
            <Form.Item name="quantity">
              <Tooltip title={'The number of licenses (activations) you are purchasing.'} placement="topLeft">
                <InputNumber
                  precision={0}
                  step={1}
                  min={0}
                  value={this.props.quantity}
                  disabled
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 6}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Subtotal </Typography.Text>
            </div>
            <Form.Item>
              <InputNumber
                disabled
                precision={2}
                decimalSeparator="."
                value={this.props.chargingAmount * this.props.quantity}
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <Tooltip title={'Discounts can be applied by using cupom codes.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  value={discount}
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                value={this.props.taxAmount}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={this.props.totalAmount}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" className="subdividers">
          Billing
        </Divider>
        <Row type="flex" justify="start">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text>
              Payment method: {this.state.purchaseMethod} {this.state.purchaseMethodSuffix}
            </Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text>Billing name: {this.state.purchaserName}</Typography.Text>
          </Col>
        </Row>
        {this.state.paymentMethod == PAYMENT_TYPE.CARD && this._renderHiddenFormFields()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }
  _renderHiddenFormFields() {
    return (
      <div {...styleHidden}>
        <Form.Item name="billing-first-name">
          <Input data-dd-privacy="mask" id="billing-first-name" name="billing-first-name" />
        </Form.Item>
        <Form.Item name="billing-last-name">
          <Input data-dd-privacy="mask" id="billing-last-name" name="billing-last-name" />
        </Form.Item>
        <Form.Item name="billing-email">
          <Input data-dd-privacy="mask" id="billing-email" name="billing-email" />
        </Form.Item>
        <Form.Item name="billing-cc-exp">
          <Input data-dd-privacy="mask" id="billing-cc-exp" name="billing-cc-exp" />
        </Form.Item>
        <Form.Item name="billing-cc-number">
          <Input data-dd-privacy="mask" id="billing-cc-number" name="billing-cc-number" />
        </Form.Item>
        <Form.Item name="cvv">
          <Input data-dd-privacy="mask" id="cvv" name="cvv" />
        </Form.Item>
      </div>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button
          className="purchaseModalCancelButton"
          disabled={this.props.isLoading}
          key="back"
          onClick={this.props.onCancel}
        >
          {' '}
          Cancel{' '}
        </Button>
        <Button disabled={this.props.isLoading} onClick={this.handlePreviousAction}>
          {' '}
          Previous{' '}
        </Button>
        <Button
          form="summary"
          htmlType="submit"
          key="submit"
          type="primary"
          loading={this.props.isLoading}
          disabled={this.props.isLoading}
          onClick={this.handleSubmit}
          className="purchaseModalConfirmationButton"
        >
          {' '}
          Submit{' '}
        </Button>
      </Row>
    );
  }
}
