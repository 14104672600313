import React from 'react';
import autoBind from 'react-autobind';
import { message, Layout } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import UserRegistrationModal from '../commonComponents/Modals/UserRegistrationModal';
import PendingInvitationsModal from '../commonComponents/Modals/PendingInvitationsModal';
import CommonCertificationCollectionView from '../commonSubviews/CommonCertificationCollectionView';
import CommonCertificationViewUserHeader from '../commonSubviews/CommonCertificationView/CommonCertificationViewUserHeader';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import config from '../../config/config';
import DataUtils from '../../components/UtilsCertification';
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/CommonDashboardStyles.less';
//
export default class UserDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      firstLoad: true,
      isMonoCertification: props.app.sharedCache().isMonoCertification(),
      certifications: [],
      selectingCertSpecsID: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    this.setState({ isLoading: true });
    //Try to get current logged user, if does not exists, handle enrollment
    const programUserExists = !!this.props.app.sharedCache().getProgramUser();
    const showRegModal = this.registrationModal ? this.registrationModal.needsToAppear() : false;
    if (!programUserExists && showRegModal) {
      this.setState({ isLoading: false });
      this.registrationModal.handleShow();
    } else {
      //Check if should make silent registration behind the scenes
      if (!programUserExists) {
        await this.registrationModal.createuser({}); //create user
        await this.props.app.reloadCache(true); //reload user object
        this.handleRegistrationCompletion();
      } else {
        await this.fetchData();
        console.log('completed fetchdata');
        this._autoStartCertification();
      }
    }
  }

  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return; //Important, check if is mounted
    const user = this.props.app.sharedCache().getProgramUser(); //get current logged user
    const userCertsResp = await this.props.app.api.certification.getByUserID(user.id);
    this.loadResponse(userCertsResp);
  }

  handleUpdate = async () => {
    this.fetchData();
  };

  async loadResponse(userCertsResp) {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    if (!this._isMounted) return; //check if is mounter before processing response and setting state!

    //Mount state
    let certifications = [];
    if (userCertsResp.statusCode == 200 && userCertsResp.body && userCertsResp.body.procs) {
      //valid data state
      certifications = DataUtils.processUserCertifications(userCertsResp.body.procs, tenantConfig.certifications);
      //Auto Select certification
      if (this.state.selectingCertSpecsID) {
        const cert = certifications.filter((item) => item.certificationID == this.state.selectingCertSpecsID);
        if (cert) {
          const paramInvitationCode = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_InvitationCode);
          const paramSessionID = this.props.app.urlManager.getQueryParam(Globals.URL_Path_LicensePurchase_SessionID);
          const queryParamsObj = {};
          if (paramInvitationCode) queryParamsObj.invitationCode = paramInvitationCode;
          if (paramSessionID) queryParamsObj.sessionID = paramSessionID;
          this.props.app.urlManager.replacePage(
            config.ApplicationRoutes.newUserCertification,
            queryParamsObj,
            cert[0].id
          );
          return; //avoid further processing
        }
      }
      //Mono certification mode: Check for auto init or autoselect when number of certifications specs on the system is 1
      if (this.state.isMonoCertification) {
        //Auto select if exists, or create a new one and select if doesn't exists
        if (userCertsResp.body.procs.length > 0) {
          this.props.app.urlManager.pushPage(config.ApplicationRoutes.newUserCertification, {}, certifications[0].id);
          return; //avoid further processing
        } else {
          this.handleStartCertification(tenantConfig.certifications[0].id);
          return;
        }
      }
    } else {
      //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, userCertsResp);
    }
    //should be available locally but we add on state j8ust in case
    this.stopLoading(false);
    this.setState({ certifications: certifications, firstLoad: false, selectingCertSpecsID: null });
  }
  //Actions
  async handleRegistrationCompletion() {
    const userObj = await this.props.app.idm.session.data.getUserObject();
    this.pendingInvitationsModal.attemptToShow(userObj.email, () => {
      this._autoStartCertification();
    });
  }
  handleInvitationsCompletion() {
    this.fetchData();
  }
  async handleStartCertification(certSpecsID) {
    this.startLoading();
    const user = this.props.app.sharedCache().getProgramUser(); //get current logged user
    //Start cert
    const resp = await this.props.app.api.certification.createByUserIDAndCertSpecsID(user.id, certSpecsID);
    if (!this._isMounted) return;
    //Check answer
    const certSpecs = this.props.app.sharedCache().getCertificationByID(certSpecsID);
    if (resp.statusCode == 200 && resp.body) {
      if (!this.state.isMonoCertification) message.success(`${Utils.camelizeString(certSpecs.uiTerm)} initiated.`);
      //Flag cert id to be selected
      this.setState({ selectingCertSpecsID: certSpecsID }, this.fetchData);
    } else {
      if (resp.body?.err?.toLowerCase().includes('process already exists')) {
        this.setState({ selectingCertSpecsID: certSpecsID }, this.fetchData);
        return;
      }
      //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _autoStartCertification() {
    console.log('inside _autoStartCertification');
    const paramCertID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_CertID);
    console.log(`paramCertID is `, paramCertID);
    const paramInvitationCode = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_InvitationCode);
    console.log(`paramInvitationCode is `, paramInvitationCode);
    const paramSessionID = this.props.app.urlManager.getQueryParam(Globals.URL_Path_LicensePurchase_SessionID);
    console.log(`paramSessionID is `, paramSessionID);

    if (paramCertID) {
      let tenantConfig = this.props.app.sharedCache().getTenantConfig();
      let certifications = DataUtils.processUserCertifications(paramCertID, tenantConfig.certifications);
      console.log(`certifications is `, certifications);
      const cert = certifications.filter((item) => item.id == paramCertID);
      console.log(`cert is `, cert);

      if (cert.length > 0) {
        const certSpecs = this.props.app.sharedCache().getCertificationByID(paramCertID);
        console.log(`certSpecs is ${certSpecs}`);
        this.handleStartCertification(certSpecs.id);
      }
    }
    if (paramInvitationCode) {
      let sessionInvitation =
        await this.props.app.classroom.sessionInvitation.validateSessionInvitation(paramInvitationCode);
      if (sessionInvitation.statusCode == 200 && sessionInvitation.body && sessionInvitation.body.invitation)
        sessionInvitation = sessionInvitation.body.invitation;
      if (sessionInvitation.email != this.props.app.sharedCache().getProgramUser().email) return;
      let tenantConfig = this.props.app.sharedCache().getTenantConfig();
      const certID = tenantConfig.certifications.find((c) => {
        if (c.requirements) return c.requirements.find((reqs) => reqs.find((r) => r.id == sessionInvitation.courseID));
        else return false;
      }).id;
      this.handleStartCertification(certID);
    }
    if (paramSessionID) {
      const sessionResp = await this.props.app.classroom.session.getSession(paramSessionID, false);
      if (sessionResp.statusCode == 200 && sessionResp.body) {
        let sessionInvitation = {
          courseID: sessionResp.body.courseID,
          sessionID: paramSessionID,
        };
        let tenantConfig = this.props.app.sharedCache().getTenantConfig();
        const certID = tenantConfig.certifications.find((c) => {
          if (c.requirements)
            return c.requirements.find((reqs) => reqs.find((r) => r.id == sessionInvitation.courseID));
          else return false;
        }).id;
        this.handleStartCertification(certID);
      }
    }
  }
  //
  render() {
    const user = this.props.app.sharedCache().getProgramUser();
    return (
      <Layout.Content className="dashboard dashboardNoNav">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <div className="application-wrapper">
          {!this.state.isMonoCertification && (
            <CommonCertificationViewUserHeader app={this.props.app} user={user} showBackButton={false} />
          )}
        </div>
        {this.state.certifications.length > 0 && (
          <CommonCertificationCollectionView
            {...Utils.propagateRef(this, 'certificationCollectionView')}
            certifications={this.state.certifications}
            handleStartCertification={this.handleStartCertification}
            app={this.props.app}
          />
        )}
        <UserRegistrationModal
          app={this.props.app}
          {...Utils.propagateRef(this, 'registrationModal')}
          completionHandler={this.handleRegistrationCompletion}
        />
        <PendingInvitationsModal
          {...Utils.propagateRef(this, 'pendingInvitationsModal')}
          app={this.props.app}
          onComplete={this.handleInvitationsCompletion}
        />
      </Layout.Content>
    );
  }
}
