import React from 'react';
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, Popover, Button } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
//
export default class CommonDetailsLicensesForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { licenseOwner: '', assignedFrom: '' };
  }
  //Public
  resetFields() {
    this.form.resetFields();
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({ ...data });
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return formData;
  }
  //Fetch license owner when the component mounts
  async componentDidMount() {
    await this._fetchLicenseOwner();
    await this._fetchAssignedOwer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.licenses !== this.props.licenses) {
      this.setFieldsValue(this.props.licenses);
      this._fetchLicenseOwner();
      this._fetchAssignedOwer();
    }
  }

  //UI
  render() {
    const emails = Array.isArray(this.props.licenses?.order?.email)
      ? this.props.licenses.order.email.join(', ')
      : 'N/A';
    const isAssignment = this.props.licenses?.isAssignment;
    const completedOn = Utils.getDateAndTimeOnUIFormatByTimestamp(this.props.licenses?.order?.completedOn);
    const { licenseOwner } = this.state;
    const redirectToOwnerPage = this.props.app.urlManager.parseUrl(
      config.ApplicationRoutes.editOrg,
      null,
      this.props.licenses?.assignedFromOwnerID
    );
    const redirectToKey = this.props.app.urlManager.parseUrl(
      config.ApplicationRoutes.adminLicensesView,
      null,
      this.props.licenses?.origActivationKey
    );

    return (
      <Form {...Utils.propagateRef(this, 'form')}>
        <Row type="flex" justify="center">
          <Col span={12}>
            <Form.Item label="Key" name="id">
              {this.props.licenses?.id}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Key Status" name="state">
              {this.props.licenses?.state}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="License ID" name="licenseID">
              {this.props.licenses?.licenseID}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Product" name={['order', 'productName']}>
              {this.props.licenses?.order?.productName}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Order" name={['license', 'orderID']}>
              {this.props.licenses?.license?.orderID}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Order Status" name={['order', 'status']}>
              {this.props.licenses?.order?.status}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Completed">{completedOn}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Popover content={emails} title="Emails">
                  Purchaser <InfoCircleOutlined style={{ marginLeft: '4px' }} />
                </Popover>
              }
              name={['order', 'name']}
            >
              {this.props.licenses?.order?.name}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="License Owner">{licenseOwner}</Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Activations" name="numActivations">
              {this.props.licenses?.numActivations}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Max Activations" name="maxActivations">
              {this.props.licenses?.maxActivations}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Reservations" name="reservedCount">
              {this.props.licenses?.reservedCount}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Assignments" name="assignCount">
              {this.props.licenses?.assignCount}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Assign Activ" name="assignActivations">
              {this.props.licenses?.assignActivations}
            </Form.Item>
          </Col>

          {isAssignment && (
            <>
              <Col span={12}>
                <span style={{ color: '#af3947' }}>Assigned License</span>
              </Col>
              <Col span={6}>
                <Form.Item label="By:" name="assignedFromOwnerID">
                  <Button
                    type="link"
                    href={redirectToOwnerPage}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<LinkOutlined />}
                  >
                    {this.state.assignedFrom || 'N/A'}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Key:" name="id">
                  <Button
                    type="link"
                    href={redirectToKey}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<LinkOutlined />}
                  >
                    {this.props.licenses?.origActivationKey || 'N/A'}
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    );
  }

  // Private methods
  async _fetchLicenseOwner() {
    const licenses = this.props.licenses;
    let licenseOwner = 'N/A';

    if (!licenses?.externalID) return;
    if (licenses.isAssignment) {
      const idmResp = await this.props.app.idm.api.user.read(licenses?.externalID);
      licenseOwner = `${idmResp.body.firstName} ${idmResp.body.lastName}`;
    } else {
      const orgResp = await this.props.app.organization.organizationApp.getOrganizationApp(licenses?.externalID);
      if (orgResp.statusCode == 200 && orgResp.body) {
        licenseOwner = orgResp.body.name;
      }
    }
    this.setState({ licenseOwner });
  }

  async _fetchAssignedOwer() {
    const assignedFromOwnerID = this.props.licenses?.assignedFromOwnerID;
    let assignedFrom = 'N/A';
    if (!assignedFromOwnerID) return;
    const orgResp = await this.props.app.organization.organizationApp.getOrganizationApp(assignedFromOwnerID);
    if (orgResp.statusCode == 200 && orgResp.body) {
      assignedFrom = orgResp.body.name;
    }
    this.setState({ assignedFrom });
  }
}
