import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//
import CommonVenueForm from '../Forms/CommonVenueForm';
//props are: app, onUpdate
export default class CommonVenueDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, venueID: null, data: null, cities: null, regions: null };
  }
  //Public
  show(venueID, cities, regions) {
    this.setState({ isVisible: true, venueID, data: null, cities, regions }, this._fetch);
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isLoading: false, isVisible: false, venueID: null, data: null, cities: null, regions: null });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.venueID) this._update(formData);
    else this._create(formData);
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.venueID ? 'Edit' : 'Create'} Venue`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Row type="flex" justify="end">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleSubmit}>
              {this.state.venueID ? 'Save' : 'Create'}
            </Button>{' '}
          </Col>
        </Row>
        <CommonVenueForm
          app={this.props.app}
          {...Utils.propagateRef(this, 'form')}
          cities={this.state.cities}
          regions={this.state.regions}
        />
      </Drawer>
    );
  }

  // API Calls
  async _fetch() {
    if (!this.state.venueID) return; //avoid loading empty TPs
    this.setState({ isLoading: true });
    const resp = await this.props.app.classroom.venue.getVenue(this.state.venueID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ data: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _update(data) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateVenues();
    const resp = await this.props.app.classroom.venue.updateVenue({
      id: this.state.data.id,
      customerID: this.state.data.customerID,
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Venue successfully updated!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
  async _create(data) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateVenues();
    const resp = await this.props.app.classroom.venue.createVenue({
      ...data,
      customerID: this.props.app.sharedCache().getCustomerID(),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Venue successfully created!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
