import Globals from '../config/Globals';
import Utils from './Utils';
//
export default class Cache {
  constructor(auth) {
    this.auth = auth;
    this.isLoading = false;
    //this objects should be cleared on clear cache
    this.tenantConfig = null;
    this.userObj = null;
    this.employeeOrg = null;
    this.pendingOrders = null;
    this.pendingApplications = null;
    this.pendingApprovals = null;
    this.products = null;
    //
    this._onDemandCache = {};
    //
    this.orgCUs = [];
  }

  async loadCache(forceUserConsistency) {
    //avoid double cache loading
    if (this.isLoading) return false;
    this.isLoading = true;
    //execute all
    const respAll = await Utils.execRequests(
      [
        this.auth.api.tenant.getConfiguration(),
        this.products ? null : this.loadProducts(),
        !this.auth.isAdmin() ? this.reloadUserObj(forceUserConsistency) : null,
        this.auth.isAdmin() ? this.loadPendingOrders() : null,
        this.auth.isAdmin() ? this.loadPendingApplications() : null,
        this.auth.isAdmin() ? this.loadPendingApprovals() : null,
      ],
      null,
      (resp, respIdx) => {
        console.log('Resp', respIdx, resp);
        if (resp && respIdx == 0) this.tenantConfig = { ...resp.body };
      },
      true
    );
    //
    this.isLoading = false;
    return respAll;
  }
  clearCache() {
    this.tenantConfig = null;
    this.userObj = null;
    this.employeeOrg = null;
    this.pendingOrders = null;
    this.pendingApplications = null;
    this.pendingApprovals = null;
    this.products = null;
    this.orgCUs = [];
  }
  //Certification
  isMonoCertification() {
    return !!(this.tenantConfig.certifications.length == 1);
  }
  getCertificationByID(certID) {
    if (!this.tenantConfig || !this.tenantConfig.certifications) return null;
    //For all certifications
    for (let certificationObj of this.tenantConfig.certifications) {
      if (certificationObj.id == certID) return certificationObj;
    }
    return null;
  }
  //Course
  getCourseByID(courseID, certID = null) {
    if (!this.tenantConfig || !this.tenantConfig.certifications) return null;
    //For all certifications
    for (let certificationObj of this.tenantConfig.certifications) {
      if (certID && certificationObj.id != certID) {
        continue;
      }
      //For all exams of tenant config
      for (let course of certificationObj.requirements) {
        if (Array.isArray(course)) {
          for (let subCourse of course) if (subCourse.id == courseID) return subCourse;
        } else if (course.id == courseID) return course;
      }
    }
    return null;
  }
  getAllUniqueCourses() {
    if (!this.tenantConfig || !this.tenantConfig.certifications) return null;
    //For all certifications
    const courses = [];
    for (let certificationObj of this.tenantConfig.certifications) {
      //For all exams of tenant config
      for (let course of certificationObj.requirements) {
        if (Array.isArray(course)) {
          for (let subCourse of course) if (!courses.find((c) => c.id == subCourse.id)) courses.push(subCourse);
        } else if (!courses.find((c) => c.id == course.id)) courses.push(course);
      }
    }
    return courses;
  }
  //Product related objects (courses and applications (for now, bundles will be added here))
  getAllProductRelatedObjects() {
    if (!this.tenantConfig || !this.tenantConfig.certifications) return null;
    //For all certifications
    const objects = [];
    const hiddenObjects = [];
    for (let certificationObj of this.tenantConfig.certifications) {
      if (!certificationObj.ui?.hideFromUI) {
        //Check for application objects of the certification
        if (
          !objects.find(
            (c) =>
              (c.id == certificationObj.id && c.isApplication) ||
              c.productID == certificationObj?.application?.productID
          ) &&
          certificationObj.application
        )
          objects.push({ ...certificationObj, isApplication: true });
        if (
          !objects.find(
            (c) => (c.id == certificationObj.id && c.isRenewal) || c.productID == certificationObj.renewal?.productID
          ) &&
          certificationObj.renewal?.productID
        )
          objects.push({ ...certificationObj, isRenewal: true });
        //For all exams of tenant config
        for (let course of certificationObj.requirements) {
          if (Array.isArray(course)) {
            for (let subCourse of course) {
              // gets the product to check if it should be hidden
              const product = (this.products || []).find((p) => p.id === subCourse.productID);
              if (!product.hideInPurchaseDialog) {
                if (!objects.find((c) => c.id == subCourse.id || c.productID == subCourse.productID))
                  objects.push(subCourse);
              } else if (!hiddenObjects.find((c) => c.id == subCourse.id || c.productID == subCourse.productID))
                hiddenObjects.push(subCourse);
            }
          } else {
            // gets the product to check if it should be hidden
            const product = (this.products || []).find((p) => p.id === course.productID);
            if (!product.hideInPurchaseDialog) {
              if (!objects.find((c) => c.id == course.id || c.productID == course.productID)) objects.push(course);
            } else if (!hiddenObjects.find((c) => c.id == course.id || c.productID == course.productID))
              hiddenObjects.push(course);
          }
        }
      } else {
        //Check for application objects of the certification
        if (
          !hiddenObjects.find(
            (c) =>
              (c.id == certificationObj.id && c.isApplication) ||
              c.productID == certificationObj?.application?.productID
          ) &&
          certificationObj.application
        )
          hiddenObjects.push({ ...certificationObj, isApplication: true });
        if (
          !hiddenObjects.find(
            (c) => (c.id == certificationObj.id && c.isRenewal) || c.productID == certificationObj.renewal?.productID
          ) &&
          certificationObj.renewal?.productID
        )
          hiddenObjects.push({ ...certificationObj, isRenewal: true });
        //For all exams of tenant config
        for (let course of certificationObj.requirements) {
          if (Array.isArray(course)) {
            for (let subCourse of course) {
              if (!hiddenObjects.find((c) => c.id == subCourse.id || c.productID == subCourse.productID))
                hiddenObjects.push(subCourse);
            }
          } else if (!hiddenObjects.find((c) => c.id == course.id || c.productID == course.productID))
            hiddenObjects.push(course);
        }
      }
    }
    //For all missing (external products)
    for (const product of this.products || []) {
      if (
        !objects.find(
          (c) =>
            c.productID == product.id || c.application?.productID == product.id || c.renewal?.productID == product.id
        ) &&
        !hiddenObjects.some((c) => c.productID == product.id) &&
        !product.hideInPurchaseDialog
      )
        objects.push({ isExternal: true, ...product });
    }
    //
    console.log(objects);
    //
    return objects;
  }
  getAllProductsID() {
    const objects = this.getAllProductRelatedObjects();
    let productsIDs = [];
    for (let object of objects) {
      if (
        !object.isApplication &&
        !object.isRenewal &&
        !object.isExternal &&
        productsIDs.indexOf(object.productID) == -1
      )
        productsIDs.push(object.productID);
      if (object.isApplication && productsIDs.indexOf(object.application.productID) == -1)
        productsIDs.push(object.application.productID);
      if (object.isRenewal && productsIDs.indexOf(object.renewal.productID) == -1)
        productsIDs.push(object.renewal.productID);
      if (object.isExternal && productsIDs.indexOf(object.id) == -1) productsIDs.push(object.id);
    }
    return productsIDs;
  }
  getProductByProductRelatedObject(object) {
    // console.log(this.getProductByID(object.productID));
    if (!object.isApplication && !object.isRenewal && !object.isExternal) return this.getProductByID(object.productID);
    if (object.isApplication) return this.getProductByID(object.application.productID);
    if (object.isRenewal) return this.getProductByID(object.renewal.productID);
    if (object.isExternal) return this.getProductByID(object.id);
    return null;
  }
  getProductRelatedObjectByProductID(productID, optionalMetadata) {
    if (optionalMetadata) return optionalMetadata;
    const objects = this.getAllProductRelatedObjects();
    for (let object of objects) {
      if (!object.isApplication && !object.isRenewal && !object.isExternal && object.productID == productID)
        return object;
      if (object.isApplication && object.application.productID == productID) return object;
      if (object.isRenewal && object.renewal.productID == productID) return object;
      if (object.isExternal && object.id == productID) return object;
    }
    return null;
  }
  //Products
  getProductByID(productID) {
    if (!this.products || this.products.length == 0) return null;
    return this.products.find((p) => p.id == productID);
  }
  //Getters
  getProgramUser() {
    return this.userObj;
  }
  getEmployeeOrg() {
    return this.employeeOrg;
  }
  getPendingOrders() {
    return this.pendingOrders;
  }
  getPendingApplications() {
    return this.pendingApplications;
  }
  getPendingApprovals() {
    return this.pendingApprovals;
  }
  getTenantConfig() {
    return this.tenantConfig;
  }
  getCustomerID() {
    return this.tenantConfig?.customerID;
  }
  getProducts() {
    return this.products;
  }
  getTenantRegistrationConfig() {
    return this.tenantConfig.customRegistration;
  }
  getPortalDomain() {
    return this.getTenantConfig()?.customer?.portalConfig?.domain;
  }
  getWorksafeTerm() {
    return this.getTenantConfig()?.customer?.worksafeTerm || 'Worksafe';
  }
  getCancelationPolicies() {
    return this.getTenantConfig()?.customer?.cancelationPolicies;
  }
  getOrganizationCUs() {
    return this.orgCUs;
  }
  async loadOrganizationCUs(force) {
    if (this.orgCUs.length > 0 && !force) return this.orgCUs;
    return await Utils.execRequests(
      [this.auth.organization.classificationUnit.getAllClassificationUnits()],
      null,
      (resp, idx) => {
        if (idx == 0 && resp && resp.body && resp.statusCode == 200) this.orgCUs = resp.body.classificationUnits;
      },
      true
    );
  }
  //Wrappers
  async reloadUserObj(forceUserConsistency) {
    // Get current logged user ID from session authorization
    const userID = this.auth?.idm?.session?.authorization?.getUserID();
    //Load BCP user
    const userResp = await this.auth.api.user.getByID(userID);
    if (userResp.statusCode == 200) {
      this.userObj = userResp.body;

      const loadedEmployeeData = await this._loadEmployeeData();
      if (!loadedEmployeeData) {
        //return { statusCode: 400 };
        console.debug('Unable to find employeers data - this is expected in certain cases and we will ignore it');
      }

      return userResp;
    }
    // Load BCP user -- for now we really dont care if the user was loaded
    // or not because we deal not found user as income registrations
    // This will change in the future when we will required to handle
    // tenant enrollments and multiple tenants login into different tenants.
    return forceUserConsistency ? userResp : { statusCode: 200 };
  }
  async loadPendingOrders() {
    const resp = await this.auth.license.order.getPendingOrders();
    if (resp.statusCode == 200 && resp.body) {
      this.pendingOrders = resp.body.orders;
    }
    return resp;
  }
  async loadPendingApplications() {
    const resp = await this.auth.api.pendingApplications.getTenantPendingApplications();
    if (resp.statusCode == 200) {
      let pendingApplications = resp.body.applications;
      const usersIDs = pendingApplications.map((application) => application.userID);
      const users = await this.fetchUserNames(usersIDs);

      this.pendingApplications = pendingApplications.map((application) => ({
        ...application,
        user: users ? users.find((user) => application.userID == user._source.id)?._source : null,
      }));
    }
    return resp;
  }

  async loadPendingApprovals() {
    const resp = await this.auth.api.tenant.pendingApprovals();
    if (resp.statusCode == 200) {
      let pendingApprovals = resp.body.paItems;
      const usersIDs = pendingApprovals.map((paItem) => paItem.userID);
      const users = await this.fetchUserNames(usersIDs);

      this.pendingApprovals = pendingApprovals.map((paItem) => ({
        ...paItem,
        user: users ? users.find((user) => paItem.userID == user._source.id)?._source : null,
      }));
    }
    return resp;
  }

  async loadProducts() {
    const resp = await this.auth.license.product.getAllProducts();
    if (resp.statusCode == 200 && resp.body) {
      this.products = resp.body.products;
    }
    return resp;
  }
  async reloadProducts() {
    this.products = null;
    return await this.loadProducts();
  }
  async fetchUserNames(usersIDs) {
    const resp = await this.auth.api.user.searchUsersByIDs(usersIDs);
    if (resp.statusCode == 200 && resp.body) {
      return resp.body.users;
    } else {
      console.log(resp);
    }
    return [];
  }
  /* On Demand Cache */
  async getVenues() {
    if (!this._onDemandCache['venues']) {
      const resp = await this.auth.classroom.venue.getAllVenues();
      if (resp.statusCode == 200 && resp.body.venues) this._onDemandCache['venues'] = resp.body.venues;
    }
    return this._onDemandCache['venues'];
  }
  getVenueByID(venueID) {
    return this._onDemandCache['venues']?.find((venue) => venue.id == venueID);
  }
  invalidateVenues() {
    this._onDemandCache['venues'] = null;
  }
  async getCities() {
    if (!this._onDemandCache['cities']) {
      const resp = await this.auth.classroom.city.getAllCities();
      if (resp.statusCode == 200 && resp.body.cities) this._onDemandCache['cities'] = resp.body.cities;
    }
    return this._onDemandCache['cities'];
  }
  getCityByID(cityID) {
    return this._onDemandCache['cities']?.find((city) => city.id == cityID);
  }
  invalidateCities() {
    this._onDemandCache['cities'] = null;
  }
  async getRegions() {
    if (!this._onDemandCache['regions']) {
      const resp = await this.auth.classroom.region.getAllRegions();
      if (resp.statusCode == 200 && resp.body.regions) this._onDemandCache['regions'] = resp.body.regions;
    }
    return this._onDemandCache['regions'];
  }
  invalidateRegions() {
    this._onDemandCache['regions'] = null;
  }
  invalidateLocations() {
    this._onDemandCache['regions'] = null;
    this._onDemandCache['cities'] = null;
  }
  async getInstructors() {
    if (!this._onDemandCache['instructors']) {
      const resp = await this.auth.classroom.instructor.getAllInstructors();
      if (resp.statusCode == 200 && resp.body.instructors) this._onDemandCache['instructors'] = resp.body.instructors;
    }
    return this._onDemandCache['instructors'];
  }
  async getExpandedInstructors() {
    if (!this._onDemandCache['expandedInstructors']) {
      const resp = await this.auth.classroom.instructor.getAllInstructors(true);
      if (resp.statusCode == 200 && resp.body.instructors)
        this._onDemandCache['expandedInstructors'] = resp.body.instructors;
    }
    return this._onDemandCache['expandedInstructors'];
  }
  invalidateInstructors() {
    this._onDemandCache['instructors'] = null;
  }
  //Cachable getter
  async getVaultPaymentNonceByExternalID(externalID, additionalInfo) {
    //**IMPORTANT**// - This cookie key logic avoids issue when user logs out on another app and login with a different user ID
    const cookieKey = Globals.Cookies_NonceKey + this.tenantConfig.id + (externalID || this.auth.getAuthorizedUserID());
    console.log(cookieKey);
    //Attempt to get on cache
    let nonce = await this.auth.idm.session.data.storage.getValue(cookieKey);
    if (nonce) {
      console.debug('Using cached nonce.');
      return nonce;
    }
    //Continue to request if cache not available
    const resp = await this.auth.license.vault.getVaultNonce(externalID, additionalInfo);
    if (resp.statusCode == 200 && resp.body && resp.body.nonce) {
      //Set plans and cache
      nonce = resp.body.nonce;
      await this.auth.idm.session.data.storage.setValue(cookieKey, nonce, Globals.Cookies_NonceCachingPeriod);
      return nonce;
    }
    console.error('Error while getting nonce', resp);
    return null;
  }
  async _loadEmployeeData() {
    const employeeResp = await this.auth.organization.employee.getEmployeeByEmployeeID(this.userObj.id, true);

    if (employeeResp.statusCode == 200 && employeeResp.body) {
      this.employeeOrg = {
        id: employeeResp.body.org?.id,
        name: employeeResp.body.org?.name,
        cus: employeeResp.body.org?.cus ? employeeResp.body.org.cus.map((cu) => cu.id) : undefined,
      };

      return true;
    }

    return false;
  }
}
