import {
  CalendarFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  EditOutlined,
  KeyOutlined,
  ReloadOutlined,
  RocketFilled,
  ShoppingCartOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Popover, Row, Typography, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import autoBind from 'react-autobind';
//
import Utils from '../../../../../components/Utils';
import UtilsSession from '../../../../../components/UtilsSession';
import Globals from '../../../../../config/Globals';
import { CourseCardActionsHOC } from './CourseCardActionsContext';
import CommonRescheduleSessionActionDrawer from '../../../../commonComponents/Drawers/CommonRescheduleSessionActionDrawer';
//
class CourseCardActions extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isCancelPopoverVisible: false,
    };
    this.drawerRef = React.createRef();
  }

  handleCancelPopover() {
    this.setState((prevState) => ({ isCancelPopoverVisible: !prevState.isCancelPopoverVisible }));
  }
  async handleCancelation() {
    this.handleCancelPopover();
    await this.props.actions.cancelCourse();
  }

  async handleReschedule() {
    if (this.drawerRef.current) {
      this.drawerRef.current.show(this.props.certificationProcess.userID);
    }
  }

  render() {
    const {
      app,
      courseSpecs,
      certificationProcess,
      session,
      message,
      actionSchedule,
      actionCancelCourse,
      actionRescheduleCourse,
      actionPurchase,
      actionRedeem,
      actionUnlockCourse,
      actionUnlockSession,
      actionLaunch,
      actionCheckResults,
      actionAddResult,
      actionEndCooldown,
      actionActivate,
      actionRetake,
      actionUpload,
      actionRequestApproval,
      actionRevoke,
      actionForceCheck,
      availableKeys,
    } = this.props;
    const isAdmin = app.isAdmin();

    const sessionsTypesThatCanBeLaunched = [
      Globals.Session_Type.ONLINE_EXT,
      Globals.Session_Type.SCHEDULED_ONLINE_EXT,
      Globals.Session_Type.SCHEDULED_VILT,
      Globals.Session_Type.SCHEDULED_WEBINAR,
    ];

    const enrollment = session?.enrolments ? session.enrolments[0] : null;
    let accessEndsOn = null;
    if (enrollment && session?.onlineAccessPeriod && session.onlineAccessPeriod >= 1) {
      // first we check if the enrolment has a release date
      if (session.accessReleasedOn)
        accessEndsOn = moment(enrollment.accessReleasedOn).add(session.onlineAccessPeriod, 'minutes');
      else if (session.type.startsWith('SCHEDULED')) {
        // if session.type starts with 'SCHEDULED' then we use the session start date
        if (session.startDate && Array.isArray(session.startDate)) {
          accessEndsOn = moment(session.startDate[0]).add(session.onlineAccessPeriod, 'minutes');
        }
      } else {
        // if not, we use the enrolment creation date
        accessEndsOn = moment(enrollment.createdOn).add(session.onlineAccessPeriod, 'minutes');
      }
    }

    const userLogged = this.props.app.sharedCache().getProgramUser();
    const currentUser = this.props.certificationProcess?.user.id;
    const disableForOrgManager = app.isOrgManager() && userLogged.id !== currentUser;

    const shouldShowLaunchAction =
      actionLaunch &&
      courseSpecs.courseType !== Globals.Course_Types_Keys.EXTERNALREQUIREMENT &&
      courseSpecs.courseType !== Globals.Course_Types_Keys.ASSIGNMENT &&
      sessionsTypesThatCanBeLaunched.includes(session?.type) &&
      (!accessEndsOn || Utils.getTimestampFromMoment(accessEndsOn) > Date.now());


    const courseTypesThatCanHavePurchase = [Globals.Course_Types_Keys.COURSE, Globals.Course_Types_Keys.ASSIGNMENT];

    const shouldShowPurchaseAction =
      actionPurchase &&
      !certificationProcess?.waivedBy &&
      (!courseSpecs.courseType || courseTypesThatCanHavePurchase.includes(courseSpecs.courseType));

    const shouldShowRedeemAction =
      actionRedeem &&
      !certificationProcess?.waivedBy &&
      (!courseSpecs.courseType || courseTypesThatCanHavePurchase.includes(courseSpecs.courseType));

    const cancelPolicy = this.props.app
      .sharedCache()
      .getTenantConfig()
      ?.customer?.cancelationPolicies?.find((c) => c.id == session?.cancelationPolicyID);
    const reschedulePolicy = this.props.app
      .sharedCache()
      .getTenantConfig()
      ?.customer?.cancelationPolicies?.find((c) => c.id == session?.reschedulePolicyID);

    return (
      <>
        <Divider />
        {message && (
          <Row justify="center">
            <Col span={12} style={{ textAlign: 'center' }}>
              {' '}
              {message}{' '}
            </Col>
          </Row>
        )}
        <Row gutter={24} type="flex" justify="center" style={{ marginTop: 16 }} className="course-card-actions">
          <Col span={24} style={{ textAlign: 'center' }}>
            {shouldShowLaunchAction && (
              <Button
                type="primary"
                icon={<RocketFilled />}
                onClick={this.props.actions.launch}
                disabled={this.props.disableActions || disableForOrgManager}
              >
                Launch
              </Button>
            )}
            {actionCheckResults &&
              (session?.type == Globals.Session_Type.ONLINE_EXT ||
                session?.type == Globals.Session_Type.SCHEDULED_ONLINE_EXT) && (
                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  onClick={this.props.actions.checkCourseResults}
                  disabled={this.props.disableActions}
                >
                  Check results
                </Button>
              )}
            {actionAddResult && isAdmin && (
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={this.props.actions.onAddResult}
                disabled={this.props.disableActions}
              >
                Add result
              </Button>
            )}
            {actionEndCooldown && isAdmin && (
              <Button
                type="primary"
                icon={<ClockCircleFilled />}
                onClick={this.props.actions.endCooldown}
                disabled={this.props.disableActions}
              >
                End Cooldown
              </Button>
            )}
            {actionUnlockCourse && isAdmin && (
              <Button
                type="primary"
                icon={<UnlockOutlined />}
                onClick={this.props.actions.unlockCourse}
                disabled={this.props.disableActions}
              >
                Unlock Course
              </Button>
            )}
            {actionSchedule && (
              <Button
                type="primary"
                icon={<CalendarFilled />}
                onClick={this.props.actions.onSchedule}
                disabled={this.props.disableActions}
              >
                Schedule
              </Button>
            )}
            {actionActivate && certificationProcess?.waivedBy && (
              <Button
                type="primary"
                icon={<KeyOutlined />}
                onClick={this.props.actions.onCourseActivate}
                disabled={this.props.disableActions}
              >
                Activate
              </Button>
            )}
            {shouldShowPurchaseAction && (
              <Popover
                title="Product Licenses"
                content="Click here to purchase this product. If you have been provided a license key for this product, please click the 'Use existing license key' button to apply it"
              >
                <Button
                  type="primary"
                  icon={<ShoppingCartOutlined />}
                  onClick={this.props.actions.onLicensePurchase}
                  style={{ marginBottom: 8 }}
                  disabled={this.props.disableActions || disableForOrgManager}
                >
                  {courseSpecs.courseType != Globals.Course_Types_Keys.ASSIGNMENT
                    ? 'Make Purchase'
                    : 'Start Assignment'}
                </Button>
              </Popover>
            )}
            {shouldShowRedeemAction && (
              <Popover
                title="Product Licenses"
                content="If you have a license available for this product, clicking here will automatically apply it. If you were provided a license key via email, you can paste it here to use it."
              >
                <Button
                  type="primary"
                  icon={<KeyOutlined />}
                  onClick={this.props.actions.onLicenseRedeem}
                  disabled={this.props.disableActions}
                >
                  Use existing license key
                </Button>
              </Popover>
            )}
            {actionUnlockSession && isAdmin && (
              <Button
                type="primary"
                icon={<UnlockOutlined />}
                onClick={this.props.actions.unlockSession}
                disabled={this.props.disableActions}
              >
                Unlock
              </Button>
            )}
            {actionCancelCourse && UtilsSession.canEnrolmentBeCanceled(session, cancelPolicy, isAdmin) && (
              <Popover
                placement="top"
                trigger="click"
                onClick={this.handleCancelPopover}
                open={this.state.isCancelPopoverVisible}
                content={
                  <div style={{ width: 300 }}>
                    <Typography.Text>
                      Are you sure you want to cancel your enrolment into this {courseSpecs?.uiTerm}?
                    </Typography.Text>
                    <br></br>
                    <Row type="flex" justify="end">
                      <Col>
                        <Button type="primary" onClick={this.handleCancelation}>
                          Yes
                        </Button>
                      </Col>
                      <Col style={{ marginLeft: 10 }}>
                        <Button type="primary" onClick={this.handleCancelPopover}>
                          No
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <Button type="primary" icon={<CloseCircleFilled />} disabled={this.props.disableActions}>
                  Cancel Enrolment
                </Button>
              </Popover>
            )}
            {actionRescheduleCourse && UtilsSession.canBeRescheduled(session, reschedulePolicy, isAdmin) && (
              <>
                <Tooltip placement="right" title="Rescheduling lets you choose a new date; this will not refund your purchase.">
                  <Button
                    type="primary"
                    icon={<CalendarFilled />}
                    onClick={this.handleReschedule}
                    disabled={this.props.disableActions}
                  >
                    Reschedule
                  </Button>
                </Tooltip>
                <CommonRescheduleSessionActionDrawer
                  app={this.props.app}
                  session={session}
                  ref={this.drawerRef}
                />
              </>
            )}
            {actionRetake && !shouldShowLaunchAction && courseSpecs.isRetakeAllowed && (
              <Button type="primary" onClick={this.props.actions.onRetake}>
                Retake Course
              </Button>
            )}
            {actionRevoke && isAdmin && (
              <Button type="primary" onClick={this.props.actions.onAdminRevoke}>
                Revoke
              </Button>
            )}
            {actionUpload &&
              (courseSpecs.allowStudentUploads == true ||
                courseSpecs.courseType == Globals.Course_Types_Keys.ASSIGNMENT) && (
                <Button type="primary" onClick={this.props.actions.onUpload}>
                  Upload Document
                </Button>
              )}
            {actionForceCheck && (
              <Button type="primary" onClick={this.props.actions.forceCheck}>
                Refresh
              </Button>
            )}
            {actionRequestApproval && (
              <Popconfirm
                title="Are you sure you want to request a review for these items? Files uploads and deletions will be disabled"
                onConfirm={() => this.props.actions.onRequestApproval()}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Request Approval</Button>
              </Popconfirm>
            )}
          </Col>
          {accessEndsOn && shouldShowLaunchAction && (
            <div style={{ textAlign: 'center' }}>
              <br></br>
              {courseSpecs.displayName} will remain available to you until {accessEndsOn && accessEndsOn.toString()}
            </div>
          )}
        </Row>
      </>
    );
  }
}
//
CourseCardActions = CourseCardActionsHOC(CourseCardActions);
export default CourseCardActions;
