import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Button } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
//
import CommonStudentSearchTable from '../StudentSelection/CommonStudentSearchTable';
import CommonStudentAddConfirmation from '../StudentSelection/CommonStudentAddConfirmation';
import CommonLoadingView from '../CommonLoadingView';
//props are: app, session
export default class CommonSessionAddStudentDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, selectedStudent: null, isLoading: false };
  }
  show() {
    this.setState({ isVisible: true });
  }
  handleClose() {
    this.setState({ isVisible: false, selectedStudent: null });
  }
  handleSelect(selectedStudent) {
    this.setState({ selectedStudent });
  }
  async handleSubmit() {
    this.startLoading();
    const addResp = await this.props.app.api.course.add(this.props.session.id, this.state.selectedStudent.id);
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert('', 'Student added successfully!');
      this.stopLoading();
      this.handleClose();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
      this.handleClose();
    }
  }
  //UI
  render() {
    return (
      <Drawer
        placement="right"
        title={`Add Student in ${this.props.session.name}`}
        width={800}
        onClose={this.handleClose}
        open={this.state.isVisible}
        bodyStyle={{ paddingBottom: 20 }}
        footer={this._renderDrawerFooter()}
      >
        <CommonLoadingView isLoading={this.state.isLoading} />
        {!this.state.selectedStudent && (
          <CommonStudentSearchTable
            {...Utils.propagateRef(this, 'StudentSearchTable')}
            app={this.props.app}
            onSelect={this.handleSelect}
          />
        )}
        {this.state.selectedStudent && (
          <CommonStudentAddConfirmation
            {...Utils.propagateRef(this, 'StudentAddConfirmation')}
            app={this.props.app}
            selectedStudent={this.state.selectedStudent}
            session={this.props.session}
          />
        )}
      </Drawer>
    );
  }
  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        {this.state.selectedStudent && (
          <Button onClick={this.handleSubmit} type="primary" style={{ marginRight: 8 }}>
            Confirm
          </Button>
        )}
      </div>
    );
  }
}
