import React from 'react';
import autoBind from 'react-autobind';
import {
  Button,
  Input,
  Layout,
  Row,
  Col,
  PageHeader,
  Tabs,
  Typography,
  Select,
  DatePicker,
  Tooltip,
  message,
} from 'antd';
import { DownloadOutlined, SearchOutlined, UndoOutlined, ReloadOutlined } from '@ant-design/icons';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
//
import Globals from '@/config/Globals';
import Utils from '@/components/Utils';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CustomComponent from '@/components/CustomComponent';
import WhiteBox from '@/views/commonComponents/WhiteBox';
import CommonProductOrdersTable from '../commonComponents/CommonProductOrdersTable';
import CommonOrganizationSelectionInput from '../commonComponents/OrganizationSelection/CommonOrganizationSelectionInput';
import CommonUserSelectionInput from '../commonComponents/CommonUserSelectionInput';
import CommonProductOrderRefundModal from '../commonComponents/Modals/CommonProductOrderRefundModal';
export default class AdminProductOrdersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      id: '',
      orders: [],
      pendingOrders: [],
      selectedTab: 'pending',
      selectedOrg: null,
      selectedUser: null,
      status: '',
      from: undefined,
      to: undefined,
      userID: '',
      orgID: '',
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    const initialState = await this._getInitialState();
    this.setState({ ...initialState }, () => {
      const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
      this.fetchDataByTab(this.state.selectedTab);

      if (this.state.selectedTab !== selectedTab && selectedTab) {
        this.handleTabChange(selectedTab);
      } else {
        this.handleTabChange(this.state.selectedTab);
      }
    });
  }

  async _getInitialState() {
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID) || 'pending';
    const status = this.props.app.idm.urlmanager.getParam('status') || '';
    const from = parseInt(this.props.app.idm.urlmanager.getParam('from')) || undefined;
    const to = parseInt(this.props.app.idm.urlmanager.getParam('to')) || undefined;
    const orgID = this.props.app.idm.urlmanager.getParam('orgID') || '';
    const userID = this.props.app.idm.urlmanager.getParam('userID') || '';

    let selectedOrg = null;
    let selectedUser = null;

    if (orgID) {
      const orgResp = await this.props.app.organization.organization.getOrganization(orgID);
      if (orgResp.statusCode === 200) {
        selectedOrg = orgResp.body;
      }
    }

    if (userID) {
      const userResp = await this.props.app.api.user.getByID(userID);
      if (userResp.statusCode === 200) {
        selectedUser = userResp.body;
      }
    }

    return {
      selectedTab,
      status,
      from,
      to,
      orgID,
      userID,
      selectedOrg,
      selectedUser,
    };
  }

  async fetchDataByTab(selectedTab) {
    this.startLoading();
    if (selectedTab === 'pending') {
      await this._searchPendingProductOrders();
    } else if (selectedTab === 'search') {
      await this._searchProductOrders();
      this._reloadURLParams();
    }
    this.stopLoading();
  }

  //Actions
  handleDownloadOrder(order) {
    this._downloadOrder(order.externalID, order.id);
  }
  handleRefundOrder(order) {
    console.log('Refund order', order);
    if (!this.state.isVisibleRefund) this.refundModal.loadModalInfo(order);
    this.setState({ isVisibleRefund: !this.state.isVisibleRefund }, () => {
      if (!this.state.isVisibleRefund) this.fetchDataByTab(this.state.selectedTab);
    });
  }

  async handleTabChange(selectedTab) {
    this.setState({ selectedTab }, async () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
      if (selectedTab === 'pending') {
        this.props.app.urlManager.updateQueryStringParam('status', '');
        this.props.app.urlManager.updateQueryStringParam('from', '');
        this.props.app.urlManager.updateQueryStringParam('to', '');
        this.props.app.urlManager.updateQueryStringParam('orgID', '');
        this.props.app.urlManager.updateQueryStringParam('userID', '');
      }
    });
    await this._reloadURLParams();
  }

  async handleSearch() {
    await this._searchProductOrders();
  }

  handleSelectStatus(value) {
    this.setState({ status: value }, () => {
      this._reloadURLParams();
    });
  }

  handleDateChange = (dates) => {
    if (dates && dates.length > 0) {
      const from = moment(dates[0]).startOf('day').valueOf();
      const to = moment(dates[1]).endOf('day').valueOf();
      this.setState({ from, to }, () => {
        this._reloadURLParams();
      });
    } else {
      this.setState({ from: undefined, to: undefined }, () => {
        this._reloadURLParams();
      });
    }
  };

  handleSelectOrg(orgObj) {
    this.setState({ selectedOrg: orgObj, selectedUser: null, orgID: orgObj?.id }, () => {
      this._reloadURLParams();
    });
  }
  handleSelectUser(userObj) {
    this.setState({ selectedUser: userObj, selectedOrg: null, userID: userObj?.id }, () => {
      this._reloadURLParams();
    });
  }
  async onRefresh() {
    this.startLoading();
    await this._searchPendingProductOrders();
    this.stopLoading();
  }
  async generateReportXLSX(productOrders) {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    // Generate XLSX header
    ws.addRow([
      'Owner ID',
      'Name',
      'Description',
      'Order ID',
      'Date',
      'Street 1',
      'Street 2',
      'City',
      'Postal Code',
      'Province',
      'Country',
      'Items Value',
      'Items Tax',
      'Items Total',
      'Shipping Charges',
      'Shipping Tax',
      'Shipping Total',
      'Order Value',
      'Order Tax',
      'Order Total',
      'Status',
      'Refunded Amount',
      'Refunded On',
      'Canceled On',
      'Cancel Reason',
      'Provider Transaction ID',
    ]);

    productOrders.forEach((order) => {
      ws.addRow([
        order.ownerID || '',
        order.name || '',
        order.description || '',
        order.orderID || '',
        order.orderDate ? Utils.getDateOnUIFormatByTimestamp(order.orderDate) : '',
        order.shippingInfo?.unit || '',
        order.shippingInfo?.street0 || '',
        order.shippingInfo?.street1 || '',
        order.shippingInfo?.city || '',
        order.shippingInfo?.postalCode || '',
        order.shippingInfo?.province || '',
        order.shippingInfo?.country || '',
        order.itemsValue || 0,
        order.itemsValueTax || 0,
        order.itemsValueTotal || 0,
        order.shippingChargesValue || 0,
        order.shippingChargesValueTax || 0,
        order.shippingChargesValueTotal || 0,
        order.orderValue || 0,
        order.orderValueTax || 0,
        order.orderValueTotal || 0,
        order.status || '',
        order.refundedAmount || 0,
        order.refundedOn ? Utils.getDateOnUIFormatByTimestamp(order.refundedOn) : '',
        order.canceledOn ? Utils.getDateOnUIFormatByTimestamp(order.canceledOn) : '',
        order.cancelReason || '',
        order.providerTransactionID || '',
      ]);
    });

    const buffer = await wb.xlsx.writeBuffer();
    Utils.downloadArrayBuffer(buffer, 'ProductOrders', 'xlsx');
  }
  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, this.state.selectedTab);
    this.props.app.urlManager.updateQueryStringParam('status', this.state.status);
    this.props.app.urlManager.updateQueryStringParam('from', this.state.from);
    this.props.app.urlManager.updateQueryStringParam('to', this.state.to);
    this.props.app.urlManager.updateQueryStringParam('orgID', this.state.orgID);
    this.props.app.urlManager.updateQueryStringParam('userID', this.state.userID);
  }

  //UI
  render() {
    const items = [
      { label: 'Pending', key: 'pending', children: <div>{this._renderPendingTab()}</div> },
      { label: 'Search', key: 'search', children: <div>{this._renderSearchTab()}</div> },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        {this._renderRefundModal()}
        <PageHeader className="pageHeader" title="Product Orders" onBack={() => this.props.app.urlManager.pushBack()} />
        <WhiteBox>
          <Layout.Content>
            <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange} items={items} />
          </Layout.Content>
        </WhiteBox>
      </Layout.Content>
    );
  }
  /* private UI */
  _renderPendingTab() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <Layout.Content>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
            <Tooltip placement="bottomLeft" title="Refresh">
              <Button
                style={{ marginRight: '4px' }}
                type="primary"
                icon={<ReloadOutlined />}
                shape="circle"
                onClick={() => this.onRefresh()}
              />
            </Tooltip>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="secondary"
                icon={<DownloadOutlined />}
                onClick={() => this.generateReportXLSX(this.state.pendingOrders)}
                style={{ marginBottom: '8px' }}
                disabled={this.state.pendingOrders.length === 0}
              >
                Export to xlsx
              </Button>
            </div>
          </div>
          {this._renderTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  _renderSearchTab() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <Layout.Content>
          <div>{this._renderFilters()}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="secondary"
              icon={<DownloadOutlined />}
              onClick={() => this.generateReportXLSX(this.state.orders)}
              style={{ marginBottom: '8px' }}
              disabled={this.state.orders.length === 0}
            >
              Export to xlsx
            </Button>
          </div>
          {this._renderTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  _renderTable() {
    const { orders, pendingOrders, selectedTab } = this.state;
    const data = selectedTab === 'pending' ? pendingOrders : orders;
    return (
      <CommonProductOrdersTable
        app={this.props.app}
        isLoading={this.state.isLoading}
        orders={data}
        id={this.state.id}
        onDownload={this.handleDownloadOrder}
        onRefund={this.handleRefundOrder}
        handleMarkShipped={this.handleMarkShipped}
      />
    );
  }

  _renderFilters() {
    const canSelectOrg = this.state.selectedUser === null;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <CommonOrganizationSelectionInput
            app={this.props.app}
            isEdit={canSelectOrg}
            selectedOrg={this.state.selectedOrg}
            isAdmin={true}
            input={{
              id: 'orgID',
              label: 'Organization',
              required: false,
              placeholder: 'Please, select an organization',
            }}
            onSelection={this.handleSelectOrg}
            allowClear={true}
          />
          <Typography.Text style={{ margin: '0 8px', position: 'relative', top: '-8px', fontSize: '16px' }}>
            or
          </Typography.Text>
          <CommonUserSelectionInput
            app={this.props.app}
            selectedUser={this.state.selectedUser}
            input={{ id: 'userID', label: 'User', required: false, placeholder: 'Please, select a user' }}
            onSelection={this.handleSelectUser}
            allowClear={true}
            disabled={!!this.state.selectedOrg}
            userID={this.state.userID}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '16px' }}>
          <div>
            <Typography.Text strong>Date Range: </Typography.Text>
            <DatePicker.RangePicker
              allowClear
              onChange={this.handleDateChange}
              style={{ width: '400px' }}
              value={this.state.from && this.state.to ? [moment(this.state.from), moment(this.state.to)] : null}
            />
          </div>
          <div style={{ marginLeft: '20px' }}>
            <Typography.Text strong>Status: </Typography.Text>
            <Select
              allowClear
              style={{ width: '260px' }}
              placeholder="Status"
              onChange={this.handleSelectStatus}
              value={this.state.status || undefined}
            >
              <Select.Option value={Globals.Product_Order.COMPLETED} key={Globals.Product_Order.COMPLETED}>
                Completed
              </Select.Option>
              <Select.Option value={Globals.Product_Order.FAILED} key={Globals.Product_Order.FAILED}>
                Failed
              </Select.Option>
              <Select.Option
                value={Globals.Product_Order.PENDING_SHIPPING}
                key={Globals.Product_Order.PENDING_SHIPPING}
              >
                Pending Shipping
              </Select.Option>
              <Select.Option value={Globals.Product_Order.CANCELLED} key={Globals.Product_Order.CANCELLED}>
                Cancelled
              </Select.Option>
              <Select.Option value={Globals.Product_Order.REFUNDED} key={Globals.Product_Order.REFUNDED}>
                Refunded
              </Select.Option>
            </Select>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <Button type="primary" icon={<SearchOutlined />} onClick={this.handleSearch}>
              Search
            </Button>
          </div>
        </div>
      </div>
    );
  }
  _renderRefundModal() {
    return (
      <CommonProductOrderRefundModal
        isVisible={this.state.isVisibleRefund}
        app={this.props.app}
        {...Utils.propagateRef(this, 'refundModal')}
        onChange={this.handleRefundOrder}
      />
    );
  }
  /* private API */
  async _searchPendingProductOrders() {
    const filters = { filters: { status: Globals.Product_Order.PENDING_SHIPPING } };
    const resp = await this.props.app.license.productOrder.searchProductOrders(filters);
    if (resp.statusCode === 200) {
      this.setState({ pendingOrders: resp.body.productOrders });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  async _searchProductOrders() {
    const filters = {
      filters: {
        status: this.state.status,
        dateFrom: this.state.from,
        dateTo: this.state.to,
        externalID: this.state.selectedOrg ? this.state.orgID : this.state.userID,
      },
    };
    const resp = await this.props.app.license.productOrder.searchProductOrders(filters);
    if (resp.statusCode === 200) {
      this.setState({ orders: resp.body.productOrders });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  async _downloadOrder(externalID, orderID) {
    this.startLoading();
    const resp = await this.props.app.license.productOrder.getProductOrderReceipt(externalID, orderID);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${orderID}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.stopLoading();
  }

  async handleMarkShipped(externalID, orderID, shippingObj) {
    this.startLoading();
    const resp = await this.props.app.license.productOrder.markShipped(externalID, orderID, shippingObj);
    if (resp.statusCode === 200) {
      message.success('Order marked as shipped.');
      this.fetchDataByTab(this.state.selectedTab);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}
