import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message, Form, Input } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//
import CommonVenueForm from '../Forms/CommonVenueForm';
//props are: app, onUpdate
export default class CommonProductCategoriesDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, categoryID: null, data: null };
  }
  //Public
  show(categoryID) {
    this.setState({ isVisible: true, categoryID, data: null }, this._fetch);
  }

  resetFields() {
    this.form.resetFields();
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({ ...data });
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    return {
      ...formData,
    };
  }

  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isLoading: false, isVisible: false, categoryID: null, data: null });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.categoryID) this._update(formData);
    else this._create(formData);
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.categoryID ? 'Edit' : 'Create'} Category`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Row type="flex" justify="end">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleSubmit}>
              {this.state.categoryID ? 'Save' : 'Create'}
            </Button>{' '}
          </Col>
        </Row>
        {this._renderProductCategoriesForm()}
      </Drawer>
    );
  }

  //private UI
  _renderProductCategoriesForm() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={14}>
            <Form.Item label="ID" name="id" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input disabled={!!this.state.categoryID} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  // API Calls
  async _fetch() {
    if (!this.state.categoryID) return;
    this.setState({ isLoading: true });
    const resp = await this.props.app.license.category.getCategory(this.state.categoryID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ data: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _update(data) {
    console.log(data, 'data');
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.license.category.updateCategory({ ...data });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Category successfully updated!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
  async _create(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.license.category.createCategory({ ...data });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Category successfully created!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }
}
