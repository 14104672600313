import { saveAs } from 'file-saver';
import moment from 'moment';
import mime from 'mime-types';
//
import Globals from '../config/Globals';
//
export default class Utils { }
//Data handling helpers
Utils.setNestedObject = function (obj, prop, value) {
  let reference = obj;
  const a = prop.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let key = a[i];
    if (i + 1 != a.length) {
      //check if is not last object
      //safe check for sub.object
      if (reference[key] == null || reference[key] == undefined) {
        if (key == 0 || key == 1) reference = [];
        else reference = reference[key] = {};
      } else reference = reference[key];
    } else {
      reference[key] = value;
    }
  }
  return obj;
};
Utils.getNestedObject = function (obj, props) {
  let a = props.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    if (obj == null || obj == undefined) return undefined;
    let key = a[i];
    if (key in obj) {
      obj = obj[key];
    } else {
      return undefined;
    }
  }
  return obj;
};
Utils.safelyGetNumericNestedObject = function (obj, props) {
  return Utils.safeNumber(Utils.getNestedObject(obj, props));
};
Utils.safelySumNumericNestedValues = function (objs, props) {
  let retValue = 0;
  for (let obj of objs) {
    retValue += Utils.safelyGetNumericNestedObject(obj, props);
  }
  return retValue;
};
Utils.safeNumber = function (value) {
  //safe check for booleans
  if (value === true) return 1;
  //safe check for strings
  let returnValue = parseFloat(value);
  if (isNaN(returnValue)) return 0;
  return returnValue;
};
Utils.caseInsensitiveObjectForKey = function (obj, key) {
  if (!obj) return null;
  const insensitiveKey = Object.keys(obj).find((k) => k.toLowerCase() === key.toLowerCase());
  if (insensitiveKey && insensitiveKey != '') return obj[insensitiveKey];
  return null;
};
Utils.toDoubleDigit = function (str) {
  return String('0' + str).slice(-2);
};
Utils.toDateFormat = function (str) {
  if (str != undefined) {
    return str.split('-').join('/');
  }
  return '';
};
Utils.getZeroTimestampFromMomentDate = function (date) {
  date.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  return Utils.getTimestampFromMoment(date);
};
Utils.getEndOfDayTimestampFromMomentDate = function (date) {
  date.set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
  return Utils.getTimestampFromMoment(date);
};
Utils.businessDaysFromDate = function (date, businessDays) {
  let counter = 0; // set to 1 to count from next business day
  let retDate = null;
  while (businessDays > 0) {
    retDate = new Date();
    retDate.setDate(date.getDate() + counter++);
    switch (tmp.getDay()) {
      case 0:
      case 6:
        break; // sunday & saturday
      default:
        businessDays--;
    }
  }
  return retDate;
};
Utils.toCurrencyFormat = function (str) {
  if (str !== undefined) {
    str = parseFloat(str);
    if (isNaN(str)) return '0.00';
    return str.toLocaleString('en', { minimumFractionDigits: '2', maximumFractionDigits: 2 });
  }
  return '0.00';
};
Utils.camelizeString = function (str) {
  return str
    .split(' ')
    .map((w) => w.replace(/./, (m) => m.toUpperCase()))
    .join(' ');
};

Utils.formatLabel = function (label) {
  return label
    .toLowerCase().replace(/_/g, ' ').replace(/^./, match => match.toUpperCase());
}
//Currency manipulation
Utils.safelyFixCurrency = function (value, toFix) {
  if (!toFix) toFix = 2;
  let rountTo = Math.pow(10, toFix);
  return parseFloat(parseFloat(Math.round(value * rountTo) / rountTo).toFixed(toFix));
};
Utils.capitalizeString = function (str) {
  return str
    .split(' ')
    .map((w) => w.replace(/./, (m) => m.toUpperCase()))
    .join(' ');
};

//Color
Utils.randomHexColor = function () {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

//FORM HELPER
Utils.defaultFormChangeHandler = function (event, object) {
  const targetID = event.target.id;
  let targetValue;
  //Choose where to get value
  if (event.target.type == 'checkbox') {
    targetValue = event.target.checked;
  } else if (event.target.type == 'select') {
    targetValue = event.target.value;
  } else {
    targetValue = event.target.value;
  }
  //set state
  object.state = Utils.setNestedObject(object.state, targetID, targetValue);
  object.setState({ ...object.state });
};

//DATES
Utils.getMonthDescription = function (month) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[month - 1];
};
Utils.getQuarterMonths = function (quarter) {
  const base = (quarter - 1) * 3;
  return [
    base + 1 < 10 ? `0${base + 1}` : base + 1,
    base + 2 < 10 ? `0${base + 2}` : base + 2,
    base + 3 < 10 ? `0${base + 3}` : base + 3,
  ];
};
Utils.getMonthName = function (month) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (month > monthNames.length) return null;
  return monthNames[month - 1];
};
Utils.getReportDateRange = function (year) {
  const now = year ? new Date(year, 11, 31) : new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const currentQuarter = Math.floor((now.getMonth() + 3) / 3);

  const availableYears = [];
  const availableMonths = [];
  const availableQuarters = [];

  for (let i = currentYear - 4; i <= currentYear; i++) {
    availableYears.push(i);
  }

  for (let i = 0; i < now.getMonth() + 1; i++) {
    availableMonths.push(i + 1 < 10 ? `0${i + 1}` : i + 1);
    if (i == 0 || i % 3 == 0) availableQuarters.push(availableQuarters.length + 1);
  }

  return {
    currentYear,
    currentMonth,
    currentQuarter,
    availableMonths,
    availableQuarters,
    availableYears,
  };
};
//date calculation
Utils.timestampAfterMinutes = function (minutes) {
  const date = new Date();
  date.setMinutes(date.getMinutes() + minutes);
  return date.getTime();
};
Utils.timestampAfterMinutesAtEOD = function (minutes) {
  const date = new Date();
  date.setHours(23);
  date.setMinutes(date.getMinutes() + minutes);
  date.setSeconds(59);
  return date.getTime();
};
Utils.timestampAfterMinutesFromDate = function (minutes, date) {
  date = new Date(date);
  date.setMinutes(date.getMinutes() + minutes);
  return date.getTime();
};
Utils.timestampAfterDays = function (days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.getTime();
};
Utils.timestampAfterDaysAtEOD = function (days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date.getTime();
};
Utils.timestampBeforeMonthsFromDate = function (months, date) {
  date = new Date(date);
  date.setMonth(date.getMonth() - months);
  return date.getTime();
};
Utils.timestampBeforeYearsFromDate = function (years, date) {
  date = new Date(date);
  date.setYear(date.getFullYear() - years);
  return date.getTime();
};
Utils.timestampAfterYears = function (years) {
  const date = new Date();
  date.setYear(date.getFullYear() + years);
  return date.getTime();
};
Utils.timestampAfterYearsFromDate = function (years, date) {
  date = new Date(date);
  date.setYear(date.getFullYear() + years);
  return date.getTime();
};
//
Utils.getDateOnCardFormatByTimestamp = function (timestamp) {
  return Utils.getDateOnCardFormat(new Date(timestamp));
};
Utils.getDateOnCardFormat = function (date) {
  if (!date || isNaN(date.getTime())) return '-';
  //optional day field
  let day = Utils.toDoubleDigit(date.getDate());
  //month is index from 0 to 11
  let month = Utils.toDoubleDigit(date.getMonth() + 1);
  return '' + day + '/' + month + '/' + date.getFullYear();
};
Utils.getDateOnMomentFormatByTimestamp = function (timestamp) {
  return Utils.getDateOnMomentFormat(new Date(timestamp));
};
Utils.getDateOnMomentFormat = function (date) {
  return moment(date, Globals.DefaultUIDateTimeFormat);
};
Utils.getCardExpirationFromMoment = function (mDate) {
  const resp = moment(mDate).format('MMYY');
  if (isNaN(resp)) return null;
  return resp;
};
Utils.getTimestampFromMoment = function (mDate) {
  return moment(mDate).toDate().getTime();
};
Utils.getCurrentDateOnUIFormat = function (excludeDay) {
  return Utils.getDateOnUIFormat(new Date(), excludeDay);
};
Utils.getDateOnUIFormat = function (date, excludeDay) {
  if (!date || isNaN(date.getTime())) return '-';
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  let day = excludeDay ? '' : '-' + Utils.toDoubleDigit(date.getDate());
  //month is index from 0 to 11
  let month = Utils.toDoubleDigit(date.getMonth() + 1);
  return '' + date.getFullYear() + '-' + month + day;
};
Utils.getDateOnUIFormatCoolDown = function (date, excludeDay) {
  if (!date || isNaN(date.getTime())) return '-';
  let nextDay = new Date(date);
  //optional day field
  if (excludeDay === undefined) excludeDay = false;

  let day = '';
  if (!excludeDay) {
    nextDay.setDate(nextDay.getDate() + 1);
    day = '-' + Utils.toDoubleDigit(nextDay.getDate());
  }
  //month is index from 0 to 11
  let month = Utils.toDoubleDigit(nextDay.getMonth() + 1);
  return '' + nextDay.getFullYear() + '-' + month + day;
};
Utils.getDateOnUIFormatByTimestamp = function (timestamp, excludeDay) {
  return Utils.getDateOnUIFormat(new Date(timestamp), excludeDay);
};
Utils.getDateOnSessionFormatByTimestamp = function (timestamp) {
  const date = new Date(timestamp);
  const month = date.toLocaleString('default', { month: 'long' });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};
Utils.getTimesOnSessionFormatByTimestamps = function (startTimestamps, endTimestamps, timezone) {
  let times = '';
  let timezoneSuffix = 'PST';

  if (timezone === 'America/Winnipeg') {
    timezoneSuffix = 'CDT';
  } else if (timezone === 'America/New_York') {
    timezoneSuffix = 'EST';
  } else {
    timezoneSuffix = 'PST';
  }

  if (startTimestamps && startTimestamps.length > 1) {
    for (let i = 0; i < startTimestamps.length; i++) {
      const date = new Date(startTimestamps[i]);
      const startDateTimeString = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone,
      });
      const startDateTimeWithTimeZone = `${startDateTimeString} ${timezoneSuffix}`;
      const endDate = new Date(endTimestamps[i]);
      const endDateTimeString = endDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone,
      });
      const endDateTimeWithTimeZone = `${endDateTimeString} ${timezoneSuffix}`;
      times += `Start time: ${startDateTimeWithTimeZone} (please arrive on time)\n`;
      times += `End time: ${endDateTimeWithTimeZone}\n`;
    }
  } else if (startTimestamps && startTimestamps.length === 1) {
    const date = new Date(startTimestamps[0]);
    const startDateTimeString = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: timezone,
    });
    const startDateTimeWithTimeZone = `${startDateTimeString} ${timezoneSuffix}`;
    const endDate = new Date(endTimestamps[0]);
    const endDateTimeString = endDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: timezone,
    });
    const endDateTimeWithTimeZone = `${endDateTimeString} ${timezoneSuffix}`;
    times += `Start time: ${startDateTimeWithTimeZone} (please arrive on time)\n`;
    times += `End time: ${endDateTimeWithTimeZone}`;
  }
  return times;
};

//Date formatting
Utils.getCurrentDateAndTimeOnUIFormat = function () {
  return Utils.getDateAndTimeOnUIFormat(new Date());
};
Utils.getDateAndTimeOnUIFormatByTimestamp = function (timestamp) {
  return Utils.getDateAndTimeOnUIFormat(new Date(timestamp));
};
Utils.getTimeInAmPmFormatFromTimestamp = function (timestamp) {
  return Utils.getTimeInAmPmFormat(new Date(timestamp));
};
Utils.getTimeInAmPmFormat = function (date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};
Utils.getDateAndTimeOnUIFormat = function (date) {
  if (!date || isNaN(date.getTime())) return '-';
  let day = '-' + Utils.toDoubleDigit(date.getDate());
  let month = Utils.toDoubleDigit(date.getMonth() + 1);
  const fullDate = '' + date.getFullYear() + '-' + month + day;

  return `${fullDate} ${Utils.getTimeInAmPmFormat(date)}`;
};
Utils.getDateAndTimeOnPrintFormatByTimestamp = function (timestamp) {
  return Utils.getDateAndTimeOnPrintFormat(new Date(timestamp));
};
Utils.getDateAndTimeOnPrintFormat = function (d) {
  if (!d || isNaN(d.getTime())) return '-';
  return `${Utils.getMonthName(d.getMonth() + 1)} ${Utils.toDoubleDigit(d.getDate())}, ${d.getFullYear()}`;
};
Utils.getIntervalString = function (interval) {
  if (interval < 60) return `${interval} minutes`;
  else if (interval / 60 > 24) {
    const days = parseInt(interval / 60 / 24);
    if (days == 1) return `1 day`;
    return `${days} days`;
  } else {
    const hours = parseInt(interval / 60);
    if (hours == 1) return `1 hour`;
    return `${hours} hours`;
  }
};

//BROWSER stuff
Utils.downloadArrayBuffer = function (data, fileName, fileType) {
  var byteArray = new Uint8Array(data);
  const blob = new Blob([byteArray]);
  saveAs(blob, `${fileName}.${fileType}`);
};
Utils.getFileExtensionFromMimeType = function (mimeType) {
  return mime.extension(mimeType);
};
Utils.downloadBlob = function (blob, fileName, fileType) {
  saveAs(blob, `${fileName}.${fileType}`);
};
Utils.isFirefox = function () {
  // Firefox 1.0+ - more here https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs
  return typeof InstallTrigger !== 'undefined';
};

//REACT stuff
//propagate ref child to get referece
Utils.propagateRef = function (parent, props) {
  return {
    ref: (_ref) => Utils.setNestedObject(parent, props, _ref),
  };
};

//Ant design form helpers
Utils.defaultCurrenyInputFormatter = function (value) {
  if (value < 0) {
    return `-$${parseFloat(value * -1).toFixed(2) || parseFloat(0).toFixed(2)}`.replace(
      /(\d)(?=-(\d{3})+(?!\d))/g,
      '$1,'
    );
  } else {
    return `$${parseFloat(value).toFixed(2) || parseFloat(0).toFixed(2)}`.replace(/(\d)(?=-(\d{3})+(?!\d))/g, '$1,');
  }
};
Utils.defaultCurrentInputParser = function (value) {
  return value.replace(/\$\s?|(,*)/g, '');
};

Utils.validateUniqueID = function (rule, value) {
  return new Promise((resolve, reject) => {
    const validPattern = /^[a-zA-Z0-9-]+$/;

    if (!validPattern.test(value)) {
      reject('Unique ID can only contain letters, numbers and the - character');
    } else {
      resolve();
    }
  });
};

//Thread helper
Utils.shortRandSleep = async function () {
  const delay = Math.random() * (100 - 50) + 50;
  return new Promise((resolve) => setTimeout(resolve, delay));
};
Utils.sleep = async function (delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

//Connection helper
Utils.execRequests = async (reqs, failureCallback, respCallback, failIfAnyFailure) => {
  //cleanup
  for (let reqIdx of reqs) {
    if (!reqs[reqIdx]) reqs[reqIdx] = new Promise((res) => res({ statusCode: 200 }));
  }
  //Make calls concurrency
  const resps = await Promise.all(reqs);
  const failure = resps.find((resp) => resp && resp.statusCode != 200);
  if (failure) {
    if (failureCallback) await failureCallback(failure);
    if (failIfAnyFailure) return false;
  }
  //
  for (let respIdx in resps) await respCallback(resps[respIdx], respIdx);
  return true;
};
Utils.replacePlaceholders = function (string, { config }) {
  const placeholdersValues = {
    '{ORG_NAME}': config.organizationName,
    '{APP_NAME}': config.applicationName,
  };

  const matches = string.match(/{.*?\}/g);
  if (!matches || matches.length < 1) {
    return string;
  }

  let newString = string;
  matches.forEach((placeholder) => {
    const placeholderValue = placeholdersValues[placeholder];

    if (placeholderValue) {
      newString = newString.replace(placeholder, placeholderValue);
    }
  });

  return newString;
};

Utils.separateArrayByGroups = function (items, groupSize) {
  const groups = [];

  items.forEach((item, index) => {
    const groupIndex = Math.floor(index / groupSize);
    if (!groups[groupIndex]) groups[groupIndex] = [];

    groups[groupIndex].push(item);
  });

  return groups;
};
Utils.splitFilenameAndExtension = function (fileName) {
  const splittedName = fileName?.split('.');
  const extension = splittedName?.[splittedName.length - 1];
  const filename = fileName?.slice(0, -(extension?.length + 1));

  return { filename, extension };
};
Utils.openFileInNewTab = function (fileUrl, fileName) {
  const { extension } = Utils.splitFilenameAndExtension(fileName);

  const previewWindow = window.open();
  previewWindow.document.location.href = `/${fileName}`;

  if (extension?.toLowerCase() == 'pdf') {
    previewWindow.document.open().write(`<iframe src="${fileUrl}" frameBorder="0" width="100%" height="100%" />`);
  } else {
    previewWindow.document.open().write(`<img src="${fileUrl}" />`);
  }

  previewWindow.document.body.style =
    'background: #000; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;';
  previewWindow.document.title = fileName;
};

//Markdown (react-rte)
Utils.markdownToolbarItems = function (isTitle) {
  return {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
      'BLOCK_ALIGNMENT_BUTTONS',
    ],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Strikethrough', style: 'STRIKETHROUGH' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      ...(!isTitle ? [{ label: 'Heading Large', style: 'header-one' }] : []),
      ...(!isTitle ? [{ label: 'Heading Medium', style: 'header-two' }] : []),
      ...(!isTitle ? [{ label: 'Heading Small', style: 'header-three' }] : []),
    ],
    ...(!isTitle
      ? {
        BLOCK_TYPE_BUTTONS: [
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' },
        ],
      }
      : {}),
    // ...(!isTitle ? { BLOCK_ALIGNMENT_BUTTONS: [
    //   { label: 'Align Left', style: 'ALIGN_LEFT' },
    //   { label: 'Align Center', style: 'ALIGN_CENTER' },
    //   { label: 'Align Right', style: 'ALIGN_RIGHT' },
    //   { label: 'Align Justify', style: 'ALIGN_JUSTIFY' },
    // ]} : {}),
  };
};
Utils.markdownEditorValidationObject = function (value) {
  return {
    required: true,
    validator: () =>
      new Promise((re, rj) => {
        return (value || '').toString('markdown').trim().length <= 1 ? rj('This field is required!') : re();
      }),
  };
};

Utils.sanitizeMarkdownContent = function (content) {
  if (!content) return content;
  content = content.replace(/(\+\+)(\*\*[^*]+?\*\*)(\+\+)/g, '$2');
  content = content.replace(/\*\*{2,}/g, '');

  return content;
};

Utils.debounce = function (fn, time) {
  let timeoutId = null;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, time, ...args);
  };
};

Utils.renderYesNo = function (value) {
  return !!value ? 'Yes' : 'No';
};
