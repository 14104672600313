// Log rocket
import { logRocketInit } from './logRocketSetup';
//Polyfills
import './polyfills';
import * as ponyfill from 'web-streams-polyfill/ponyfill';
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// Components
import App from './app/App';
import config from './app/config/config';
//
import './app/assets/stylesheets/index.less';
import 'antd/dist/antd.less';
//
const StreamSaver = require('streamsaver');
StreamSaver.WritableStream = ponyfill.WritableStream;
StreamSaver.mitm = window.location.origin + '/wb/mitm.html';
//Log rocket
logRocketInit();
// Render router with App
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
