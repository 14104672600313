import React from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import { Layout, Menu, Button, Popconfirm, Tag, Row, Col, Typography, Alert, Spin, message } from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
  GlobalOutlined,
  CreditCardOutlined,
  SettingOutlined,
  ContainerOutlined,
  SearchOutlined,
  HomeOutlined,
  FileDoneOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  MenuOutlined,
  CloseOutlined,
  FileSearchOutlined,
  CalendarOutlined,
  PieChartOutlined,
  MailOutlined,
  UserAddOutlined,
  FormOutlined,
  HistoryOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { isIE } from 'react-device-detect';
//
import CustomComponent from './components/CustomComponent';
import AppRoutes from './AppRoutes';
//
import Logo from './views/commonComponents/Logo';
import IDMUserBadge from './views/commonComponents/IDMUserBadge';
import CommonLoadingView from './views/commonComponents/CommonLoadingView';
import PendingInvitationsModal from './views/commonComponents/Modals/PendingInvitationsModal';
import AdminUsersReportModal from './views/commonComponents/Modals/AdminUsersReportModal';
//
import CommonContactModal from './views/commonComponents/Modals/CommonContactModal';
//
import config from './config/config';
import Globals from './config/Globals';
//
import './assets/stylesheets/AppViewController.less';
import Utils from './components/Utils';
//
const { Content, Sider, Header } = Layout;

export default class AuthorizedViewController extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    // reset history state if restarting app
    this.props.app.props.history.location.state = undefined;
    // bind contact modal global action
    this.props.app.openContactModal = this.handleContactShow.bind(this);
    // set title
    document.title = this.props.app.themeManager.theme.applicationName;
    //
    this.state = {
      //sidebar
      collapsed: false,
      isSidebarVisible: true,
      //state and modals
      isLoading: false,
      contactFormVisible: false,
      isUsersReportModalVisible: true,
      // Org switcher
      visibleOrgSwitcher: false,
      isLoadingOrgSwitcher: false,
      selectedOrgID: this.props.app.urlManager.selectedOrg,
      orgs: [],
    };
  }
  //Life cycle
  async componentDidMount() {
    const user = this.props.app.sharedCache().getProgramUser();
    //Show invitation modal?
    if (user) this.pendingInvitationsModal.attemptToShow(user.email);
    //Show org switch?
    if (this.props.app.isOrgManager()) this._showOrgSwitcher(user?.managerOf);
    //Is sidebar not visible due to width reasons?
    if (window.innerWidth < 940) this.setState({ collapsed: true, isSidebarVisible: window.innerWidth > 650 });
    //Load simple suer obj
    const userObj = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObj });
    //
    await this._checkForValidAuthorization();
  }

  // Actions
  //User badge
  async handleLogout() {
    this.setState({ isLoading: true }, async () => {
      await this.props.app.idm.authenticator.logout();
      this.props.app.sharedCache().clearCache();
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.login);
      await this.props.app.redirectToAuth();
    });
  }
  handleChangePassword() {
    this.props.app.idm.user.changePassword({ tenantID: this.props.app.tenantID });
  }
  handleRegisterOrg() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.registerOrg);
  }
  handleProfile() {
    this.props.app.urlManager.redirectToPortal(Globals.PortalFEPath_UserProfile);
  }
  //Sidebar
  handleCollapseSidebar() {
    this.setState({ collapsed: !this.state.collapsed });
  }
  handleToggleShowSidebar() {
    this.setState({ isSidebarVisible: !this.state.isSidebarVisible });
  }
  //Contact modal
  handleContactShow() {
    this.setState({ contactFormVisible: true }, () => {
      if (this.contactModal) this.contactModal.loadAuthorizedInfo();
    });
  }
  handleContactClose() {
    this.setState({ contactFormVisible: false });
  }
  //Sidebar items
  handleSelect(i) {
    if (i.key == config.ApplicationRoutes.licenses + 'my' || i.key == config.ApplicationRoutes.licenses + 'org')
      this._actionLicensePageLink();
    else if (i.key == config.ApplicationRoutes.productOrders + 'org') this._actionProductOrderPageLink();
    else if (i.key == 'user-report') this.usersReportModal.show();
    else if (i.key == config.ApplicationRoutes.profile)
      this.props.app.urlManager.redirectToPortal(Globals.PortalFEPath_UserProfile);
    else if (i.key == 'create-new-org') this.props.app.urlManager.pushPage(config.ApplicationRoutes.registerOrg);
    else if (this.state.orgs?.find((o) => o.orgID == i.key)) this.props.app.urlManager.switchOrg(i.key);
  }
  async handleExitOrg(org, e) {
    if (e) e.stopPropagation();
    this.startLoading();
    const removed = await this._selfRemoveAsManager(org);
    if (removed) {
      message.loading('Please, allow a few seconds to have you removed from the company.', 30);
      await Utils.sleep(30000);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage);
      window.location.reload();
    }
    this.stopLoading();
  }
  handleStopPropagation(e) {
    if (e) e.stopPropagation();
  }

  // UI
  render() {
    return (
      <Layout className="container-layout">
        {this._renderUserReportModal()}
        {isIE && <Alert message={Globals.CompabilityMessage} banner />}
        {this._renderHeader()}
        {this._renderPendingInvitationsModal()}
        <Layout>
          {this._renderUserNavigationBar()}
          <Layout>
            <Content className="main-content" id="main-content">
              <CommonLoadingView isLoading={this.state.isLoading} />
              <AppRoutes appRef={this} />
            </Content>
            {this._renderFooter()}
            {this._renderContactModal()}
          </Layout>
        </Layout>
      </Layout>
    );
  }

  /* private UI */
  _renderHeader() {
    return (
      <Header className="appHeader">
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <a href={this.props.app.themeManager.theme.config.websiteLink} className="bccsaHeaderLogo" target="_blank">
              <Logo app={this.props.app} isLeft />
            </a>
            {!this.props.app.isUser() && (
              <div className="mobileHeaderMenu">
                {!this.state.isSidebarVisible && <MenuOutlined onClick={this.handleToggleShowSidebar} />}
                {this.state.isSidebarVisible && <CloseOutlined onClick={this.handleToggleShowSidebar} />}
              </div>
            )}
          </Col>
          <Col className="headerMiddleLogo">
            {' '}
            <Logo app={this.props.app} isCenter authorized />{' '}
          </Col>
          <Col>
            {this.props.app.isSysAdmin() && (
              <Tag
                style={{
                  color: this.props.app.themeManager.primaryColor,
                  borderColor: this.props.app.themeManager.primaryColor,
                  marginRight: 30,
                }}
              >
                SysAdmin
              </Tag>
            )}
            {this.props.app.idm.isLogged() && (
              <IDMUserBadge
                app={this.props.app}
                user={this.state.userObj}
                onChangePassword={this.handleChangePassword}
                onLogout={this.handleLogout}
                onRegisterOrg={this.handleRegisterOrg}
                onProfile={this.handleProfile}
              />
            )}
          </Col>
        </Row>
      </Header>
    );
  }
  _renderUserNavigationBar() {
    // this should not happen, but it take our precautions :p
    if (!this.props.app.idm.isLogged()) return <></>;
    if (!this.state.isSidebarVisible || this.props.app.isUser()) return <></>;
    //helpers
    const pathName = this.props.app.props.history.location.pathname;
    let pendingOrdersCount = this.props.app.sharedCache().getPendingOrders()?.length || 0;
    let pendingApplicationsCount = this.props.app.sharedCache().getPendingApplications()?.length || 0;
    let pendingStudentsRequestsCount = this.props.app.sharedCache().getPendingApprovals()?.length || 0;

    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    //flags
    const {
      showSessions,
      showPendingOrders,
      showPendingApplications,
      showApplicationDashboard,
      showEmailCertificates,
    } = tenantConfig.menuDisplayOptions || {};
    const { orgMgrCanEnrolBySession } = tenantConfig;
    //optional comp
    const orgSwitcher = this._getOrgSwitcher();
    return (
      <Sider theme="light" collapsible collapsed={this.state.collapsed} onCollapse={this.handleCollapseSidebar}>
        <Menu
          theme="light"
          className="sideNavigation"
          style={{ height: '100%' }}
          selectedKeys={[pathName]}
          mode="vertical"
          onClick={this.handleSelect}
          items={[
            ...(orgSwitcher ? orgSwitcher : []),
            ...(this.props.app.isAdmin() && showApplicationDashboard
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.appStats}>
                        <PieChartOutlined /> Application Stats
                      </Link>
                    ),
                    key: config.ApplicationRoutes.appStats,
                  },
                ]
              : []),
            ...(this.props.app.isUser()
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.homepage}>
                        <HomeOutlined /> Home
                      </Link>
                    ),
                    key: config.ApplicationRoutes.homepage,
                  },
                ]
              : []),
            ...(this.props.app.isUser()
              ? [
                  {
                    label: (
                      <>
                        <FileDoneOutlined />
                        {this.state.collapsed ? '' : ' My Licenses'}
                      </>
                    ),
                    key: config.ApplicationRoutes.licenses + 'my',
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager()
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.orgManagerSearch}>
                        <SearchOutlined />
                        {this.state.collapsed ? '' : ' User Search'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.orgManagerSearch,
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager()
              ? [
                  {
                    label: (
                      <>
                        <FileDoneOutlined />
                        {this.state.collapsed ? '' : ' Org Licenses'}
                      </>
                    ),
                    key: config.ApplicationRoutes.licenses + 'org',
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager() && tenantConfig.licModPurchaseEnabled
              ? [
                  {
                    label: (
                      <>
                        <ShoppingOutlined />
                        {this.state.collapsed ? '' : ' Product Orders'}
                      </>
                    ),
                    key: config.ApplicationRoutes.productOrders + 'org',
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager() && !tenantConfig.orgModEmployeeMode
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.invitations}>
                        <UserOutlined />
                        {this.state.collapsed ? '' : ' Invite Students'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.invitations,
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager() && tenantConfig.licModVaultingEnabled
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.simpleOrgBillingView}>
                        <CreditCardOutlined />
                        {this.state.collapsed ? '' : ' Org Billing'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.simpleOrgBillingView,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin()
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.userSearch}>
                        <SearchOutlined />
                        {this.state.collapsed ? '' : ' User Search'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.userSearch,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() || (this.props.app.isOrgManager() && orgMgrCanEnrolBySession)
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.registerStudents}>
                        <UserAddOutlined />
                        {this.state.collapsed ? '' : ' Enroll Students'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.registerStudents,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() && showSessions
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.sessionsHome}>
                        <CalendarOutlined />
                        {this.state.collapsed ? '' : ' Sessions'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.sessionsHome,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() && !tenantConfig.orgModDisabled
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.organizations}>
                        <GlobalOutlined />
                        {this.state.collapsed ? '' : ' Organizations'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.organizations,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() && tenantConfig.licModPurchaseEnabled
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.adminProductOrders}>
                        <ShoppingOutlined />
                        {this.state.collapsed ? '' : ' Product Orders'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.adminProductOrders,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() && showEmailCertificates
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.emailCertificates}>
                        <MailOutlined />
                        {this.state.collapsed ? '' : ' Email Certificates'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.emailCertificates,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() && showPendingApplications
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.pendingApplications}>
                        <div className={`waivesBadge pendingApplications ${!this.state.collapsed ? 'expanded' : ''}`}>
                          {' '}
                          {pendingApplicationsCount > 99 ? '99+' : pendingApplicationsCount}{' '}
                        </div>
                        <FileSearchOutlined />
                        <span>{this.state.collapsed ? '' : ' Pending Applications'}</span>
                      </Link>
                    ),
                    key: config.ApplicationRoutes.pendingApplications,
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager() && !tenantConfig.orgModEmployeeMode
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.invitationHistory}>
                        <HistoryOutlined />
                        {this.state.collapsed ? '' : ' Invitation History'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.invitationHistory,
                  },
                ]
              : []),
            ...(this.props.app.isOrgManager()
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.orgManagerSettings}>
                        <SettingOutlined />
                        {this.state.collapsed ? '' : 'Settings'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.orgManagerSettings,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin()
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.studentsRequests}>
                        <div className={`waivesBadge studentsRequests ${!this.state.collapsed ? 'expanded' : ''}`}>
                          {' '}
                          {pendingStudentsRequestsCount > 99 ? '99+' : pendingStudentsRequestsCount}{' '}
                        </div>
                        <FormOutlined />
                        <span>{this.state.collapsed ? '' : 'Students Requests'}</span>
                      </Link>
                    ),
                    key: config.ApplicationRoutes.studentsRequests,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin() && showPendingOrders
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.pendingOrders}>
                        <ShoppingCartOutlined />
                        <div className={`waivesBadge ${!this.state.collapsed ? 'expanded' : ''}`}>
                          {' '}
                          {pendingOrdersCount > 99 ? '99+' : pendingOrdersCount}{' '}
                        </div>
                        <span>{this.state.collapsed ? '' : ' Pending Orders'}</span>
                      </Link>
                    ),
                    key: config.ApplicationRoutes.pendingOrders,
                  },
                ]
              : []),
            ...(this.props.app.isAdmin()
              ? [
                  {
                    label: (
                      <>
                        <ContainerOutlined />
                        {this.state.collapsed ? '' : ' Reports'}
                      </>
                    ),
                    key: 'reportsSubmenu',
                    children: [
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.REVENUE_PERIOD)
                        ? [
                            {
                              label: (
                                <Link to={config.ApplicationRoutes.revenuePerPeriodReport}>Revenue per period</Link>
                              ),
                              key: config.ApplicationRoutes.revenuePerPeriodReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.AFFILIATE_COMMISION)
                        ? [
                            {
                              label: (
                                <Link to={config.ApplicationRoutes.affiliateComissionReport}>
                                  Affiliates commission
                                </Link>
                              ),
                              key: config.ApplicationRoutes.affiliateComissionReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.CERTIFICATION_PERIOD)
                        ? [
                            {
                              label: (
                                <Link to={config.ApplicationRoutes.achievedCertificationsPerPeriodReport}>
                                  Achieved per period
                                </Link>
                              ),
                              key: config.ApplicationRoutes.achievedCertificationsPerPeriodReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.EXPIRED_CERTIFICATIONS)
                        ? [
                            {
                              label: (
                                <Link to={config.ApplicationRoutes.expiredCertificationsReport}>
                                  Expired certifications
                                </Link>
                              ),
                              key: config.ApplicationRoutes.expiredCertificationsReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.APPLICATIONS_EXPORT)
                        ? [
                            {
                              label: <Link to={config.ApplicationRoutes.applicationReport}>Applications</Link>,
                              key: config.ApplicationRoutes.applicationReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.APPLICATION_STATUS)
                        ? [
                            {
                              label: <Link to={config.ApplicationRoutes.applicationStatus}>Application Status</Link>,
                              key: config.ApplicationRoutes.applicationStatus,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.CUSTOM_USERS)
                        ? [
                            {
                              label: <>Export</>,
                              key: 'user-report',
                            },
                          ]
                        : []),
                      ...(!tenantConfig.orgModDisabled
                        ? [
                            {
                              label: <Link to={config.ApplicationRoutes.orgLicensesReport}>Organization licenses</Link>,
                              key: config.ApplicationRoutes.orgLicensesReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.EXAM_ANALYSIS)
                        ? [
                            {
                              label: <Link to={config.ApplicationRoutes.examAnalysisReport}>Exam Analysis</Link>,
                              key: config.ApplicationRoutes.examAnalysisReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.SESSIONS_LIST)
                        ? [
                            {
                              label: <Link to={config.ApplicationRoutes.sessionsListReport}>Sessions List</Link>,
                              key: config.ApplicationRoutes.sessionsListReport,
                            },
                          ]
                        : []),
                      ...(tenantConfig.reportTypes?.includes(Globals.REPORT_TYPES.SESSIONS_RESULTS)
                        ? [
                            {
                              label: <Link to={config.ApplicationRoutes.sessionResultsReport}>Sessions Results</Link>,
                              key: config.ApplicationRoutes.sessionResultsReport,
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : []),
            ...(this.props.app.isAdmin()
              ? [
                  {
                    label: (
                      <Link to={config.ApplicationRoutes.settings}>
                        <SettingOutlined />{' '}
                        {this.state.collapsed
                          ? ''
                          : this.props.app.sharedCache().getTenantConfig()?.settingsDisplayOptions?.itemText ||
                            'Settings'}
                      </Link>
                    ),
                    key: config.ApplicationRoutes.settings,
                  },
                ]
              : []),
            ...(this.props.app.isUser()
              ? [
                  {
                    label: (
                      <>
                        <UserOutlined />
                        {this.state.collapsed ? '' : ' Profile'}
                      </>
                    ),
                    key: config.ApplicationRoutes.profile,
                  },
                ]
              : []),
          ]}
        />
      </Sider>
    );
  }
  _renderFooter() {
    return (
      <Row type="flex" justify="end" align="middle" className="appFooter">
        <Col>
          <Typography.Text className="appFooterColumn">
            <a className="appFooterColumn" href={this.props.app.themeManager.theme.config.websiteLink} target="_blank">
              {' '}
              {this.props.app.themeManager.theme.config.websiteLabel}{' '}
            </a>
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text className="appFooterColumn">
            <a className="appFooterColumn" key="contactUs" onClick={this.handleContactShow}>
              {' '}
              Contact Us{' '}
            </a>
          </Typography.Text>
        </Col>
        <Col>
          {' '}
          <Logo app={this.props.app} isFooter />{' '}
        </Col>
      </Row>
    );
  }
  /* private UI comps */
  _getOrgSwitcher() {
    if (!this.state.visibleOrgSwitcher) return null;
    const { isLoadingOrgSwitcher, orgs } = this.state;
    const selectedOrg = orgs?.find((org) => org.orgID == this.state.selectedOrgID);
    const { orgModAllowOrgSelfRegistration } = this.props.app.sharedCache().getTenantConfig();
    return [
      {
        label: isLoadingOrgSwitcher ? 'Loading...' : selectedOrg?.name,
        key: 'orgSubmenu',
        className: 'orgSwitcher',
        icon: isLoadingOrgSwitcher ? <Spin size="small" style={{ marginRight: 10 }} /> : <GlobalOutlined />,
        children: orgs
          ?.map((org) => ({
            key: org.orgID,
            label: (
              <Row type="flex" justify="space-between">
                <Col> {org.name} </Col>
                <Col>
                  <Popconfirm
                    placement="bottom"
                    title={`Are you sure that exit the organization ${org.name}?`}
                    onConfirm={this.handleExitOrg.bind(this, org)}
                    okText="Yes"
                    cancelText="No"
                    zIndex={99999}
                  >
                    <Button
                      onClick={this.handleStopPropagation}
                      style={{ marginLeft: 10, opacity: 0.7 }}
                      shape="circle"
                      type="danger"
                      size="small"
                    >
                      <MinusOutlined />
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            ),
          }))
          ?.concat(
            orgModAllowOrgSelfRegistration
              ? [
                  {
                    key: 'create-new-org',
                    label: (
                      <Row type="flex" justify="space-between">
                        <Col> Register new organization </Col>
                        <Col>
                          <Button
                            onClick={this.handleRegisterOrg}
                            style={{ marginLeft: 10, opacity: 0.7 }}
                            shape="circle"
                            type="secondary"
                            size="small"
                          >
                            <PlusOutlined />
                          </Button>
                        </Col>
                      </Row>
                    ),
                  },
                ]
              : []
          ),
      },
    ];
  }
  /* modals */
  _renderUserReportModal() {
    return <AdminUsersReportModal app={this.props.app} {...Utils.propagateRef(this, 'usersReportModal')} />;
  }
  _renderPendingInvitationsModal() {
    return <PendingInvitationsModal {...Utils.propagateRef(this, 'pendingInvitationsModal')} app={this.props.app} />;
  }
  _renderContactModal() {
    return (
      <CommonContactModal
        isVisible={this.state.contactFormVisible}
        app={this.props.app}
        handleCancel={this.handleContactClose}
        handleSubmissionComplete={this.handleContactClose}
        {...Utils.propagateRef(this, 'contactModal')}
      />
    );
  }

  /* private helpers */
  async _checkForValidAuthorization() {
    //Rare case where user has two tabs open, no Prog. access but logs in on IDM
    //Prog. gets notified with valid session, however, no app access - handle logout
    if (!(this.props.app.isAdmin() || this.props.app.isUser() || this.props.app.isOrgManager())) {
      await this.props.app.idm.authenticator.logout();
      this.props.app.alertController.showWarningAlert(
        'Login error',
        "Your session is not valid for this application! Please, click on 'Log in' to use an existing account or click on 'Create account' to create a new account."
      );
      this.props.app.urlManager.pushPage('/');
      await this.props.app.redirectToAuth();
      return true;
    }
    return false;
  }
  async _showOrgSwitcher(organizations) {
    this.setState({ visibleOrgSwitcher: true, isLoadingOrgSwitcher: true });
    const resp = await this.props.app.organization.organizationApp.getOrganizationAppsByIDs(organizations);
    if (resp.statusCode == 200 && resp.body && resp.body.orgs) {
      const orgs = resp.body.orgs;
      this.setState({ orgs, isLoadingOrgSwitcher: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoadingOrgSwitcher: false });
    }
  }
  /* private actions */
  _actionLicensePageLink() {
    let externalID = '';
    let queryParams = null;
    if (this.props.app.isOrgManager()) {
      queryParams = { type: 'organization' };
      externalID = this.state.selectedOrgID;
    } else externalID = this.props.app.idm.session.authorization.getUserID();
    //
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.commonLicenses, queryParams, externalID);
  }
  _actionProductOrderPageLink() {
    let externalID = '';
    let queryParams = null;
    if (this.props.app.isOrgManager()) {
      queryParams = { type: 'organization' };
      externalID = this.state.selectedOrgID;
    } else externalID = this.props.app.idm.session.authorization.getUserID();
    //
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.productOrders, queryParams, externalID);
  }
  async _selfRemoveAsManager(org) {
    const newManagers = org?.managers.filter((user) => user.email != this.state.userObj?.email) || [];
    const resp = await this.props.app.organization.organizationApp.updateOrganizationApp(org.orgID, {
      managers: newManagers,
      metadata: org.metadata,
      isAffiliate: org.isAffiliate,
    });
    console.log(resp);
    if (resp.statusCode == 200) return true;
    this.props.app.alertController.showAPIErrorAlert(null, resp);
    return false;
  }
}
