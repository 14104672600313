import React from 'react';
import autoBind from 'react-autobind';
import {
  Alert,
  Button,
  Col,
  Input,
  Layout,
  PageHeader,
  Result,
  Row,
  Select,
  Spin,
  Table,
  message,
  Tooltip,
  Form,
} from 'antd';
import { GrCertificate } from 'react-icons/gr';
import { IoCheckmarkCircle, IoCloseCircle, IoPeople, IoSchool } from 'react-icons/io5';

import CustomComponent from '../../components/CustomComponent';
import WhiteBox from '../commonComponents/WhiteBox';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
import CommonOrganizationSelectionModal from '../commonComponents/OrganizationSelection/CommonOrganizationSelectionModal';
export default class CommonRegisterStudentsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.STEPS = {
      CERTIFICATION: 1,
      SESSIONS: 2,
      STUDENTS: 3,
      SUMMARY: 4,
    };

    this.state = {
      currenStep: this.STEPS.CERTIFICATION,
      selectedCertificationID: null,
      selectedCourseID: null,
      skippedCertificationStep: false,
      sessions: [],
      noSessionsAvailable: false,
      selectedSession: null,
      availableLicenses: 0,
      users: [],
      noLicensesAvailable: false,
      searchTerm: '',
      selectedUsers: [],
      selectedUsersRows: [],
      currentUsersPage: 1,
      isSubmitting: false,
      hasError: false,
      errors: [],
      isLoadingLocations: true,
      organization: [],
    };
  }

  async componentDidMount() {
    // load on demand cache if its not loaded already
    await this.props.app.sharedCache().getVenues();
    await this.props.app.sharedCache().getCities();

    const isMonoCertification = this.props.app.sharedCache().isMonoCertification();
    const certifications = this.props.app.sharedCache().getTenantConfig().certifications;

    if (isMonoCertification) {
      const certID = certifications[0].id;

      const courses = this._getCourses(certID);

      if (courses.length > 1) {
        this.handleSelectCertification(certID);
        return;
      }

      this.setState({
        currenStep: this.STEPS.SESSIONS,
        selectedCertificationID: certID,
        selectedCourseID: courses[0].value,
        skippedCertificationStep: true,
      });
    }
  }

  resetState() {
    this.setState({
      currenStep: this.STEPS.CERTIFICATION,
      selectedCertificationID: null,
      selectedCourseID: null,
      skippedCertificationStep: false,
      sessions: [],
      noSessionsAvailable: false,
      selectedSession: null,
      availableLicenses: 0,
      users: [],
      noLicensesAvailable: false,
      searchTerm: '',
      selectedUsers: [],
      selectedUsersRows: [],
      currentUsersPage: 1,
      isSubmitting: false,
      hasError: false,
      errors: [],
    });
  }

  async handleGoToSessions() {
    this.startLoading();

    const courseSpecs = this.props.app.sharedCache().getCourseByID(this.state.selectedCourseID);
    const orgID = this._getOrgID();

    const [licensesResp, sessionsResp] = await Promise.all([
      this.props.app.license.license.getAvailableLicensesByExternalAndProductIDs(orgID, courseSpecs.productID),
      this.props.app.classroom.session.getSessionList(
        Date.now(),
        Utils.timestampAfterYears(1),
        this.state.selectedCourseID
      ),
    ]);

    if (!this._isMounted) return;

    // Check if requests failed
    if (sessionsResp.statusCode !== 200) {
      this.props.app.alertController.showAPIErrorAlert(null, sessionsResp);
      this.stopLoading();
      return;
    }

    if (licensesResp.statusCode !== 200) {
      this.props.app.alertController.showAPIErrorAlert(null, licensesResp);
      this.stopLoading();
      return;
    }

    // Create auxiliar variables to set in state
    let availableLicenses = 0;
    let sessions = [];

    // Validate available licenses
    if (licensesResp.statusCode == 200 && licensesResp.body) {
      availableLicenses = licensesResp.body.availableLicenses.total;

      if (availableLicenses < 1) {
        this.setState({
          availableLicenses: 0,
          noLicensesAvailable: true,
          isLoading: false,
        });

        return;
      }
    }

    if (sessionsResp.statusCode == 200 && sessionsResp.body) {
      const { sessions: returnedSessions } = sessionsResp.body;

      // Filter for available sessions
      sessions = returnedSessions
        .filter((session) => {
          const isAvailable = session.state === Globals.Session_State.AVAILABLE;

          // Check available seats
          const enrolmentCount = session.enrolmentCount ?? 0;
          const reservedCapacity = session.reservedCapacity ?? 0;
          const enrolmentReservedCount = session.enrolmentReservedCount ?? 0;
          const availableSeats =
            session.capacity > 0
              ? session.capacity - enrolmentCount - reservedCapacity + enrolmentReservedCount
              : Globals.MaxStudentsBatchEnrol; //default to 10 which is the max we allow for batch enrolments
          const hasAvailableSeats = session.capacity != -1 ? availableSeats > 0 : true;

          // Check enrolment date
          let isEnrolmentOpen = true;
          if (session.allowEnrolmentUntil && session.allowEnrolmentUntil !== -1) {
            const allowEnrolmentUntil = new Date(session.allowEnrolmentUntil);
            isEnrolmentOpen = allowEnrolmentUntil > new Date();
          }

          return isAvailable && hasAvailableSeats && isEnrolmentOpen;
        })
        .map((session) => ({
          ...session,
          availableSeats:
            session.capacity == -1
              ? Globals.MaxStudentsBatchEnrol
              : session.capacity - (session.enrolmentCount ?? 0) - (session.reservedCapacity ?? 0),
        }));

      if (sessions.length === 0) {
        this.setState({
          sessions: [],
          noSessionsAvailable: true,
          isLoading: false,
        });

        return;
      }
    }
    // Fetch and set venue information for each session
    const sessionsWithVenues = await Promise.all(
      sessions.map(async (session) => ({
        ...session,
        venue: await this._getLocation(session),
      }))
    );

    this.setState({
      sessions: sessionsWithVenues,
      availableLicenses,
      isLoading: false,
      noSessionsAvailable: false,
      noLicensesAvailable: false,
      currenStep: this.STEPS.SESSIONS,
    });
  }
  handleChangeSelection() {
    if (this.selectionModal) this.selectionModal.show();
  }
  handleSelectOrg(org) {
    this.setState({ organization: org });
  }

  handleSearchUsers() {
    this.setState(
      {
        currentUsersPage: 1,
      },
      this._searchUsers
    );
  }

  handlePaginateUsers(currentUsersPage) {
    this.setState({ currentUsersPage }, this._searchUsers);
  }

  handleSelectCertification(certID) {
    this.setState(
      {
        selectedCertificationID: certID,
        selectedCourseID: null,
        sessions: [],
        availableLicenses: 0,
        noSessionsAvailable: false,
        noLicensesAvailable: false,
        users: [],
        selectedUsers: [],
        selectedUsersRows: [],
      },
      () => {
        const courses = this._getCourses();

        if (courses.length === 1) {
          this.handleSelectCourse(courses[0].value);
        }
      }
    );
  }

  async handleSelectCourse(courseID) {
    this.setState({
      selectedCourseID: courseID,
      sessions: [],
      availableLicenses: 0,
      noSessionsAvailable: false,
      noLicensesAvailable: false,
      users: [],
      selectedUsers: [],
      selectedUsersRows: [],
    });
  }

  async handleSelectSession(selectedSession) {
    this.startLoading();

    await this._searchUsers();

    this.setState({
      selectedSession,
      currenStep: this.STEPS.STUDENTS,
      selectedUsers: [],
      selectedUsersRows: [],
    });
  }

  handleSelectStudents(selectedUsersRows, selectedUsers) {
    const maxUsers = this._getMaxEnrollNumber();

    if (selectedUsers.length > maxUsers) {
      message.error(`You can select only ${maxUsers} students.`);
      return;
    }

    this.setState({
      selectedUsersRows,
      selectedUsers,
    });
  }

  handleRemoveUser(student) {
    this.setState((prevState) => ({
      selectedUsers: prevState.selectedUsers.filter((user) => user.id !== student.id),
      selectedUsersRows: prevState.selectedUsersRows.filter((row) => row !== student.id),
    }));
  }

  async handleSubmit() {
    this.setState({
      hasError: false,
      errors: [],
      isSubmitting: true,
      currenStep: this.STEPS.SUMMARY,
    });

    const payload = {
      courseID: this.state.selectedCourseID,
      sessionID: this.state.selectedSession.id,
      orgID: this._getOrgID(),
      students: this.state.selectedUsers.map((user) => ({
        userID: user.id,
      })),
      autoEnrolSessionID: this.state.selectedSession.autoEnrolOnSession,
    };

    const resp = await this.props.app.api.certification.batchRedeem(this.state.selectedCertificationID, payload);

    if (resp.statusCode !== 200 || !resp.body?.students) {
      this.setState({
        hasError: true,
        errors: [],
        isSubmitting: false,
      });

      return;
    }

    const studentsWithError = resp.body?.students.filter((user) => user.status !== 200);

    if (studentsWithError.length > 0) {
      this.setState({
        hasError: true,
        errors: studentsWithError,
        isSubmitting: false,
      });

      return;
    }

    this.setState({ isSubmitting: false });
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <PageHeader className="pageHeader" title="Enroll Students" />
        <WhiteBox>
          {this._getCertificationStep()}
          {this._getSessionsSteps()}
          {this._getStudentsStep()}
          {this._getSummaryStep()}
        </WhiteBox>
      </Layout.Content>
    );
  }

  _getOrgID() {
    let externalID = '';

    if (this.props.app.isOrgManager()) {
      externalID = this.props.app.urlManager.selectedOrg;
    } else if (this.props.app.isSysAdmin()) {
      externalID = this.state.organization.id;
    }
    return externalID;
  }

  async _searchUsers() {
    this.setState({ users: [], isLoading: true });

    const orgID = this._getOrgID();
    const usersResp = await this.props.app.api.user.searchUsersByTerm(this.state.searchTerm, {
      from: Globals.Table_PagingItemsPerPage * (this.state.currentUsersPage - 1),
      sortField: null,
      sortOrder: 'asc',
      status: 'ALL',
      courseSpecsID: [],
      signinDate1: null,
      signinDate2: null,
      certExpDate1: null,
      certExpDate2: null,
      organization: orgID,
    });

    if (!this._isMounted) return;

    if (usersResp.statusCode == 200 && usersResp.body && usersResp.body.users) {
      const users = usersResp.body.users.map((user) => user._source);
      this.setState({ users, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, usersResp);
      this.stopLoading();
    }
  }

  _getCourses(courseID = null) {
    const { selectedCertificationID } = this.state;
    if (!courseID && !selectedCertificationID) return [];

    const certObj = this.props.app.sharedCache().getCertificationByID(courseID ?? selectedCertificationID);
    if (!certObj?.requirements) return [];

    const courses = [];

    certObj.requirements.forEach((course) => {
      if (Array.isArray(course)) {
        course.forEach((subCourse) => {
          courses.push({
            value: subCourse.id,
            label: subCourse.displayName,
          });
        });
      } else {
        courses.push({
          value: course.id,
          label: course.displayName,
        });
      }
    });

    return courses;
  }

  _getCertificationStep() {
    if (this.state.currenStep !== this.STEPS.CERTIFICATION) {
      return null;
    }

    const isMonoCertification = this.props.app.sharedCache().isMonoCertification();
    const certifications = this.props.app.sharedCache().getTenantConfig().certifications;
    const courses = this._getCourses();

    return (
      <Result
        icon={<GrCertificate size={80} style={{ color: '#000' }} />}
        title="First, select the course"
        subTitle="The course you want to enroll students"
        extra={
          <>
            {this.state.noSessionsAvailable && (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 32 }}>
                <Alert
                  showIcon
                  type="error"
                  description="There are no sessions available for this course."
                  style={{ width: 450 }}
                />
              </div>
            )}

            {this.state.noLicensesAvailable && (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 32 }}>
                <Alert
                  showIcon
                  type="error"
                  description="You don't have any available licenses for this course."
                  style={{ width: 450 }}
                />
              </div>
            )}
            {this.props.app.isAdmin() && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                <CommonOrganizationSelectionModal
                  app={this.props.app}
                  requiresWorksafeValidation={false}
                  isOrgApp={true}
                  allowEmptySelection={false}
                  allowOrgsListing={true}
                  {...Utils.propagateRef(this, 'selectionModal')}
                  onSelection={this.handleSelectOrg}
                />

                <Form.Item required={false} name="organization" id={'orgID'} style={{ width: 450 }}>
                  <Row
                    className="employerInputContainer"
                    align="center"
                    justify="middle"
                    style={{ height: 40, fontSize: 16 }}
                  >
                    {this.state.organization?.name || 'Select company'}
                    <Tooltip title="Select company">
                      <Button
                        className="swapButton"
                        size="small"
                        icon={<SwapOutlined />}
                        shape="circle"
                        type="dashed"
                        onClick={this.handleChangeSelection}
                      />
                    </Tooltip>
                  </Row>
                </Form.Item>
              </div>
            )}
            {!isMonoCertification && (
              <div>
                <Select
                  style={{ width: 450 }}
                  placeholder="Select the certification..."
                  size="large"
                  value={this.state.selectedCertificationID}
                  onChange={this.handleSelectCertification}
                  options={certifications
                    .filter((cert) => !cert.ui?.hideFromUI)
                    .map((cert) => ({
                      value: cert.id,
                      label: cert.description,
                    }))}
                />
              </div>
            )}

            <div style={{ marginTop: 16 }}>
              <Select
                style={{ width: 450 }}
                placeholder="Select the course..."
                size="large"
                value={this.state.selectedCourseID}
                onChange={this.handleSelectCourse}
                disabled={!this.state.selectedCertificationID || courses.length === 1}
                options={courses}
              />
            </div>

            <div style={{ marginTop: 32 }}>
              <Button
                type="primary"
                size="large"
                onClick={this.handleGoToSessions}
                disabled={!this.state.selectedCourseID || this.state.noSessionsAvailable}
                loading={this.state.isLoading}
              >
                Next <ArrowRightOutlined />
              </Button>
            </div>
          </>
        }
      />
    );
  }

  _getSessionsSteps() {
    if (this.state.currenStep !== this.STEPS.SESSIONS) {
      return null;
    }

    const earliestSessionDate = this.state.sessions.reduce((acc, session) => {
      if (session && session.startDate) {
        const earliestOnSession = session.startDate.sort((a, b) => a - b)[0];
        if (earliestOnSession < acc) return earliestOnSession;
      }

      return acc;
    }, Infinity);

    return (
      <>
        <Result
          icon={<IoSchool size={80} style={{ color: '#000' }} />}
          title="Select the session"
          subTitle="The session you want to enroll the students"
          extra={
            !this.state.skippedCertificationStep && (
              <Button
                type="primary"
                size="large"
                onClick={() => this.setState({ currenStep: this.STEPS.CERTIFICATION })}
                disabled={!this.state.selectedCourseID}
              >
                <ArrowLeftOutlined /> Previous
              </Button>
            )
          }
        />

        <Table
          dataSource={this.state.sessions}
          rowKey="id"
          columns={[
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: (props) => Globals.getTemplateTypeIcon(props, true),
            },
            {
              title: 'Name',
              key: 'name',
              render: (session) => session.name || '-',
            },
            {
              title: 'Venue',
              key: 'venue',
              render: (session) => session.venue || '-',
            },
            {
              title: 'Available seats',
              key: 'availableSeats',
              render: (props) => {
                return props.capacity != -1 ? props.availableSeats : 'unlimited';
              },
            },
            {
              title: 'Dates',
              key: 'dates',
              render: (props) => {
                if (props.startDate && props.startDate.length > 0) {
                  return props.startDate.map((s) => Utils.getDateAndTimeOnUIFormatByTimestamp(s)).join(', ');
                }

                return '-';
              },
            },
            {
              title: '',
              key: 'select',
              align: 'right',
              render: (props) => {
                return (
                  <Button
                    type="primary"
                    size="small"
                    onClick={this.handleSelectSession.bind(this, props)}
                    loading={this.state.isLoading}
                  >
                    Select
                  </Button>
                );
              },
            },
          ]}
        />
      </>
    );
  }

  async _getLocation(session) {
    const venue = this.props.app.sharedCache().getVenueByID(session.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    return `${venue?.address?.street1 || ''}${city ? `, ${city.name}` : ''}`;
  }

  _getMaxEnrollNumber() {
    let maxEnrol = 0;
    if (this.state.selectedSession?.availableSeats == -1) maxEnrol = this.state.availableLicenses;
    else maxEnrol = Math.min(this.state.availableLicenses, this.state.selectedSession?.availableSeats ?? 0);
    return Math.min(maxEnrol, Globals.MaxStudentsBatchEnrol); // forcing a max of 10 due to api limitations
  }

  _getStudentsStep() {
    if (this.state.currenStep !== this.STEPS.STUDENTS) {
      return null;
    }
    const { refundPolicyURL } = this.props.app.sharedCache().getTenantConfig();

    return (
      <>
        <Result
          icon={<IoPeople size={80} style={{ color: '#000' }} />}
          title="Select the students"
          subTitle="The students you want to enroll"
          extra={
            <>
              <Button
                type="primary"
                size="large"
                onClick={() => this.setState({ currenStep: this.STEPS.SESSIONS })}
                disabled={!this.state.selectedCourseID || this.state.isSubmitting}
              >
                <ArrowLeftOutlined /> Previous
              </Button>
            </>
          }
        />

        {this.state.selectedSession && this.state.selectedUsers.length > 0 && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: '#fff',
              zIndex: 999,
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 50,
              borderTop: '1px solid #eee',
            }}
          >
            {refundPolicyURL && (
              <span style={{ marginRight: 32, fontWeight: 'bold' }}>
                By clicking submit, you agree to the refund and cancellation{' '}
                <a href={refundPolicyURL} target="_blank">
                  policy
                </a>
              </span>
            )}
            <Button type="primary" onClick={this.handleSubmit} loading={this.state.isSubmitting}>
              Submit
            </Button>
          </div>
        )}

        <Alert
          showIcon
          type="info"
          description={`According to the maximum capacity of the session you selected and the number of licenses available to your organization you can select up to ${this._getMaxEnrollNumber()} students!`}
          style={{ marginBottom: 32 }}
        />

        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Search..."
              size="large"
              prefix={<SearchOutlined />}
              onChange={(e) => this.setState({ searchTerm: e.target.value })}
              value={this.state.searchTerm}
            />
          </Col>
          <Col span={4}>
            <Button type="primary" size="large" onClick={this.handleSearchUsers} loading={this.state.isLoading}>
              Search
            </Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <h1>Your Students</h1>
          </Col>
          <Col span={12}>
            <h1>Selected Students</h1>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Table
              dataSource={this.state.users}
              rowKey="id"
              loading={this.state.isLoading}
              locale={{
                emptyText: 'No users found!',
              }}
              columns={[
                {
                  title: 'Name',
                  key: 'name',
                  render: (p) => `${p.firstName} ${p.lastName}`,
                },
                {
                  title: 'E-mail',
                  dataIndex: 'email',
                },
              ]}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: this.state.selectedUsersRows,
                onChange: this.handleSelectStudents,
              }}
              pagination={{
                pageSize: Globals.Table_PagingItemsPerPage,
                showSizeChanger: false,
                hideOnSinglePage: true,
                position: ['bottomCenter'],
                total: this.state.total,
                onChange: this.handlePaginateUsers,
                current: this.state.currentUsersPage,
              }}
            />
          </Col>

          <Col span={12}>
            <Table
              rowKey="id"
              dataSource={this.state.selectedUsers}
              columns={[
                {
                  title: 'Name',
                  key: 'name',
                  render: (p) => `${p.firstName} ${p.lastName}`,
                },
                {
                  title: 'E-mail',
                  dataIndex: 'email',
                },
                {
                  title: '',
                  key: 'actions',
                  align: 'right',
                  render: (props) => {
                    return (
                      <Button type="link" onClick={this.handleRemoveUser.bind(this, props)}>
                        <CloseCircleOutlined />
                      </Button>
                    );
                  },
                },
              ]}
            />
          </Col>
        </Row>
      </>
    );
  }

  _getSummaryStep() {
    if (this.state.currenStep !== this.STEPS.SUMMARY) {
      return null;
    }

    const icon = this.state.isSubmitting ? (
      <Spin size="large" />
    ) : this.state.hasError ? (
      <IoCloseCircle size={80} style={{ color: '#c7161f' }} />
    ) : (
      <IoCheckmarkCircle size={80} style={{ color: '#92c716' }} />
    );

    const title = this.state.isSubmitting ? 'Please, wait' : this.state.hasError ? 'Attention!' : 'Success!';

    const subTitle = this.state.isSubmitting
      ? 'Enrolling students...'
      : this.state.hasError
        ? 'There were problems while enrolling one or more students...'
        : 'Students succesfully enrolled!';

    return (
      <Result
        icon={icon}
        title={title}
        subTitle={subTitle}
        extra={
          <>
            {!this.state.isSubmitting && !this.state.hasError && (
              <Button
                type="primary"
                size="large"
                onClick={this.resetState}
                disabled={!this.state.selectedCourseID || this.state.isSubmitting}
              >
                Start Over
              </Button>
            )}

            {!this.state.isSubmitting && this.state.hasError && (
              <>
                {this.state.errors.length > 0 && (
                  <>
                    The following students cannot be enrolled:
                    <Table
                      dataSource={this.state.errors}
                      rowKey="userID"
                      columns={[
                        {
                          title: 'User',
                          key: 'user',
                          render: ({ userID }) => {
                            const user = this.state.selectedUsers.find((user) => user.id === userID);

                            if (!user) {
                              return '-';
                            }

                            return `${user.fullName} - ${user.email}`;
                          },
                        },
                        {
                          title: 'Reason',
                          key: 'reason',
                          dataIndex: 'errDesc',
                        },
                      ]}
                    />
                  </>
                )}

                <Button type="primary" size="large" onClick={this.handleSubmit} disabled={this.state.isSubmitting}>
                  Retry
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => this.setState({ currenStep: this.STEPS.STUDENTS })}
                  disabled={this.state.isSubmitting}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </>
            )}
          </>
        }
      />
    );
  }
}
