import React from 'react';
import autoBind from 'react-autobind';
import { Form, Button, Layout, PageHeader, Table, Tabs, Typography, Alert } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import * as ExcelJS from 'exceljs';
//
import CustomComponent from '../../components/CustomComponent';

//
import WhiteBox from '../commonComponents/WhiteBox';
import CommonLicensesTable from '../commonComponents/CommonLicensesTable';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonLicenseOrderPreview from '../commonComponents/CommonLicenseOrderPreview';
//
const { TabPane } = Tabs;
//
import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';

export default class CommonLicenseConsumptionView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      consumptions: [],
      consumptionSortedInfo: null,
      keysSortedInfo: null,
      isLoadingUsersInfo: false,
      usersInfo: [],
      selectedTab: 'details',
      license: null,
      order: null,
      isExporting: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);

    const externalID = this.props.match.params.id;
    const licenseID = this.props.match.params.id2;
    this._fetchData(licenseID, externalID);
  }

  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }

  handleFilterChange(stateName) {
    return (pagination, filters, sorter) => {
      this.setState({ [stateName]: sorter });
    };
  }

  async handleExportToExcel() {
    this.setState({ isExporting: true });

    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    ws.addRow(['Date', 'Name', 'Certificate number', 'License key']);

    this.state.consumptions.forEach((consumption) => {
      const user = this.state.usersInfo.find((user) => user._source.id == consumption.externalID) || {};
      const userName = user._source ? user._source.fullName : 'N/A';
      const certificateNumber = user._source?.memberNumber ? user._source.memberNumber : 'N/A';

      ws.addRow([
        consumption.createdOn ? Utils.getDateOnUIFormatByTimestamp(consumption.createdOn) : '--',
        userName,
        certificateNumber,
        consumption.activationKey,
      ]);
    });

    const buffer = await wb.xlsx.writeBuffer();
    Utils.downloadArrayBuffer(buffer, `${this.state.order.name}#${this.state.order.id} - License Consumption`, 'xlsx');

    this.setState({ isExporting: false });
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader
          className="pageHeader"
          title="License information"
          onBack={() => this.props.app.urlManager.pushBack()}
        />

        <WhiteBox>
          <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
            <TabPane tab="Details" key="details">
              {this._renderLicenseID()}
              {this._renderDetailsTab()}
            </TabPane>

            {this.state.license && (
              <TabPane tab="License keys" key="keys">
                {this._renderLicenseID()}
                {this._renderKeysTable()}
              </TabPane>
            )}

            {this.state.license && (
              <TabPane tab="Used" key="consumption">
                {this._renderLicenseID()}
                {this._renderConsumptionTable()}
              </TabPane>
            )}
          </Tabs>
        </WhiteBox>
      </Layout.Content>
    );
  }

  /* private */
  _renderLicenseID() {
    if (!this.state.license?.id) {
      return null;
    }

    return (
      <Alert
        type="info"
        showIcon
        style={{ margin: '8px 0 24px' }}
        message={
          <>
            License ID: <strong>{this.state.license.id}</strong>
          </>
        }
      />
    );
  }

  _renderDetailsTab() {
    if (!this.state.order) {
      return null;
    }

    const productSpecs = this.props.app.sharedCache().getProductByID(this.state.order.productID);

    return (
      <Form>
        <CommonLicenseOrderPreview licenseOrder={this.state.order} product={productSpecs || {}} app={this.props.app} consumption={this.state.consumptions} />
      </Form>
    );
  }

  _renderConsumptionTable() {
    let { consumptionSortedInfo } = this.state;
    consumptionSortedInfo = consumptionSortedInfo || {};

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '15%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '--'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: consumptionSortedInfo.columnKey === 'createdOn' && consumptionSortedInfo.order,
      },
      {
        title: 'Name',
        dataIndex: 'externalID',
        key: 'externalID',
        width: '15%',
        render: (externalID) => {
          if (this.state.isLoadingUsersInfo) {
            return 'Loading...';
          }

          const user = this.state.usersInfo.find((user) => user._source.id == externalID) || {};
          return user._source ? user._source.fullName : 'N/A';
        },
        sorter: (a, b) => a.externalID.localeCompare(b.externalID),
        sortOrder: consumptionSortedInfo.columnKey === 'externalID' && consumptionSortedInfo.order,
      },
      {
        title: 'Certificate number',
        dataIndex: 'externalID',
        key: 'externalID',
        width: '15%',
        render: (externalID) => {
          if (this.state.isLoadingUsersInfo) {
            return 'Loading...';
          }

          const user = this.state.usersInfo.find((user) => user._source.id == externalID) || {};
          return user._source?.memberNumber ? user._source.memberNumber : 'N/A';
        },
      },
      {
        title: 'Completion date',
        dataIndex: 'externalID',
        key: 'externalID',
        width: '15%',
        render: (externalID) => {
          if (this.state.isLoadingUsersInfo) {
            return 'Loading...';
          }

          const user = this.state.usersInfo.find((user) => user._source.id == externalID) || {};
          return user._source?.certificationDate
            ? Utils.getDateOnUIFormatByTimestamp(user._source.certificationDate)
            : '--';
        },
      },
      {
        title: 'License key',
        dataIndex: 'activationKey',
        key: 'activationKey',
        width: '15%',
      },
    ];

    const props = {
      rowKey: 'licenseID',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange('consumptionSortedInfo'),
      locale: { emptyText: 'No consumptions found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <>
        <Button
          type="primary"
          style={{ marginBottom: 16 }}
          icon={<ExportOutlined />}
          loading={this.state.isExporting}
          onClick={this.handleExportToExcel}
          disabled={this.state.isLoadingUsersInfo || this.state.consumptions.length < 1}
        >
          Export to Excel
        </Button>
        <Table
          className="adminSearchUsersTable"
          columns={columns}
          dataSource={this.state.consumptions}
          scroll={{ x: true }}
          sticky
          {...props}
        />
      </>
    );
  }

  _renderKeysTable() {
    let { keysSortedInfo, license } = this.state;
    keysSortedInfo = keysSortedInfo || {};
    license = license || {};

    const columns = [
      {
        title: 'Key',
        dataIndex: 'id',
        key: 'id',
        render: (key) => <Typography.Paragraph copyable>{key}</Typography.Paragraph>,
        sorter: (a, b) => a.id - b.id,
        sortOrder: keysSortedInfo.columnKey === 'id' && keysSortedInfo.order,
      },
      {
        title: 'Max activations',
        dataIndex: 'maxActivations',
        key: 'maxActivations',
        sorter: (a, b) => a.maxActivations - b.maxActivations,
        sortOrder: keysSortedInfo.columnKey === 'maxActivations' && keysSortedInfo.order,
      },
      {
        title: 'Activations',
        dataIndex: 'numActivations',
        key: 'numActivations',
        sorter: (a, b) => a.numActivations - b.numActivations,
        sortOrder: keysSortedInfo.columnKey === 'numActivations' && keysSortedInfo.order,
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange('keysSortedInfo'),
      locale: { emptyText: 'No keys found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <Table
        className="adminSearchUsersTable"
        columns={columns}
        dataSource={license.activations || []}
        scroll={{ x: true }}
        sticky
        {...props}
      />
    );
  }

  async _fetchData(licenseOrOrderID, externalID) {
    this.setState({ isLoading: true });
    await Utils.execRequests(
      [await this._fetchOrder(licenseOrOrderID, externalID), await this._fetchLicense(licenseOrOrderID, externalID)],
      null,
      (resp, index) => {
        if (index == 0) {
          // Order
          console.log('Order', resp);
          if (resp.statusCode == 200 && resp.body) {
            this.setState({ order: resp.body || {} });
          } else {
            this.props.app.alertController.showAPIErrorAlert(null, resp);
          }
        }

        if (index == 1) {
          // License
          console.log('License', resp);
          if (resp.statusCode == 200 && resp.body) {
            if (resp.body.consumptions.length > 0) {
              const usersIDs = resp.body.consumptions.map((item) => item.externalID);
              this._fetchUserNames(usersIDs);
            }

            this.setState({
              consumptions: resp.body.consumptions,
              license: resp.body,
            });
          }
        }
      }
    );

    this.setState({ isLoading: false });
  }

  async _fetchOrder(orderID, externalID) {
    const resp = await this.props.app.license.order.getOrder(externalID, orderID);
    return resp;
  }

  async _fetchLicense(licenseID, externalID) {
    const resp = await this.props.app.license.license.getLicense(licenseID, externalID);
    return resp;
  }

  async _fetchDataOld(licenseOrOrderID, externalID) {
    this.props.app.alertController.showAPIErrorAlert(null, resp);
  }

  async _fetchUserNames(usersIDs) {
    this.setState({ isLoadingUsersInfo: true });

    const resp = await this.props.app.api.user.searchUsersByIDs(usersIDs);

    if (resp.statusCode == 200 && resp.body) {
      this.setState({ usersInfo: resp.body.users });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoadingUsersInfo: false });
  }
}
