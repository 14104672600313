import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, BookOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
//
export default class AdminVouchersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      vouchers: [],
      sortedInfo: { columnKey: 'createdOn', order: 'asc' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadVouchers();
    if (resp) {
      this.setState({ vouchers: resp });
      this.stopLoading();
    } else this.stopLoading();
  }

  //Actions
  handleCreateVoucher() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.voucher, null, Globals.URL_NewIndentifier);
  }
  //Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Table row actions
  handleEditVoucher(voucherID) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.voucher, null, voucherID);
  }
  handleVoucherConsumptions(voucherID) {
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.voucher,
      { [Globals.URL_Path_TabID]: 'consumptions' },
      voucherID
    );
  }
  async handleDeleteVoucher(voucherID) {
    this.startLoading();
    const resp = await this._deleteVoucher(voucherID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Voucher Code',
        key: 'id',
        dataIndex: 'id',
        width: '10%',
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
      },
      {
        title: 'Discount',
        key: 'discount',
        width: '5%',
        render: (props) => (props.discPercent ? `${props.discPercent * 100}%` : `$${props.discAmount}`),
        sorter: (a, b) =>
          (a.discPercent ? `${a.discPercent * 100}%` : `$${a.discAmount}`).localeCompare(
            b.discPercent ? `${b.discPercent * 100}%` : `$${b.discAmount}`
          ),
        sortOrder: sortedInfo.columnKey === 'discount' && sortedInfo.order,
      },
      {
        title: 'Product(s)',
        key: 'products',
        width: '10%',
        render: (props) => this._getProductsDescription(props.productID),
      },
      {
        title: 'Expiration',
        key: 'expDate',
        width: '5%',
        render: (props) => (props.expDate ? Utils.getDateAndTimeOnUIFormatByTimestamp(props.expDate) : 'N/A'),
        sorter: (a, b) => (a.expDate || 0) - (b.expDate || 0),
        sortOrder: sortedInfo.columnKey === 'expDate' && sortedInfo.order,
      },
      {
        title: 'Max Activations',
        key: 'maxAct',
        width: '10%',
        render: (props) => props.maxActivations || 'Unlimited',
        sorter: (a, b) => (a.maxActivations || 0) - (b.maxActivations || 0),
        sortOrder: sortedInfo.columnKey === 'maxAct' && sortedInfo.order,
      },
      {
        title: 'Max Discount',
        key: 'maxDisc',
        width: '10%',
        render: (props) => this._discountValue(props),
        sorter: (a, b) => (this._discountValue(a) + '').localeCompare(this._discountValue(b) + ''),
        sortOrder: sortedInfo.columnKey === 'maxDisc' && sortedInfo.order,
      },
      {
        title: 'Activations',
        key: 'act',
        width: '5%',
        render: (props) => props.numActivations || '0',
        sorter: (a, b) => (a.numActivations || 0) - (b.numActivations || 0),
        sortOrder: sortedInfo.columnKey === 'act' && sortedInfo.order,
      },
      {
        title: 'Created date',
        key: 'createdOn',
        width: '10%',
        render: (props) => (props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditVoucher.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Consumption">
                <Button
                  style={{ marginLeft: 5 }}
                  variant="none"
                  icon={<BookOutlined />}
                  shape="circle"
                  onClick={this.handleVoucherConsumptions.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Button
                  style={{ marginLeft: 5 }}
                  variant="none"
                  icon={<DeleteOutlined />}
                  shape="circle"
                  onClick={this.handleDeleteVoucher.bind(this, props.id)}
                />
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No vouchers found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />

        <PageHeader className="pageHeader" title="Vouchers" onBack={() => this.props.app.urlManager.pushBack()} />
        <Layout.Content>
          <Row type="flex" justify="end">
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateVoucher}>
                {' '}
                Create new voucher{' '}
              </Button>
            </Col>
          </Row>
          <Layout.Content>
            <Table className="adminVouchersTable" columns={columns} dataSource={this.state.vouchers} {...props} />
          </Layout.Content>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private API */
  async _loadVouchers() {
    const resp = await this.props.app.license.voucher.getVouchers();
    if (resp.statusCode == 200 && resp.body && resp.body.vouchers) {
      return resp.body.vouchers;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteVoucher(voucherID) {
    const resp = await this.props.app.license.voucher.deleteVoucher(voucherID);
    if (resp.statusCode == 200) {
      message.success('Voucher deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }

  /* private Helpers */
  _getProductsDescription(productID) {
    if (Array.isArray(productID)) {
      let retVal = '';
      for (let _subproductID of productID) {
        if (retVal.length) retVal += `, ${this.props.app.sharedCache().getProductByID(_subproductID).name}`;
        else retVal += `${this.props.app.sharedCache().getProductByID(_subproductID).name}`;
      }
      return retVal;
    } else return this.props.app.sharedCache().getProductByID(productID).name;
  }
  _discountValue(props) {
    if (props.maxQuantity && props.maxQuantity != -1) return props.maxQuantity + ' UN';
    else if (props.maxDiscount && props.maxDiscount != -1) return Utils.toCurrencyFormat(props.maxDiscount);
    else return 'N/A';
  }
}
