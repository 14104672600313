import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Col, Row, Table, Button, Drawer, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonOrgForm from '../commonComponents/Forms/CommonOrgForm';
import CommonOrgLocationDrawer from '../commonComponents/Drawers/CommonOrgLocationDrawer';
import CommonSearchUsersView from '../commonSubviews/CommonSearchUsersView';
import CommonOrgLocationsTable from '../commonComponents/CommonOrgLocationsTable';
import CommonOrgContactsTable from '../commonComponents/CommonOrgContactsTable';
import CommonOrgContactDrawer from '../commonComponents/Drawers/CommonOrgContactDrawer';
import CommonNotificationsSettingsForm from '../commonComponents/Forms/CommonNotificationsSettingsForm';
//
import AdminManagersForm from './AdminManagersForm';
import CommonBillingView from '../commonSubviews/CommonBillingView';
import OrgManagersInvitationHistoryView from '../orgmanagersSubviews/OrgManagersInvitationHistoryView';
import OrgManagersInvitationsView from '../orgmanagersSubviews/OrgManagersInvitationsView';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
const { TabPane } = Tabs;
//
export default class AdminEditOrgView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      sortedInfo: null,
      isDrawerVisible: false,
      selectedTab: 'details',
      org: {},
      locations: [],
      contacts: [],
      orgMgrNotifications: null,
    };
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    await this._fetchData();
    if (this.state.selectedTab != selectedTab && selectedTab) {
      this.handleTabChange(selectedTab);
    } else {
      this.handleTabChange(this.state.selectedTab);
    }
  }
  //Actions
  async handleSubmit({ isAffiliate, metadata, notes }) {
    this._updateOnAPI(
      { isAffiliate, managers: this.state.org.managers, metadata, notes },
      'Org successfully updated',
      this._fetchData
    );
  }
  //Table actions
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Locations actions
  handleCreateLocation() {
    this.locationsDrawer.show();
  }
  hanelEditLocation(loc) {
    this.locationsDrawer.show(loc);
  }
  handleDeleteLocation(loc) {
    this._deleteLocation(loc);
  }
  handleLocationUpdated(loc) {
    if (loc.id) this._updateLocation(loc);
    else this._createLocation(loc);
  }
  //Contacts actions
  handleAddContact() {
    this.contactsDrawer.show();
  }
  handleDeleteContact(contact) {
    this._deleteContact(contact);
  }
  handleContactAdded(contact) {
    this._addContact(contact);
  }
  //Managers actions
  handleAddManagers() {
    this.setState({ isDrawerVisible: true });
  }
  handleCloseDrawer() {
    this.setState({ isDrawerVisible: false });
  }
  async handleManagersSubmit() {
    const users = this.managersForm.getUsers();
    if (!users || !users.length) {
      message.error(
        'Please select at least one user from the list before submitting. Managers must be existing users of the application.'
      );
      return;
    }
    //
    const newManagers = users.map((user) => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }));
    const { isAffiliate, metadata, managers, notes } = this.state.org;
    this._updateOnAPI(
      { isAffiliate, metadata, managers: [...managers, ...newManagers], notes },
      `Manager${newManagers.length > 1 ? 's' : ''} successfully added`,
      () => {
        this.managersForm.reset();
        this.setState({ isDrawerVisible: false });
        this._fetchData();
      }
    );
  }
  handleRemoveManager(manager) {
    const { isAffiliate, metadata, managers, notes } = this.state.org;
    const newManagers = managers.filter((user) => user.email != manager.email);
    this._updateOnAPI({ isAffiliate, metadata, managers: newManagers, notes }, `Manager successfully removed`, () => {
      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
        org: { ...prevState.org, managers: newManagers },
      }));
    });
  }
  //Tab
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
  //UI
  render() {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const orgID = this.props.match.params.id;

    const items = [
      {
        key: 'details',
        label: 'Org Details',
        children: (
          <CommonOrgForm
            editMode
            onSubmit={this.handleSubmit}
            isLoading={this.state.isLoading}
            app={this.props.app}
            {...Utils.propagateRef(this, 'form')}
            selectedOrg={this.state.org}
          />
        ),
      },
      tenantConfig.licModVaultingEnabled && {
        key: 'billing',
        label: 'Billing',
        children: <CommonBillingView app={this.props.app} type={'org'} externalID={this.props.match.params.id} />,
      },
      { key: 'managers', label: 'Managers', children: this._renderManagersTab() },
      {
        key: 'managerSettings',
        label: 'Manager Settings',
        children: (
          <CommonNotificationsSettingsForm
            app={this.props.app}
            orgID={this.props.match.params.id}
            data={this.state.orgMgrNotifications}
          />
        ),
      },
      {
        key: 'members',
        label: tenantConfig.orgModEmployeeMode ? 'Employees' : 'Students',
        children: (
          <CommonSearchUsersView hideHeader isOrgMembersView defaultOrgID={this.state.org?.orgID} {...this.props} />
        ),
      },
      !tenantConfig.orgModEmployeeMode && {
        key: 'students',
        label: 'Add Students',
        children: <OrgManagersInvitationsView adminEditOrgView={true} orgID={this.state.org?.orgID} {...this.props} />,
      },
      !tenantConfig.orgModDisabled &&
        !tenantConfig.orgModEmployeeMode && {
          key: 'invitation',
          label: 'Invitation History',
          children: this._renderInvitationHistoryTab(),
        },
      { key: 'locations', label: 'Operating Locations', children: this._renderLocationsTab() },
      { key: 'contacts', label: 'Contacts', children: this._renderContactsTab() },
    ].filter(Boolean);

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader
          className="pageHeader"
          title="Edit Organization"
          onBack={() => this.props.app.urlManager.pushBack()}
        />
        <Layout.Content>
          <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange} items={items} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderLocationsTab() {
    return (
      <>
        <CommonOrgLocationDrawer
          {...Utils.propagateRef(this, 'locationsDrawer')}
          onUpdate={this.handleLocationUpdated}
        />
        <Row type="flex" justify="end" style={{ marginBottom: 15 }}>
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleCreateLocation}>
              Create Location
            </Button>{' '}
          </Col>
        </Row>
        <CommonOrgLocationsTable
          locations={this.state.locations || []}
          isLoading={this.state.isLoading}
          onDelete={this.handleDeleteLocation}
          onEdit={this.hanelEditLocation}
        />
      </>
    );
  }
  _renderContactsTab() {
    return (
      <>
        <CommonOrgContactDrawer
          {...Utils.propagateRef(this, 'contactsDrawer')}
          app={this.props.app}
          onSelection={this.handleContactAdded}
        />
        <Row type="flex" justify="end" style={{ marginBottom: 15 }}>
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleAddContact}>
              Add Contact
            </Button>{' '}
          </Col>
        </Row>
        <CommonOrgContactsTable
          contacts={this.state.contacts || []}
          isLoading={this.state.isLoading}
          onDelete={this.handleDeleteContact}
        />
      </>
    );
  }

  _renderInvitationHistoryTab() {
    return (
      <>
        <OrgManagersInvitationHistoryView
          orgID={this.props.match.params.id}
          showPageHeader={false}
          app={this.props.app}
          invitation={this.state.invitation || []}
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
  _renderManagersTab() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No managers found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    const columns = [
      {
        title: 'Name',
        key: 'name',
        width: '15%',
        render: (user) => `${user.firstName} ${user.lastName}`,
        sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'E-mail',
        key: 'email',
        dataIndex: 'email',
        width: '15%',
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
      },
      {
        title: 'Phone',
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        width: '15%',
        sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
        sortOrder: sortedInfo.columnKey === 'phoneNumber' && sortedInfo.order,
      },
      {
        title: 'Registration Date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '13%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : ''),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: '10%',
        key: 'Actions',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title={`Remove ${props.firstName} ${props.lastName}`}>
                <Popconfirm
                  placement="top"
                  okText="Yes"
                  cancelText="No"
                  title={`Are you sure that you want to remove ${props.firstName} ${props.lastName} from managers list?`}
                  onConfirm={this.handleRemoveManager.bind(this, props)}
                >
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    return (
      <>
        <Row type="flex" justify="end" style={{ marginBottom: 15 }}>
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleAddManagers}>
              {' '}
              Add Managers{' '}
            </Button>{' '}
          </Col>
        </Row>
        <Table
          className="adminSearchUsersTable"
          columns={columns}
          dataSource={this.state.org.managers || []}
          {...props}
        />
        {this._renderDrawer()}
      </>
    );
  }
  _renderDrawer() {
    const { managers } = this.state.org;
    const orgEmails = managers ? managers.map((user) => user.email) : [];
    return (
      <Drawer
        placement="right"
        title="Add Managers"
        width={600}
        onClose={this.handleCloseDrawer}
        open={this.state.isDrawerVisible}
        bodyStyle={{ paddingBottom: 20 }}
        footer={this._renderDrawerFooter()}
      >
        <AdminManagersForm
          app={this.props.app}
          emailsToFilter={orgEmails}
          {...Utils.propagateRef(this, 'managersForm')}
        />
      </Drawer>
    );
  }
  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleCloseDrawer} style={{ marginRight: 8 }}>
          {' '}
          Cancel{' '}
        </Button>
        <Button onClick={this.handleManagersSubmit} type="primary">
          {' '}
          Submit{' '}
        </Button>
      </div>
    );
  }

  /* private API calls */
  async _fetchData() {
    const orgID = this.props.match.params.id;
    this.startLoading();
    await Promise.all([this._getOrgData(orgID), this._getLocations(orgID), this._getContacts(orgID)]);
    this.stopLoading();
  }
  async _getOrgData(orgID) {
    const resp = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);
    if (resp.statusCode == 200 && resp.body) {
      const orgData = resp.body;
      const orgMgrNotifications = orgData.orgMgrNotifications;
      let managers = [];
      if (orgData.managers) {
        const ids = orgData.managers.map((user) => user.id);
        managers = await this._getManagers(ids);
      }
      this.setState({ org: { ...orgData, managers }, orgMgrNotifications });
      this.form.setFormData(orgData);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  async _getManagers(ids) {
    const resp = await this.props.app.api.user.searchUsersByIDs(ids);
    if (resp.statusCode == 200 && resp.body && resp.body.users) return resp.body.users.map((user) => user._source);
    this.props.app.alertController.showAPIErrorAlert(null, resp);
    return [];
  }
  async _getLocations(orgID) {
    const resp = await this.props.app.organization.organizationAppLocation.getAllOrganizationAppLocations(orgID);
    if (resp.statusCode == 200 && resp.body && resp.body.locations) {
      this.setState({ locations: resp.body.locations });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  async _getContacts(orgID) {
    const resp = await this.props.app.organization.organizationAppContact.getAllOrganizationAppContacts(orgID);
    if (resp.statusCode == 200 && resp.body && resp.body.appOrgContacts) {
      const ids = resp.body.appOrgContacts.map((c) => c.id);
      const cResp = await this.props.app.organization.contact.searchContactsByIDs({ contactIDs: ids });
      if (cResp.statusCode == 200 && cResp.body && cResp.body.contacts)
        this.setState({ contacts: cResp.body.contacts });
      else this.props.app.alertController.showAPIErrorAlert(null, cResp);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  /* update calls */
  async _updateOnAPI(payload, successMessage, successCb) {
    this.startLoading();
    const orgID = this.props.match.params.id;
    //Update voucher
    if (payload.metadata?.voucherID) {
      //comptibility
      const updateVoucher = await this._updateVoucher(payload.metadata?.voucherID, payload, orgID);
      if (!updateVoucher) {
        this.stopLoading();
        return;
      }
    } else {
      // vaucher may be null but user is trying to set a discount
      if (payload.metadata?.discAmount || payload.metadata?.discPercent) {
        const createVoucher = await this.props.app.license.voucher.createVoucher({
          description: `Org: ${orgID} Voucher was null - Auto generated on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(Date.now())}`,
          productID: this.props.app.sharedCache().getAllProductsID(),
          discAmount: payload.metadata?.discAmount || 0,
          discPercent: payload.metadata?.discPercent || 0,
          userIDRestriction: [orgID],
          isPrivate: true,
          includeAllProducts: true,
        });
        if (createVoucher.statusCode == 200 && createVoucher.body && createVoucher.body.voucherID) {
          payload.metadata.voucherID = createVoucher.body.voucherID;
        } else {
          this.props.app.alertController.showAPIErrorAlert(null, createVoucher);
        }
      }
    }
    //Update organization
    const updateOrg = await this._updateOrg(orgID, payload);
    if (!updateOrg) {
      this.stopLoading();
      return;
    }
    //
    message.success(successMessage);
    if (successCb) successCb();
  }
  async _updateOrg(orgID, body) {
    const resp = await this.props.app.organization.organizationApp.updateOrganizationApp(orgID, body);
    if (resp.statusCode == 200) return true;
    this.props.app.alertController.showAPIErrorAlert(null, resp);
    return false;
  }
  async _updateVoucher(voucherID, data, orgID) {
    const resp = await this.props.app.license.voucher.updateVoucher(
      {
        description: `Org: ${data.name} Voucher - Auto generated on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(Date.now())}`,
        productID: this.props.app.sharedCache().getAllProductsID(),
        discAmount: data.metadata?.discAmount || 0,
        discPercent: data.metadata?.discPercent || 0,
        userIDRestriction: [orgID],
        isPrivate: true,
        includeAllProducts: true,
      },
      voucherID
    );
    if (resp.statusCode == 200) return true;
    this.props.app.alertController.showAPIErrorAlert(null, resp);
    return false;
  }
  /* org location calls */
  async _deleteLocation(loc) {
    this.startLoading();
    const resp = await this.props.app.organization.organizationAppLocation.removeOrganizationAppLocation(
      loc.orgID,
      loc.id
    );
    if (resp.statusCode == 200 && resp.body) {
      message.success('Location removed with success!');
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _updateLocation(loc) {
    this.startLoading();
    const resp = await this.props.app.organization.organizationAppLocation.updateOrganizationAppLocation(
      loc.orgID,
      loc.id,
      loc
    );
    if (resp.statusCode == 200 && resp.body) {
      message.success('Location updated with success!');
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _createLocation(loc) {
    this.startLoading();
    //assign expected values
    loc.orgID = this.state.org.orgID;
    loc.id = Date.now() + '';
    //
    const resp = await this.props.app.organization.organizationAppLocation.createOrganizationAppLocation(
      loc.orgID,
      loc.id,
      loc
    );
    if (resp.statusCode == 200 && resp.body) {
      message.success('Location created with success!');
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  /* org contact calls */
  async _deleteContact(contact) {
    this.startLoading();
    const resp = await this.props.app.organization.organizationAppContact.removeOrganizationAppContact(
      this.state.org.orgID,
      contact.id
    );
    if (resp.statusCode == 200 && resp.body) {
      message.success('Contact removed with success!');
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _addContact(contact) {
    this.startLoading();
    //assign expected values
    contact.orgID = this.state.org.orgID;
    //
    const resp = await this.props.app.organization.organizationAppContact.createOrganizationAppContact(
      contact.orgID,
      contact.id
    );
    if (resp.statusCode == 200 && resp.body) {
      message.success('Contact added with success!');
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}
