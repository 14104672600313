import React from 'react';
import autoBind from 'react-autobind';
import { Button, Tooltip, Row, Form } from 'antd';
import { SwapOutlined, CloseOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
//
import CommonSearchUsersModal from '../commonComponents/Modals/CommonSearchUsersModal';
//
//props are: input { id, label, required, placeholder }, isAdmin, selectedUser, app, onSelection, allowClear
export default class CommonUserSelectionInput extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  handleUserSelected(userObj) {
    if (this.props.onSelection) this.props.onSelection(userObj);
  }
  handleChangeSelection() {
    if (this.selectionModal) this.selectionModal.show();
  }
  handleClearSelection() {
    if (this.props.onSelection) this.props.onSelection(null);
  }
  //UI
  render() {
    const fullName = this.props.selectedUser
      ? this.props.selectedUser.fullName ||
        `${this.props.selectedUser.firstName || ''} ${this.props.selectedUser.lastName || ''}`.trim()
      : '';

    return (
      <>
        <CommonSearchUsersModal
          app={this.props.app}
          {...Utils.propagateRef(this, 'selectionModal')}
          onSelection={this.handleUserSelected}
        />
        <Form.Item
          required={this.props.input?.required}
          label={this.props.input?.label || 'User'}
          name="userID"
          id={this.props.input?.id || 'userID'}
        >
          <Row className="employerInputContainer" type="flex" align="start" justify="middle">
            {fullName || this.props.input?.placeholder || 'Select User'}
            <Tooltip title="Select user">
              <Button
                className="swapButton"
                size="small"
                icon={<SwapOutlined />}
                shape="circle"
                type="dashed"
                onClick={this.handleChangeSelection}
                disabled={this.props.disabled}
              />
            </Tooltip>

            {this.props.allowClear && this.props.selectedUser && (
              <Tooltip title="Clear selection">
                <Button
                  size="small"
                  style={{ margin: '4px' }}
                  icon={<CloseOutlined />}
                  shape="circle"
                  type="dashed"
                  onClick={this.handleClearSelection}
                />
              </Tooltip>
            )}
          </Row>
        </Form.Item>
      </>
    );
  }
}
