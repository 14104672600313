import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import '../../assets/stylesheets/CommonCourseTile.less';

import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
import CommonLoadingView from '../commonComponents/CommonLoadingView';

export default class CommonResultCard extends React.Component {
  //props are: app, currentCourse, label
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  async handleDownload(file) {
    await this._download(
      this.props.currentCourse?.userID,
      this.props.currentCourse?.certProcID,
      this.props.currentCourse?.id,
      file
    );
  }
  async handlePreviewItem(file) {
    await this._previewItem(
      this.props.currentCourse?.userID,
      this.props.currentCourse?.certProcID,
      this.props.currentCourse?.id,
      file
    );
  }

  render() {
    const { currentCourse, app, label } = this.props;
    const passed = currentCourse.state == Globals.Course_State.PASS;
    const courseSpecs = app.sharedCache().getCourseByID(currentCourse.courseID);
    const isOldFormat = !!currentCourse.courseResultV1;
    const statusText = passed ? courseSpecs.passUiTerm || 'Passed' : courseSpecs.failUiTerm || 'Failed';

    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} />
        <Row>
          <Col span={24}>
            {label && <p>{label}</p>}

            <div className="data-card">
              <h2>{Utils.capitalizeString(courseSpecs.uiTerm)} result</h2>

              <Row type="flex" justify="space-between">
                <Col className="data-item">
                  <small>Status</small>
                  <div className="value">
                    <div className={`status-dot ${passed ? 'green' : 'red'}`} />
                    {isOldFormat ? (
                      <span> {statusText} </span>
                    ) : (
                      <span>
                        {' '}
                        {Utils.capitalizeString(currentCourse?.courseResult?.resultStatus?.toLowerCase() || '')}{' '}
                      </span>
                    )}
                  </div>
                </Col>

                <Col className="data-item">
                  <small>Grade</small>
                  <div className="value">
                    <span>
                      {isOldFormat
                        ? parseInt(currentCourse.courseResultV1?.grade || 0)
                        : currentCourse?.courseResult?.resultGrade || 0}
                    </span>
                  </div>
                </Col>

                <Col className="data-item">
                  <small>{Utils.capitalizeString(courseSpecs.uiTerm)}</small>
                  <div className="value">
                    <span>{courseSpecs.description}</span>
                  </div>
                </Col>

                {isOldFormat && (
                  <Col className="data-item">
                    <small>No show</small>
                    <div className="value">
                      <span>{currentCourse.courseResultV1?.noShow ? 'Yes' : 'No'}</span>
                    </div>
                  </Col>
                )}

                {!isOldFormat && currentCourse?.courseResult?.attendanceStatus && (
                  <Col className="data-item">
                    <small>Attendance</small>
                    <div className="value">
                      <span>
                        {Utils.capitalizeString(currentCourse?.courseResult?.attendanceStatus?.toLowerCase() || '-')}
                      </span>
                    </div>
                  </Col>
                )}

                {!isOldFormat && !courseSpecs.courseResult?.resultDate && (
                  <Col className="data-item">
                    <small>Results Received On</small>
                    <div className="value">
                      <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(currentCourse.courseResult?.resultDate)}</span>
                    </div>
                  </Col>
                )}

                {isOldFormat && currentCourse.courseResultV1?.resultDate && (
                  <Col className="data-item">
                    <small>Scheduled To</small>
                    <div className="value">
                      <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(currentCourse.courseResultV1?.resultDate)}</span>
                    </div>
                  </Col>
                )}

                <Col className="data-item">
                  <small>Created on</small>
                  <div className="value">
                    <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(currentCourse.createdOn)}</span>
                  </div>
                </Col>

                <Col className="data-item">
                  <small>Updated on</small>
                  <div className="value">
                    <span>{Utils.getDateAndTimeOnUIFormatByTimestamp(currentCourse.updatedOn)}</span>
                  </div>
                </Col>

                {currentCourse?.expiryDate && currentCourse?.expiryDate != -1 && (
                  <Col span={24} className="data-item">
                    <small>Expiration Date</small>
                    <div className="value">
                      <span>{Utils.getDateOnUIFormatByTimestamp(currentCourse?.expiryDate)}</span>
                    </div>
                  </Col>
                )}

                {!isOldFormat && this.props.app.isAdmin() && currentCourse?.courseResult?.additionalNotes && (
                  <Col span={24} className="data-item">
                    <small>Student Notes</small>
                    <div className="value">
                      <span>{currentCourse?.courseResult?.additionalNotes}</span>
                    </div>
                  </Col>
                )}
                {!isOldFormat && this.props.app.isAdmin() && currentCourse?.courseResult?.resultNotes && (
                  <Col span={24} className="data-item">
                    <small>Result Notes</small>
                    <div className="value">
                      <span>{currentCourse?.courseResult?.resultNotes}</span>
                    </div>
                  </Col>
                )}
                {!isOldFormat && currentCourse?.submissionItems?.length > 0 && (
                  <Col span={24} className="data-item">
                    <small>Uploaded files</small>
                    <div className="value">
                      <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                        <li>
                          {currentCourse.submissionItems.map(({ fileID, fileName }) => (
                            <div
                              key={fileID}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 8,
                              }}
                            >
                              <Tooltip title={fileName}>
                                <span style={{ marginLeft: 8 }}>{fileName}</span>
                              </Tooltip>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => this.handleDownload({ fileName, fileID })}
                                  style={{
                                    background: 'transparent',
                                    border: 'none',
                                    borderRadius: 'none',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <DownloadOutlined />
                                </button>
                                {this._renderPreviewButton({ fileName, fileID })}
                              </div>
                            </div>
                          ))}
                        </li>
                      </ul>
                    </div>
                  </Col>
                )}
                {isOldFormat && currentCourse?.courseResult?.comments && (
                  <Col span={24} className="data-item">
                    <small>Result Comments</small>
                    <div className="value">
                      <span>{currentCourse?.courseResult?.comments}</span>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  _renderPreviewButton(item) {
    const { fileName, fileID } = item;
    const { extension } = Utils.splitFilenameAndExtension(fileName);
    const disabled = !['png', 'jpeg', 'jpg', 'gif', 'pdf'].includes(extension?.toLowerCase());
    return (
      <button
        type="button"
        onClick={() => this.handlePreviewItem({ fileName, fileID })}
        disabled={disabled}
        style={{
          background: 'transparent',
          border: 'none',
          borderRadius: 'none',
          fontSize: '16px',
          cursor: 'pointer',
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? 'default' : 'pointer',
        }}
      >
        {!disabled && <EyeOutlined />}
        {disabled && (
          <Tooltip title="Preview is not available.">
            <EyeOutlined />
          </Tooltip>
        )}
      </button>
    );
  }

  async _download(userID, certProcID, courseID, file) {
    this.startLoading();
    const resp = await this.props.app.api.courseFile.downloadDocument(userID, certProcID, courseID, file.fileID);
    console.log(resp);
    // if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(
        resp.body,
        filename || file.fileName,
        extension || Utils.getFileExtensionFromMimeType(resp.body.type)
      );
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
    this.stopLoading();
  }

  async _previewItem(userID, certProcID, courseID, file) {
    this.startLoading();
    const resp = await this.props.app.api.courseFile.getSignURL(userID, certProcID, courseID, file.fileID);
    // if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
    this.stopLoading();
  }
}
