import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Row, Button, message, Modal, Typography, Popconfirm } from 'antd';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLicenseOrderPreview from '../CommonLicenseOrderPreview';
import CommonLoadingView from '../CommonLoadingView';
//
import Utils from '../../../components/Utils';
//
import '../../../assets/stylesheets/CommonLicenseInvoiceApprovalModal.less';
//
//props are: app, onChange, isVisible
export default class CommonLicenseInvoiceApprovalModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, isRejecting: false, licenseOrder: {}, product: {} };
  }
  //
  loadModalInfo(licOrder) {
    const productSpecs = this.props.app.sharedCache().getProductByID(licOrder.productID);
    this.setState({ isLoading: false, isRejecting: false, licenseOrder: licOrder, product: productSpecs });
  }

  //Actions
  handleCancel() {
    this.props.onChange();
  }
  handleAfterClose() {
    if (this.rejectForm) this.rejectForm.resetFields();
    this.setState({ isLoading: false, isRejecting: false, licenseOrder: {}, product: {} });
  }
  //Sub-actions
  handleSetRejecting() {
    this.setState({ isRejecting: true });
  }
  async handleReject() {
    const formData = await this.rejectForm.validateFields();
    if (!formData) return;
    this._rejectOrder(formData);
  }
  handleApprove() {
    this._approveOrder();
  }
  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        title={this.state.isRejecting ? 'Reject Order' : 'Approve or Reject Order'}
        afterClose={this.handleAfterClose}
        open={this.props.isVisible}
        confirmLoading={this.state.isLoading}
        closable={false}
        footer={null}
        className="approvalModal"
      >
        <CommonLoadingView isLoading={this.state.isLoading} />
        {this._renderContents()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  _renderContents() {
    if (this.state.isRejecting) {
      return (
        <Form layout="vertical" style={{ width: 480 }} {...Utils.propagateRef(this, 'rejectForm')}>
          <Divider orientation="left">Rejection details:</Divider>
          <Form.Item
            name="reason"
            label="Reason"
            rules={[{ required: true, message: 'Reason is required when rejecting an invoice!' }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      );
    } else {
      return (
        <>
          <Form>
            <CommonLicenseOrderPreview
              app={this.props.app}
              licenseOrder={this.state.licenseOrder}
              product={this.state.product}
              invoiceMode
            />
          </Form>
          <Row type="flex" justify="start">
            <Typography.Text keyboard underline>
              Please click <b>Approve</b> to approve this order from {this.state.licenseOrder.name} if payment was
              already received.
            </Typography.Text>
            <Typography.Text keyboard underline>
              Click <b>Reject</b> to cancel the order and notify the purchaser or <b>Cancel</b> to go back.
            </Typography.Text>
          </Row>
        </>
      );
    }
  }
  _renderButtonsFooter() {
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider />
        {this.state.isRejecting ? (
          <>
            <Button
              key="back"
              type="link"
              onClick={this.handleCancel}
              loading={this.state.isLoading}
              disabled={this.state.isLoading}
            >
              {' '}
              Cancel{' '}
            </Button>
            <Popconfirm key="reject" title="Are you sure you want to reject this order?" onConfirm={this.handleReject}>
              <Button
                type="primary"
                loading={this.state.isLoading}
                disabled={this.state.isLoading}
                className="confirmButton"
              >
                {' '}
                Submit{' '}
              </Button>
            </Popconfirm>
          </>
        ) : (
          <>
            <Button
              key="back"
              type="link"
              onClick={this.handleCancel}
              loading={this.state.isLoading}
              disabled={this.state.isLoading}
            >
              {' '}
              Cancel{' '}
            </Button>
            <Button
              key="reject"
              onClick={this.handleSetRejecting}
              loading={this.state.isLoading}
              disabled={this.state.isLoading}
              className="confirmButton"
            >
              {' '}
              Reject{' '}
            </Button>
            <Popconfirm
              key="approve"
              title="Are you sure you want to approve this order?"
              onConfirm={this.handleApprove}
            >
              <Button
                type="primary"
                loading={this.state.isLoading}
                disabled={this.state.isLoading}
                className="confirmButton"
              >
                {' '}
                Approve{' '}
              </Button>
            </Popconfirm>
          </>
        )}
      </Row>
    );
  }

  /* private methods */
  async _approveOrder() {
    this.setState({ isLoading: true });
    const tenant = this.props.app.sharedCache().getTenantConfig();
    //Build request
    const params = {
      orderID: this.state.licenseOrder.id,
      user: {
        id: this.state.licenseOrder.externalID,
      },
      appURL: this.props.app.urlManager.appURL,
    };
    //Make request
    const resp = await this.props.app.license.order.approveInvoiceOrder(params);
    if (resp.statusCode == 200 && resp.body) {
      message.success('Order successfully approved!');
      this.setState({ isLoading: false, isRejecting: false, licenseOrder: {}, product: {} });
      if (this.props.onChange) this.props.onChange();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
  async _rejectOrder(data) {
    this.setState({ isLoading: true });
    const tenant = this.props.app.sharedCache().getTenantConfig();
    const params = {
      orderID: this.state.licenseOrder.id,
      user: {
        id: this.state.licenseOrder.externalID,
      },
      cancelReason: data.reason,
    };
    //Make request
    const resp = await this.props.app.license.order.rejectInvoiceOrder(params);
    if (resp.statusCode == 200 && resp.body) {
      message.success('Order successfully rejected!');
      this.setState({ isLoading: false, isRejecting: false, licenseOrder: {}, product: {} });
      if (this.props.onChange) this.props.onChange();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
}
