import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, Table } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//

export default class OrgManagersInvitationHistoryView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      invitations: [],
      sortedInfo: null,
      orgID: null,
      showPageHeader: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this._fetchData();
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      rowKey: 'createdOn',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No invitation history found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    const columns = [
      {
        title: 'E-mail',
        dataIndex: 'userEmail',
        key: 'userEmail',
        width: '15%',
        sorter: (a, b) => a.userEmail.localeCompare(b.userEmail),
        sortOrder: sortedInfo.columnKey === 'userEmail' && sortedInfo.order,
      },
      {
        title: 'Created',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '13%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : ''),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '13%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
      },
      {
        title: 'Expired',
        dataIndex: 'isExpired',
        key: 'isExpired',
        width: '13%',
        sorter: (a, b) => a.isExpired - b.isExpired,
        sortOrder: sortedInfo.columnKey === 'isExpired' && sortedInfo.order,
      },
      {
        title: 'Last Update',
        dataIndex: 'updatedOn',
        key: 'updatedOn',
        width: '13%',
        render: (updatedOn) => (updatedOn ? Utils.getDateOnUIFormatByTimestamp(updatedOn) : ''),
        sorter: (a, b) => a.updatedOn - b.updatedOn,
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        {this.props.showPageHeader ?? (
          <PageHeader
            className="pageHeader"
            title="Invitation History"
            onBack={() => this.props.app.urlManager.pushBack()}
          />
        )}

        <Layout.Content>
          <Table className="adminSearchUsersTable" columns={columns} dataSource={this.state.invitations} {...props} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  // API
  async _fetchData() {
    this.startLoading();

    const orgID = this.props.orgID || this.props.app.urlManager.selectedOrg;
    const reverseOrder = true;

    const resp = await this.props.app.organization.organizationAppInvitationHistory.getOrgAppInvitationHistory(
      orgID,
      reverseOrder
    );

    if (resp.statusCode == 200 && resp.body) {
      this.setState({ invitations: resp.body });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
}
