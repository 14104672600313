import React from 'react';
import autoBind from 'react-autobind';
import { Button, Result, Divider, Row, Col, Tooltip } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../../components/CustomComponent';
import Utils from '../../../../components/Utils';
import CommonLoadingView from '../../../commonComponents/CommonLoadingView';
//
import '../../../../assets/stylesheets/CommonApplicationTile.less';

export default class ApplicationRejectedSubComponent extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { rejectedItems = [], reviewNotes } = this.props;
    const rejectedItemsRows = Utils.separateArrayByGroups(rejectedItems, 4);

    const adminNotes = reviewNotes ? (
      <>
        <strong>Admin notes:</strong> {reviewNotes}
      </>
    ) : null;

    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} usePortal />
        <Row>
          <Col span={24}>
            <Result
              status="error"
              title="Your application was rejected by an administrator."
              subTitle={adminNotes}
              extra={[
                <Button type="primary" onClick={this.props.submitAcknowledge}>
                  Review Application
                </Button>,
                <Button key="downloadapplication" onClick={this.props.onDownloadApplication}>
                  Download Application
                </Button>,
              ]}
            />
          </Col>
        </Row>

        {rejectedItemsRows.length > 0 && <Divider />}

        {rejectedItemsRows.map((tiles, index) => (
          <Row gutter={24} key={String(index)} style={{ marginBottom: 24 }}>
            {tiles.map((tile) => (
              <Col xs={24} sm={12} md={12} lg={6} key={tile.id} className="tile-column">
                <div className="rejected-tile">
                  <header>
                    <strong>{tile.name}</strong>
                  </header>
                  {tile.applicationItemData?.currentApplicationItem?.additionalNotes && (
                    <div className="body">
                      <small>Rejection reason</small>
                      <p>{tile.applicationItemData?.currentApplicationItem?.additionalNotes}</p>
                    </div>
                  )}
                  {tile.applicationItemData?.currentApplicationItem?.fileItems?.length > 0 && (
                    <div className="body">
                      <small>Uploaded files</small>
                      <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                        <li>
                          {tile.applicationItemData.currentApplicationItem.fileItems.map(({ fileID, fileName }) => (
                            <div
                              key={fileID}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 8,
                              }}
                            >
                              <Tooltip title={fileName}>
                                <span className="card-file-name" style={{ marginLeft: 8 }}>
                                  {fileName}
                                </span>
                              </Tooltip>
                              <div className="application-file-actions-buttons">
                                <button
                                  className={status}
                                  type="button"
                                  onClick={() =>
                                    this.props.onDownloadItem(tile.applicationItemData.currentApplicationItem, {
                                      fileID,
                                      fileName,
                                    })
                                  }
                                >
                                  <DownloadOutlined />
                                </button>
                                <button
                                  className={status}
                                  type="button"
                                  onClick={() =>
                                    this.props.onPreviewItem(tile.applicationItemData.currentApplicationItem, {
                                      fileID,
                                      fileName,
                                    })
                                  }
                                >
                                  <EyeOutlined />
                                </button>
                              </div>
                            </div>
                          ))}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </>
    );
  }
}
