import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import CommonVenueDrawer from '../../commonComponents/Drawers/CommonVenueDrawer';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
//
export default class AdminVenuesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      venues: [],
      cities: [],
      regions: [],
      sortedInfo: { columnKey: 'name', order: 'ascend' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
  handleCreateVenue() {
    this.venueDrawer.show(null, this.state.cities, this.state.regions);
  }
  //Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Table row actions
  handleEditVenue(venueID) {
    this.venueDrawer.show(venueID, this.state.cities, this.state.regions);
  }
  async handleDeleteVenue(venueID) {
    this.startLoading();
    const resp = await this._deleteVenue(venueID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Venues" onBack={() => this.props.app.urlManager.pushBack()} />
        <CommonVenueDrawer
          {...Utils.propagateRef(this, 'venueDrawer')}
          onUpdate={this.fetchData}
          app={this.props.app}
        />
        <Layout.Content>
          <Row type="flex" justify="end">
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateVenue}>
                {' '}
                Create new Venue{' '}
              </Button>
            </Col>
          </Row>
          {this._renderVenuesTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderVenuesTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: '20%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Address',
        key: 'address',
        dataIndex: 'address',
        width: '35%',
        sorter: (a, b) => this._getAddressString(a.address).localeCompare(this._getAddressString(b.address)),
        render: (props) => this._getAddressString(props),
        sortOrder: sortedInfo.columnKey === 'address' && sortedInfo.order,
      },
      {
        title: 'City',
        key: 'city',
        width: '10%',
        sorter: (a, b) =>
          (this.state.cities || [])
            .find((c) => c.id == a.cityID)
            .name.localeCompare((this.state.cities || []).find((c) => c.id == b.cityID).name),
        render: (props) => {
          const city = (this.state.cities || []).find((c) => c.id == props.cityID);
          return `${city.name} (${city.province})`;
        },
        sortOrder: sortedInfo.columnKey === 'city' && sortedInfo.order,
      },
      {
        title: 'Region',
        key: 'region',
        width: '10%',
        render: (props) =>
          (this.state.regions || []).find(
            (c) => c.id == (this.state.cities || []).find((c) => c.id == props.cityID).regionID
          ).name,
      },
      {
        title: 'Created On',
        key: 'createdOn',
        width: '15%',
        render: (props) => (props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditVenue.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete the venue '${props.name}'?`}
                  onConfirm={this.handleDeleteVenue.bind(this, props.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No venues found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Layout.Content>
        <Table className="adminVenuesTable" columns={columns} dataSource={this.state.venues} {...props} />
      </Layout.Content>
    );
  }

  /* private Helper */
  _getAddressString(address) {
    if (address) return `${address.street1}${address.street2 ? `, ${address.street2}` : ''}, ${address.postalCode}`;
    return '';
  }

  /* private API */
  async _loadAll() {
    const resp = await Promise.all([
      this.props.app.sharedCache().getRegions(),
      this.props.app.sharedCache().getCities(),
      this.props.app.sharedCache().getVenues(),
    ]);
    if (!resp[0]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load regions!');
      return false;
    }
    if (!resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    if (!resp[2]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load venues!');
      return false;
    }
    return { cities: resp[1], regions: resp[0], venues: resp[2] };
  }
  async _deleteVenue(venueID) {
    this.props.app.sharedCache().invalidateVenues();
    const resp = await this.props.app.classroom.venue.deleteVenue(venueID);
    if (resp.statusCode == 200) {
      message.success('Venue deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
