import React from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import Utils from '../../components/Utils';
//
import CustomComponent from '../../components/CustomComponent';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonAssignedLicensesTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null, isLoading: false };
  }

  //Actions
  async handleRemoveLicense(row) {
    await this._removeLicense(row.externalID, row.assignedActivationKey);
    this.props.refreshPage();
  }

  //Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Assigned On',
        dataIndex: 'createdOn',
        key: 'createdOn',
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
        sorter: (a, b) => a.createdOn - b.createdOn,
        render: (createdOn) => moment(createdOn).format('YYYY-MM-DD'),
      },
      {
        title: 'Product',
        key: 'productName',
        sortOrder: sortedInfo.columnKey === 'productName' && sortedInfo.order,
        sorter: (a, b) => {
          const productNameA = this.props.app.sharedCache().getProductByID(a.productID)?.name || '';
          const productNameB = this.props.app.sharedCache().getProductByID(b.productID)?.name || '';
          return productNameA.localeCompare(productNameB);
        },
        render: (text, record) => {
          const productName = this.props.app.sharedCache().getProductByID(record.productID)?.name;
          return productName ? productName : '-';
        },
      },
      {
        title: 'License ID',
        dataIndex: 'licenseID',
        key: 'licenseID',
        sortOrder: sortedInfo.columnKey === 'licenseID' && sortedInfo.order,
        sorter: (a, b) => a.licenseID.localeCompare(b.licenseID),
      },
      {
        title: 'Student',
        dataIndex: 'assignedToName',
        key: 'assignedToName',
        sortOrder: sortedInfo.columnKey === 'assignedToName' && sortedInfo.order,
        sorter: (a, b) => a.assignedToName.localeCompare(b.assignedToName),
        render: (assignedToName, record) => (
          <Tooltip title={record.assignedToEmail}>
            <span>{assignedToName}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Consumed On',
        dataIndex: 'activatedOn',
        key: 'activatedOn',
        render: (activatedOn) => (activatedOn !== -1 ? Utils.getDateOnUIFormatByTimestamp(activatedOn) : ''),
        sorter: (a, b) => a.activatedOn - b.activatedOn,
        sortOrder: sortedInfo.columnKey === 'activatedOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        key: 'Actions',
        align: 'center',
        render: (text, record) =>
          record.activatedOn === -1 && (
            <Tooltip placement="bottomLeft" title="Delete License">
              <Popconfirm
                placement="left"
                okText="Yes"
                cancelText="No"
                title={`This will remove this license from this student. Confirm?`}
                onConfirm={() => this.handleRemoveLicense(record)}
              >
                <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
              </Popconfirm>
            </Tooltip>
          ),
      },
    ];
    const props = {
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      refreshPage: this.props.refreshPage,
      locale: { emptyText: 'No Assigned Licenses found!' },
      pagination: { pageSize: 50, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return <Table columns={columns} dataSource={this.props.assignedLicenses} {...props} />;
  }

  /* private API */
  async _removeLicense(externalID, activationKey) {
    const resp = await this.props.app.license.license.unassignLicense(externalID, activationKey);
    if (resp.statusCode == 200) {
      message.success('License removed with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
