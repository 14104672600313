import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Select, Row, Col, Empty } from 'antd';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
//
import WhiteBox from '../../commonComponents/WhiteBox';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
//
export default class AdminExamAnalysisReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.anyInstructorID = 'ANY';

    this.state = {
      isLoading: false,
      reportDates: [],
      disabled: true,
      instructors: [],
      courseID: this.props.app.sharedCache().getAllUniqueCourses()[0].id,
      selectedInstructorID: this.anyInstructorID,
      reportData: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._loadInstructors();
  }

  async handleFilter(dates) {
    let [from, to] = dates || [];
    if (!from || !to) {
      this.setState({ disabled: true });
      return;
    }

    this.setState({ isLoading: true });

    to = to.format('YYYY-MM-DD');
    from = from.format('YYYY-MM-DD');

    const resp = await this.props.app.classroom.report.sessionResultsAnalysis(
      this.state.courseID,
      this.state.selectedInstructorID === this.anyInstructorID ? undefined : this.state.selectedInstructorID,
      from,
      to
    );

    if (resp.statusCode === 200) {
      this.setState({
        reportData: {
          ...resp.body,
          passRate: Math.round(resp.body.passRate),
          failRate: Math.round(resp.body.failRate),
        },
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }

  //Custom filter
  handleChangeCertification(courseID) {
    this.setState({ courseID });
  }

  handleChangeInstructor(selectedInstructorID) {
    this.setState({ selectedInstructorID });
  }

  render() {
    const courses = this.props.app.sharedCache().getAllUniqueCourses();
    const { instructors, reportData } = this.state;

    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Exam Analysis" />

          <Layout.Content>
            <div className="usersFilters">
              <Row type="flex">
                <Col className="filterItem">
                  <strong>Course:</strong>

                  <Select
                    style={{ minWidth: 200 }}
                    value={this.state.courseID}
                    size="large"
                    onChange={this.handleChangeCertification}
                  >
                    {courses.map((course) => (
                      <Select.Option key={course.id} value={course.id}>
                        {course.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col className="filterItem">
                  <strong>Instructor:</strong>

                  <Select
                    style={{ minWidth: 240 }}
                    value={this.state.selectedInstructorID}
                    size="large"
                    onChange={this.handleChangeInstructor}
                  >
                    <Select.Option value={this.anyInstructorID}>Any</Select.Option>

                    {instructors.map((instructor) => (
                      <Select.Option key={instructor.id} value={instructor.id}>
                        {instructor.firstName} {instructor.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col className="filterItem">
                  <DateRangeFilter
                    buttonLabel="Generate report"
                    onFilter={this.handleFilter}
                    buttonDisabled={this.disabled || this.state.isDownloading}
                    buttonLoading={this.state.isDownloading}
                  />
                </Col>
              </Row>
            </div>
          </Layout.Content>

          {reportData && (
            <Row style={{ height: 330, marginTop: 32 }} gutter={32}>
              {reportData.failCount > 0 ||
              reportData.passCount > 0 ||
              reportData.failRate > 0 ||
              reportData.passRate > 0 ? (
                <>
                  <Col span={12}>
                    <WhiteBox title="Pass/Fail Rate (%)" style={{ height: 330 }}>
                      <ResponsivePie
                        data={[
                          {
                            id: 'Fail',
                            label: 'Fail',
                            value: reportData.failRate,
                            color: '#E77D89',
                          },
                          {
                            id: 'Pass',
                            label: 'Pass',
                            value: reportData.passRate,
                            color: '#A1E5BC',
                          },
                        ]}
                        colors={{ datum: 'data.color' }}
                        margin={{ top: 24, right: 0, bottom: 100, left: 0 }}
                        activeOuterRadiusOffset={8}
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        theme={{
                          labels: { text: { fontSize: 13 } },
                        }}
                      />
                    </WhiteBox>
                  </Col>

                  <Col span={12}>
                    <WhiteBox title="Pass/Fail Rate" style={{ height: 330 }}>
                      <ResponsiveBar
                        data={[
                          {
                            id: 'Pass/Fail Rate',
                            label: 'Pass/Fail Rate',
                            value: reportData.passCount + reportData.failCount,
                            Passed: reportData.passCount,
                            Failed: reportData.failCount,
                            PassedColor: '#A1E5BC',
                            FailedColor: '#E77D89',
                          },
                        ]}
                        keys={['Passed', 'Failed']}
                        layout="horizontal"
                        colors={({ data, id }) => data[`${id}Color`]}
                        labelTextColor="#333"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        margin={{ top: 0, right: 0, bottom: 70, left: 120 }}
                        axisLeft={{ tickSize: 0, tickPadding: 20, tickRotation: 0 }}
                        enableGridY={false}
                        theme={{
                          legends: { text: { fontSize: 13 } },
                          axis: { ticks: { text: { fontSize: 13 } } },
                          labels: { text: { fontSize: 13 } },
                        }}
                      />
                    </WhiteBox>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <Empty description="Not data found with these filters." />
                </Col>
              )}
            </Row>
          )}
        </Layout.Content>
      </>
    );
  }

  async _loadInstructors() {
    this.startLoading();
    const instructors = await this.props.app.sharedCache().getInstructors();
    this.setState({ instructors, isLoading: false });
  }
}
