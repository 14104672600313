import React from 'react';
import autoBind from 'react-autobind';
import EventWrapper from 'react-big-calendar/lib/addons/dragAndDrop/EventWrapper';
import { Typography, Row, Col } from 'antd';
import moment from 'moment';
//
import Globals from '../../../config/Globals';
//
import CommonSessionPopover from '../Popovers/CommonSessionPopover';
export default class CommonSessionCalendarEvent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  onEdit() {
    if (this.props?.event?.onSelect) this.props.event.onSelect(this.props.event.metadata.id);
  }
  //UI
  render() {
    const { app, metadata: session, isMonthlyView } = this.props.event;
    const course = app.sharedCache().getCourseByID(session?.courseID);

    const isSessionCancelled = session.state === Globals.Session_State.CANCELLED;
    const backgroundColor = isSessionCancelled ? '#B4B4B4' : this.props.event.backgroundColor;

    //type != than date means show more popover visualization
    const isMonthly = isMonthlyView && this.props.resizable != undefined;
    const eventName =
      session?.name ||
      (this.props.event.description != undefined ? `Unavailable - ${this.props.event.description}` : 'Unavailable') +
        ' ' +
        (course?.displayName || '') +
        '';
    return (
      <EventWrapper {...this.props}>
        <CommonSessionPopover app={app} session={session} onSelect={this.onEdit}>
          <div
            className="rbc-event cleanEventContainer"
            style={this._getStyle()}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
          >
            <div className="customSessionEvent" style={{ height: '100%', backgroundColor: backgroundColor }}>
              {!isMonthly && (
                <Row type="flex" className="secondRow">
                  {session?.type && Globals.getTemplateTypeIcon(session?.type, false)}
                  <Typography.Text className="sessionName">{eventName}</Typography.Text>
                </Row>
              )}
              <Row type="flex" justify="space-between">
                <Col>
                  <Typography.Text ellipsis={true} className={isMonthly ? 'titleLabel' : 'dateLabel'}>
                    {isMonthly && session?.type && Globals.getTemplateTypeIcon(session?.type, false)}
                    {isMonthly && eventName} - {moment(this.props.event.start, 'hh:mm A').format('hh:mm A')}
                    {' - '}
                    {moment(this.props.event.end, 'hh:mm A').format('hh:mm A')}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </div>
        </CommonSessionPopover>
      </EventWrapper>
    );
  }
  /* private UI */
  _getStyle() {
    if (!this.props.style) return {};
    return {
      top: `${this.props.style.top}%`,
      left: `${this.props.style.xOffset}%`,
      width: `${this.props.style.width}%`,
      height: `${this.props.style.height}%`,
    };
  }
}
