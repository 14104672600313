import React from 'react';
import autoBind from 'react-autobind';
import { Modal, Row, Col, Typography, Input, Button, Divider, Table, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
//
import CommonOrganizationSelectionConfirmationDrawer from './CommonOrganizationSelectionConfirmationDrawer';
//
const NEW_STATE = { isLoading: false, isVisible: false, searchTerm: '', data: null, selectedOrg: null };
//props are: app, requiresWorksafeValidation, allowOrgsListing, onSelection, allowEmptySelection, isOrgApp
export default class CommonOrganizationSelectionModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = NEW_STATE;
  }
  //Public
  show() {
    this.setState({ ...NEW_STATE, isVisible: true }, () => (this.props.allowOrgsListing ? this._search() : null));
  }
  //Actions
  handleClose() {
    this.setState({ isVisible: false });
  }
  //Search actions
  handleChange(e) {
    this.setState({ searchTerm: e.target.value }, this.handleSearch);
  }
  handleSearch() {
    if (this.state.searchTerm?.length > 0) this._scheduleSearch();
    else if (this.props.allowOrgsListing) this._scheduleSearch();
    else this.setState({ data: null });
  }
  //Table actions
  handleSelectOrg(item) {
    this.setState({ selectedOrg: item }, () => {
      if (this.props.requiresWorksafeValidation) this.confirmationDrawer.show();
      else this.handleModalValidationCompleted();
    });
  }
  //Drawer actions
  handleModalValidationCancelled() {
    this.setState({ selectedOrg: null });
  }
  handleModalValidationCompleted() {
    if (this.props.onSelection) this.props.onSelection(this.state.selectedOrg);
    this.handleClose();
  }
  //Addition actions
  handleEmptySelection() {
    this.setState({ selectedOrg: null }, this.handleModalValidationCompleted);
  }

  //UI
  render() {
    const worksafeTerm = this.props.app.sharedCache().getWorksafeTerm();
    const columns = [
      {
        title: 'Name',
        key: 'name',
        render: (props) => (
          <Tag>
            {props.name}
            {props.tradeName ? ` - ${props.tradeName}` : ''}
          </Tag>
        ),
      },
      {
        title: '',
        key: 'select',
        align: 'right',
        render: (item) => {
          if (item.deletionDate > 0)
            return (
              <Button type="primary" size="small" disabled>
                Deleted Company
              </Button>
            );
          else
            return (
              <Button type="primary" size="small" onClick={this.handleSelectOrg.bind(this, item)}>
                Select
              </Button>
            );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      scroll: { x: true },
      locale: {
        emptyText: this.state.data
          ? 'No companies found!'
          : `Please, type your employer's name or ${worksafeTerm} above!`,
      },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Modal
        title="Company selection"
        open={this.state.isVisible}
        onCancel={this.handleClose}
        footer={null}
        width={700}
      >
        <CommonOrganizationSelectionConfirmationDrawer
          app={this.props.app}
          selectedOrg={this.state.selectedOrg}
          {...Utils.propagateRef(this, 'confirmationDrawer')}
          onValidationCancelled={this.handleModalValidationCancelled}
          onValidationCompleted={this.handleModalValidationCompleted}
        />
        {/* Search bar */}
        <Row type="flex">
          <Col span={24}>
            <Input
              prefix={<SearchOutlined />}
              size="large"
              onPressEnter={this.handleSearch}
              placeholder={`Type your employer's name or ${worksafeTerm} ID here`}
              value={this.state.searchTerm}
              onChange={this.handleChange}
              autoFocus
            />
          </Col>
        </Row>
        {/* Table section */}
        <Divider />
        <Row type="flex">
          {' '}
          <Col span={24}>
            {' '}
            <Table columns={columns} dataSource={this.state.data || []} {...props} />{' '}
          </Col>{' '}
        </Row>
        {/* Unknown employer section */}
        {this.props.allowEmptySelection ? (
          <>
            <Divider />
            <Row type="flex" justify="center">
              <Col>
                <Typography.Text type="secondary">Unable to find your company? You can always select </Typography.Text>
                <Button style={{ marginLeft: 4 }} type="dashed" size="small" onClick={this.handleEmptySelection}>
                  Unknown Employer
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <div style={{ width: 600 }} />
        )}
      </Modal>
    );
  }
  /* private - debounces */
  _scheduleSearch() {
    if (this.state.isLoading) return;
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this._search();
      this.debounce = null;
    }, 250);
  }
  /* private - API */
  async _search() {
    if (!this._isMounted) return;
    this.setState({ data: [], isLoading: true });

    // Request
    let resp;

    const params = {
      from: 0,
      noDefaultSorting: true,
    };

    if (this.props.onlyActives) {
      params.status = 'Active';
    }

    if (this.props.isOrgApp) {
      resp = await this.props.app.organization.organizationApp.searchOrganizationsAppsByTerm(
        this.state.searchTerm || '',
        params
      );
    } else {
      resp = await this.props.app.organization.organization.searchOrganizationsByTerm(
        this.state.searchTerm || '',
        params
      );
    }
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp?.body?.orgs) {
      const orgs = resp.body.orgs;
      this.setState({ data: orgs, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: null, isLoading: false });
    }
  }
}
