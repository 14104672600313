import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Card, Tooltip, Button, Divider, Tag, Typography, Alert } from 'antd';
import Utils from '../../../../components/Utils';
import { MdComputer } from 'react-icons/md';
import { CalendarOutlined } from '@ant-design/icons';
import Globals from '../../../../config/Globals';
//props are: onSelect, onCancel, courseSpecs, sessions, app
export default class CommonCoursePurchaseModalSubStep_LoadingView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    const uiTerm = this.props?.courseSpecs?.uiTerm?.toLowerCase() || '';
    const onlineCount = (
      this.props.sessions?.filter(
        (s) => s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT
      ) || []
    ).length;
    const schedulable = (
      this.props.sessions?.filter(
        (s) => s.type != Globals.Session_Type.ONLINE && s.type != Globals.Session_Type.ONLINE_EXT
      ) || []
    ).length;
    return (
      <div>
        <Row type="flex" justify="center" style={{ marginTop: 20 }}>
          <Alert
            message={`Attention!`}
            type="info"
            description={
              <Typography.Text>
                {`You have different styles of sessions available for ${this.props.courseSpecs?.description}.`}
                <br></br>
                {`Please choose the style that is applicable to you for this ${uiTerm}.`}
              </Typography.Text>
            }
          />
        </Row>
        <Divider orientation="left">Select a session type:</Divider>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Card
              hoverable
              style={{ width: 340, marginBottom: 20, marginTop: 20 }}
              onClick={() => this.props.onSelect(1)}
            >
              <Row type="flex" justify="start" align="middle" style={{ height: 110 }}>
                <Col span={6}>
                  {' '}
                  <MdComputer size={40} />{' '}
                </Col>
                <Col span={15}>
                  <Typography.Text strong>{`Online ${Utils.capitalizeString(uiTerm)}`}</Typography.Text>
                  <br />
                  <Typography.Text>
                    {'Online sessions are held enterly in you computer at your own time.'}
                  </Typography.Text>
                </Col>
                <Col span={2} offset={1}>
                  <Tooltip title={`${onlineCount} Available Online Sesssions`}>
                    <Tag color="#9DADBC">{onlineCount}</Tag>
                  </Tooltip>
                </Col>
              </Row>
              <Row type="flex" justify="center" style={{ marginTop: 10 }}>
                {' '}
                <Tag color={this.props.app.themeManager.primaryColor}> Select </Tag>{' '}
              </Row>
            </Card>
          </Col>
          <Col offset={2}>
            <Card
              hoverable
              style={{ width: 340, marginBottom: 20, marginTop: 20 }}
              onClick={() => this.props.onSelect(2)}
            >
              <Row type="flex" justify="start" align="middle" style={{ height: 110 }}>
                <Col span={6}>
                  {' '}
                  <CalendarOutlined style={{ fontSize: 40 }} />{' '}
                </Col>
                <Col span={15}>
                  <Typography.Text strong>{`Scheduled ${Utils.capitalizeString(uiTerm)}`}</Typography.Text>
                  <br />
                  <Typography.Text>
                    {
                      'Scheduled sessions take place on a specific time and location (being online or presential locations).'
                    }
                  </Typography.Text>
                </Col>
                <Col span={2} offset={1}>
                  <Tooltip title={`${schedulable} Available Scheduled Sesssions`}>
                    <Tag color="#9DADBC">{schedulable}</Tag>
                  </Tooltip>
                </Col>
              </Row>
              <Row type="flex" justify="center" style={{ marginTop: 10 }}>
                {' '}
                <Tag color={this.props.app.themeManager.primaryColor}> Select </Tag>{' '}
              </Row>
            </Card>
          </Col>
        </Row>
        {this._renderButtonsFooter()}
      </div>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button form="summary" htmlType="submit" key="submit" type="secondary" onClick={this.props.onCancel}>
          {' '}
          Cancel{' '}
        </Button>
      </Row>
    );
  }
}
