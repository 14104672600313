import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table } from 'antd';
//props are: emailCertificates, isLoading
export default class CommonEmailCertificatesTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Email',
        key: 'lrnmail',
        width: '30%',
        sorter: (a, b) => (a.lrnmail || '').localeCompare(b.lrnmail || ''),
        sortOrder: sortedInfo.columnKey === 'lrnmail' && sortedInfo.order,
        render: (props) => props.lrnmail || '',
      },
      {
        title: 'Name',
        key: 'lrnname',
        width: '30%',
        sorter: (a, b) => (a.lrnname || '').localeCompare(b.lrnname || ''),
        sortOrder: sortedInfo.columnKey === 'lrnname' && sortedInfo.order,
        render: (props) => props.lrnname || '',
      },
      {
        title: 'Certificate Number',
        key: 'certno',
        width: '20%',
        sorter: (a, b) => (a.certno || '').localeCompare(b.certno || ''),
        sortOrder: sortedInfo.columnKey === 'certno' && sortedInfo.order,
        render: (props) => props.certno || '',
      },
      {
        title: 'Completion Date',
        key: 'completiondate',
        width: '20%',
        sorter: (a, b) => (a.completiondate || '').localeCompare(b.completiondate || ''),
        sortOrder: sortedInfo.columnKey === 'completiondate' && sortedInfo.order,
        render: (props) => props.completiondate || '',
      },
    ];
    const props = {
      rowKey: (props) => `${props.certno}${props.completiondate}`,
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No email certificates found!' },
      pagination: false,
    };
    return (
      <Table
        className="adminEmailCertificatesTable"
        columns={columns}
        dataSource={this.props.emailCertificates}
        {...props}
      />
    );
  }
}
