import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Form, InputNumber, Input, Col, Button } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
//
export default class CommonCertificationIncompleteCertWarningsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, incompleteCertWarning: null, index: -1 };
  }
  //Public
  show(incompleteCertWarning, index) {
    this.setState({ isVisible: true, incompleteCertWarning, index: index != undefined ? index : -1 }, () => {
      if (incompleteCertWarning) this.form.setFieldsValue({ ...incompleteCertWarning });
    });
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isVisible: false, incompleteCertWarning: null, index: -1 });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onUpdate(formData, this.state.index);
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.incompleteCertWarning ? 'Edit' : 'Create'} Incomplete Certification Warning`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="template"
                label="Email Template"
                extra="Email template to be used to send incomplete certification warnings"
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item
                name="period"
                label="Period"
                extra="Email student after an incomplete certification has had no courses completed (days)."
              >
                <InputNumber style={{ width: '100%' }} min={-1} />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              {' '}
              <Button type="primary" onClick={this.handleSubmit}>
                Done
              </Button>{' '}
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}
