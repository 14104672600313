import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Form, Select, Col, Button } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
//
//props are: onUpdate, courses
export default class CommonCertificationCoursesReqsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, requirements: null, index: -1 };
  }
  //Public
  show(requirements, index) {
    this.setState({ isVisible: true, requirements, index: index != undefined ? index : -1 }, () => {
      if (requirements) this.form.setFieldsValue({ requirements });
    });
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isVisible: false, requirements: null, index: -1 });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onUpdate(formData.requirements, this.state.index);
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.requirements ? 'Edit' : 'Create'} Requirement`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="requirements"
                label="Requirement(s)"
                extra="List of requirements that can be completed to advance to the certification next step."
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Select mode="multiple" style={{ minWidth: 300 }}>
                  {' '}
                  {(this.props.courses || []).map((opt) => (
                    <Select.Option key={opt.id} value={opt.id}>
                      {opt.name}
                    </Select.Option>
                  ))}{' '}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              {' '}
              <Button type="primary" onClick={this.handleSubmit}>
                Done
              </Button>{' '}
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}
