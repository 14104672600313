import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Row, Col, Button, Divider } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CommonCertificationForm from '../../commonComponents/Forms/CommonCertificationForm';
import CommonCertificationApplicationForm from '../../commonComponents/Forms/CommonCertificationApplicationForm';
import CommonCertificationRenewalForm from '../../commonComponents/Forms/CommonCertificationRenewalForm';
import CommonSettingsCoursesTable from '../../commonComponents/CommonSettingsCoursesTable';
//
const RequestMask = { CERTIFICATION: 2, COURSES: 4, EQUIVS: 8, REQS: 16, ALL: 2 | 4 | 8 | 16 };
//
export default class AdminCertificationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const certificationID = this.props.match.params.id;
    this.state = {
      isLoading: false,
      isNew: certificationID == Globals.URL_NewIndentifier,
      certificationID: certificationID == Globals.URL_NewIndentifier ? null : certificationID,
      certification: null,
      courses: [],
      equivalences: [],
      requirements: [],
      certEquivalences: [],
      certRequirements: [],
      selectedTab: 'main',
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    //Load data
    if (this.state.certificationID) await this.fetchData(RequestMask.ALL);
    //Tab
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        if (this.state.certification) {
          this.certForm.setFieldsValue(this.state.certification);
          this.applicationForm.setFieldsValue(this.state.certification);
          this.renewalApplicationForm.setFieldsValue(this.state.certification);
          this.renewalForm.setFieldsValue(this.state.certification);
        }
      });
    } else this.stopLoading();
  }

  //Actions
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
  //courses actions
  async handleAddCourse() {
    if (!this.state.certificationID) return;
    const confirm = await this.props.app.alertController.showQuestionAlert(
      'Attention!',
      'Any unsaved change on the certification page will be discarded. Are you sure you want to go courses page?'
    );
    if (!confirm) return;
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.course,
      null,
      this.state.certificationID,
      Globals.URL_NewIndentifier
    );
  }
  async handleDeleteCourse(course) {
    //double validation
    const confirmation = await this.props.app.alertController.showPromptAlert(
      'Attention!',
      `Course ${course.displayName}-${course.description} will be deleted and this operation is not reversible! Are you sure you want to delete it? (type the display name to confirm)`
    );
    if (confirmation != course.displayName) return;
    //continue
    this.startLoading();
    await this._deleteCourse(course.certificationID, course.id);
    await this.fetchData(RequestMask.ALL);
    this.stopLoading();
  }
  async handleEditCourse(course) {
    if (!this.state.certificationID) return;
    const confirm = await this.props.app.alertController.showQuestionAlert(
      'Attention!',
      'Any unsaved change on the certification page will be discarded. Are you sure you want to go courses page?'
    );
    if (!confirm) return;
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.course, null, course.certificationID, course.id);
  }
  //submission
  async handleCertSubmit() {
    //validate forms
    const formData = await this.certForm.validateFields();
    if (!formData) {
      this.handleTabChange('main');
      return;
    }
    const appData = await this.applicationForm.validateFields();
    if (!appData) {
      this.handleTabChange('application');
      return;
    }
    const renewalAppData = await this.renewalApplicationForm.validateFields();
    const renewalData = await this.renewalForm.validateFields();
    if (!renewalAppData || !renewalData) {
      this.handleTabChange('renewal');
      return;
    }
    //build cert obj
    const certObj = {
      ...formData,
      ...appData,
      ...(renewalData?.renewal
        ? {
            ...renewalData,
            renewal: { ...renewalData?.renewal, ...renewalAppData },
          }
        : renewalData),
    };
    //
    this.startLoading();
    if (this.state.certificationID) await this._updateCert(certObj);
    else await this._createCert(certObj);
    this.stopLoading();
  }

  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader
            className="pageHeader"
            title={'Certification'}
            onBack={() => this.props.app.urlManager.pushBack()}
          />
          <WhiteBox>
            <Row type="flex" justify="end">
              {' '}
              <Col>
                {' '}
                <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCertSubmit}>
                  {' '}
                  {this.state.certificationID ? 'Update' : 'Create'} Certification{' '}
                </Button>{' '}
              </Col>{' '}
            </Row>
            <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
              <Tabs.TabPane tab="Main" key="main" forceRender>
                <CommonCertificationForm
                  {...Utils.propagateRef(this, 'certForm')}
                  isEdit={!!this.state.certificationID}
                  app={this.props.app}
                  courses={this.state.courses}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Application" key="application" forceRender>
                <CommonCertificationApplicationForm
                  {...Utils.propagateRef(this, 'applicationForm')}
                  equivalences={this.state.equivalences}
                  requirements={this.state.requirements}
                  certEquivalences={this.state.certEquivalences}
                  certRequirements={this.state.certRequirements}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Expiration & Renewal" key="renewal" forceRender>
                <CommonCertificationRenewalForm
                  app={this.props.app}
                  {...Utils.propagateRef(this, 'renewalForm')}
                  courses={this.state.courses}
                  requirements={this.state.requirements}
                />
                <Divider orientation="left">Application</Divider>
                <CommonCertificationApplicationForm
                  {...Utils.propagateRef(this, 'renewalApplicationForm')}
                  equivalences={this.state.equivalences}
                  requirements={this.state.requirements}
                  isRenewal
                  certEquivalences={this.state.certEquivalences}
                  certRequirements={this.state.certRequirements}
                  certification={this.state.certification}
                />
              </Tabs.TabPane>
              {!this.state.isNew && (
                <Tabs.TabPane tab="Courses" key="courses" forceRender>
                  {' '}
                  {this._renderCoursesTab()}{' '}
                </Tabs.TabPane>
              )}
            </Tabs>
          </WhiteBox>
        </Layout.Content>
      </>
    );
  }

  /* private UI */
  _renderCoursesTab() {
    return (
      <>
        <Row type="flex" justify="end">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleAddCourse}>
              Create Course
            </Button>{' '}
          </Col>
        </Row>
        <Row type="flex" style={{ marginTop: 10 }}>
          <Col span={24}>
            <CommonSettingsCoursesTable
              courses={this.state.courses}
              onDelete={this.handleDeleteCourse}
              onEdit={this.handleEditCourse}
              isLoading={this.state.isLoading}
            />
          </Col>
        </Row>
      </>
    );
  }

  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.CERTIFICATION) === RequestMask.CERTIFICATION
        ? this.props.app.config.certification.getCertification(this.state.certificationID)
        : null,
      (mask & RequestMask.COURSES) === RequestMask.COURSES
        ? this.props.app.config.course.getCourses(this.state.certificationID)
        : null,
      (mask & RequestMask.EQUIVS) === RequestMask.EQUIVS
        ? this.props.app.config.certEquivalency.getCertEquivalences(this.state.certificationID)
        : null,
      (mask & RequestMask.EQUIVS) === RequestMask.EQUIVS
        ? this.props.app.config.equivalency.getAllEquivalencies()
        : null,
      (mask & RequestMask.REQS) === RequestMask.REQS
        ? this.props.app.config.certRequirement.getAllCertRequirements(this.state.certificationID)
        : null,
      (mask & RequestMask.REQS) === RequestMask.REQS ? this.props.app.config.requirement.getAllRequirements() : null,
    ]);
    if (
      (mask & RequestMask.CERTIFICATION) === RequestMask.CERTIFICATION &&
      !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.id)
    ) {
      this.props.app.alertController.showAPIErrorAlert('Could not load certification!', resp[0]);
      return false;
    }
    if (
      (mask & RequestMask.COURSES) === RequestMask.COURSES &&
      !(resp[1].statusCode == 200 && resp[1].body && resp[1].body.courses)
    ) {
      this.props.app.alertController.showAPIErrorAlert('Could not load certification courses!', resp[1]);
      return false;
    }
    if (
      (mask & RequestMask.EQUIVS) === RequestMask.EQUIVS &&
      !(resp[2].statusCode == 200 && resp[2].body && resp[2].body.certEquivalences)
    ) {
      this.props.app.alertController.showAPIErrorAlert('Could not load certification equivalences!', resp[2]);
      return false;
    }
    if (
      (mask & RequestMask.EQUIVS) === RequestMask.EQUIVS &&
      !(resp[3].statusCode == 200 && resp[3].body && resp[3].body.equivalences)
    ) {
      this.props.app.alertController.showAPIErrorAlert('Could not load equivalences!', resp[3]);
      return false;
    }
    if (
      (mask & RequestMask.REQS) === RequestMask.REQS &&
      !(resp[4].statusCode == 200 && resp[4].body && resp[4].body.certRequirements)
    ) {
      this.props.app.alertController.showAPIErrorAlert('Could not load certification requirements!', resp[4]);
      return false;
    }
    if (
      (mask & RequestMask.REQS) === RequestMask.REQS &&
      !(resp[5].statusCode == 200 && resp[5].body && resp[5].body.requirements)
    ) {
      this.props.app.alertController.showAPIErrorAlert('Could not load requirements!', resp[5]);
      return false;
    }
    return {
      ...((mask & RequestMask.CERTIFICATION) === RequestMask.CERTIFICATION ? { certification: resp[0].body } : {}),
      ...((mask & RequestMask.COURSES) === RequestMask.COURSES ? { courses: resp[1].body.courses } : {}),
      ...((mask & RequestMask.EQUIVS) === RequestMask.EQUIVS
        ? { certEquivalences: resp[2].body.certEquivalences }
        : {}),
      ...((mask & RequestMask.EQUIVS) === RequestMask.EQUIVS ? { equivalences: resp[3].body.equivalences } : {}),
      ...((mask & RequestMask.REQS) === RequestMask.REQS ? { certRequirements: resp[4].body.certRequirements } : {}),
      ...((mask & RequestMask.REQS) === RequestMask.REQS ? { requirements: resp[5].body.requirements } : {}),
    };
  }
  async _createCert(certData) {
    if (!this._isMounted) return;
    const resp = await this.props.app.config.certification.createCertification({
      ...certData,
      ...(this.props.app.isSysAdmin() && certData.id
        ? {
            id: certData.id,
          }
        : {
            id: `Cert-${this.props.app.sharedCache().getCustomerID()}-${this.props.app.sharedCache().getTenantConfig().id}-${config.Stage}-${certData.title.toLowerCase().replace(/[ ?&/]/g, '')}-${Date.now()}`,
          }),
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
      customerID: this.props.app.sharedCache().getTenantConfig().customerID,
      //invisble
      certificationEngineType: 'STREAM',
      lockedBy: [],
      superseededBy: [],
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Certification created with success!');
      //
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.certifications);
      //
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _updateCert(certData) {
    const resp = await this.props.app.config.certification.updateCertification({
      ...certData,
      id: this.state.certificationID,
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
      customerID: this.props.app.sharedCache().getTenantConfig().customerID,
      //invisble
      certificationEngineType: 'STREAM',
      lockedBy: [],
      superseededBy: [],
    });
    if (resp.statusCode == 200) {
      message.success('Certification updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteCourse(certID, courseID) {
    const resp = await this.props.app.config.course.deleteCourse(certID, courseID);
    if (resp.statusCode == 200) {
      message.success('Course deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
