import React from 'react';
import autoBind from 'react-autobind';
import { Alert, Form, Col, Row, Divider, Result, Button, Spin, Input, Steps } from 'antd';
import MaskedInput from 'antd-mask-input';
//
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
import _CommonSessionSelectionModal from './_CommonSessionSelectionModal';
import '../../../assets/stylesheets/CommonCourseLicenseRedeemModal.less';
import CommonCoursePurchaseModalSubStep_InfoConfirmation from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_InfoConfirmation';
//
const STEPS = { FORM: 'FORM', LOADING: 'LOADING', RESULT: 'RESULT', INFO_CONFIRMATION: 'INFO_CONFIRMATION' };
const LicenseInput = (props) => {
  const mask = React.useMemo(() => [{ mask: '******{-}*******{-}*******{-}*******', placeholderChar: '#' }], []);
  return <MaskedInput {...props} value={props.availablekey} mask={mask} />;
};
//props are: course, app, onChange, isActivation (optional)
export default class CommonCourseLicenseRedeemModal extends _CommonSessionSelectionModal {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  async handleSubmit() {
    let resp = null;
    if (this.props.course?.availableKeys?.[0]) resp = { licenseKey: this.props.course?.availableKeys?.[0] };
    else
      resp = await this.currentChild.form.validateFields(
        this.props.isActivation ? ['itaNumber'] : ['licenseKey', 'itaNumber']
      );
    if (resp) {
      //Start loading indicator
      this.startLoading();
      //if ita is required, update user object and parts on API
      if (this.state.itaRequired && !(await this._addITANumber(resp.itaNumber))) {
        this.stopLoading();
        return;
      }
      //Redeem license
      await this._redeemLicense(resp.licenseKey);
    }
  }
  /* Overwrites */
  _advanceAfterSessionSelection(sessionID, session, materialShippingInfo) {
    const shouldShowInfoConfirmStep =
      session?.confirmInfoOnEnrolment &&
      !materialShippingInfo &&
      (this.props.app.isUser() || this.props.app.isSysAdmin());
    const nextStep = shouldShowInfoConfirmStep ? STEPS.INFO_CONFIRMATION : STEPS.FORM;
    this.setState(
      {
        sessionID,
        session,
        materialShippingInfo,
        stepper: { ...this.state.stepper, current: nextStep, status: 'waiting', error: null },
        itaRequired: false,
        isLoading: false,
      },
      () => {
        if (this.props.isActivation) this.handleSubmit();
      }
    );
  }
  _getTitle() {
    return 'Activate License Key';
  }
  _getStepIndex() {
    const increaseStepIdx = this._shouldShowInfoConfirmationStep() ? 1 : 0;
    if (this.getCurrentStep() == STEPS.INFO_CONFIRMATION) return 1;
    else if (this.getCurrentStep() == STEPS.FORM) return 1 + increaseStepIdx;
    else if (this.getCurrentStep() == STEPS.LOADING) return 2 + increaseStepIdx;
    else if (this.getCurrentStep() == STEPS.RESULT) return 3 + increaseStepIdx;
    return super._getStepIndex();
  }
  _renderStepperSteps() {
    return (
      <>
        {super._renderStepperSteps()}
        {this._shouldShowInfoConfirmationStep() && <Steps.Step title="Confirmation" />}
        <Steps.Step title="License Key" />
        <Steps.Step title="Activating" />
        <Steps.Step title={this.state?.stepper?.error ? 'Failed' : 'Completed'} />
      </>
    );
  }
  _renderSelectedStep() {
    if (this.getCurrentStep() == STEPS.INFO_CONFIRMATION) return this._renderInfoConfirmationStep();
    else if (this.getCurrentStep() == STEPS.FORM) return this._renderLicenseFormStep();
    else if (this.getCurrentStep() == STEPS.LOADING) return this._renderLoadingStep();
    else if (this.getCurrentStep() == STEPS.RESULT) return this._renderResultStep();
    return super._renderSelectedStep();
  }
  /* Sub-steps */
  _renderInfoConfirmationStep() {
    return (
      <CommonCoursePurchaseModalSubStep_InfoConfirmation
        app={this.props.app}
        selectedSession={this.state.session}
        onNext={this._advanceAfterSessionSelection.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onPrevious={this.goToInitialStep.bind(this)}
        user={this._getCurrentUser()}
      />
    );
  }
  _renderLicenseFormStep() {
    return (
      <>
        {this._renderWarningAlert()}
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </>
    );
  }
  _renderLoadingStep() {
    return (
      <Result
        status="info"
        title="Processing."
        subTitle={this.props.isActivation ? 'Activating license...' : 'Redeeming license..'}
        extra={<Spin spinning />}
      />
    );
  }
  _renderResultStep() {
    let result = null;
    if (this.state.stepper.error) {
      result = {
        status: 'error',
        title: this.props.isActivation ? 'Error while activating license.' : 'Error while redeeming license.',
        subTitle: this._extractError(),
        extra: (
          <Button type="primary" onClick={() => this.goToInitialStep()}>
            Retry
          </Button>
        ),
      };
    } else {
      result = {
        status: 'success',
        title: `License redeemed!`,
        subTitle: this.props.isActivation
          ? `License was activate into ${this.state.session?.name} with success!`
          : `License was redeemed into ${this.state.session?.name} with success!`,
        extra: (
          <Button type="primary" onClick={() => this.handleCompleted()}>
            Done
          </Button>
        ),
      };
    }
    return <Result {...result} />;
  }
  /* Form step */
  /* subforms */
  _renderWarningAlert() {
    if (!this.state.session || !this.state.session?.onlineAccessPeriod || this.state.session?.onlineAccessPeriod <= 0)
      return <></>;
    const courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.course?.courseID);
    return (
      <Alert
        style={{ marginTop: 10 }}
        type="warning"
        showIcon
        message={`Once you complete this operation, you will have ${Utils.getIntervalString(this.state.session?.onlineAccessPeriod)} to complete ${courseSpecs?.description}.
                           Your license will expire on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(Utils.timestampAfterMinutesAtEOD(this.state.session?.onlineAccessPeriod))}.`}
      />
    );
  }
  _renderForm() {
    return (
      <Form key="redeemForm" className="redeemModalForm" {...Utils.propagateRef(this, 'currentChild.form')}>
        <Divider orientation="left">{'License Key'}</Divider>
        {!this.props.isActivation && (
          <Row type="flex" justify="center">
            <Col>
              <Form.Item name="licenseKey" rules={[{ min: 30, max: 30, message: 'Invalid license license key!' }]}>
                <LicenseInput
                  className="licenseKeyInput"
                  name="licenseKey"
                  placeholder="######-########-########-#######"
                  availablekey={this.props.course?.availableKeys?.[0]}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {this.state.itaRequired && (
          <>
            <Divider orientation="left" />
            <Row type="flex" justify="center">
              <Col span={20}>
                <Form.Item
                  name="itaNumber"
                  label="ITA Number"
                  rules={[{ required: true, min: 1, message: 'Invalid license key!' }]}
                  validateStatus="error"
                  help="The owner of this license is an organization that is on the apprenticeship program and you are required to have an ITA number to use it!"
                >
                  <Input name="itaNumber" className="itaNumberInput" placeholder="e.g. A89BCD21" allowClear />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }
  _renderButtonsFooter() {
    if (!this.state.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={() => this.handleCancel()}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          disabled={this.state.isLoading}
          key="previous"
          onClick={() => this.goToInitialStep()}
          className="confirmButton"
        >
          {' '}
          Previous{' '}
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={this.state.isLoading}
          onClick={this.handleSubmit}
          disabled={this.state.isLoading}
          className="confirmButton"
        >
          {' '}
          Activate{' '}
        </Button>
      </Row>
    );
  }

  /* API calls */
  async _redeemLicense(licenseKey) {
    //Start loading step
    this.setState({ stepper: { current: STEPS.LOADING, status: 'loading', error: null }, isLoading: true });
    //Make request
    const redeemResp = await this.props.app.api.certification.redeemLicense({
      userID: this.props.course?.userID,
      certID: this.props.course?.certProcID,
      courseID: this.props.course?.courseID,
      activationCode: licenseKey,
      sessionID: this.state.sessionID,
      autoEnrolSessionID: this.state.session?.autoEnrolOnSession || null,
      materialShippingInfo: this.state.materialShippingInfo,
    });
    if (!this._isMounted) return;
    console.debug('License redeem resp: ', redeemResp);
    if (redeemResp.statusCode == 200 && redeemResp.body) {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null },
        itaRequired: false,
        isLoading: false,
      });
      return true;
    } else if (redeemResp.statusCode == 400 && redeemResp.body.errCode == 'ITA_REQUIRED') {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.FORM, status: 'waiting', error: null },
        itaRequired: true,
        isLoading: false,
      });
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: redeemResp.body },
        itaRequired: false,
        isLoading: false,
      });
    }
    return false;
  }
  async _addITANumber(itaNumber) {
    const resp = await this._updateUser(itaNumber);
    if (resp) {
      this.props.app.alertController.showSuccessAlert('', 'ITA number saved!');
      return true;
    } else return false;
  }
  async _updateUser(itaNumber) {
    //Get user
    let user = this.props.app.sharedCache().getProgramUser();
    if (!user || this.props.course?.userID != user.id) {
      user = await this.props.app.api.user.getByID(this.props.course?.userID);
      if (user.statusCode != 200) {
        console.log('ERROR while updating user object');
        this.props.app.alertController.showAPIErrorAlert('Error', user);
        return false;
      } else user = user.body;
    }
    //Build user
    user = { ...user, itaNumber };
    //Save partition on IDM
    const resp = await this.props.app.api.user.updateITA(user.id, { itaNumber });
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      let puser = this.props.app.sharedCache().getProgramUser();
      if (puser && this.props.course?.userID == puser.id) await this.props.app.sharedCache().reloadUserObj(); //reload user on cache
      //Invalidate old partition values if same user as logged
      if (puser && this.props.course?.userID == puser.id)
        await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL); //reload user on cache
      console.log('updated user with success');
    } else {
      console.log('ERROR while updating user');
      this.props.app.alertController.showAPIErrorAlert('Error', resp);
      return false;
    }
    return true;
  }
  _extractError() {
    if (this.state.stepper?.error && this.state.stepper?.error.err) return this.state.stepper?.error.err;
    //40x codes
    else if (this.state.stepper?.error && this.state.stepper?.error.message) return this.state.stepper?.error.message;
    return JSON.stringify(this.state.stepper?.error);
  }
  _shouldShowInfoConfirmationStep() {
    const shouldShowInfoConfirmStep = this.props.app.isUser() || this.props.app.isSysAdmin();
    return this.state.session?.confirmInfoOnEnrolment && shouldShowInfoConfirmStep;
  }
}
