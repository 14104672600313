import React from 'react';
import autoBind from 'react-autobind';
import { Form, AutoComplete, Spin, Typography, Tag } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
export default class AdminManagersForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      searchField: '',
      searchRequest: null,
      searchRequestIsLoading: false,
      searchResults: [],
      selectedUsers: [],
    };
  }

  handleSearchUser(search) {
    if (this.state.searchRequest) clearTimeout(this.state.searchRequest);

    if (search) {
      this.setState({
        searchField: search,
        searchRequest: setTimeout(async () => {
          if (!search) return;
          this.setState({ searchRequestIsLoading: true });

          const resp = await this.props.app.api.user.searchUsersByTerm(search);

          let searchResults = [];

          if (resp.statusCode === 200) {
            searchResults = resp.body.users
              .map((user) => user._source)
              .filter((user) => !this.state.selectedUsers.find((findUser) => findUser.email == user.email))
              .filter((user) => !this.props.emailsToFilter.includes(user.email));
          } else {
            this.props.app.alertController.showAPIErrorAlert(null, resp);
          }

          this.setState({
            searchRequestIsLoading: false,
            searchRequest: null,
            searchResults,
          });
        }, 500),
      });
    } else {
      this.setState({
        searchField: '',
        searchRequest: null,
        searchRequestIsLoading: false,
        searchResults: [],
      });
    }
  }

  getUsers() {
    return this.state.selectedUsers;
  }

  reset() {
    this.setState({
      searchField: '',
      searchRequest: null,
      searchRequestIsLoading: false,
      searchResults: [],
      selectedUsers: [],
    });
  }

  handleSelectUser(userID) {
    const user = this.state.searchResults.find((elem) => elem.id === userID);

    this.setState((prevState) => ({
      searchField: '',
      selectedUsers: [...prevState.selectedUsers, user],
    }));
  }

  handleRemoveEmail(email) {
    this.setState((prevState) => ({
      selectedUsers: prevState.selectedUsers.filter((user) => user.email !== email),
    }));
  }

  render() {
    return (
      <Form layout="vertical">
        <Form.Item label="User" help="Select one or more users that will become managers of the organization">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AutoComplete
              style={{ width: '100%' }}
              onSelect={this.handleSelectUser}
              value={this.state.searchField}
              onChange={this.handleSearchUser}
              placeholder="Search..."
            >
              {this.state.searchResults.map(this._renderUsersOptions)}
            </AutoComplete>
            {this.state.searchRequestIsLoading && <Spin style={{ marginLeft: 10 }} />}
          </div>
        </Form.Item>

        {this.state.selectedUsers.map((user) => (
          <Tag key={user.id} closable onClose={() => this.handleRemoveEmail(user.email)}>
            {user.email} {user.fullName && `- ${user.fullName}`}
          </Tag>
        ))}
      </Form>
    );
  }

  /* private */
  _renderUsersOptions(item) {
    return (
      <AutoComplete.Option key={item.id}>
        <Typography.Text style={{ marginRight: 15 }}>
          {item.fullName} ({item.email})
        </Typography.Text>
      </AutoComplete.Option>
    );
  }
}
