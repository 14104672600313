import React from 'react';
import autoBind from 'react-autobind';
import { Form, Radio, Input, Select, Divider, Col, Row, InputNumber, Button, Modal, Checkbox } from 'antd';
import { CloudUploadOutlined, RocketOutlined, GlobalOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import config from '../../../config/config';
//props: app, onSubmit, trainingProviders
export default class CommonRequirementAcceptedProviderModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { requirement: null, optionID: null, optionType: null, isVisible: false };
  }
  //
  show(requirement, optionID) {
    const existingOption = optionID ? requirement.acceptedProviders.find((o) => o.id == optionID) : null;
    this.setState(
      {
        requirement,
        optionID,
        optionType: existingOption ? existingOption.type : Globals.Providers_Types.FILE,
        isVisible: true,
      },
      () => {
        this.form.setFieldsValue(existingOption || { type: this.state.optionType });
      }
    );
  }

  //Actions
  handleCancel() {
    this.handleAfterClose();
  }
  handleAfterClose() {
    this.setState({ requirement: null, optionID: null, optionType: null, isVisible: false }, () =>
      this.form.resetFields()
    );
  }
  async handleSubmit() {
    const resp = await this.form.validateFields();
    if (resp) {
      if (!this.props.app.isSysAdmin()) {
        resp.id =
          resp.id ||
          `${resp.trainingProviderID}-${this.props.app.sharedCache().getCustomerID()}-${config.Stage}-${Date.now()}`;
      }
      this.props.onSubmit(resp, this.state.optionID);
      this.handleAfterClose();
    }
  }
  handleTypeChange(optionType) {
    this.setState({ optionType: optionType.target.value });
  }
  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        title={`${this.state.optionID ? 'Update' : 'Add'} Accepted Provider for Requirement ${this.state.requirement?.internalName}`}
        afterClose={this.handleAfterClose}
        open={this.state.isVisible}
        closable
        footer={null}
      >
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderForm() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row type="flex" justify="start">
          <Col span={22}>
            <Form.Item label="Option Type" name="type">
              <Radio.Group onChange={this.handleTypeChange} value={this.state.optionType}>
                <Radio value={Globals.Providers_Types.FILE}>
                  <CloudUploadOutlined style={{ marginRight: 7 }} />
                  {Utils.capitalizeString(Globals.Providers_Types.FILE.toLowerCase())}
                </Radio>
                <Radio value={Globals.Providers_Types.INTERNAL}>
                  <RocketOutlined style={{ marginRight: 7 }} />
                  {Utils.capitalizeString(Globals.Providers_Types.INTERNAL.toLowerCase())}
                </Radio>
                <Radio value={Globals.Providers_Types.EXTERNAL} disabled>
                  <GlobalOutlined style={{ marginRight: 7 }} />
                  {Utils.capitalizeString(Globals.Providers_Types.EXTERNAL.toLowerCase())}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          {this.props.app.isSysAdmin() && (
            <Col span={11}>
              <Form.Item
                label="Unique ID"
                name="id"
                rules={[{ validator: Utils.validateUniqueID }, { required: false }]}
                extra="This field is available to sysadmins only and will be auto generated for normal admins or if not specified!"
              >
                <Input disabled={!!this.state.optionID} />
              </Form.Item>
            </Col>
          )}
          <Col span={!this.props.app.isSysAdmin() ? 24 : 12} offset={!this.props.app.isSysAdmin() ? 0 : 1}>
            <Form.Item
              label="Training Provider"
              name="trainingProviderID"
              rules={[{ required: true, message: `Please, select a training provider!` }]}
            >
              <Select>
                {this.props.trainingProviders &&
                  this.props.trainingProviders.map((item) => {
                    const inUse = !!this.state.requirement?.acceptedProviders?.find(
                      (o) => o.trainingProviderID == item.id && this.state.optionType == o.type
                    );
                    return (
                      <Select.Option disabled={inUse} value={item.id} key={item.id}>{`${item.name}`}</Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={14}>
            <Form.Item
              label="Optional Name"
              name="optionalName"
              extra="If not specified, Training Provider name will be used instead."
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {this.state.optionType == Globals.Providers_Types.FILE && (
          <Row type="flex" justify="start">
            <Col span={9}>
              <Form.Item
                label="Max number of files"
                name="maxFiles"
                extra="If not specified, users will be able to add as many files as desired."
              >
                <InputNumber style={{ maxWidth: 100 }} />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Has expiration?" name="hasExpirationDate" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Has issued date?" name="hasIssuedDate" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label="Display provider link?" name="displayLink" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        )}
        {this.state.optionType == Globals.Providers_Types.FILE && (
          <Row type="flex" justify="start">
            <Col span={24}>
              <Form.Item
                label="Optional Link"
                name="optionalLink"
                rules={[
                  { required: false, message: 'This field is required!' },
                  { type: 'url', message: 'This field must be a valid url.' },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        )}
        {this.state.optionType == Globals.Providers_Types.INTERNAL && (
          <Row type="flex" justify="start">
            <Col span={24}>
              <Form.Item
                label="Launch Link"
                name="launchLink"
                rules={[
                  { required: true, message: 'This field is required!' },
                  { type: 'url', message: 'This field must be a valid url.' },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button onClick={this.handleCancel}> Cancel </Button>
        <Button style={{ border: 0, marginLeft: 20 }} type="primary" onClick={this.handleSubmit}>
          {' '}
          Submit{' '}
        </Button>
      </Row>
    );
  }
}
