import React from 'react';
import { DatePicker, Button } from 'antd';
import '../assets/stylesheets/DateRangeFilter.less';
import CustomComponent from './CustomComponent';

export default class DateRangeFilter extends CustomComponent {
  state = {
    dates: this.props.value || [],
  };

  handleDateChange = (dates) => {
    this.setState({ dates });
    this.props.onChange?.(dates);
  };

  handleFilter = () => {
    this.props.onFilter?.(this.props.value ?? this.state.dates);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ dates: this.props.value || [] });
    }
  }

  render() {
    return (
      <div className="dateRangeFilterContainer">
        <span className="label">Date:</span>
        <DatePicker.RangePicker
          onChange={this.handleDateChange}
          value={this.props.value ?? this.state.dates}
          size="large"
        />
        <Button
          type="primary"
          size="large"
          style={{ marginLeft: 4 }}
          onClick={this.handleFilter}
          disabled={this.props.buttonDisabled}
          loading={this.props.buttonLoading}
        >
          {this.props.buttonLabel || 'Filter'}
        </Button>
      </div>
    );
  }
}

