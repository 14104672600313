import React from 'react';
import { Divider } from 'antd';
//
import CustomComponent from '../../../../components/CustomComponent';
//
import '../../../../assets/stylesheets/BaseCourseTab.less';
//
export default class CourseTabWrapper extends CustomComponent {
  render(children) {
    return (
      <div style={this._defaultStyle()}>
        {children}
        <Divider />
        {/* Bellow is a dirty hack on the animation context, so we fake content below divider
              to make proper height calculation. Otherwise it does calculate height until divider :/ */}
        <div style={{ fontSize: 2 }}>{' _ '}</div>
      </div>
    );
  }
  _defaultStyle() {
    return { display: this.props.collapsed ? 'block' : 'none' };
  }
}
