module.exports = {
  "IDMClientOptions": {
    "autoEnrollRole": [
      "TBD"
    ],
    "disableImpersonatingApp": true,
    "externalAuth": true,
    "externalAuthPath": "/login",
    "externalValidatePath": "/validate",
    "externalRegistrationPath": "/register",
    "externalProfilePath": "/profile",
    "apiEndpoint": "https://api-auth.bccsa-services.ca",
    "cookiesEndpoint": "TBD",
    "roles": {
      "IDM_USER": "59a72bbd-9a43-427e-9c92-afd74ab19431"
    },
    "partitions": {
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "SMConfigEndpoint": "https://api-config.bccsa-services.ca",
  "SMClassroomEndpoint": "https://api-classroom.bccsa-services.ca",
  "SMAuditingEndpoint": "https://m7x1ux9mmi.execute-api.ca-central-1.amazonaws.com/prod",
  "SMLicenseEndpoint": "https://api-license.bccsa-services.ca",
  "ApplicationAPIEndpoint": "https://api-sitereadybc.bccsa-services.ca",
  "APIStatsEndpoint": "https://api-stats.bccsa-services.ca",
  "CSPFile": "prod.js",
  "ThemeDomainsMapping": {
    "asbestos.bccsa-services.ca": {
      "themeKey": "asbestos",
      "tenantID": "ASBESTOSBC-01P",
      "adminRole": "0c0ee8b8-411_BC___Asbestos",
      "userRole": "6089f304-96e_BC___Asbestos",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "c9cf00f1-531b-4960-b63f-8cf40a5c0753",
      "idmSecret": "5efd7d7f-5382-47f9-9a7c-9dcd5d9e7f31",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "ccpo.bccsa-services.ca": {
      "themeKey": "bccsa",
      "tenantID": "CCPOBC-01P",
      "adminRole": "3a00bb68-64e_CCPO",
      "userRole": "1d3472dc-cdc_CCPO",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "363745ee-1ccd-5f1e-9507-7f8d98450c59",
      "idmSecret": "2d166343-3c17-5d70-8994-4648306ad24f",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "ncso1.bccsa-services.ca": {
      "themeKey": "ncso",
      "tenantID": "NCSOBC-01P",
      "adminRole": "9e237087-c9d_NCSO_BC",
      "userRole": "98be1110-3e0_NCSO_BC",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "b4b075ae-b9fe-4be0-9f0f-039dac41ba39",
      "idmSecret": "e185621e-1ea1-4fb4-9951-f5f3ec2b9fd6",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "ncso.bccsa-services.ca": {
      "themeKey": "ncso",
      "tenantID": "NCSOBC-02P",
      "adminRole": "71b1eea7-fc5_BC___NCSO_P2",
      "userRole": "54b1a4dd-cbd_BC___NCSO_P2",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "de97adeb-dc34-434a-a520-e0b24ba7152d",
      "idmSecret": "05ea1578-9347-4a29-a389-04480de347da",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "rsa.bccsa-services.ca": {
      "themeKey": "rsa",
      "tenantID": "RSABC-01P",
      "adminRole": "367e1e93-ed9_BC___RSA",
      "userRole": "26ed28a8-404_BC___RSA",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "57eb88e5-6c67-408f-bd49-cd2ad63a5087",
      "idmSecret": "ac673479-11d6-4faf-9b54-11517ea790b2",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "sitereadybc.bccsa-services.ca": {
      "themeKey": "bccsa",
      "tenantID": "SRBC-01P",
      "adminRole": "9dd37bd2-8ae_SiteReadyBC",
      "userRole": "31e1df5a-c28_SiteReadyBC",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "6c905594-146a-4ab1-b873-ae122e0f2db4",
      "idmSecret": "b6a58647-835a-470b-99af-7871821e8b9a",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "tcp.bccsa-services.ca": {
      "themeKey": "tcp",
      "tenantID": "TCPBC-01P",
      "adminRole": "c544ef6d-923_BC___TCP",
      "userRole": "5e9ad6e6-79e_BC___TCP",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "333005d7-468d-4cc0-8484-fada74ccfd61",
      "idmSecret": "39c09e07-2def-4381-8a92-c3583f6b2d99",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "training.bccsa-services.ca": {
      "themeKey": "training",
      "tenantID": "TRAINING-01P",
      "adminRole": "abdcee8b-3a0_BC___Training",
      "userRole": "788c71b4-a8e_BC___Training",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "5f001256-3f4e-41ce-a31e-ffa84d899973",
      "idmSecret": "1c027b73-7641-496a-bd69-4070c27efdb9",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "www.bccsa.ca/": {
      "themeKey": "bccsa",
      "tenantID": "WWWBCCSA-01P",
      "adminRole": "43ae8712-827_BC___WWWBCCSA",
      "userRole": "404a2a2b-fc9_BC___WWWBCCSA",
      "portalUserRole": "9d6d3cf4-04a_BC___Students_Portal",
      "idmKey": "xxxxxxxx",
      "idmSecret": "xxxxxxxx",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "sitereadymb.constructionsafety.ca": {
      "themeKey": "csam",
      "tenantID": "SRMB-01P",
      "adminRole": "dbc7c8bd-176_SiteReadyMB",
      "userRole": "b9478ff6-994_SiteReadyMB",
      "portalUserRole": "5c002926-c5f_MB___Students_Portal",
      "idmKey": "6aeedbba-54ad-41e9-8cd7-c0efa2c9e96a",
      "idmSecret": "6ba37e4e-860c-419c-b791-a4a2b24b23aa",
      "idmExternalAuthDomain": "portal.constructionsafety.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org-csam.bccsa-services.ca"
    },
    "ncso.ihsa.ca": {
      "themeKey": "ihsa",
      "tenantID": "NCSOON-01P",
      "adminRole": "0ddb083c-94b_ON___NCSO",
      "userRole": "db2e7ca4-9c4_ON___NCSO",
      "portalUserRole": "ed369b9b-b3d_ON___Students_Portal",
      "idmKey": "90a3ff88-9265-481e-bed1-efe5abd51bef",
      "idmSecret": "17db8c24-e343-45bf-9a6a-05ce34918e09",
      "idmExternalAuthDomain": "portal-ncso.ihsa.ca",
      "idmExternalCookiesDomain": "https://portal-ncso.ihsa.ca",
      "orgModEndpoint": "https://api-org-ihsa.bccsa-services.ca"
    }
  },
  "IncludesStageOnName": false,
  "Stage": "prod",
  "LogRocketOptions": {
    "applicationId": "ue6tsn/programfe"
  },
  "ApplicationRoutes": {
    "login": "/",
    "register": "/register",
    "homepage": "/",
    "profile": "/profile",
    "certification": "/settings/certification/:id",
    "userCertification": "/cert/:id/:id2",
    "settings": "/settings",
    "userDashboard": "/user/:id",
    "userProfile": "/user/:id/profile",
    "userSearch": "/users",
    "pendingOrders": "/orders/pending",
    "adminCertification": "/certifications",
    "usersImport": "/importUsers",
    "licenses": "/licenses",
    "adminProductOrders": "/admin-orders",
    "productOrders": "/orders/:id",
    "productOrdersDetails": "/orders-details/:id/:id2",
    "organizations": "/organizations",
    "voucher": "/voucher/:id",
    "commonLicenses": "/licenses/:id",
    "commonCourses": "/courses/:id",
    "orgLicensesReport": "/org-licenses-report",
    "revenuePerPeriodReport": "/revenue-per-period-report",
    "achievedCertificationsPerPeriodReport": "/achieved-per-period-report",
    "affiliateComissionReport": "/affiliate-comission-report",
    "expiredCertificationsReport": "/expired-certifications-report",
    "applicationReport": "/application-report",
    "applicationStatus": "/application-status",
    "sessionsListReport": "/sessions-list-report",
    "sessionResultsReport": "/session-results-report",
    "affiliateComissionDetails": "/affiliate-comission-details/:id",
    "orgManagerSearch": "/",
    "orgManagerSettings": "/settings",
    "licenseConsumption": "/license/:id/:id2",
    "registerOrg": "/org/new",
    "editOrg": "/org/edit/:id",
    "invitations": "/invitations",
    "invitationHistory": "/invitation-history",
    "newAdminCertification": "/certification/:id/:id2",
    "newUserCertification": "/certification/:id",
    "prices": "/settings/prices",
    "products": "/settings/products",
    "product": "/settings/product/:id",
    "productCategories": "/settings/productCategories",
    "vouchers": "/settings/vouchers",
    "pendingApplications": "/applications",
    "reviewApplication": "/applications/:id/:id2/:id3",
    "studentsRequests": "/requests",
    "pendingApprovals": "/requests/:id/",
    "trainingProviders": "/settings/trainingProviders",
    "locations": "/settings/locations",
    "requirements": "/settings/requirements",
    "equivalencies": "/settings/equivalencies",
    "courseProviders": "/settings/courseProviders",
    "venues": "/settings/venues",
    "instructors": "/settings/instructors",
    "instructor": "/settings/instructor/:id",
    "templates": "/settings/templates",
    "template": "/settings/template/:id",
    "sessionsHome": "/sessions",
    "sessions": "/settings/sessions",
    "sessionCreate": "/settings/session/new",
    "sessionEdit": "/settings/session/:id/edit",
    "session": "/settings/session/:id",
    "appStats": "/stats",
    "adminBillingView": "/billing/:id/:id2",
    "simpleBillingView": "/billing/:id",
    "simpleOrgBillingView": "/billing/org",
    "certifications": "/settings/certifications",
    "course": "/settings/course/:id/:id2",
    "faqManagement": "/settings/faq",
    "faq": "/faq",
    "myUpcomingCourses": "/upcomingCourses/:id",
    "examAnalysisReport": "/exam-analysis",
    "emailCertificates": "/email-certificates",
    "registerStudents": "/register-students",
    "adminLicensesView": "/licenses-view/:id",
    "mergeStudents": "/merge-students/:id"
  }
}