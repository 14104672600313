import React from 'react';
import autoBind from 'react-autobind';
import { Modal, Row, Col, Typography, Input, Button, Divider, Table, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import Utils from '@/components/Utils';
import Globals from '@/config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
//
const NEW_STATE = { isLoading: false, isVisible: false, searchTerm: '', data: null, selectedUser: null };
//props are: app, onSelection, allowEmptySelection, isOrgApp
export default class CommonSearchUsersModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = NEW_STATE;
  }
  //Public
  show() {
    this.setState({ ...NEW_STATE, isVisible: true });
  }
  //Actions
  handleClose() {
    this.setState({ isVisible: false });
  }
  //Search actions
  handleChange(e) {
    this.setState({ searchTerm: e.target.value }, this.handleSearch);
  }
  handleSearch() {
    if (this.state.searchTerm?.length > 0) this._scheduleSearch();
    else this.setState({ data: null });
  }
  //Table actions
  handleSelectUser(item) {
    this.setState({ selectedUser: item });
    if (this.props.onSelection) this.props.onSelection(item);
    this.handleClose();
  }

  //Addition actions
  handleEmptySelection() {
    this.setState({ selectedUser: null });
  }

  //UI
  render() {
    const columns = [
      {
        title: 'Name',
        key: 'fullName',
        render: (props) => (
          <Tag>
            {props.name}
            {props.fullName ? `${props.fullName}` : ''}
          </Tag>
        ),
      },
      {
        title: '',
        key: 'select',
        align: 'right',
        render: (item) => {
          if (item.deletionDate > 0)
            return (
              <Button type="primary" size="small" disabled>
                Deleted User
              </Button>
            );
          else
            return (
              <Button type="primary" size="small" onClick={this.handleSelectUser.bind(this, item)}>
                Select
              </Button>
            );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      scroll: { x: true },
      locale: { emptyText: this.state.data ? 'No users found!' : `Please, type your user's name above!` },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Modal title="User selection" open={this.state.isVisible} onCancel={this.handleClose} footer={null} width={700}>
        {/* Search bar */}
        <Row type="flex">
          <Col span={24}>
            <Input
              prefix={<SearchOutlined />}
              size="large"
              onPressEnter={this.handleSearch}
              placeholder={`Type your user's name here`}
              value={this.state.searchTerm}
              onChange={this.handleChange}
              autoFocus
            />
          </Col>
        </Row>
        {/* Table section */}
        <Divider />
        <Row type="flex">
          {' '}
          <Col span={24}>
            {' '}
            <Table columns={columns} dataSource={this.state.data || []} {...props} />{' '}
          </Col>{' '}
        </Row>
        {/* Unknown employer section */}
        <div style={{ width: 600 }} />
      </Modal>
    );
  }
  /* private - debounces */
  _scheduleSearch() {
    if (this.state.isLoading) return;
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this._search();
      this.debounce = null;
    }, 250);
  }
  /* private - API */
  async _search() {
    if (!this._isMounted) return;
    this.setState({ data: [], isLoading: true });
    //request
    const resp = await this.props.app.api.user.searchUsersByTerm(this.state.searchTerm);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp?.body?.users) {
      const users = resp.body.users?.map((user) => user._source);
      this.setState({ data: users, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: null, isLoading: false });
    }
  }
}
