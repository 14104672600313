import React from 'react';
import { Row, Col, Button } from 'antd';
import autoBind from 'react-autobind';
import ReactMarkdown from 'react-markdown';

import '../../../../assets/stylesheets/CommonApplicationStart.less';

export default class ApplicationStartSubComponent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  async handleStartApplication() {
    this.props.startLoading();

    const resp = await this.props.app.api.application.start(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.applicationType
    );

    if (resp.statusCode == 200) {
      this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.props.stopLoading();
  }

  render() {
    return (
      <Row>
        <Col span={24} className="application-start-container">
          <ReactMarkdown children={this.props.applicationDescription} linkTarget="_blank" />
        </Col>

        <Col>
          <Button type="primary" onClick={this.handleStartApplication}>
            Start Application
          </Button>
        </Col>
      </Row>
    );
  }
}
