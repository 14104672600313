import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Card } from 'antd';
//
import CustomComponent from '../../../components/CustomComponent';
//props are: app, session, student, selectedStudent
export default class CommonStudentAddConfirmation extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
  }

  //UI
  render() {
    return (
      <>
        <Row>
          <Col span={24}>
            <h2 style={{ marginLeft: 10 }}>{`Confirm Add Student to ${this.props.session.name}`}</h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="New Student" bordered={false}>
              {this.props.selectedStudent.firstName + ' ' + this.props.selectedStudent.lastName}
              <br />
              {this.props.selectedStudent.email}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
