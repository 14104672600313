import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Drawer, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as ExcelJS from 'exceljs';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminAffiliateComissionReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      payments: [],
      sortedInfo: null,
      paymentsSortedInfo: null,
      firstLoad: true,
      isDrawerVisible: false,
      selectedOrg: null,
      reportDates: [],
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async handleFilter(dates) {
    let [from, to] = dates || [];

    if (!from || !to) {
      this.setState({ payments: [] });
      return;
    }

    this.startLoading();
    this.setState({ payments: [], reportDates: dates });

    from = Utils.getZeroTimestampFromMomentDate(from);
    to = Utils.getEndOfDayTimestampFromMomentDate(to);

    const resp = await this.props.app.api.affiliate.getAffiliatePaymentsFromToTimestamp(from, to);

    if (this.state.firstLoad) this.setState({ firstLoad: false });

    if (resp.statusCode == 200 && resp.body && resp.body.payments) {
      const groupByOrg = (resp.body.payments || []).reduce((acc, curr) => {
        if (!acc[curr.orgName]) {
          acc[curr.orgName] = {
            payments: [],
            amount: 0,
            quantity: 0,
          };
        }

        acc[curr.orgName].payments.push(curr);
        acc[curr.orgName].amount += curr.totalValue;
        acc[curr.orgName].quantity += curr.quantity || 0;

        return acc;
      }, {});

      const payments = Object.keys(groupByOrg).map((orgName) => ({
        id: orgName,
        orgName: orgName,
        numberOfRegistrations: groupByOrg[orgName].payments.length,
        ...groupByOrg[orgName], // amount, quantity and payments array
      }));

      this.setState({ payments });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  _onRow(record) {
    return {
      onClick: () => {
        this.openDrawer(record);
      }, // click row
      onDoubleClick: () => {
        this.openDrawer(record);
      }, // double click row
    };
  }

  openDrawer(record) {
    this.setState({ selectedOrg: record, isDrawerVisible: true });
  }

  handleCloseDrawer() {
    this.setState({ isDrawerVisible: false });
  }

  async generateReportXLSX() {
    if (this.state.payments.length < 1) {
      return;
    }

    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    // Generate XLSX header
    ws.addRow(['Affiliate', 'Date', 'Course name', 'User name', 'Commission', 'Num. of licenses', 'Order ID']);

    // Generate XLSX rows
    this.state.payments.forEach((orgPayments) => {
      orgPayments.payments.forEach((payment) => {
        ws.addRow([
          orgPayments.orgName,
          payment.createdOn ? Utils.getDateOnUIFormatByTimestamp(payment.createdOn) : '',
          payment.courseID ? this.props.app.sharedCache().getCourseByID(payment.courseID).displayName : '',
          payment.name,
          Number(Utils.toCurrencyFormat(payment.totalValue)) || 0,
          payment.quantity,
          payment.licenseOrderID,
        ]);
      });
    });

    const buffer = await wb.xlsx.writeBuffer();

    let [from, to] = this.state.reportDates;
    from = from.format('YYYY-MM-DD');
    to = to.format('YYYY-MM-DD');
    Utils.downloadArrayBuffer(buffer, `commission-report-${from}-to-${to}`, 'xlsx');
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  handlePaymentsFilterChange(pagination, filters, sorter) {
    this.setState({ paymentsSortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Affiliate',
        key: 'orgName',
        dataIndex: 'orgName',
        width: '15%',
        sorter: (a, b) => a.orgName.localeCompare(b.orgName),
        sortOrder: sortedInfo.columnKey === 'orgName' && sortedInfo.order,
      },
      {
        title: 'Number of orders',
        key: 'numberOfRegistrations',
        dataIndex: 'numberOfRegistrations',
        width: '15%',
        sorter: (a, b) => (a.numberOfRegistrations || 0) - (b.numberOfRegistrations || 0),
        sortOrder: sortedInfo.columnKey === 'numberOfRegistrations' && sortedInfo.order,
      },
      {
        title: 'Number of licenses',
        key: 'quantity',
        dataIndex: 'quantity',
        width: '15%',
        render: (quantity) => quantity || '-',
        sorter: (a, b) => a.quantity - b.quantity,
        sortOrder: sortedInfo.columnKey === 'quantity' && sortedInfo.order,
      },
      {
        title: 'Amount due ($)',
        key: 'amount',
        dataIndex: 'amount',
        width: '15%',
        render: (amount) => `$${Utils.toCurrencyFormat(amount)}`,
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'amount' && sortedInfo.order,
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: this.state.firstLoad ? 'Search payments' : 'No payments found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Affiliate Commission per Period" />
          <Layout.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <DateRangeFilter buttonLabel="Run Report" onFilter={this.handleFilter} />
              <Button
                type="primary"
                style={{ marginLeft: 4 }}
                icon={<DownloadOutlined />}
                onClick={this.generateReportXLSX}
                disabled={this.state.payments.length < 1}
              >
                Export to xlsx
              </Button>
            </div>
            <Table
              className="adminSearchUsersTable"
              onRow={this._onRow}
              columns={columns}
              dataSource={this.state.payments}
              {...props}
            />
          </Layout.Content>

          {this._renderDrawer()}
        </Layout.Content>
      </>
    );
  }

  _renderDrawer() {
    let { selectedOrg } = this.state;
    selectedOrg = selectedOrg || {};

    return (
      <Drawer
        placement="right"
        title={selectedOrg.orgName}
        width={800}
        onClose={this.handleCloseDrawer}
        open={this.state.isDrawerVisible}
        bodyStyle={{ paddingBottom: 20 }}
        footer={this._renderDrawerFooter()}
      >
        {this._renderPaymentsTable()}
      </Drawer>
    );
  }

  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleCloseDrawer}>Close</Button>
      </div>
    );
  }

  _renderPaymentsTable() {
    let { paymentsSortedInfo, selectedOrg } = this.state;
    paymentsSortedInfo = paymentsSortedInfo || {};
    selectedOrg = selectedOrg || {};

    const { payments = [] } = selectedOrg;

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '15%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : ''),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: paymentsSortedInfo.columnKey === 'createdOn' && paymentsSortedInfo.order,
      },
      {
        title: 'Course name',
        key: 'courseID',
        dataIndex: 'courseID',
        width: '25%',
        sorter: (a, b) => (a.courseID && b.courseID ? a.courseID.localeCompare(b.courseID) : 0),
        sortOrder: paymentsSortedInfo.columnKey === 'courseID' && paymentsSortedInfo.order,
        render: (courseID) => (courseID ? this.props.app.sharedCache().getCourseByID(courseID).displayName : ''),
      },
      {
        title: 'Order ID',
        key: 'licenseOrderID',
        dataIndex: 'licenseOrderID',
        width: '20%',
        sorter: (a, b) => (a.licenseOrderID && b.licenseOrderID ? a.licenseOrderID.localeCompare(b.licenseOrderID) : 0),
        sortOrder: paymentsSortedInfo.columnKey === 'licenseOrderID' && paymentsSortedInfo.order,
      },
      {
        title: 'User name',
        key: 'name',
        dataIndex: 'name',
        width: '25%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: paymentsSortedInfo.columnKey === 'name' && paymentsSortedInfo.order,
      },
      {
        title: 'Commission',
        key: 'totalValue',
        dataIndex: 'totalValue',
        width: '15%',
        render: (totalValue) => `$${Utils.toCurrencyFormat(totalValue)}`,
        sorter: (a, b) => a.totalValue.localeCompare(b.totalValue),
        sortOrder: paymentsSortedInfo.columnKey === 'totalValue' && paymentsSortedInfo.order,
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handlePaymentsFilterChange,
      locale: { emptyText: 'No payments found' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return <Table className="adminSearchUsersTable" columns={columns} dataSource={payments} {...props} />;
  }
}
