import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Table, Drawer, Row, Tooltip, Col, Popconfirm, Button, message, Divider } from 'antd';
import { CloudUploadOutlined, RocketOutlined, GlobalOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import scrollIntoView from 'scroll-into-view';
//
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//
import CommonEquivalencyForm from '../Forms/CommonEquivalencyForm';
import CommonEquivalencyAcceptedProviderModal from '../Modals/CommonEquivalencyAcceptedProviderModal';
import Globals from '../../../config/Globals';
//props are: app, onUpdate
export default class CommonEquivalencyDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      isVisible: false,
      equivalencyID: null,
      equivalencyData: null,
      trainingProviders: null,
      requirements: [],
      sortedInfo: null,
    };
  }
  //Public
  show(equivalencyID, trainingProviders, requirements) {
    this._scrollToTop();
    this.setState(
      { isVisible: true, equivalencyID, equivalencyData: null, trainingProviders, requirements },
      this._fetchEquivalency
    );
  }
  //Private actions
  handleClose() {
    if (this.props.onUpdate) this.props.onUpdate();
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      equivalencyID: null,
      equivalencyData: null,
      trainingProviders: null,
      requirements: null,
      sortedInfo: null,
    });
  }
  //Equivalency
  async handleSubmitEquivalency() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.equivalencyID) this._updateEquivalency(formData);
    else this._createEquivalency(formData);
  }
  //Accepted Providers Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Accepted Providers
  handleAddProvider() {
    this.providerModal.show(this.state.equivalencyData, null);
  }
  handleEditProvider(optionID) {
    this.providerModal.show(this.state.equivalencyData, optionID);
  }
  handleDeleteProvider(optionID) {
    const equivalency = this.state.equivalencyData;
    equivalency.acceptedProviders.splice(
      equivalency.acceptedProviders.indexOf(equivalency.acceptedProviders.find((o) => o.id == optionID)),
      1
    );
    this._saveEquivalencyLocallyAndWarn(equivalency); //save
  }
  handleProviderOptionModal(data, optionID) {
    const equivalency = this.state.equivalencyData;
    if (optionID)
      equivalency.acceptedProviders = equivalency.acceptedProviders.map((o) =>
        o.id == optionID ? { ...o, ...data } : o
      );
    else
      equivalency.acceptedProviders = equivalency.acceptedProviders
        ? equivalency.acceptedProviders.concat([data])
        : [data];
    //Save
    this._saveEquivalencyLocallyAndWarn(equivalency);
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.equivalencyID ? 'Edit' : 'Create'} Equivalency`}
        placement="right"
        onClose={this.handleClose}
        width={1000}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonEquivalencyAcceptedProviderModal
          app={this.props.app}
          trainingProviders={this.state.trainingProviders}
          onSubmit={this.handleProviderOptionModal}
          {...Utils.propagateRef(this, 'providerModal')}
        />
        <Row type="flex" justify="end" className="scrollHere">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleSubmitEquivalency}>
              {this.state.equivalencyID ? 'Save' : 'Create'} Equivalency
            </Button>{' '}
          </Col>
        </Row>
        <CommonEquivalencyForm
          app={this.props.app}
          {...Utils.propagateRef(this, 'form')}
          isEdit={!!this.state.equivalencyID}
          trainingProviders={this.state.trainingProviders}
          requirements={this.state.requirements || []}
        />
        {this.state.equivalencyID && (
          <>
            <Divider>Providers</Divider>
            <Row type="flex" justify="end">
              {' '}
              <Col>
                {' '}
                <Button type="primary" onClick={this.handleAddProvider}>
                  Add Provider
                </Button>{' '}
              </Col>{' '}
            </Row>
            {this._renderProvidersTable()}
          </>
        )}
      </Drawer>
    );
  }

  /* private UI */
  _renderProvidersTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (e) => {
          if (e == Globals.Providers_Types.FILE)
            return (
              <>
                <CloudUploadOutlined style={{ marginRight: 7 }} />
                File
              </>
            );
          else if (e == Globals.Providers_Types.EXTERNAL)
            return (
              <>
                <GlobalOutlined style={{ marginRight: 7 }} />
                External
              </>
            );
          else if (e == Globals.Providers_Types.INTERNAL)
            return (
              <>
                <RocketOutlined style={{ marginRight: 7 }} />
                Internal
              </>
            );
          else return '';
        },
      },
      {
        title: 'Training Provider',
        key: 'trainingProviderID',
        dataIndex: 'trainingProviderID',
        sorter: (a, b) =>
          ((this.state.trainingProviders || []).find((p) => p.id == a.trainingProviderID)?.name || '').localeCompare(
            (this.state.trainingProviders || []).find((p) => p.id == b.trainingProviderID)?.name || ''
          ),
        render: (props) => (this.state.trainingProviders || []).find((p) => p.id == props)?.name,
        sortOrder: sortedInfo.columnKey === 'trainingProviderID' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditProvider.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete the Provider '${(this.state.trainingProviders || []).find((p) => p.id == props.trainingProviderID)?.name}' from the accepted providers of this equivalency?`}
                  onConfirm={this.handleDeleteProvider.bind(this, props.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No training providers accepetd on this equivalency!' },
      onRow: this._onRow,
      pagination: { pageSize: 5, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return (
      <Layout.Content style={{ marginTop: 15 }}>
        <Table
          className="adminAcceptedProvidersTable"
          columns={columns}
          {...props}
          dataSource={this.state.equivalencyData?.acceptedProviders || []}
        />
      </Layout.Content>
    );
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.handleEditCity(record.id);
      }, // click row
      onDoubleClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.handleEditCity(record.id);
      }, // double click row
    };
  }

  //private helpers
  _saveEquivalencyLocallyAndWarn(equivalencyData) {
    message.warning('Please, save this equivalency in order to save this change to the accepted providers.');
    this.setState({ equivalencyData });
  }
  _scrollToTop() {
    const element = document.querySelector('.scrollHere');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }

  // API Calls
  //Equivalency
  async _fetchEquivalency() {
    if (!this.state.equivalencyID) return; //avoid loading empty equivalencies
    this.setState({ isLoading: true });
    const resp = await this.props.app.config.equivalency.getEquivalency(this.state.equivalencyID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ equivalencyData: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _updateEquivalency(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.config.equivalency.updateEquivalency({
      id: this.state.equivalencyData.id,
      customerID: this.state.equivalencyData.customerID,
      acceptedProviders: this.state.equivalencyData.acceptedProviders || [],
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Equivalency successfully updated!');
      this._fetchEquivalency();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _createEquivalency(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const equivalency = {
      ...data,
      customerID: this.props.app.sharedCache().getCustomerID(),
      acceptedProviders: [],
      ...(this.props.app.isSysAdmin() && data.id
        ? {
            id: data.id,
          }
        : {
            id: `Equivalency-${this.props.app.sharedCache().getCustomerID()}-${this.props.app.sharedCache().getTenantConfig().id}-${config.Stage}-${data.internalName.toLowerCase().replace(/[ ?&/]/g, '')}-${Date.now()}`,
          }),
    };
    const resp = await this.props.app.config.equivalency.createEquivalency(equivalency);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Equivalency successfully created!');
      this.show(equivalency.id, this.state.trainingProviders, this.state.requirements);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
