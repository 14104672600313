import React from 'react';
import autoBind from 'react-autobind';
import { Alert, Drawer, Row, Form, Select, Col, Button, message, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
//
import CommonStudentSearchTable from '../StudentSelection/CommonStudentSearchTable';
export default class CommonAssignLicenseDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isVisible: false,
      products: props.app.sharedCache().products,
      errorMessage: null,
      productID: null,
      availableKeys: [],
      selectedUser: null,
      selectedProduct: null,
      orgID: null,
      numAssignments: 1,
    };
  }
  show(student) {
    this.setState({ isVisible: true, student });
  }

  //Life cycle
  async componentDidMount() {
    await this.setOrgID();
    await this._getProducts();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.isVisible && !this.props.isVisible) {
      this.setState({
        selectedUser: null,
        selectedProduct: null,
        numAssignments: 1,
      });
    }
  }

  async setOrgID() {
    let orgID = null;
    if (this.props.app.isAdmin()) {
      orgID = this.props.orgID;
    } else {
      orgID = this.props.app.urlManager.selectedOrg;
    }
    this.setState({ orgID });
  }

  //action
  async handleSubmit() {
    await this._assignLicense();
    this.props.refreshPage();
  }

  handleSelect(selectedUser) {
    this.setState({ selectedUser });
  }

  //UI
  render() {
    const { errorMessage, products, selectedUser, availableKeys, numAssignments } = this.state;
    const { users, shouldShowUserSelect, shouldShowProductSelect, product } = this.props;
    const uniqueProducts = products.filter(
      (product, index, self) => index === self.findIndex((p) => p.id === product.id)
    );
    const availability =
      product?.record?.licNumber -
      product?.record?.licActivated -
      product?.record?.assignCount -
      product?.record?.reservedCount;

    const disableAssign =
      (shouldShowUserSelect && !this.state.selectedUser) ||
      availableKeys.length === 0 ||
      (shouldShowProductSelect && !this.state.productID) ||
      availability === 0;

    return (
      <Drawer
        open={this.props.isVisible}
        title="Assign License to Student"
        placement="right"
        onClose={this.props.onClose}
        width={800}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button type="secondary" onClick={this.props.onClose} style={{ marginRight: '8px' }}>
              Cancel
            </Button>
            <Button type="primary" onClick={this.handleSubmit} disabled={disableAssign}>
              Assign
            </Button>
          </div>
        }
      >
        <Form layout="vertical">
          {errorMessage && <Alert title="Attention" description={errorMessage} type="error" showIcon />}
          <br />
          {!shouldShowUserSelect && (
            <>
              <Row>
                <Col span={16}>
                  <p>
                    <strong>Name:</strong> {users?.record.fullName}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <p>
                    <strong>Email:</strong> {users?.record.email}
                  </p>
                </Col>
              </Row>
            </>
          )}
          {this.state.selectedUser && (
            <>
              <Button
                style={{ justifyContent: 'flex-start', marginBottom: '16px' }}
                type="primary"
                onClick={() => this.setState({ selectedUser: null })}
              >
                <ArrowLeftOutlined /> Back
              </Button>
              <Row>
                <Col span={16}>
                  {selectedUser && (
                    <p>
                      <strong>Name:</strong> {selectedUser.fullName}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  {selectedUser && (
                    <p>
                      <strong>Email:</strong> {selectedUser.email}
                    </p>
                  )}
                </Col>
              </Row>
            </>
          )}
          {!shouldShowProductSelect && (
            <Row>
              <Col span={16}>
                <p>
                  <strong>Product:</strong> {product?.record.productName}
                </p>
              </Col>
            </Row>
          )}
          {shouldShowProductSelect && (
            <Row>
              <Col span={16}>
                <Form.Item name="productID" label="Product">
                  <Select
                    onChange={(value) => this.setState({ productID: value })}
                    disabled={availableKeys.length === 0}
                  >
                    {uniqueProducts.map((product) => (
                      <Select.Option key={product.id} value={product.id}>
                        {product.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {availability > 0 && (
            <Row>
              <Col span={16}>
                <Form.Item label="Number of Licenses to Assign">
                  <InputNumber
                    min={1}
                    max={Math.min(availability, 5)}
                    value={numAssignments}
                    onChange={(value) => this.setState({ numAssignments: value })}
                  />
                  <div style={{ marginTop: '4px', fontSize: '12px', color: '#888' }}>
                    {`Available licenses: ${availability}`}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          {availability === 0 && (
            <Alert
              message="No available licenses for this product."
              type="warning"
              showIcon
              style={{ marginTop: '16px' }}
            />
          )}
        </Form>
        {shouldShowUserSelect && !this.state.selectedUser && availableKeys.length > 0 && (
          <CommonStudentSearchTable
            {...Utils.propagateRef(this, 'StudentSearchTable')}
            app={this.props.app}
            onSelect={this.handleSelect}
            student={this.state.student}
          />
        )}
      </Drawer>
    );
  }

  //API call
  async _getProducts() {
    await this.setOrgID();
    const { products, orgID } = this.state;

    const resp = await this.props.app.license.key.getAvailableActivationKeys(orgID);
    if (resp.statusCode === 200 && resp.body.keys) {
      const availableKeys = resp.body.keys;
      const productIDs = availableKeys.map((license) => license.productID);

      const filteredProducts = products.filter((product) => productIDs.includes(product.id));
      this.setState({ availableKeys });

      if (filteredProducts.length > 0) {
        const sortedProducts = filteredProducts.sort((a, b) => a.name.localeCompare(b.name));

        this.setState({ products: sortedProducts, errorMessage: null });
      } else {
        this.setState({ errorMessage: 'Your organization does not have any licenses that can be assigned.' });
      }
    }
  }

  async _assignLicense() {
    await this.setOrgID();
    const { orgID, availableKeys, selectedUser, numAssignments } = this.state;

    const org = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);

    let user = null;
    if (this.props.shouldShowUserSelect && selectedUser) {
      user = selectedUser;
    } else {
      user = this.props.users?.record;
    }

    let productID = null;
    if (this.props.shouldShowProductSelect) {
      productID = this.state.productID;
    } else {
      productID = this.props.product?.record.productID;
    }

    const activationKey = availableKeys.find((key) => key.productID === productID)?.id;

    const body = {
      activationKey,
      productID,
      user: {
        name: user?.fullName,
        email: user?.email,
      },
      numAssignments: numAssignments,
      employerName: org.name,
    };
    const resp = await this.props.app.license.license.assignLicense(orgID, user?.id, body);
    if (resp.statusCode === 200) {
      message.success(resp.body?.msg || 'License assigned successfully');
      this.props.onClose();
    } else {
      message.error(resp.body?.msg || 'Failed to assign license');
      this.props.onClose();
    }
  }
}
