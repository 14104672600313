import React from 'react';
import autoBind from 'react-autobind';

import {
  Alert,
  Drawer,
  Button,
  Col,
  Input,
  Layout,
  PageHeader,
  Result,
  Row,
  Select,
  Table,
  message,
  Tooltip,
  Popconfirm,
  Spin,
} from 'antd';
import {
  EditOutlined,
  ReloadOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  SwapOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { IoPeople, IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';

import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
import DataUtils from '@/components/UtilsCertification';

import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import WhiteBox from '../commonComponents/WhiteBox';
import CommonStudentSearchTable from '../commonComponents/StudentSelection/CommonStudentSearchTable';

export default class AdminMergeStudentsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.STEPS = {
      CERTIFICATION: 1,
      SUMMARY: 2,
      RESULT: 3,
    };

    this.state = {
      sourceUser: null,
      sourceUserCertsResp: [],
      certifications: [],
      validationResults: [],
      isLoading: false,
      isLoadingValidation: false,
      isLoadingMove: false,
      firstLoad: true,
      currenStep: this.STEPS.CERTIFICATION,
      selectedCertificationID: null,
      selectedCertification: null,
      isVisible: false,
      selectedStudent: null,
      moveSuccess: false,
      canProceedWithMove: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.userID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    if (this.state.firstLoad && this.userID) {
      this.fetchData();
    }
  }

  resetState() {
    this.setState({
      isLoading: false,
      isLoadingValidation: false,
      isLoadingMove: false,
      firstLoad: true,
      currenStep: this.STEPS.CERTIFICATION,
      selectedCertificationID: null,
      selectedCertification: null,
      isVisible: false,
      moveSuccess: false,
      selectedStudent: null,
      validationResults: [],
    });
  }

  //API
  async fetchData() {
    this.startLoading();
    const userID = this.userID;
    const sourceUser = await this.props.app.api.user.getByID(userID);
    const sourceUserCertsResp = await this.props.app.api.certification.getByUserID(userID);
    this.loadData(sourceUser, sourceUserCertsResp);
    this.stopLoading();
    this.setState({ sourceUser });
  }

  async loadData(sourceUser, sourceUserCertsResp) {
    if (
      sourceUser.statusCode == 200 &&
      sourceUser.body &&
      sourceUserCertsResp.statusCode == 200 &&
      sourceUserCertsResp.body &&
      sourceUserCertsResp.body.procs
    ) {
      const tenantConfig = this.props.app.sharedCache().getTenantConfig();
      this.processCertifications(sourceUserCertsResp.body.procs, tenantConfig.certifications);
      this.setState({ isLoading: false, firstLoad: false });
    }
  }
  async validateMoveCertificationToUser(sourceUserID, targetUserID, certificationID) {
    this.setState({ isLoadingValidation: true });
    try {
      const resp = await this.props.app.api.certification.validateMoveCertificationToUser(
        sourceUserID,
        certificationID,
        targetUserID
      );

      if (resp.statusCode === 200) {
        const validationResults = resp.body.courses.map((course) => ({
          name: course.name,
          status: course.status,
          validated: course.validated,
        }));

        const canMove = resp.body.courses.every((course) => course.validated === true);
        this.setState({
          validationResults,
          canProceedWithMove: canMove,
        });
      } else {
        message.error('Error while validating move');
        this.setState({
          validationResults: [],
          canProceedWithMove: false,
        });
      }
    } catch (error) {
      message.error('Error while validating moving certification');
    } finally {
      this.setState({ isLoadingValidation: false });
    }
  }

  async moveCertificationToUser(sourceUserID, targetUserID, certificationID) {
    try {
      this.setState({ isLoadingMove: true });
      const resp = await this.props.app.api.certification.moveCertificationToUser(
        sourceUserID,
        certificationID,
        targetUserID
      );
      if (resp.statusCode === 200) {
        this.setState({
          currenStep: this.STEPS.RESULT,
          moveSuccess: true,
          isLoadingMove: false,
        });
      } else {
        this.setState({
          currenStep: this.STEPS.RESULT,
          moveSuccess: false,
          isLoadingMove: false,
        });
      }
    } catch (error) {
      this.setState({
        currenStep: this.STEPS.RESULT,
        moveSuccess: false,
        isLoadingMove: false,
      });
      console.error('Error moving certification:', error);
    }
  }

  //Actions
  handleGoToCertificationsStep() {
    this.setState({ currenStep: this.STEPS.CERTIFICATION });
  }
  handleGoToSummaryStep = async () => {
    if (this.state.selectedCertificationID && this.state.selectedStudent) {
      this.setState({ isLoadingValidation: true });
      await this.validateMoveCertificationToUser(
        this.userID,
        this.state.selectedStudent.id,
        this.state.selectedCertificationID
      );
      this.setState({ currenStep: this.STEPS.SUMMARY });
    }
  };
  handleGoToResultStep() {
    this.setState({ currenStep: this.STEPS.RESULT });
    this.moveCertificationToUser(this.userID, this.state.selectedStudent.id, this.state.selectedCertificationID);
  }
  handleClose() {
    this.setState({ isVisible: false, selectedStudent: null });
  }
  handleSelect(selectedStudent) {
    this.setState({ selectedStudent, isVisible: false });
  }
  handleChangeStudentSelection() {
    this.setState({ isVisible: true });
  }
  handleSelectCertification(certID) {
    const selectedCertification = this.state.certifications.find((cert) => cert.id === certID);
    this.setState({
      selectedCertificationID: certID,
      selectedCertification,
    });
  }

  async processCertifications(userCerts, tenantCerts) {
    const validCertifications = userCerts
      .filter((userCert) => tenantCerts.some((tenantCert) => tenantCert.id === userCert.certificationID))
      .map((userCert) => {
        const correspondingCert = tenantCerts.find((tenantCert) => tenantCert.id === userCert.certificationID);
        return {
          ...userCert,
          displayName: correspondingCert.description,
        };
      });

    this.setState({ certifications: validCertifications });
  }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <PageHeader className="pageHeader" title="Merge Students" />
        <WhiteBox>
          {this._getCertificationStep()}
          {this._getSummaryStep()}
          {this._getResultStep()}
        </WhiteBox>
      </Layout.Content>
    );
  }

  //Private UI
  _getCertificationStep() {
    if (this.state.currenStep !== this.STEPS.CERTIFICATION) {
      return null;
    }
    if (this.state.isLoadingValidation) {
      return (
        <div style={{ textAlign: 'center', marginTop: 48, height: 100 }}>
          <Spin size="large" />
          <span style={{ fontSize: 20, marginLeft: 4 }}> Validating move...</span>
        </div>
      );
    }
    return (
      <Result
        icon={<UserSwitchOutlined size={80} style={{ color: '#000' }} />}
        title={`Moving from student ${this.state.sourceUser?.body.firstName} ${this.state.sourceUser?.body.lastName} - (${this.state.sourceUser?.body.email})`}
        subTitle="Select the certification and student you want to move it to"
        extra={
          <>
            {this.state.certifications.length == 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 32 }}>
                <Alert
                  showIcon
                  type="error"
                  description="There are no certification available for this user."
                  style={{ width: 450 }}
                />
              </div>
            )}
            <Row justify="center" align="middle" style={{ marginBottom: 16 }}>
              <Select
                style={{ width: 450 }}
                placeholder="Select the certification..."
                size="large"
                value={this.state.selectedCertificationID}
                onChange={this.handleSelectCertification}
                options={this.state.certifications.map((cert) => ({
                  value: cert.id,
                  label: cert.displayName,
                }))}
              />
            </Row>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Row
                className="employerInputContainer"
                align="middle"
                justify="center"
                style={{ height: 40, fontSize: 16, width: 450 }}
              >
                {this.state.selectedStudent?.fullName || 'Select Student'}
                <Tooltip title="Select Student">
                  <Button
                    className="swapButton"
                    size="small"
                    icon={<SwapOutlined />}
                    shape="circle"
                    type="dashed"
                    onClick={this.handleChangeStudentSelection}
                  />
                </Tooltip>
              </Row>
            </div>

            <Row justify="center" align="middle" style={{ marginTop: 32 }}>
              <Button
                type="primary"
                icon={<ArrowRightOutlined />}
                size="large"
                onClick={this.handleGoToSummaryStep}
                disabled={!this.state.selectedCertificationID || !this.state.selectedStudent}
              >
                Next
              </Button>
            </Row>

            <Drawer
              placement="right"
              title={`Select Student`}
              width={800}
              onClose={this.handleClose}
              open={this.state.isVisible}
              bodyStyle={{ paddingBottom: 20 }}
            >
              <CommonLoadingView isLoading={this.state.isLoading} />
              <CommonStudentSearchTable
                {...Utils.propagateRef(this, 'StudentSearchTable')}
                app={this.props.app}
                onSelect={this.handleSelect}
                loadOnSearchOnly
              />
            </Drawer>
          </>
        }
      />
    );
  }
  _getSummaryStep() {
    if (this.state.currenStep !== this.STEPS.SUMMARY) {
      return null;
    }
    const columns = [
      {
        title: 'Status',
        dataIndex: 'validated',
        render: (text, record) => {
          if (record.validated === true) {
            return <IoCheckmarkCircle size={40} style={{ color: '#92c716' }} />;
          } else {
            return <IoCloseCircle size={40} style={{ color: '#c7161f' }} />;
          }
        },
      },
      {
        title: 'Course',
        dataIndex: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'status',
      },
    ];

    return (
      <Result
        icon={<IoPeople size={80} style={{ color: '#000' }} />}
        subTitle={
          <div style={{ textAlign: 'center' }}>
            <p>
              <strong>Moving from student:</strong> {this.state.sourceUser?.body.firstName || '-'}{' '}
              {this.state.sourceUser?.body.lastName || '-'} - ({this.state.sourceUser?.body.email || '-'})
            </p>
            <p>
              <strong>To Student:</strong> {this.state.selectedStudent?.fullName || '-'}
              {` - (${this.state.selectedStudent?.email || '-'})`}
            </p>
            <p>
              <strong>Certification Process:</strong> {this.state.selectedCertification?.displayName || '-'}
            </p>
          </div>
        }
        extra={
          <>
            <Table
              rowKey="id"
              dataSource={this.state.validationResults}
              loading={this.state.isLoadingValidation}
              columns={columns}
              pagination={{
                hideOnSinglePage: true,
              }}
            />

            <Row justify="center" align="middle" style={{ marginTop: 32 }}>
              <Button
                type="primary"
                size="large"
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: 8 }}
                onClick={this.handleGoToCertificationsStep}
                loading={this.state.isLoading}
                disabled={!this.state.selectedCertificationID || !this.state.selectedStudent}
              >
                Back
              </Button>

              <Popconfirm
                placement="bottom"
                okText="Ok"
                cancelText="Cancel"
                onConfirm={this.handleGoToResultStep}
                title={`This operation will remove the certification process and courses from ${this.state.sourceUser?.body.firstName || '-'} ${this.state.sourceUser?.body.lastName || '-'} and move them to ${this.state.selectedStudent.fullName}. Are you sure you want to continue ?`}
              >
                <Button
                  type="primary"
                  icon={<ArrowRightOutlined />}
                  size="large"
                  loading={this.state.isLoadingMove}
                  disabled={!this.state.canProceedWithMove}
                  style={{ marginLeft: 4 }}
                >
                  Next
                </Button>
              </Popconfirm>
            </Row>
          </>
        }
      />
    );
  }
  _getResultStep() {
    if (this.state.currenStep !== this.STEPS.RESULT) {
      return null;
    }
    const { moveSuccess } = this.state;

    if (this.state.isLoadingMove) {
      return (
        <div style={{ textAlign: 'center', marginTop: 48, height: 100 }}>
          <Spin size="large" />
          <span style={{ fontSize: 20, marginLeft: 4 }}> Moving certification...</span>
        </div>
      );
    }

    return (
      <Result
        status={moveSuccess ? 'success' : 'error'}
        title={moveSuccess ? 'Success!' : 'Error!'}
        subTitle={
          moveSuccess
            ? `${this.state.selectedCertification?.displayName} moved to ${this.state.selectedStudent?.fullName}`
            : `Error reported by API!`
        }
        extra={
          <>
            <Row justify="center" align="middle" style={{ marginTop: 32 }}>
              <Button type="primary" size="large" onClick={this.resetState} loading={this.state.isLoadingMove}>
                Start Over
              </Button>
            </Row>
          </>
        }
      />
    );
  }
}
