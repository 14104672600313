import moment from 'moment';
import Globals from '../config/Globals';
//
export default class UtilsCalendar {}

/* sessoes */
UtilsCalendar.getEventsBasedOnSessions = function (sessions, calendars, app, onSelect, isMonthlyView) {
  let sessionsArray = [];
  sessionsArray = sessions.reduce((acc, curr) => {
    return acc.concat(
      (curr.startDate || [])
        .sort((a, b) => a - b)
        .map((time, i) => {
          const calendar = (calendars || []).find((c) => c.courseID == curr.courseID);
          return UtilsCalendar.newSessionEvent(
            curr,
            calendar,
            time,
            curr.endDate.sort((a, b) => a - b)[i],
            onSelect,
            app,
            isMonthlyView
          );
        })
    );
  }, []);
  let eventsArray = [];
  if (Array.isArray(calendars)) {
    eventsArray = calendars.map((c) => {
      return UtilsCalendar.newSessionEvent({ id: 'avEvent' }, c, c.startTime, c.endTime, onSelect, app, isMonthlyView);
    });
  }
  return sessionsArray.concat(eventsArray);
};
UtilsCalendar.newSessionEvent = function (session, calendar, startDate, endDate, onSelect, app, isMonthlyView) {
  const id = `${session.id}${startDate}${endDate}`;
  const bgCalendarColor = session.calendarColor || calendar?.calendarColor || app.themeManager.primaryColor;
  const sessionNotAvailable =
    session.state == Globals.Session_State.LOCKED ||
    (session.allowEnrolmentUntil && Date.now() > session.allowEnrolmentUntil);
  const sessionCompleted = session.state == Globals.Session_State.COMPLETED;
  const sessionInPast = Date.now() > moment(endDate).toDate();
  const instructorDescription = calendar?.description;

  return {
    resourceId: id,
    id,
    backgroundColor: sessionCompleted ? '#ECD8D8' : sessionNotAvailable && !sessionInPast ? '#101820' : bgCalendarColor,
    //auxs
    onSelect,
    app,
    isMonthlyView: !!isMonthlyView,
    //props
    start: moment(startDate).toDate(),
    end: moment(endDate).toDate(),
    dragEnabled: false,
    description: instructorDescription,
    //extra info
    metadata: { ...session, event: { startDate, endDate } },
  };
};
