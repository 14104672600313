import React from 'react';
import autoBind from 'react-autobind';
import { Result, Button, message } from 'antd';
import { ClockCircleFilled } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//props are: app, isVisible, certificationProcess, certificationSpecs, onUpdate
export default class CommonCertificationViewCooldownTabView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Actions
  handleCooldownForceEnd() {
    const lastCourse = this.props.certificationProcess.courses[this.props.certificationProcess.courses?.length - 1];
    if (lastCourse) this._forceCourseCooldownEnd(lastCourse);
  }

  //UI
  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return <></>;
    //
    const subtitle = `${Utils.camelizeString(this.props.certificationSpecs.uiTerm)} is in cooldown period and you must wait until ${Utils.getDateOnUIFormatCoolDown(this._getCooldownEndDate())} to continue.`;
    const resultProps = !this.props.app.isAdmin()
      ? {}
      : {
        extra: [
          <Button type="primary" key="1" icon={<ClockCircleFilled />} onClick={this.handleCooldownForceEnd}>
            End Cooldown
          </Button>,
        ],
      };
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} />
        <Result status="warning" title="Cooldown" subTitle={subtitle} {...resultProps} />
      </>
    );
  }

  //Private
  _getCooldownEndDate() {
    const courses = this.props.certificationProcess.courses;
    const lastCourse = courses.reduce((latest, course) => {
      return new Date(course.createdOn) > new Date(latest.createdOn) ? course : latest;
    }, courses[0]);

    const time = new Date(lastCourse?.courseResult?.resultDate);
    time.setDate(time.getDate() + (this._getCooldownPeriod(lastCourse) || 0));
    return time;
  }
  _getCooldownPeriod(course) {
    const courseSpecs = this.props.app.sharedCache().getCourseByID(course.courseID);
    const { certificationProcess } = this.props;
    //Count retries
    let retries = 0;
    for (let course of certificationProcess.courses) {
      if (course.courseID == courseSpecs.id && !course.invalidated && course.state == Globals.Course_State.FAIL)
        retries++;
    }
    return courseSpecs.coolDownPeriod && courseSpecs.coolDownPeriod != '-1'
      ? Array.isArray(courseSpecs.coolDownPeriod)
        ? courseSpecs.coolDownPeriod[retries - 1] || courseSpecs.coolDownPeriod[courseSpecs.coolDownPeriod.length - 1]
        : courseSpecs.coolDownPeriod
      : null;
  }
  //API
  async _forceCourseCooldownEnd(currentCourse) {
    this.startLoading();
    const resp = await this.props.app.api.course.forceEndCooldown(
      currentCourse.userID,
      currentCourse.certProcID,
      currentCourse.id,
      { comments: '' }
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Cooldown ended with success!');
      await this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading();
  }
}

CommonCertificationViewCooldownTabView.GetTabTitleView = function (certSpecs, certProc) {
  if (!certSpecs || !certProc) return '';
  if (certProc.state == Globals.CertificationProcess_State.COOLDOWN)
    return `${Utils.camelizeString(certSpecs.uiTerm)} in Cooldown`;
  return '';
};
