import React from 'react';
import { Row, Col } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import Globals from '../../../../../config/Globals';
//
import CourseCardActions from '../Subcomponents/CourseCardActions';
//
export default class PaymentRequired extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess, session } = this.props;
    const isWaived = !!certificationProcess.waivedBy;
    const courseType = this.props.courseSpecs.courseType;
    return (
      <>
        <Row justify="center">
          <Col span={12} style={{ textAlign: 'center' }}>
            {isWaived &&
              courseType !== Globals.Course_Types_Keys.ASSIGNMENT &&
              `To take the ${this.props.courseSpecs.description} you need to activate this ${this.props.courseSpecs.uiTerm}. Please click continue the activation.`}
            {!isWaived &&
              courseType !== Globals.Course_Types_Keys.ASSIGNMENT &&
              `To take the ${this.props.courseSpecs.description} please click the buttons below to make a purchase, enter a license key or upload a file.`}
            {courseType === Globals.Course_Types_Keys.ASSIGNMENT &&
              `In order to start your ${this.props.courseSpecs.description}, please click the Start Assignment button below.`}
          </Col>
        </Row>
        <CourseCardActions
          app={app}
          disableActions={this.props.disableActions}
          courseSpecs={courseSpecs}
          certificationProcess={certificationProcess}
          availableKeys={this.props.availableKeys}
          session={session}
          actionPurchase
          actionRedeem
          actionActivate
        />
      </>
    );
  }
}
PaymentRequired.CardStatusHeader = function ({ courseSpecs }) {
  return (
    <span className="warning">
      <InfoCircleFilled />{' '}
      {courseSpecs.courseType == Globals.Course_Types_Keys.ASSIGNMENT ? 'Assignment not started' : 'Purchase required'}
    </span>
  );
};
