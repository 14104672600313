import React from 'react';
import autoBind from 'react-autobind';
import {
  Select,
  Form,
  Divider,
  Table,
  Row,
  Tooltip,
  Popconfirm,
  Button,
  Col,
  Input,
  InputNumber,
  Popover,
  Switch,
} from 'antd';
import { QuestionCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonCertificationCoursesReqsDrawer from '../Drawers/CommonCertificationCoursesReqsDrawer';
//props: app, isEdit, courses
export default class CommonCertificationForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      bannerText: MarkdownEditor.createEmptyValue(),
      showBanner: false,
    };
  }
  //Public
  resetFields(type) {
    this.setState({
      bannerText: MarkdownEditor.createEmptyValue(),
      showBanner: false,
    });
    this.form.resetFields();
  }
  setFieldsValue(data) {
    const bannerEnabled = data.ui?.banner?.content?.length > 0;
    this.setState(
      {
        showBanner: bannerEnabled,
        requirementsIDs: data.requirementsIDs || [],
        bannerText: bannerEnabled
          ? MarkdownEditor.createValueFromString(data.ui.banner.content, 'markdown')
          : MarkdownEditor.createEmptyValue(),
      },
      () =>
        this.form.setFieldsValue({
          ...data,
          'ui.cardBackground': data.ui?.cardBackground,
          'ui.showWaiveFeature': !!data.ui?.showWaiveFeature,
          'ui.courseTabLabel': data.ui?.courseTabLabel,
          'ui.certificationOrder': data.ui?.certificationOrder,
          'ui.showBanner': data.ui?.banner?.content?.length > 0,
          'ui.bannerType': data.ui?.banner?.type,
          'ui.hideFromUI': !!data.ui?.hideFromUI,
        })
    );
  }
  async validateFields() {
    if (!this.form) return false;
    let formData = null;
    try {
      formData = await this.form.validateFields();
    } catch (e) {}
    if (!formData) return false;
    //
    const ui = {
      cardBackground: formData['ui.cardBackground'],
      showWaiveFeature: !!formData['ui.showWaiveFeature'],
      courseTabLabel: formData['ui.courseTabLabel'],
      certificationOrder: formData['ui.certificationOrder'],
      hideFromUI: !!formData['ui.hideFromUI'],
      ...(this.state.showBanner
        ? { banner: { content: this.state.bannerText.toString('markdown'), type: formData['ui.bannerType'] } }
        : {}),
    };
    //remove uneeded keys
    for (const k of Object.keys(formData)) {
      if (k.includes('ui.')) delete formData[k];
    }
    //
    return {
      ...formData,
      ui,
      requirementsIDs: this.state.requirementsIDs || [],
      commissionPercent: formData.commissionPercent || -1,
      disabledEmails: formData.disabledEmails || [],
    };
  }

  //Actions
  handleChangeShowBanner() {
    this.setState({ showBanner: !this.state.showBanner });
  }
  handleEditRequirements(reqs) {
    this.requirementsDrawer.show(reqs, this.state.requirementsIDs.indexOf(reqs));
  }
  handleAddRequirements() {
    this.requirementsDrawer.show();
  }
  handleDeleteRequirements(reqs) {
    const requirementsIDs = this.state.requirementsIDs;
    requirementsIDs.splice(requirementsIDs.indexOf(reqs), 1);
    this.setState({ requirementsIDs });
  }
  handleRequirementsUpdated(reqs, index) {
    let requirementsIDs = this.state.requirementsIDs;
    if (!requirementsIDs) requirementsIDs = [];
    if (index != -1) requirementsIDs[index] = reqs;
    else requirementsIDs.push(reqs);
    this.setState({ requirementsIDs });
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row gutter={32}>
          {this.props.app.isSysAdmin() && (
            <Col span={11}>
              <Form.Item
                label="Unique ID"
                name="id"
                rules={[{ validator: Utils.validateUniqueID }, { required: false }]}
                extra="This field is available to sysadmins only and will be auto generated for normal admins or if not specified!"
              >
                <Input disabled={this.props.isEdit} />
              </Form.Item>
            </Col>
          )}
          <Col offset={1} span={12}>
            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {this._renderUISubform()}
        {this._renderCommissionSubform()}
        {this._renderCertificate()}
        {this._renderCourses()}
        {this._renderMisc()}
      </Form>
    );
  }
  /* Subforms */
  _renderUISubform() {
    return (
      <>
        <Divider orientation="left">User Interface</Divider>
        <Row gutter={32}>
          <Col span={11}>
            <Form.Item
              name="shortDescription"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Short Description
                  <Popover
                    title="Short Description"
                    content="This field will be displayed to users on their interface."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={12}>
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Description
                  <Popover
                    title="Description"
                    content="This field will be displayed to users on their interface or emails."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item
              name="uiTerm"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  UI Term
                  <Popover title="UI Term" content="How this entity will be called for users.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_UiTerm).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              name="ui.cardBackground"
              label={
                <>
                  UI Card Background Image
                  <Popover
                    title="Card Background Image"
                    content="Which image should be used for this certification card."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch allowClear style={{ width: '100%' }}>
                {Object.keys(Globals.Cert_UiLogo).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              name="ui.courseTabLabel"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Course Tab Label
                  <Popover
                    title="Course Tab Label"
                    content="The label of courses tab of this certification view. Example.: Courses"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item
              name="ui.certificationOrder"
              label={
                <>
                  Certification Order
                  <Popover
                    title="Certification Order"
                    content="In case of multiple certifications, you can specify the order this certification will be displayed."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col offset={1} span={3}>
            <Form.Item
              name="ui.showWaiveFeature"
              valuePropName="checked"
              label={
                <>
                  Show waive feature
                  <Popover title="Show waive feature" content="Indicates if should show waive feature for admins.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col offset={1} span={3}>
            <Form.Item
              name="ui.hideFromUI"
              valuePropName="checked"
              label={
                <>
                  Hide Certification
                  <Popover
                    title="Hide Certification"
                    content="Indicates if the certification card should be displayed or not."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={3}>
            <Form.Item
              name="ui.showBanner"
              valuePropName="checked"
              label={
                <>
                  Show banner
                  <Popover title="Show banner" content="Indicates if should show banner of certification page.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch onChange={this.handleChangeShowBanner} />
            </Form.Item>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item
              name="ui.bannerType"
              rules={[{ required: this.state.showBanner, message: 'This field is required!' }]}
              label={
                <>
                  Banner Type
                  <Popover title="Banner Type" content="Type of the banner">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_BannerType).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Banner Text" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.bannerText}
                onChange={(bannerText) => this.setState({ bannerText })}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderCommissionSubform() {
    return (
      <>
        <Divider orientation="left">Comission Settings</Divider>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="commissionPaymentMode"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Comission Payment Mode
                  <Popover
                    title="Commission Payment Mode"
                    content="The behaviour of the commission payment, only works if commissionValue or commissionPercetage are set."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                <Select.Option value={1} key={1}>
                  Course Redeem - This mode will generate the commission payment at course redeem if payment is full and
                  done by the user
                </Select.Option>
                <Select.Option value={2} key={2}>
                  Certification Completion - This mode will generate the commission payment at certification completion
                  if payment is full and done by the user
                </Select.Option>
                <Select.Option value={3} key={3}>
                  License Purchase - This mode will generate the commission payment at license purchase if payment is
                  full and done by the user
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="commissionAssignMode"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Comission Assign Mode
                  <Popover
                    title="Commission Assign Mode"
                    content="The behaviour of how commission is assigned to certification, only works if commissionValue or commissionPercetage are set."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                <Select.Option value={1} key={1}>
                  Once - This mode will append a new commission to certification only if no commission was ever added
                  (only one course commission per cert.) - if payment is full and done by the user - (only works with
                  payment mode 1)
                </Select.Option>
                <Select.Option value={2} key={2}>
                  Multiple - This mode will append a new commission to certification every redeem if payment is full and
                  done by the user
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={4}>
            <Form.Item
              name="commissionValue"
              label={
                <>
                  Commission Value
                  <Popover
                    title="Commission Value"
                    content="The fixed amount that should be paid to affilliates at cert completion. Takes precedence over percent. -1 means no commission."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} precision={2} />
            </Form.Item>
          </Col>
          <Col offset={1} span={4}>
            <Form.Item
              name="commissionPercent"
              label={
                <>
                  Commission Percent
                  <Popover
                    title="Commission Percent"
                    content="The percentage amount of the courses payment of a certificate. -1 means no commission."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} max={100} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderCertificate() {
    return (
      <>
        <Divider orientation="left">Certification Settings</Divider>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="certificateType"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Certificate Type
                  <Popover
                    title="Certificate Type"
                    content="What type of certification will be printed after completion"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_CertTypes).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item
              name="certificateTemplate"
              label={
                <>
                  Certificate Template
                  <Popover title="Certificate Template" content="Certification PDF template ">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch allowClear style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_CertTemplates).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item
              name="certNumberMode"
              label={
                <>
                  Certification Number Mode
                  <Popover
                    title="Certification Number Mode"
                    content="Which mode the system will operate to assign a certificate and member number."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch allowClear style={{ width: '100%' }}>
                <Select.Option value={'1'} key={1}>
                  1 - Reuse from the tenant (cert share a track with other courses and certifications).
                </Select.Option>
                <Select.Option value={'2'} key={2}>
                  2 - Reuse from tenant (cert share a track with other certs)
                </Select.Option>
                <Select.Option value={'3'} key={3}>
                  3 - Reuse from certSpecID (cert share a track in a certification with other certs)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="certNumberInitialNumber"
              label={
                <>
                  Certification Number Initial Number
                  <Popover
                    title="Certification Number Initial Number"
                    content="The initial certification number for this certification."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item
              name="certNumberMaskFormat"
              label={
                <>
                  Certification Number Mask Format
                  <Popover
                    title="Certification Number Mask Format"
                    content="The certification number masking.'@y' is replaced by the year and 'X' by numbers."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item
              name="enterMaintenanceDays"
              label={
                <>
                  Days to Enter Maintenance
                  <Popover
                    title="Days to Enter Maintenance"
                    content="Threshold in days before the expiration date when a certification will enter maintenance mode."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderCourses() {
    const columns = [
      { title: 'Order', key: 'order', render: (e) => this.state?.requirementsIDs.indexOf(e) + 1 },
      {
        title: 'Possible Requirements',
        key: 'requirementsIDs',
        render: (e) => e.join(', '),
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditRequirements.bind(this, props)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete these requirements?`}
                  onConfirm={this.handleDeleteRequirements.bind(this, props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: (p) => JSON.stringify(p),
      locale: { emptyText: 'No requirements found!' },
      pagination: false,
    };
    return (
      <>
        <Divider orientation="left">Certification Requirements Flow</Divider>
        <CommonCertificationCoursesReqsDrawer
          {...Utils.propagateRef(this, 'requirementsDrawer')}
          onUpdate={this.handleRequirementsUpdated}
          courses={this.props.courses || []}
        />
        <Row gutter={32}>
          <Col span={20}>
            <Table
              className="adminCertificationRequirements"
              columns={columns}
              dataSource={[...(this.state.requirementsIDs || [])]}
              {...props}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={this.handleAddRequirements}>
              Add Requirement
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  _renderMisc() {
    const { courses } = this.props;
    const coursesWithDefault = [{ id: '', name: 'Default' }, ...(courses || [])];

    return (
      <>
        <Divider orientation="left">Miscellaneous</Divider>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              name="setCompletionUsingRequirement"
              label={
                <>
                  Certification completion date uses
                  <Popover
                    title="Certification completion date uses"
                    content={
                      <div style={{ maxWidth: 600 }}>
                        By default, a certification completion date is set using the completion date of the last
                        requirement met. You can change that behavior by selecting a different requirement in the
                        certification. If a course is selected, the completion date of that course will be used as the
                        completion date for the certification, regardless of when the last requirement was met.
                      </div>
                    }
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single">
                {coursesWithDefault.map((opt) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="notifyAdmins"
              valuePropName="checked"
              label={
                <>
                  Notify Admins
                  <Popover
                    title="Notify Admins"
                    content="Indicates if should email some user notifications to admins (e.g. expiration notifications)."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="disabledEmails"
              label="Disabled Email event(s)"
              extra="List of email event that are disabled at tenant-wide level."
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                showSearch
                filterOption={(input, option) => {
                  const provider = (Globals.Tenant_DisablableEmails || []).find((c) => option.value == c);
                  return provider.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {Globals.Tenant_DisablableEmails.map((opt) => (
                  <Select.Option key={opt} value={opt}>
                    {opt}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}
