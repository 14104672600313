import ApplicationsChart from './ApplicationsChart';
import CertificationsChart from './CertificationsChart';
import ExamsChart from './ExamsChart';
import PassFailRateChart from './PassFailRateChart';
import RewritesChart from './RewritesChart';

export default class Charts {}

Charts.Applications = ApplicationsChart;
Charts.Certifications = CertificationsChart;
Charts.Exams = ExamsChart;
Charts.PassFailRate = PassFailRateChart;
Charts.Rewrites = RewritesChart;
