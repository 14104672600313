import React from 'react';
import autoBind from 'react-autobind';
import { Form, Row, Col, Input } from 'antd';
import Utils from '../../../components/Utils';
//props: app, isEdit
export default class CommonTrainingProviderForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  resetFields() {
    this.form.resetFields();
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({ ...data });
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return formData;
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        {this.props.app.isSysAdmin() && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Unique ID"
                name="id"
                rules={[{ validator: Utils.validateUniqueID }, { required: false }]}
                extra="This field is available to sysadmins only and will be auto generated for normal admins or if not specified!"
              >
                <Input disabled={this.props.isEdit} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Link"
              name="url"
              rules={[
                { required: false, message: 'This field is required!' },
                { type: 'url', message: 'This field must be a valid url.' },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
