import React from 'react';
import autoBind from 'react-autobind';
import { Select, Form, Divider, Table, Row, Tooltip, Popconfirm, Button, Col, Input, InputNumber, Popover } from 'antd';
import { QuestionCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
//
import CommonCertificationExpirationRuleDrawer from '../Drawers/CommonCertificationExpirationRuleDrawer';
import CommonCertificationExpirationWarningsDrawer from '../Drawers/CommonCertificationExpirationWarningsDrawer';
import CommonCertificationIncompleteCertWarningsDrawer from '../Drawers/CommonCertificationIncompleteCertWarningsDrawer';

//props: courses
export default class CommonCertificationRenewalForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { warnings: [], rules: [], incompleteCertWarnings: [] };
  }
  //Public
  resetFields() {
    this.setState({ warnings: [], rules: [], incompleteCertWarnings: [] });
    this.form.resetFields();
  }
  setFieldsValue(data) {
    this.setState(
      {
        warnings: data?.warningRemainingDays || [],
        rules: data?.renewal?.rules || [],
        incompleteCertWarnings: data?.warningIncompleteCertificationDays || [],
      },
      () => {
        this.form.setFieldsValue({ ...(data || {}), ...(data?.renewal || {}) });
      }
    );
  }
  async validateFields() {
    if (!this.form) return false;
    let formData = null;
    try {
      formData = await this.form.validateFields();
    } catch (e) {}
    if (!formData) return false;
    //
    const { taxRate, productID, expireOnRenewal, ...ds } = formData;
    //
    return {
      warningRemainingDays: this.state.warnings,
      warningIncompleteCertificationDays: this.state.incompleteCertWarnings,
      ...ds,
      ...(taxRate || productID || expireOnRenewal || this.state.rules?.length > 0
        ? {
            renewal: {
              taxRate,
              productID,
              expireOnRenewal,
              rules: this.state.rules || [],
            },
          }
        : { renewal: null }),
    };
  }

  //Actions
  handleChangeEnabled() {
    this.setState({ isEnabled: !this.state.isEnabled });
  }
  handleEditRule(rule) {
    this.ruleDrawer.show(rule, this.state.rules.indexOf(rule));
  }
  handleDeleteRule(rule) {
    const rules = this.state.rules;
    rules.splice(rules.indexOf(rule), 1);
    this.setState({ rules });
  }
  handleRuleUpdated(rule, index) {
    let rules = this.state.rules;
    if (!rules) rules = [];
    if (index != -1) rules[index] = rule;
    else rules.push(rule);
    this.setState({ rules });
  }
  handleAddRule() {
    this.ruleDrawer.show();
  }
  handleAddWarning() {
    this.warningDrawer.show();
  }
  handleEditWarning(warning) {
    console.log(this.state.warnings.indexOf(warning));
    this.warningDrawer.show(warning, this.state.warnings.indexOf(warning));
  }
  handleDeleteWarning(warning) {
    const warnings = this.state.warnings;
    warnings.splice(warnings.indexOf(warning), 1);
    this.setState({ warnings });
  }
  handleWarningUpdated(warning, index) {
    console.log(index);
    let warnings = this.state.warnings;
    if (!warnings) warnings = [];
    if (index != -1) warnings[index] = warning;
    else warnings.push(warning);
    this.setState({ warnings });
  }
  handleAddIncompleteCertWarning() {
    this.incompleteCertWarningDrawer.show();
  }
  handleEditIncompleteCertWarning(incompleteCertWarning) {
    this.incompleteCertWarningDrawer.show(
      incompleteCertWarning,
      this.state.incompleteCertWarnings.indexOf(incompleteCertWarning)
    );
  }
  handleDeleteIncompleteCertWarning(warning) {
    const incompleteCertWarnings = this.state.incompleteCertWarnings;
    const index = incompleteCertWarnings.indexOf(warning);
    if (index !== -1) {
      incompleteCertWarnings.splice(index, 1);
      this.setState({ incompleteCertWarnings });
    }
  }
  handleUpdatedIncompleteCertWarning(incompleteCertWarning, index) {
    let incompleteCertWarnings = this.state.incompleteCertWarnings;
    if (!incompleteCertWarnings) incompleteCertWarnings = [];
    if (index !== -1) incompleteCertWarnings[index] = incompleteCertWarning;
    else incompleteCertWarnings.push(incompleteCertWarning);
    this.setState({ incompleteCertWarnings });
  }

  //UI
  render() {
    return (
      <>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          {this._renderGeneralSubform()}
          {this._renderRules()}
          {this._renderWarnings()}
          {this._renderIncompleteCertificationWarnings()}
        </Form>
      </>
    );
  }
  /* Subforms */
  _renderGeneralSubform() {
    return (
      <>
        <Row gutter={32}>
          <Col span={3}>
            <Form.Item
              name="taxRate"
              label={
                <>
                  Renewal Tax rate
                  <Popover title="Tax rate" content="Tax rate of the application purchase.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={0} precision={2} max={1} />
            </Form.Item>
          </Col>
          <Col offset={1} span={8}>
            <Form.Item
              name="productID"
              label={
                <>
                  Renewal Product ID
                  <Popover title="Renewal Product ID" content="Renewal product ID on license module.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={5}>
            <Form.Item
              name="allowRenewalBefore"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Allow Renewal Before (years)
                  <Popover
                    title="Allow Renewal Before (years)"
                    content="Time in years when renewal process is allowed for the certification, if allowRenewalBeforeInMonths exists, it will take precedence. -1 means disabled."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col offset={1} span={5}>
            <Form.Item
              name="allowRenewalBeforeInMonths"
              label={
                <>
                  Allow Renewal Before (months)
                  <Popover
                    title="Allow Renewal Before (months)"
                    content="Time in months when renewal process is allowed for the certification."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              name="validityPeriod"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Validity Period (years)
                  <Popover
                    title="Validity Period (years)"
                    content="Number of years certification is valid once achieved, if validityPeriodInMonths exists it takes precedence over this value. -1 means no expiration."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              name="validityPeriodInMonths"
              label={
                <>
                  Validity Period (months)
                  <Popover
                    title="Validity Period (months)"
                    content="Number of months certification is valid once achieved."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item
              name="validityRequirementID"
              label="Validity Requirement ID"
              extra="Indicates if/what requirement expiration to use on completion. Takes precedence over this other validity values."
            >
              <Select allowClear mode="single" style={{ minWidth: 300 }}>
                {' '}
                {(this.props.courses || []).map((opt) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.name}
                  </Select.Option>
                ))}{' '}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="expireOnRenewal" label="Request new uploads for">
              <Select allowClear mode="multiple" style={{ minWidth: 300 }}>
                {(this.props.requirements || [])
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((opt) => (
                    <Select.Option key={opt.id} value={opt.id}>
                      {opt.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderRules() {
    const columns = [
      { title: 'Until Days After Expiration', key: 'untilDaysAfterExpiration', dataIndex: 'untilDaysAfterExpiration' },
      {
        title: 'Force Retake Requirements IDs',
        key: 'forceRetakerequirementsIDs',
        dataIndex: 'forceRetakerequirementsIDs',
      },
      { title: 'Price Override', key: 'priceOverride', dataIndex: 'priceOverride' },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditRule.bind(this, props)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete this rule?`}
                  onConfirm={this.handleDeleteRule.bind(this, props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = { rowKey: (p) => JSON.stringify(p), locale: { emptyText: 'No rules found!' }, pagination: false };
    return (
      <>
        <CommonCertificationExpirationRuleDrawer
          {...Utils.propagateRef(this, 'ruleDrawer')}
          onUpdate={this.handleRuleUpdated}
          courses={this.props.courses}
        />
        <Divider orientation="left">Renewal rules</Divider>
        <Row gutter={32}>
          <Col span={21}>
            <Table
              className="adminGenericTable"
              columns={columns}
              dataSource={[...(this.state.rules || [])]}
              {...props}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={this.handleAddRule}>
              Add Rule
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  _renderWarnings() {
    const columns = [
      { title: 'Template', key: 'template', dataIndex: 'template' },
      { title: 'Period (days)', key: 'period', dataIndex: 'period' },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditWarning.bind(this, props)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete this warning?`}
                  onConfirm={this.handleDeleteWarning.bind(this, props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = { rowKey: (p) => JSON.stringify(p), locale: { emptyText: 'No warnings found!' }, pagination: false };
    return (
      <>
        <CommonCertificationExpirationWarningsDrawer
          {...Utils.propagateRef(this, 'warningDrawer')}
          onUpdate={this.handleWarningUpdated}
        />
        <Divider orientation="left">Expiration Warnings</Divider>
        <Row gutter={32}>
          <Col span={21}>
            <Table
              className="adminGenericTable"
              columns={columns}
              dataSource={[...(this.state.warnings || [])]}
              {...props}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={this.handleAddWarning}>
              Add Warning
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  _renderIncompleteCertificationWarnings() {
    const columns = [
      { title: 'Template', key: 'template', dataIndex: 'template' },
      { title: 'Period (days)', key: 'period', dataIndex: 'period' },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditIncompleteCertWarning.bind(this, props)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete this warning?`}
                  onConfirm={() => this.handleDeleteIncompleteCertWarning(props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = { rowKey: (p) => JSON.stringify(p), locale: { emptyText: 'No warnings found!' }, pagination: false };
    return (
      <>
        <CommonCertificationIncompleteCertWarningsDrawer
          {...Utils.propagateRef(this, 'incompleteCertWarningDrawer')}
          onUpdate={this.handleUpdatedIncompleteCertWarning}
        />
        <Divider orientation="left">Incomplete Certification Warnings</Divider>
        <Row gutter={32}>
          <Col span={21}>
            <Table
              className="adminGenericTable"
              columns={columns}
              dataSource={[...(this.state.incompleteCertWarnings || [])]}
              {...props}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={this.handleAddIncompleteCertWarning}>
              Add Warning
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
