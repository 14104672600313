import React from 'react';
import autoBind from 'react-autobind';
import {
  Select,
  Form,
  Divider,
  Table,
  Row,
  Tooltip,
  Popconfirm,
  Button,
  Col,
  Input,
  InputNumber,
  Popover,
  Switch,
} from 'antd';
import { QuestionCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import CommonCertificationApplicationSectionForm from './CommonCertificationApplicationSectionForm';
//props: equivalences, certEquivalences, requirements, certRequirements, isRenewal
export default class CommonCertificationApplicationForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { applicationDescription: MarkdownEditor.createEmptyValue(), isEnabled: false };
  }
  //Public
  resetFields() {
    this.setState({ applicationDescription: MarkdownEditor.createEmptyValue(), isEnabled: false });
    this.form.resetFields();
    if (this.equivalenciesSectionForm) this.equivalenciesSectionForm.resetFields();
    if (this.requirementsSectionForm) this.requirementsSectionForm.resetFields();
    if (this.customRequirementsSectionsForm1) this.customRequirementsSectionsForm1.resetFields();
    if (this.customRequirementsSectionsForm2) this.customRequirementsSectionsForm2.resetFields();
    if (this.electiveRequirementsSectionForm) this.electiveRequirementsSectionForm.resetFields();
  }
  setFieldsValue(data) {
    const ds = this.props.isRenewal ? data?.renewal?.application : data?.application;
    const isEnabled = ds?.applicationEnabled;
    this.setState(
      {
        isEnabled,
        applicationDescription: isEnabled
          ? MarkdownEditor.createValueFromString(ds?.applicationDescription, 'markdown')
          : MarkdownEditor.createEmptyValue(),
      },
      () => {
        this.form.setFieldsValue({ ...(ds || {}) });
        if (this.equivalenciesSectionForm) this.equivalenciesSectionForm.setFieldsValue(data, this.props.isRenewal);
        if (this.requirementsSectionForm) this.requirementsSectionForm.setFieldsValue(data, this.props.isRenewal);
        if (this.customRequirementsSectionsForm1)
          this.customRequirementsSectionsForm1.setFieldsValue(data, this.props.isRenewal);
        if (this.customRequirementsSectionsForm2)
          this.customRequirementsSectionsForm2.setFieldsValue(data, this.props.isRenewal);
        if (this.electiveRequirementsSectionForm)
          this.electiveRequirementsSectionForm.setFieldsValue(data, this.props.isRenewal);
      }
    );
  }
  async validateFields() {
    if (!this.form) return false;
    //validate main form
    let formData = null;
    try {
      formData = await this.form.validateFields();
    } catch (e) {}
    if (!formData) return false;
    //validate subforms
    const subforms = await Promise.all([
      this.equivalenciesSectionForm ? this.equivalenciesSectionForm.validateFields() : Promise.resolve({}),
      this.requirementsSectionForm ? this.requirementsSectionForm.validateFields() : Promise.resolve({}),
      this.electiveRequirementsSectionForm
        ? this.electiveRequirementsSectionForm.validateFields()
        : Promise.resolve({}),
      this.customRequirementsSectionsForm1
        ? this.customRequirementsSectionsForm1.validateFields()
        : Promise.resolve({}),
      this.customRequirementsSectionsForm2
        ? this.customRequirementsSectionsForm2.validateFields()
        : Promise.resolve({}),
    ]);
    if (subforms.find((f) => !f)) return false;
    console.log(formData, subforms);
    //not enabled? return empty application
    if (!this.state.isEnabled) return { application: null };
    //
    return {
      ['application']: {
        ...formData,
        ...subforms[0],
        ...subforms[1],
        ...subforms[2],
        customRequirementsSections: []
          .concat(subforms[3].sectionEnabled ? [subforms[3]] : [])
          .concat(subforms[4].sectionEnabled ? [subforms[4]] : []),
        applicationDescription: this.state.applicationDescription.toString('markdown'),
      },
    };
  }

  //Actions
  handleChangeEnabled() {
    this.setState({ isEnabled: !this.state.isEnabled });
  }

  //UI
  render() {
    return (
      <>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row gutter={32}>
            <Col span={11}>
              <Form.Item
                name="applicationEnabled"
                valuePropName="checked"
                label={
                  <>
                    {this.props.isRenewal && 'Renewal'} Application Enabled?
                    <Popover title="Application Enabled?" content="Indicates if the application is enabled.">
                      <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                    </Popover>
                  </>
                }
              >
                <Switch onChange={this.handleChangeEnabled} />
              </Form.Item>
            </Col>
          </Row>
          {this.state.isEnabled && this._renderGeneralSubform()}
        </Form>
        {/* Sections */}
        {this.state.isEnabled && (
          <CommonCertificationApplicationSectionForm
            label="Equivalences"
            keyValue="equivalenciesSection"
            options={this.props.equivalences}
            relations={this.props.certEquivalences.filter((r) => r.type == (this.props.isRenewal ? 'RENEW' : 'NEW'))}
            {...Utils.propagateRef(this, 'equivalenciesSectionForm')}
          />
        )}
        {this.state.isEnabled && (
          <CommonCertificationApplicationSectionForm
            label="Requirements"
            keyValue="requirementsSection"
            options={this.props.requirements}
            relations={this.props.certRequirements.filter(
              (r) => r.section == 'GENERAL' && r.type == (this.props.isRenewal ? 'RENEW' : 'NEW')
            )}
            {...Utils.propagateRef(this, 'requirementsSectionForm')}
          />
        )}
        {this.state.isEnabled && (
          <CommonCertificationApplicationSectionForm
            label="Custom Requirements 1"
            keyValue="customRequirementsSections"
            arrValue={0}
            options={this.props.requirements}
            relations={this.props.certRequirements.filter(
              (r) => r.section == 'CUSTOM_0' && r.type == (this.props.isRenewal ? 'RENEW' : 'NEW')
            )}
            {...Utils.propagateRef(this, 'customRequirementsSectionsForm1')}
          />
        )}
        {this.state.isEnabled && (
          <CommonCertificationApplicationSectionForm
            label="Custom Requirements 2"
            keyValue="customRequirementsSections"
            arrValue={1}
            options={this.props.requirements}
            relations={this.props.certRequirements.filter(
              (r) => r.section == 'CUSTOM_1' && r.type == (this.props.isRenewal ? 'RENEW' : 'NEW')
            )}
            {...Utils.propagateRef(this, 'customRequirementsSectionsForm2')}
          />
        )}
        {this.state.isEnabled && (
          <CommonCertificationApplicationSectionForm
            label="Elective Requirements"
            keyValue="electiveRequirementsSection"
            options={this.props.requirements}
            relations={this.props.certRequirements.filter(
              (r) => r.section == 'ELECTIVE' && r.type == (this.props.isRenewal ? 'RENEW' : 'NEW')
            )}
            isElective
            {...Utils.propagateRef(this, 'electiveRequirementsSectionForm')}
          />
        )}
      </>
    );
  }
  /* Subforms */
  _renderGeneralSubform() {
    return (
      <>
        <Divider orientation="left">General</Divider>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Description" name="applicationDescription" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.applicationDescription}
                onChange={(applicationDescription) => this.setState({ applicationDescription })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          {!this.props.isRenewal && (
            <Col span={3}>
              <Form.Item
                name="taxRate"
                label={
                  <>
                    Tax rate
                    <Popover title="Tax rate" content="Tax rate of the application purchase.">
                      <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                    </Popover>
                  </>
                }
              >
                <InputNumber style={{ width: '100%' }} min={0} precision={2} max={1} />
              </Form.Item>
            </Col>
          )}
          {!this.props.isRenewal && (
            <Col offset={1} span={8}>
              <Form.Item
                name="productID"
                rules={[{ required: true, message: 'This field is required!' }]}
                label={
                  <>
                    Application Product ID
                    <Popover title="Product ID" content="Application product ID on license module.">
                      <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                    </Popover>
                  </>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col offset={this.props.isRenewal ? 0 : 1} span={5}>
            <Form.Item
              name="allowPreApplication"
              valuePropName="checked"
              label={
                <>
                  Allow Pre-Application
                  <Popover
                    title="Allow Pre-Application"
                    content="Indicates if application allows pre-applications before final submission."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={10}>
            <Form.Item
              name="termsLink"
              label={
                <>
                  Terms and Conditions Link
                  <Popover
                    title="Terms and Conditions Link"
                    content="The terms and conditions link user will be accepting before submitting the final application."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="approvalWebhooks"
              label={
                <>
                  Approval Webhook URLs
                  <Popover
                    title="Approval Webhook URLs"
                    content="List containing webhooks to be invoked when the application is approved."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="tags" style={{ minWidth: 200 }}>
                {(this.props.isRenewal
                  ? this.props.certification?.renewal?.approvalWebhook || []
                  : this.props.certification?.application?.approvalWebhook || []
                ).map((approvalWebhook) => (
                  <Select.Option key={approvalWebhook?.id} value={approvalWebhook?.id}>
                    {approvalWebhook}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}
