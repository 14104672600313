import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Row, Col, Button } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CommonTemplateForm from '../../commonComponents/Forms/CommonTemplateForm';
//
const RequestMask = { TEMPLATE: 2, VENUES: 4, CITIES: 8, ALL: 2 | 4 | 8 };
//
export default class AdminTemplateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const templateID = this.props.match.params.id;
    this.state = {
      isLoading: false,
      templateID: templateID == Globals.URL_NewIndentifier ? null : templateID,
      template: null,
      venues: null,
      courses: null,
      selectedTab: 'main',
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    //Load data
    await this.fetchData(this.state.templateID ? RequestMask.ALL : RequestMask.VENUES | RequestMask.CITIES);
    //Tab
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        if (this.state.templateID && this.state.template) this.templateForm.setFieldsValue(this.state.template);
      });
    } else this.stopLoading();
  }

  //Actions
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
  async handleTemplateSubmit() {
    const formData = await this.templateForm.validateFields();
    if (!formData) return;
    this.startLoading();
    if (this.state.templateID) await this._updateTemplate(formData);
    else await this._createTemplate(formData);
    this.stopLoading();
  }

  //UI
  render() {
    const items = [
      {
        label: 'Settings',
        key: 'main',
        children: (
          <>
            <Row type="flex" justify="end">
              {' '}
              <Col>
                {' '}
                <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleTemplateSubmit}>
                  {' '}
                  {this.state.templateID ? 'Update' : 'Create'} Template{' '}
                </Button>{' '}
              </Col>{' '}
            </Row>
            <CommonTemplateForm
              {...Utils.propagateRef(this, 'templateForm')}
              app={this.props.app}
              isEdit={!!this.state.templateID}
              courses={this.state.courses}
              venues={this.state.venues}
            />
          </>
        ),
      },
    ];
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title={'Template'} onBack={() => this.props.app.urlManager.pushBack()} />
          <WhiteBox>
            <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange} items={items} />
          </WhiteBox>
        </Layout.Content>
      </>
    );
  }

  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.TEMPLATE) === RequestMask.TEMPLATE
        ? this.props.app.classroom.template.getTemplate(this.state.templateID)
        : null,
      (mask & RequestMask.VENUES) === RequestMask.VENUES ? this.props.app.sharedCache().getVenues() : null,
      (mask & RequestMask.CITIES) === RequestMask.CITIES ? this.props.app.sharedCache().getCities() : null,
    ]);
    if (
      (mask & RequestMask.TEMPLATE) === RequestMask.TEMPLATE &&
      !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.id)
    ) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if ((mask & RequestMask.VENUES) === RequestMask.VENUES && !resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load venues!');
      return false;
    }
    if ((mask & RequestMask.CITIES) === RequestMask.CITIES && !resp[2]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    return {
      ...((mask & RequestMask.TEMPLATE) === RequestMask.TEMPLATE ? { template: resp[0].body } : {}),
      ...((mask & RequestMask.VENUES) === RequestMask.VENUES ? { venues: resp[1] } : {}),
      courses: this.props.app.sharedCache().getAllUniqueCourses(),
    };
  }
  async _createTemplate(templateData) {
    if (!this._isMounted) return;
    const resp = await this.props.app.classroom.template.createTemplate({
      ...templateData,
      capacity: templateData.capacity || -1, //auto-unlimited if not set
      isPrivate: !!templateData.isPrivate,
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Templated added with success!');
      //
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.template, null, resp.body.id);
      this.setState({ templateID: resp.body.id });
      await this.fetchData(RequestMask.TEMPLATE);
      //
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _updateTemplate(templateData) {
    const resp = await this.props.app.classroom.template.updateTemplate(this.state.templateID, {
      ...templateData,
      courseID: undefined,
      capacity: templateData.capacity || -1, //auto-unlimited if not set
      isPrivate: !!templateData.isPrivate,
    });
    if (resp.statusCode == 200) {
      message.success('Template updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
