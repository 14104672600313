import React from 'react';
import autoBind from 'react-autobind';
import {
  Select,
  Layout,
  PageHeader,
  Button,
  Table,
  Drawer,
  Input,
  Form,
  message,
  Tooltip,
  Popconfirm,
  Tag,
  Checkbox,
} from 'antd';
import { DeleteOutlined, SendOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import Utils from '../../components/Utils';
import config from '../../config/config';
import Globals from '../../config/Globals';
//

export default class OrgManagersInvitationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      invitations: [],
      isMembersAddDrawerVisible: false,
      sortedInfo: null,
      failures: [],
      attachLicenses: false,
      products: props.app.sharedCache().products,
      productID: null,
      orgID: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.setOrgID();
    await this._getProducts();
    this._fetchData();
  }

  async setOrgID() {
    let orgID = null;
    if (this.props.app.isOrgManager()) {
      orgID = this.props.app.urlManager.selectedOrg;
    } else {
      orgID = this.props.orgID;
    }
    this.setState({ orgID });
  }

  handleAddMembers() {
    this.setState({ isMembersAddDrawerVisible: true });
  }

  handleCloseMembersDrawer() {
    this.form.resetFields();
    this.setState({ isMembersAddDrawerVisible: false });
  }

  async handleSubmitInvitations() {
    const formData = await this.form.validateFields();

    if (!formData.emails) {
      message.error('Please, type at least one e-mail address!');
      return;
    }

    const emails = formData.emails.replace(/\n/g, ',').replace(' ', ',').split(',');

    const invitations = emails.map((email) => ({
      userEmail: email,
    }));

    if (invitations.length > 50) {
      message.error('You can invite only 50 members at a time');
      return;
    }

    this._submitInvites(invitations);
  }

  async handleRemoveInvite(record) {
    this.startLoading();

    await this.setOrgID();
    const { orgID } = this.state;
    const resp = await this.props.app.organization.organizationAppInvitation.deleteOrganizationAppInvitation(
      orgID,
      record.userID
    );

    if (resp.statusCode == 200) {
      message.success('Invitation successfully removed!');
      this.setState((prevState) => ({
        ...prevState,
        invitations: prevState.invitations.filter((invite) => invite.userID != record.userID),
      }));
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async handleResendInvitation(record) {
    this.startLoading();

    const tenantConfig = this.props.app.sharedCache().getTenantConfig();

    await this.setOrgID();
    const { orgID } = this.state;
    const resp = await this.props.app.organization.organizationAppInvitation.resendOrganizationAppInvitation(
      orgID,
      record.userID,
      {
        userID: record.userID,
        invitationBaseURL: this.props.app.urlManager.appURL,
        expiryDays: tenantConfig.organizationExpireInvitationAfter || 30,
      }
    );

    if (resp.statusCode == 200 && resp.body) {
      message.success(`Invite successfully resent`);
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      rowKey: 'createdOn',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No pending invites found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    const columns = [
      {
        title: 'E-mail',
        key: 'userEmail',
        dataIndex: 'userEmail',
        width: '15%',
        sorter: (a, b) => a.userEmail.localeCompare(b.userEmail),
        sortOrder: sortedInfo.columnKey === 'userEmail' && sortedInfo.order,
      },
      {
        title: 'Invited at',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '13%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : ''),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'License keys',
        dataIndex: 'assignedActivationKeys',
        key: 'assignedActivationKeys',
        width: '13%',
        render: (keys) => (keys ? keys.join(', ') : ''),
        sorter: (a, b) => a.assignedActivationKeys - b.assignedActivationKeys,
        sortOrder: sortedInfo.columnKey === 'assignedActivationKeys' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: '10%',
        key: 'Actions',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Remove invitation">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to remove ${props.userEmail} invitation?`}
                  onConfirm={this.handleRemoveInvite.bind(this, props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>{' '}
              <Tooltip placement="bottomLeft" title="Resend invitation">
                <Button
                  variant="none"
                  icon={<SendOutlined />}
                  shape="circle"
                  onClick={this.handleResendInvitation.bind(this, props)}
                />
              </Tooltip>
            </span>
          );
        },
      },
    ];

    if (this.props.adminEditOrgView) {
      return (
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <Layout.Content>
            <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleAddMembers}>
              Add Students
            </Button>
            <Table className="adminSearchUsersTable" columns={columns} dataSource={this.state.invitations} {...props} />
          </Layout.Content>
          {this._renderAddMembersDrawer()}
        </Layout.Content>
      );
    } else {
      return (
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />

          <PageHeader className="pageHeader" title="Invitations" onBack={() => this.props.app.urlManager.pushBack()} />

          <Layout.Content>
            <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleAddMembers}>
              Add Students
            </Button>
            <Button type="link" href={config.ApplicationRoutes.invitationHistory}>
              Invitation History
            </Button>
            <Table className="adminSearchUsersTable" columns={columns} dataSource={this.state.invitations} {...props} />
          </Layout.Content>

          {this._renderAddMembersDrawer()}
        </Layout.Content>
      );
    }
  }

  /** private */
  _renderAddMembersDrawer() {
    const { products } = this.state;
    const uniqueProducts = products.filter(
      (product, index, self) => index === self.findIndex((p) => p.id === product.id)
    );

    return (
      <Drawer
        placement="right"
        title="Send invitations"
        width={600}
        onClose={this.handleCloseMembersDrawer}
        open={this.state.isMembersAddDrawerVisible}
        bodyStyle={{ paddingBottom: 20 }}
        footer={this._renderDrawerFooter()}
      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Form.Item name="emails" label="E-mails" help="Separate e-mails by line break, space or comma.">
            <Input.TextArea rows={7} />
          </Form.Item>
          <Form.Item
            name="attachLicenses"
            style={{ marginTop: 8 }}
            extra={
              <>
                * Only products for which you have available licenses are listed below.
                <p>
                  Please note "assign licenses to student" does not automatically enroll a student in a session, it only
                  provides a license for that course. The student is still required to find a session and register.
                </p>
              </>
            }
          >
            <Checkbox
              id="attachLicenses"
              checked={this.state.attachLicenses}
              onChange={(e) => this.setState({ attachLicenses: e.target.checked })}
            />
            <strong style={{ marginLeft: 10 }}>Assign licenses to students</strong>
          </Form.Item>

          <Form.Item name="productID" label="Product" style={{ marginTop: 16 }}>
            <Select onChange={(value) => this.setState({ productID: value })}>
              {uniqueProducts.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        {this.state.failures && this.state.failures.length > 0 && (
          <>
            <h1>These invitations were not sent:</h1>
            <ul>
              {this.state.failures.map((item) => (
                <li style={{ marginTop: 10 }}>
                  <strong>{item.invitation.userEmail}</strong>: <br />
                  <Tag color="red">{item.reason}</Tag>
                </li>
              ))}
            </ul>
          </>
        )}
      </Drawer>
    );
  }

  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleCloseMembersDrawer} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button onClick={this.handleSubmitInvitations} type="primary">
          Submit
        </Button>
      </div>
    );
  }

  // API
  async _fetchData() {
    this.startLoading();

    await this.setOrgID();
    const { orgID } = this.state;
    const resp = await this.props.app.organization.organizationAppInvitation.getOrganizationAppInvitationByOrgID(orgID);

    if (resp.statusCode == 200 && resp.body && resp.body.invitations) {
      this.setState({ invitations: resp.body.invitations });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _getProducts() {
    await this.setOrgID();
    const { products, orgID } = this.state;

    const resp = await this.props.app.license.key.getAvailableActivationKeys(orgID);
    if (resp.statusCode === 200 && resp.body.keys) {
      const availableKeys = resp.body.keys;
      const productIDs = availableKeys.map((license) => license.productID);

      const filteredProducts = products.filter((product) => productIDs.includes(product.id));
      this.setState({ availableKeys });

      if (filteredProducts.length > 0) {
        const sortedProducts = filteredProducts.sort((a, b) => a.name.localeCompare(b.name));

        this.setState({ products: sortedProducts, errorMessage: null });
      } else {
        this.setState({ errorMessage: 'No products found.' });
      }
    }
  }

  async _submitInvites(invites) {
    this.startLoading();

    if (!this.state.attachLicenses) {
      this._createInvites(invites);
      return;
    }
    const { productID } = this.state;
    await this.setOrgID();
    const { orgID } = this.state;

    const resp = await this.props.app.license.key.getAvailableKeys(orgID, invites.length, productID);

    if (resp.statusCode == 200 && resp.body.keys) {
      const keys = resp.body.keys;

      invites = invites.map((invite, index) => ({
        ...invite,
        additionalDetails: { licenseKey: keys[index] },
      }));

      this._createInvites(invites);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  async _createInvites(invites) {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();

    await this.setOrgID();
    const { orgID } = this.state;
    const resp = await this.props.app.organization.organizationAppInvitation.createOrganizationAppBulkInvitation(
      orgID,
      {
        users: invites,
        invitationBaseURL: this.props.app.urlManager.appURL,
        expiryDays: tenantConfig.organizationExpireInvitationAfter || 30,
      }
    );

    if (resp.statusCode == 200 && resp.body) {
      if (resp.body.failures && resp.body.failures.length > 0) {
        const emails = resp.body.failures.map((item) => item.invitation.userEmail).join('\n');
        this.form.setFieldsValue({ emails });

        message.error('Some of your invitations could not be sent');
        this.setState({ failures: resp.body.failures, isLoading: false });
      } else {
        message.success(`Invite${invites.length > 0 ? 's' : ''} successfully sent`);
        this.handleCloseMembersDrawer();
        this._fetchData();
      }
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}
