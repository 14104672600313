import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Alert, InputNumber, Button, Tooltip, Typography, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
//
import { PAYMENT_TYPE } from './CommonLicensePurchaseModalStep_PaymentView_Federated';
import Utils from '../../../../components/Utils';
//
//props are: app, user, onCancel, onNext, product, gatewayURL, selectedSession
//chargingAmount, quantity, taxAmount, totalAmount, productObject, autoRedeem
const styleHidden = { style: { opacity: 0 } };
export default class CommonLicensePurchaseModalStep_SummaryView_Moneris extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.checkoutGateway = null;
    this.state = {
      firstName: '',
      lastName: '',
      purchaserName: 'loading...',
      purchaseMethod: '',
      paymentMethod: PAYMENT_TYPE.CARD,
      loadingMoneris: false,
    };
  }
  setSensitiveInfo(data) {
    this.checkoutGateway = null;
    this.setState(
      {
        purchaserName: `${data.firstName} ${data.lastName}`,
        firstName: data.firstName,
        lastName: data.lastName,
        paymentMethod: data.paymentMethod,
        purchaseMethod: data.paymentMethod == PAYMENT_TYPE.CARD ? '(Credit Card)' : 'Invoice',
        loadingMoneris: true,
      },
      () => {
        //load moneris
        if (data.paymentMethod == PAYMENT_TYPE.CARD) this._loadMonerisSDK();
      }
    );
  }
  //Validation
  async validateFields() {
    return this.form.validateFields();
  }
  //Actions
  async handleSubmit() {
    await this.props.onNext(this.state);
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) this.checkoutGateway.closeCheckout();
  }
  async handlePreviousAction() {
    const resp = await this.form.validateFields();
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) this.checkoutGateway.closeCheckout();
    if (resp) this.props.onPrevious({ ...resp, ...this.state });
  }
  //UI
  render() {
    return (
      <Form {...Utils.propagateRef(this, 'form')} action={this.props.gatewayUrl} key="summary" id="summary">
        {
          /* show expiration alert */
          this.props.autoRedeem && this.props.selectedSession && this.props.selectedSession.onlineAccessPeriod > 0 && (
            <Alert
              style={{ marginTop: 10 }}
              type="warning"
              showIcon
              message={`Once you complete this operation, you will have ${Utils.getIntervalString(this.props.selectedSession.onlineAccessPeriod)} to complete ${this.props.productObject.description}.
                           Your license will expire on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(Utils.timestampAfterMinutesAtEOD(this.props.selectedSession.onlineAccessPeriod))}.`}
            />
          )
        }
        {this.state.paymentMethod == PAYMENT_TYPE.CARD ? this._renderMonerisCheckout() : this._renderInvoiceSummary()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }
  /* Private UI */
  _renderMonerisCheckout() {
    return (
      <div style={{ height: this.state.loadingMoneris ? 250 : 650 }}>
        {this.state.loadingMoneris && (
          <Row type="flex" justify="center" style={{ marginTop: '250px' }}>
            <Col>
              {' '}
              <Spin spinning size="large"></Spin>{' '}
            </Col>
          </Row>
        )}
        <div {...(this.state.loadingMoneris ? styleHidden : { style: { opacity: 1 } })} id="monerisCheckout"></div>
      </div>
    );
  }
  _renderInvoiceSummary() {
    const discount = this.props.discountAmount > 0 ? this.props.discountAmount * this.props.quantity * -1 : 0;
    return (
      <>
        <Divider orientation="left">Order Summary</Divider>
        <Row type="flex" justify="start">
          <Typography.Text strong>Please, confirm the order information carefully:</Typography.Text>
        </Row>
        <Divider orientation="left" className="subdividers">
          Order
        </Divider>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 8}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Product </Typography.Text>
            </div>
            <Form.Item>
              <Input value={this.props.product.name} disabled className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Price </Typography.Text>
            </div>
            <Form.Item>
              <Tooltip title={'The original price of the product.'} placement="topLeft">
                <InputNumber
                  name="amount"
                  value={this.props.chargingAmount}
                  decimalSeparator="."
                  precision={2}
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 2}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 2}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Quantity </Typography.Text>
            </div>
            <Form.Item name="quantity">
              <Tooltip title={'The number of licenses (activations) you are purchasing.'} placement="topLeft">
                <InputNumber
                  precision={0}
                  step={1}
                  min={0}
                  value={this.props.quantity}
                  disabled
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 6}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Subtotal </Typography.Text>
            </div>
            <Form.Item>
              <InputNumber
                disabled
                precision={2}
                decimalSeparator="."
                value={this.props.chargingAmount * this.props.quantity}
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <Tooltip title={'Discounts can be applied by using cupom codes.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  value={discount}
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                value={this.props.taxAmount}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={this.props.totalAmount}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" className="subdividers">
          Billing
        </Divider>
        <Row type="flex" justify="start">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text>Payment method: {this.state.purchaseMethod}</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text>Billing name: {this.state.purchaserName}</Typography.Text>
          </Col>
        </Row>
      </>
    );
  }
  _renderButtonsFooter() {
    const isCard = this.state.paymentMethod == PAYMENT_TYPE.CARD;
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button
          className="purchaseModalCancelButton"
          disabled={this.props.isLoading}
          key="back"
          onClick={this.props.onCancel}
        >
          {' '}
          Cancel{' '}
        </Button>
        {!isCard && (
          <Button disabled={this.props.isLoading} onClick={this.handlePreviousAction}>
            {' '}
            Previous{' '}
          </Button>
        )}
        {!isCard && (
          <Button
            form="summary"
            key="submit"
            type="primary"
            loading={this.props.isLoading}
            disabled={this.props.isLoading}
            onClick={this.handleSubmit}
            className="purchaseModalConfirmationButton"
          >
            {' '}
            Submit{' '}
          </Button>
        )}
      </Row>
    );
  }
  /* Private Moneris */
  async _loadMonerisSDK() {
    return new Promise((resolve) => {
      //Load into div
      function loadIntoDiv() {
        this.checkoutGateway = new monerisCheckout();
        this.checkoutGateway.setMode(this.props.payload.providerEnv);
        this.checkoutGateway.setCheckoutDiv('monerisCheckout');
        this.checkoutGateway.setCallback('page_loaded', this._handleMonerisLoaded);
        this.checkoutGateway.setCallback('cancel_transaction', this._handleMonerisTransactionCancel);
        this.checkoutGateway.setCallback('error_event', this._handleMonerisErrorEvent);
        this.checkoutGateway.setCallback('payment_receipt', this._handleMonerisPaymentReceipt);
        this.checkoutGateway.setCallback('payment_complete', this._handleMonerisPaymentCompleted);
        this.checkoutGateway.startCheckout(this.props.payload.ticketID);
        resolve();
      }
      //Load script
      const existingScript = document.getElementById('monerisSDK');
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = this.props.payload?.providerLib;
        script.id = 'monerisSDK';
        document.body.appendChild(script);
        // Resolve the promise once the script is loaded
        script.addEventListener('load', loadIntoDiv.bind(this));
      } else loadIntoDiv.bind(this)();
    });
  }
  //Moneris SDK callbacks
  _handleMonerisLoaded() {
    console.debug('moneris loaded');
    this.setState({ loadingMoneris: false });
  }
  _handleMonerisTransactionCancel() {
    console.debug('moneris transaction cancel');
    this.handlePreviousAction();
  }
  _handleMonerisErrorEvent(resp) {
    console.error('moneris error event', resp);
    this.props.app.alertController.showErrorAlert(
      'Payment provider internal error!',
      `Please, try reloading the page and starting the purchase process again, if issue persists, contact support.`
    );
  }
  _handleMonerisPaymentReceipt() {
    console.debug('moneris payment receipt');
  }
  _handleMonerisPaymentCompleted() {
    console.debug('moneris payment completed');
    this.handleSubmit();
  }
}
