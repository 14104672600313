import { Button, Result, Spin, Steps } from 'antd';
import React from 'react';
import autoBind from 'react-autobind';
//
import Utils from '../../../components/Utils';
//
import _CommonSessionSelectionModal from './_CommonSessionSelectionModal';
import CommonCoursePurchaseModalSubStep_InfoConfirmation from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_InfoConfirmation';
//
const STEPS = { LOADING: 'LOADING', RESULT: 'RESULT', INFO_CONFIRMATION: 'INFO_CONFIRMATION' };
export default class CommonCourseScheduleModal extends _CommonSessionSelectionModal {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  /* Overwrites */
  _advanceAfterSessionSelection(sessionID, session, materialShippingInfo) {
    const shouldShowInfoConfirmStep =
      session?.confirmInfoOnEnrolment &&
      !materialShippingInfo &&
      (this.props.app.isUser() || this.props.app.isSysAdmin());
    if (shouldShowInfoConfirmStep) {
      this.setState({
        sessionID,
        session,
        stepper: { ...this.state.stepper, current: STEPS.INFO_CONFIRMATION, status: 'waiting', error: null },
        isLoading: false,
      });
    } else {
      this._enrolIntoSession(sessionID, session, materialShippingInfo);
    }
  }
  _getStepIndex() {
    const increaseStepIdx = this._shouldShowInfoConfirmationStep() ? 1 : 0;
    if (this.getCurrentStep() == STEPS.INFO_CONFIRMATION) return 1;
    else if (this.getCurrentStep() == STEPS.LOADING) return 1 + increaseStepIdx;
    else if (this.getCurrentStep() == STEPS.RESULT) return 2 + increaseStepIdx;
    return super._getStepIndex();
  }
  _renderStepperSteps() {
    return (
      <>
        {super._renderStepperSteps()}
        {this._shouldShowInfoConfirmationStep() && <Steps.Step title="Confirmation" />}
        <Steps.Step title="Processing" />
        <Steps.Step title={this.state?.stepper?.error ? 'Failed' : 'Completed'} />
      </>
    );
  }
  _renderSelectedStep() {
    if (this.getCurrentStep() == STEPS.INFO_CONFIRMATION) return this._renderInfoConfirmationStep();
    else if (this.getCurrentStep() == STEPS.LOADING) return this._renderLoadingStep();
    else if (this.getCurrentStep() == STEPS.RESULT) return this._renderResultStep();
    return super._renderSelectedStep();
  }
  /* UI */
  _renderInfoConfirmationStep() {
    return (
      <CommonCoursePurchaseModalSubStep_InfoConfirmation
        app={this.props.app}
        selectedSession={this.state.session}
        onNext={this._advanceAfterSessionSelection.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onPrevious={this.goToInitialStep.bind(this)}
        user={this._getCurrentUser()}
      />
    );
  }
  _renderLoadingStep() {
    return (
      <Result status="info" title="Processing." subTitle="Enroling into selected session." extra={<Spin spinning />} />
    );
  }
  _renderResultStep() {
    let result = null;
    if (this.state.stepper.error) {
      result = {
        status: 'error',
        title: (
          <>
            {`Error while enroling into session.`}
            <br />
            <span style={{ fontSize: 14 }}>{this._extractError()}</span>
          </>
        ),
        //subTitle: this._extractError(),
        extra: (
          <Button type="primary" onClick={() => this.goToInitialStep()}>
            Retry
          </Button>
        ),
      };
    } else {
      result = {
        status: 'success',
        title: `Success`,
        subTitle: `You are now enroled into ${this.state.session?.name}!`,
        extra: (
          <Button type="primary" onClick={() => this.handleCompleted()}>
            Done
          </Button>
        ),
      };
    }
    return <Result {...result} />;
  }

  /* API calls */
  async _enrolIntoSession(sessionID, session, materialShippingInfo) {
    //Start loading
    this.setState({
      sessionID,
      session,
      stepper: { current: STEPS.LOADING, status: 'loading', error: null },
      isLoading: true,
    });
    let user = this.props.app.sharedCache().getProgramUser();
    if (!user || this.props.course?.userID != user.id) {
      user = await this.props.app.api.user.getByID(this.props.course?.userID);
      if (user.statusCode != 200) {
        console.log('ERROR while retrieving user object');
        this.props.app.alertController.showAPIErrorAlert('Error', user);
        return false;
      } else user = user.body;
    }
    //Make request
    const enrolmentResp = await this.props.app.classroom.sessionEnrolment.createSessionEnrolment(
      sessionID,
      this.props.course?.userID,
      this.props.course?.id,
      {
        ticketID: this.props.course?.sessionTicketID,
        externalID: this.props.course?.id,
        //allow admins to enroll users into private sessions without a invitation code -- this might change on the future
        bypassInvitationCode: !!this.props.app.isAdmin(),
        autoEnrolSessionID: session?.autoEnrolOnSession ? session.autoEnrolOnSession : null,
        preferredLanguage: user.preferredLanguage,
        isSchedule: true,
        materialShippingInfo,
      }
    );
    if (!this._isMounted) return;
    //Response
    console.debug('Enrolment resp: ', enrolmentResp);
    if (enrolmentResp.statusCode == 200 && enrolmentResp.body) {
      this._reloadUntilEnroled();
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: enrolmentResp.body },
        isLoading: false,
      });
    }
  }
  async _reloadUntilEnroled() {
    console.debug('Waiting session ID propagation!');
    //Reload certification
    const certResp = await this.props.app.api.certification.getByUserIDAndCertID(
      this.props.course?.userID,
      this.props.course?.certProcID
    );
    if (certResp.statusCode == 200 && certResp.body) {
      const course = certResp.body?.courses?.find((c) => c.id == this.props.course?.id);
      if (course && course.sessionID) {
        this.setState({
          stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null },
          isLoading: false,
        });
        return;
      }
    }
    //Reschedule another attempt
    await Utils.sleep(1000);
    return await this._reloadUntilEnroled();
  }
  _extractError() {
    if (this.state.stepper?.error && this.state.stepper?.error.err) return this.state.stepper?.error.err;
    //40x codes
    else if (this.state.stepper?.error && this.state.stepper?.error.message) return this.state.stepper?.error.message;
    return JSON.stringify(this.state.stepper?.error);
  }
  _shouldShowInfoConfirmationStep() {
    const shouldShowInfoConfirmStep = this.props.app.isUser() || this.props.app.isSysAdmin();
    return this.state.session?.confirmInfoOnEnrolment && shouldShowInfoConfirmStep;
  }
}
