import React from 'react';
import autoBind from 'react-autobind';
import ReactMarkdown from 'react-markdown';
import {
  Select,
  Form,
  Dropdown,
  Divider,
  Table,
  Row,
  Tooltip,
  Popconfirm,
  Button,
  Col,
  Input,
  InputNumber,
  Popover,
  Switch,
} from 'antd';
import { QuestionCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonCertificationExpirationWarningsDrawer from '../Drawers/CommonCertificationExpirationWarningsDrawer';
//props: app, isEdit

export default class CommonCourseForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      onlineWarningRemainingDays: [],
      warningRemainingDays: [],
      calendarColor: '#FFFFFF',
      cardDescription: MarkdownEditor.createEmptyValue(),
      products: props.app.sharedCache().products,
    };
  }
  //Public
  resetFields() {
    this.setState({
      warningRemainingDays: [],
      onlineWarningRemainingDays: [],
      calendarColor: '#FFFFFF',
      cardDescription: MarkdownEditor.createEmptyValue(),
    });
    this.form.resetFields();
  }
  setFieldsValue(data) {
    this.setState(
      {
        calendarColor: data?.calendarColor || '#FFFFFF',
        warningRemainingDays: data.warningRemainingDays || [],
        onlineWarningRemainingDays: data.onlineWarningRemainingDays || [],
        cardDescription: data.cardDescription
          ? MarkdownEditor.createValueFromString(data.cardDescription, 'markdown')
          : MarkdownEditor.createEmptyValue(),
      },
      () => this.form.setFieldsValue({ ...data })
    );
  }
  async validateFields() {
    if (!this.form) return false;
    let formData = null;
    try {
      formData = await this.form.validateFields();
    } catch (e) {}
    if (!formData) return false;
    //
    return {
      ...formData,
      warningRemainingDays: this.state.warningRemainingDays,
      onlineWarningRemainingDays: this.state.onlineWarningRemainingDays,
      requirementsIDs: formData.requirementsIDs || [],
      webhooks: formData.webhooks || [],
      courseFailWebhooks: formData.courseFailWebhooks || [],
      requiredOnRenew: !!formData.requiredOnRenew,
      calendarColor: this.state.calendarColor,
      cardDescription: this.state.cardDescription.toString('markdown'),
    };
  }

  //Actions
  handleChangeShowBanner() {
    this.setState({ showBanner: !this.state.showBanner });
  }
  handleEditWarning(propName, warning) {
    this[`warningDrawer${propName}`].show(warning, this.state[propName].indexOf(warning));
  }
  handleAddWarning(propName) {
    this[`warningDrawer${propName}`].show();
  }
  handleDeleteWarning(propName, warning) {
    const v = this.state[propName];
    v.splice(v.indexOf(warning), 1);
    this.setState({ [propName]: v });
  }
  handleWarningUpdated(propName, warning, index) {
    let v = this.state[propName];
    if (!v) v = [];
    if (index != -1) v[index] = warning;
    else v.push(warning);
    this.setState({ [propName]: v });
  }

  async handleSelectProduct(productID) {
    const product = this.state.products.find((product) => product.id === productID);
    //
    if (product) {
      //
      this.setState({
        selectedProduct: product,
      });
    }
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row gutter={32}>
          {this.props.app.isSysAdmin() && (
            <Col span={11}>
              <Form.Item
                label="Unique ID"
                name="id"
                rules={[{ validator: Utils.validateUniqueID }, { required: false }]}
                extra="This field is available to sysadmins only and will be auto generated for normal admins or if not specified!"
              >
                <Input disabled={this.props.isEdit} />
              </Form.Item>
            </Col>
          )}
          <Col offset={1} span={12}>
            <Form.Item
              label="Display Name"
              name="displayName"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {this._renderUISubform()}
        {this._renderSettingsSubform()}
        {this._renderCertificate()}
        {this._renderWarnings()}
      </Form>
    );
  }
  /* Subforms */
  _renderUISubform() {
    const overlay = (
      <div style={{ zIndex: 9999 }}>
        {' '}
        <ChromePicker
          color={this.state.calendarColor}
          disableAlpha={true}
          onChangeComplete={(calendarColor) => {
            this.setState({ calendarColor: calendarColor.hex });
          }}
        />{' '}
      </div>
    );
    return (
      <>
        <Divider orientation="left">User Interface</Divider>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Description
                  <Popover title="Description" content="This field will be displayed to users on their interface.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item
              name="cardDescription"
              valuePropName="none"
              label={
                <>
                  Card Description
                  <Popover
                    title="Card Description"
                    content="This field will be displayed to users on their course card."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.cardDescription}
                onChange={(cardDescription) => this.setState({ cardDescription })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="uiTerm"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  UI Term
                  <Popover title="UI Term" content="How this entity will be called for users.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_UiTerm).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              name="uiLogo"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  UI Logo
                  <Popover title="UI Logo" content="Which image should be used for this course card.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select
                mode="single"
                showSearch
                rules={[{ required: true, message: 'This field is required!' }]}
                style={{ width: '100%' }}
              >
                {Object.keys(Globals.Course_UiLogo).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={3}>
            <Form.Item
              name="calendarColor"
              label={
                <>
                  Calendar Event Color
                  <Popover
                    title="Calendar Event Color"
                    content="Indicates which color should be used for this course events on the calendar (users and admins)."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Dropdown trigger={['click']} overlay={overlay}>
                <Button style={{ background: this.state.calendarColor, marginLeft: 20 }}> </Button>
              </Dropdown>
            </Form.Item>
          </Col>
          <Col offset={1} span={3}>
            <Form.Item
              name="showCollapsed"
              valuePropName="checked"
              label={
                <>
                  Collapse Card
                  <Popover
                    title="Collapse Card"
                    content={
                      'If on, this setting will cause the course calendar to be displayed in a collapsed state by default'
                    }
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={9}>
            <Form.Item
              name="passUiTerm"
              label={
                <>
                  Passed UI Term
                  <Popover
                    title="Passed UI Term"
                    content="Term to be used to indicate if a course was passed or requirement completed in the course card."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={9}>
            <Form.Item
              name="failUiTerm"
              label={
                <>
                  Failed UI Term
                  <Popover
                    title="Failed UI Term"
                    content="Term to be used to indicate if a course was failed or requirement not met in the course card."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={3}>
            <Form.Item
              name="preventLaunchIfCompleted"
              valuePropName="checked"
              label={
                <>
                  Prevent launch after course has a result
                  <Popover
                    title="Prevent launch after course has a result"
                    content={'If enabled, students will not see the Launch button if course has a result.'}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="isRetakeAllowed"
              valuePropName="checked"
              label={
                <>
                  Allow the course to be retaken
                  <Popover
                    title="Allow the course to be retaken"
                    content={
                      'If enabled, students will be able to enrol again onto completed courses that have no expiration date.'
                    }
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name="allowStudentUploads"
              valuePropName="checked"
              label={
                <>
                  Allow students to upload files
                  <Popover
                    title="Allow students to upload files"
                    content={'If enabled, students will be able to upload files.'}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderSettingsSubform() {
    return (
      <>
        <Divider orientation="left">Course Settings</Divider>
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item
              name="productID"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Course Product ID
                  <Popover title="Renewal Product ID" content="Course product ID on license module.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select
                value={this.state.selectedProduct?.id}
                onChange={this.handleSelectProduct}
                style={{ width: '400px' }}
                placeholder="Select a product"
              >
                {this.state.products.map((product) => (
                  <Option key={product.id} value={product.id}>
                    {product.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item
              name="materialsProductID"
              label={
                <>
                  Course Material Product ID
                  <Popover
                    title="Course material Product ID"
                    content="Optional course material product ID on license module."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              name="courseType"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Course Type
                  <Popover title="Course Type" content="Indicates the type of the course.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.Course_Types).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Globals.Course_Types[c]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={5}>
            <Form.Item
              name="validityPeriodInYears"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Validity (Years)
                  <Popover
                    title="Validity (Years)"
                    content="Number of years the course is valid for, it is preceeded by months. If -1 it is ignored."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              name="validityPeriodInMonths"
              rules={[{ required: false, message: 'This field is required!' }]}
              label={
                <>
                  Validity (Months)
                  <Popover
                    title="Validity (Months)"
                    content="Number of months the course is valid for. If -1 it is ignored. Value has precedence over years if both are informed."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col offset={2} span={4}>
            <Form.Item
              name="maxRetry"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Maximum retries
                  <Popover
                    title="Max Retries"
                    content="Maximum number of attempts of this course before cooling down, -1 indicates no max attempts."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col offset={1} span={4}>
            <Form.Item
              name="coolDownPeriod"
              label={
                <>
                  Cooldown Period
                  <Popover
                    title="Cooldown Period"
                    content="After reaching max retry what is the number of days the user must be placed on cooldown, -1 indicates no cooldown"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
          <Col offset={2} span={6}>
            <Form.Item
              name="lockAfterFailure"
              valuePropName="checked"
              style={{ marginLeft: 10 }}
              label={
                <>
                  Prevent user from re-registering
                  <Popover
                    title="Prevent user from re-registering"
                    content=" If enabled, it will cause a course to be locked for a student after a failure. It will be like a permanent cooldown. Admins will be able to override the lock."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={14}>
            <Form.Item
              name="webhooks"
              label={
                <>
                  Completion Webhook URLs
                  <Popover
                    title="Completion Webhook URLs"
                    content="Array of strings containing webhooks to be invoked when course is completed"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="tags" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="courseFailWebhooks"
              label={
                <>
                  Failed Course Webhook URLs
                  <Popover
                    title="Failed Course Webhook URLs"
                    content="List of URL values containing webhooks to be invoked when a FAIL value is received for the course"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="tags" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item
              name="requiredOnRenew"
              valuePropName="checked"
              label={
                <>
                  Required on Renewal
                  <Popover
                    title="Required on Renewal"
                    content={'Indicates if this course is required on certification renewal'}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={14}>
            <Form.Item
              name="requirementsIDs"
              label={
                <>
                  Requirements IDs
                  <Popover
                    title="Requirements IDs"
                    content="Indicates what other courses this course required inside this certification"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="tags" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item
              name="allowIfAnyRequirementMet"
              valuePropName="checked"
              label={
                <>
                  Allow course if any requirement is met
                  <Popover
                    title="Allow course if any requirement is met"
                    content={'Indicates if this course will be allowed if any requirement is met'}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderCertificate() {
    return (
      <>
        <Divider orientation="left">Certificate Settings</Divider>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="courseCertificateType"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Certificate Type
                  <Popover
                    title="Certificate Type"
                    content="What type of certification will be printed after completion"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_CertTypes).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item
              name="courseCertificateTemplate"
              label={
                <>
                  Certificate Template
                  <Popover title="Certificate Template" content="Certification PDF template ">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch allowClear style={{ width: '100%' }}>
                {Object.keys(Globals.Certification_CertTemplates).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item
              name="courseCertNumberMode"
              label={
                <>
                  Certification Number Mode
                  <Popover
                    title="Certification Number Mode"
                    content="Which mode the system will operate to assign a certificate."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch allowClear style={{ width: '100%' }}>
                <Select.Option value={'1'} key={1}>
                  1 - Reuse from the tenant (cert share a track with other courses and certifications).
                </Select.Option>
                <Select.Option value={'2'} key={2}>
                  2 - Reuse from tenant (cert share a track with other certs)
                </Select.Option>
                <Select.Option value={'3'} key={3}>
                  3 - Reuse from certSpecID (cert share a track in a certification with other certs)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="courseCertNumberInitialNumber"
              label={
                <>
                  Certification Number Initial Number
                  <Popover
                    title="Certification Number Initial Number"
                    content="The initial certification number for this course."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item
              name="courseCertNumberMaskFormat"
              label={
                <>
                  Certification Number Mask Format
                  <Popover
                    title="Certification Number Mask Format"
                    content="The certification number masking.'@y' is replaced by the year and 'X' by numbers."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item
              name="reuseCertNumber"
              valuePropName="checked"
              label={
                <>
                  Reuse Cert Number
                  <Popover
                    title="Reuse Cert Number"
                    content={'Indicates if the engine will reuse the course certificate number on repeated courses'}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderWarnings() {
    return (
      <>
        <Divider orientation="left">Warnings</Divider>
        <Row gutter={32}>
          <Col span={10}>
            <Form.Item
              name="warningDuePaymentRemainingDays"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Warning Due Payment Remaining Days
                  <Popover
                    title="Warning Due Payment Remaining Days"
                    content="Indicates the number of days before a scheduled course renewal date, the unpaid reschedule should be warned for users. -1 indicates it never warns the user"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ width: '100%' }} min={-1} />
            </Form.Item>
          </Col>
        </Row>
        {this._renderWarningRemainingDays('warningRemainingDays')}
        {this._renderWarningRemainingDays('onlineWarningRemainingDays', true)}
      </>
    );
  }
  _renderWarningRemainingDays(propName, isOnline) {
    const columns = [
      { title: 'Template', key: 'template', dataIndex: 'template' },
      { title: 'Period (days)', key: 'period', dataIndex: 'period' },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditWarning.bind(this, propName, props)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete this warning?`}
                  onConfirm={this.handleDeleteWarning.bind(this, propName, props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = { rowKey: (p) => JSON.stringify(p), locale: { emptyText: 'No warnings found!' }, pagination: false };
    return (
      <>
        <CommonCertificationExpirationWarningsDrawer
          {...Utils.propagateRef(this, 'warningDrawer' + propName)}
          onUpdate={this.handleWarningUpdated.bind(this, propName)}
        />
        <Divider orientation="left">{isOnline ? 'Online Access' : 'Expiration '} Warning - Remaining Days</Divider>
        <Row gutter={32}>
          <Col span={21}>
            <Table
              className="adminGenericTable"
              columns={columns}
              dataSource={[...(this.state[propName] || [])]}
              {...props}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={this.handleAddWarning.bind(this, propName)}>
              Add Warning
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
