import React from 'react';
import autoBind from 'react-autobind';
import { Button, Tooltip, Tag } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import CustomComponent from '../../components/CustomComponent';
import '../../assets/stylesheets/CommonCertificationProcess.less';

import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
export default class CommonCertificationCard extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    let id = props.certification.certificationID;
    if (!id) id = props.certification.id; //check for placeholder
    //
    const certSpecs = this.props.app.sharedCache().getCertificationByID(id);
    this.state = {
      certification: props.certification,
      certSpecs: certSpecs,
      loadingPrintFile: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.certification) {
      if (prevProps.certification.id !== this.props.certification.id) {
        this.updateCard();
      }
    }
  }

  updateCard = () => {
    let id = this.props.certification.certificationID;
    if (!id) id = this.props.certification.id; //check for placeholder
    const certSpecs = this.props.app.sharedCache().getCertificationByID(id);
    this.setState({ certification: this.props.certification, certSpecs: certSpecs });
  };
  async handlePrintCert(certProcId) {
    if (!this.props.app.isAdmin()) return;
    //start print button loading
    this.setState({ loadingPrintFile: true });
    //Get file
    const resp = await this.props.app.api.certification.getPrintingCertificate(this.props.customUser.id, certProcId);
    if (resp.statusCode == 200) {
      //ask to download
      Utils.downloadBlob(resp.body, 'file', 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
    }
    //start print button loading
    this.setState({ loadingPrintFile: false });
  }
  handleShowTimeline(certProcId) {
    if (this.props.app.isAdmin() || this.props.app.isOrgManager()) {
      this.props.app.urlManager.pushPage(
        config.ApplicationRoutes.newAdminCertification,
        {},
        certProcId,
        this.props.customUser.id
      );
    } else {
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.newUserCertification, {}, certProcId);
    }
  }

  render() {
    const uiTerm = Utils.camelizeString(this.state.certSpecs?.uiTerm);

    const cardBackground = this.state.certSpecs?.ui.cardBackground;
    const cardLogo = cardBackground
      ? require(`../../assets/images/cardsBackgrounds/${cardBackground}.png`).default
      : null;
    const cardStyle = cardLogo ? { backgroundImage: `url(${cardLogo})` } : { height: 'auto' };

    return (
      <div>
        <div className="card" style={cardStyle}>
          {Globals.getCertificationAction(this.state.certification.state) != '' && (
            <div className="action-badge">{Globals.getCertificationAction(this.state.certification.state)}</div>
          )}

          <div className="overlay">
            <div className="content">
              <div className="details">
                <small>
                  <strong>Number: {this.state.certification?.certNumber ?? 'N/A'}</strong>
                </small>
                <h1>
                  {this.state.certSpecs?.description || ''}
                  {!this.state.certification.createdOn > 0 && (
                    <Tag color="default" style={{ fontWeight: 'normal' }}>
                      Not started
                    </Tag>
                  )}
                </h1>
                {Globals.getCertificationDisplayState(this.state.certification.state, this.state.certSpecs) && (
                  <Tag style={{ textAlign: 'center' }} color="blue">
                    {Globals.getCertificationDisplayState(this.state.certification.state, this.state.certSpecs)}
                  </Tag>
                )}
                <strong>
                  <small>{this._renderDate()}</small>
                </strong>
              </div>
              <div className="actions">
                {!this.state.certification?.certificationID && (
                  <Button
                    style={{ border: '1px solid #fff' }}
                    onClick={() => this.props.handleStartCertification(this.state.certification.id)}
                    type="primary"
                  >
                    Start {uiTerm}
                  </Button>
                )}
                {this.state.certification?.certificationID && (
                  <Button
                    style={{ border: '1px solid #fff' }}
                    onClick={() => this.handleShowTimeline(this.state.certification.id)}
                    type="primary"
                  >
                    Enter
                  </Button>
                )}
                {this.props.app.isAdmin() &&
                  this.state.certification.certNumber &&
                  this.state.certSpecs?.certificateType == Globals.CertificationProcess_CertificationPrintType.CERT && (
                    <Tooltip title={`Print`}>
                      <Button
                        style={{ border: '1px solid #fff', marginLeft: 8 }}
                        icon={<PrinterOutlined />}
                        onClick={() => this.handlePrintCert(this.state.certification.id)}
                        loading={this.state.loadingPrintFile}
                        type="primary"
                      />
                    </Tooltip>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderDate() {
    const { completionDate, expiryDate } = this.state.certification;

    if ((completionDate && completionDate != -1) || (expiryDate && expiryDate != -1)) {
      return expiryDate && expiryDate != -1
        ? `Expiration date: ${Utils.getDateOnUIFormatByTimestamp(expiryDate)}`
        : `Achieved on: ${Utils.getDateOnUIFormatByTimestamp(completionDate)}`;
    }

    return null;
  }
}
