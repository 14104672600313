import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Alert } from 'antd';

import '../../../assets/stylesheets/ApplicationSubmissionStep.less';

import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
import ApplicationUtils from '../../../components/UtilsApplication';

import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';

import ApplicationWaitingView from './CommonApplicationTab/ApplicationWaitingView';
import ApplicationApprovedView from './CommonApplicationTab/ApplicationApprovedView';
import ApplicationRejectedView from './CommonApplicationTab/ApplicationRejectedView';
import ApplicationSubmissionView from './CommonApplicationTab/ApplicationSubmissionView';
import ApplicationStartView from './CommonApplicationTab/ApplicationStartView';
export default class CommonCertificationApplicationTabController extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      foreignItems: null,
    };
  }

  componentDidMount() {
    this._loadForeignItems();
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  // Actions
  handleDownloadItem(currentApplicationItem, file) {
    this._downloadItem(this.props.user.id, this.props.certificationProcess.id, currentApplicationItem.sourceID, file);
  }

  handlePreviewItem(currentApplicationItem, file) {
    this._previewItem(this.props.user.id, this.props.certificationProcess.id, currentApplicationItem.sourceID, file);
  }

  handleUpdate() {
    return Promise.all([this.props.onUpdate(), this._loadForeignItems()]);
  }

  handleDownloadApplication() {
    this._downloadApplication();
  }

  //UI
  render() {
    //Check if not visible, don't render
    if (!this.props.isVisible) return <></>;
    const applicationProcess = this._getApplicationProcess();
    const applicationSpecs = this._getApplicationSpecs();
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} usePortal />
        {this._renderViewByState(applicationProcess, applicationSpecs)}
      </>
    );
  }

  // Private UI
  _renderViewByState(applicationProcess, applicationSpecs) {
    if (!applicationProcess) return this._renderStartSubview(applicationProcess, applicationSpecs);
    else if (applicationProcess.state == Globals.ApplicationState.WAITING_APPROVAL)
      return this._renderWaitingSubview(applicationProcess, applicationSpecs);
    else if (applicationProcess.state == Globals.ApplicationState.APPROVED)
      return this._renderApprovedSubview(applicationProcess, applicationSpecs);
    else if (
      applicationProcess.state == Globals.ApplicationState.REJECTED &&
      (!applicationProcess.reviewAcknowledge || applicationProcess.reviewAcknowledge == -1)
    ) {
      return this._renderRejectedSubview(applicationProcess, applicationSpecs);
    } else return this._renderSubmissionView(applicationProcess, applicationSpecs);
  }
  _renderStartSubview(applicationProcess, applicationSpecs) {
    return (
      <ApplicationStartView
        app={this.props.app}
        startLoading={this.startLoading}
        stopLoading={this.stopLoading}
        user={this.props.user}
        applicationType={this.props.applicationType}
        certificationProcess={this.props.certificationProcess}
        onUpdate={this.handleUpdate}
        applicationDescription={applicationSpecs.applicationDescription}
      />
    );
  }
  _renderWaitingSubview(applicationProcess, applicationSpecs) {
    return <ApplicationWaitingView onDownloadApplication={this.handleDownloadApplication} />;
  }
  _renderApprovedSubview(applicationProcess, applicationSpecs) {
    return (
      <ApplicationApprovedView
        certificationProcess={this.props.certificationProcess}
        submitAcknowledge={this._acknowledge}
        alreadyAcknowledge={applicationProcess.reviewAcknowledge && applicationProcess.reviewAcknowledge != -1}
        onDownloadApplication={this.handleDownloadApplication}
        reviewNotes={applicationProcess.reviewNotes}
      />
    );
  }
  _renderRejectedSubview(applicationProcess, applicationSpecs) {
    const sections = ApplicationUtils.getSections(applicationSpecs);
    const rejectedItems = sections
      .map((section) => section.tiles)
      .flat()
      .map((tile) => ({
        ...tile,
        applicationItemData: ApplicationUtils.getTileData(
          tile,
          applicationProcess,
          this.props.certificationProcess,
          this.state.foreignItems
        ),
      }))
      .filter((tile) => tile.applicationItemData.status == Globals.ApplicationItemsStatus.REJECTED);
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} usePortal />

        <ApplicationRejectedView
          user={this.props.user}
          certificationProcess={this.props.certificationProcess}
          app={this.props.app}
          rejectedItems={rejectedItems}
          onDownloadItem={this.handleDownloadItem}
          onDownloadApplication={this.handleDownloadApplication}
          onPreviewItem={this.handlePreviewItem}
          applicationType={this.props.applicationType}
          submitAcknowledge={this._acknowledge}
          reviewNotes={applicationProcess.reviewNotes}
        />
      </>
    );
  }
  _renderSubmissionView(applicationProcess, applicationSpecs) {
    const isApplicationRejected = applicationProcess.state == Globals.ApplicationState.REJECTED;
    return (
      <>
        {applicationProcess.reviewNotes && (
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Alert
                type={isApplicationRejected ? 'error' : 'info'}
                message={`${isApplicationRejected ? 'Your application was rejected:' : 'Admin notes:'} ${applicationProcess.reviewNotes}`}
                showIcon
              />
            </Col>
          </Row>
        )}
        {this.state.foreignItems && (
          // Application depends on foreign items to be loaded to render UI and validate application
          <ApplicationSubmissionView
            applicationProcess={applicationProcess}
            certificationSpecs={this.props.certificationSpecs}
            applicationType={this.props.applicationType}
            app={this.props.app}
            foreignItems={this.state.foreignItems}
            loadForeignItems={this._loadForeignItems}
            user={this.props.user}
            certificationProcess={this.props.certificationProcess}
            onUpdate={this.handleUpdate}
            onDownloadItem={this.handleDownloadItem}
            onPreviewItem={this.handlePreviewItem}
            onDownloadApplication={this.handleDownloadApplication}
          />
        )}
      </>
    );
  }

  // Private methods
  _getApplicationProcess() {
    const { certificationProcess } = this.props;
    return certificationProcess.applications
      ?.sort((a, b) => b.createdOn - a.createdOn)
      ?.find((application) => application.type == this.props.applicationType);
  }
  _getApplicationSpecs() {
    return this.props.applicationType == Globals.ApplicationTypes.NEW
      ? this.props.certificationSpecs.application
      : this.props.certificationSpecs.renewal.application;
  }

  // Private API
  async _loadForeignItems() {
    this.startLoading();

    const resp = await this.props.app.api.application.getForeignItems(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.applicationType
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.setState({
        isLoading: false,
        foreignItems: resp.body.items,
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
      this.stopLoading();
    }
  }

  async _downloadItem(userID, certProcID, sourceID, file) {
    this.startLoading();

    const resp = await this.props.app.api.applicationItemFile.download(userID, certProcID, sourceID, file.fileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(
        resp.body,
        filename || file.fileName,
        extension || Utils.getFileExtensionFromMimeType(resp.body.type)
      );
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.stopLoading();
  }

  async _previewItem(userID, certProcID, sourceID, file) {
    this.startLoading();

    const resp = await this.props.app.api.applicationItemFile.getSignURL(userID, certProcID, sourceID, file.fileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.stopLoading();
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
  }

  async _acknowledge() {
    this.setState({ isLoading: true });

    const applicationProcess = this._getApplicationProcess();

    const resp = await this.props.app.api.application.acknowledge(
      this.props.user.id,
      this.props.certificationProcess.id,
      applicationProcess.type
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }

  async _downloadApplication() {
    this.startLoading();
    try {
      await this.props.app.api.newApplicationDownload(
        this.props.certificationProcess,
        this.state.foreignItems,
        this.props.certificationSpecs,
        this.props.applicationType
      );
    } catch (e) {
      this.props.app.alertController.showErrorAlert('Could not download application!', e.message);
    }
    if (!this._isMounted) return;
    this.stopLoading();
  }
}
