import { ResponsiveBar } from '@nivo/bar';
import { Empty } from 'antd';
import React from 'react';

export default class RewritesChart extends React.Component {
  render() {
    const { exams } = this.props;

    const data = (exams || []).reduce(
      (acc, curr, index) => ({
        ...acc,
        [curr.exam.displayName]: curr.rewrites,
        [`${curr.exam.displayName}Color`]: index % 2 == 0 ? '#af3947' : '#ff9e56',
      }),
      {
        id: 'Total',
        label: 'Total',
      }
    );
    const keys = (exams || []).reduce((acc, curr) => [...acc, curr.exam.displayName], []);

    const allEmpty = keys.every((key) => data[key] === 0);
    if (allEmpty) {
      return <Empty description="No data to be displayed here" style={{ marginTop: 56 }} />;
    }

    return (
      <ResponsiveBar
        data={[data]}
        keys={keys}
        layout="vertical"
        colors={({ data, id }) => data[`${id}Color`]}
        labelTextColor="#fff"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        margin={{ top: 0, right: 0, bottom: 150, left: 0 }}
        enableGridY={false}
        theme={{
          legends: { text: { fontSize: 13 } },
          labels: { text: { fontSize: 13 } },
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateY: 40,
            itemsSpacing: 66,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'top-to-bottom',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [{ on: 'hover', style: { itemOpacity: 1 } }],
          },
        ]}
      />
    );
  }
}
