import React from 'react';
import autoBind from 'react-autobind';
import { Tabs, Layout, PageHeader, Button, message, Modal, Table, Typography, Select, DatePicker, Tooltip } from 'antd';
import { ShoppingCartOutlined, DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonProductPurchaseModal from '../commonComponents/Modals/ProductPurchaseModal/CommonProductPurchaseModal';

import config from '@/config/config';
import Globals from '@/config/Globals';
import Utils from '@/components/Utils';

export default class CommonProductOrdersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const type = this.props.app.idm.urlmanager.getParam('type');
    this.isOrg = type === 'organization';
    this.state = {
      id: this.props.match.params.id,
      sortedInfo: null,
      isLoading: false,
      pageTitle: 'Org Product Orders',
      selectedTab: 'licenses',
      //data
      orders: [],
      status: '',
      from: undefined,
      to: undefined,
      //modal
      isPurchaseModalVisible: false,
      purchaseModalRequiresAttention: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    //Set title properly
    let pageTitle = 'Org Product Orders';
    if (this.isOrg) pageTitle = 'Org Product Orders';
    else pageTitle = 'My Product Orders';
    this.setState({ pageTitle });
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    await this._loadAll();
  }

  //Actions
  async handleSelectStatus(value) {
    await this.setState({ status: value });
  }
  async handleDateChange(dates) {
    if (dates) {
      const [from, to] = dates.map((date) => moment(date).valueOf());
      await this.setState({ from, to });
    } else {
      await this.setState({ from: undefined, to: undefined });
    }
  }
  handleSearch = async () => {
    this.setState({ isLoading: true }, async () => {
      await this._searchProductOrders();
      this.setState({ isLoading: false });
    });
  };
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, () => {
      this.fetchData();
    });
  }
  handleProductOrdersDetails(row) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.productOrdersDetails, null, this.state.id, row.id);
  }
  handleDownloadOrder(row) {
    this._downloadOrder(row.externalID, row.id);
  }
  handleBuyProduct() {
    if (!this.state.isPurchaseModalVisible) this.purchaseModal.loadModalInfo();
    this.setState({ isPurchaseModalVisible: !this.state.isPurchaseModalVisible }, () => {
      if (!this.state.isPurchaseModalVisible) this._loadAll();
    });
  }

  async generateReportXLSX() {
    const { orders } = this.state;

    if (orders.length < 1) {
      return;
    }

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    // Generate XLSX header
    ws.addRow([
      'Owner ID',
      'Name',
      'Description',
      'Order ID',
      'Date',
      'Items Value',
      'Items Tax',
      'Items Total',
      'Shipping Charges',
      'Shipping Tax',
      'Shipping Total',
      'Order Value',
      'Order Tax',
      'Order Total',
      'Status',
      'Refunded Amount',
      'Refunded On',
      'Canceled On',
      'Cancel Reason',
      'Provider Transaction ID',
    ]);

    orders.forEach((order) => {
      ws.addRow([
        order.ownerID || '',
        order.name || '',
        order.description || '',
        order.orderID || '',
        order.orderDate ? Utils.getDateOnUIFormatByTimestamp(order.orderDate) : '',
        order.itemsValue || 0,
        order.itemsValueTax || 0,
        order.itemsValueTotal || 0,
        order.shippingChargesValue || 0,
        order.shippingChargesValueTax || 0,
        order.shippingChargesValueTotal || 0,
        order.orderValue || 0,
        order.orderValueTax || 0,
        order.orderValueTotal || 0,
        order.status || '',
        order.refundedAmount || 0,
        order.refundedOn ? Utils.getDateOnUIFormatByTimestamp(order.refundedOn) : '',
        order.canceledOn ? Utils.getDateOnUIFormatByTimestamp(order.canceledOn) : '',
        order.cancelReason || '',
        order.providerTransactionID || '',
      ]);
    });

    const buffer = await wb.xlsx.writeBuffer();
    Utils.downloadArrayBuffer(buffer, 'productOrders', 'xlsx');
  }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <CommonProductPurchaseModal
          {...Utils.propagateRef(this, 'purchaseModal')}
          user={this.state.user}
          org={this.state.org}
          isVisible={this.state.isPurchaseModalVisible}
          onChange={this.handleBuyProduct}
          app={this.props.app}
          onRequiresAttention={(modal) => {
            this.purchaseModal = modal;
            if (this.state.isLoading) this.setState({ purchaseModalRequiresAttention: true });
            else this.handleBuyProduct();
          }}
        />
        <PageHeader
          className="pageHeader"
          title={this.state.pageTitle}
          onBack={() => this.props.app.urlManager.pushBack()}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={this.handleBuyProduct} icon={<ShoppingCartOutlined />}>
            Buy
          </Button>
          <Button
            type="secondary"
            style={{ marginLeft: 4 }}
            icon={<DownloadOutlined />}
            onClick={this.generateReportXLSX}
          >
            Export to xlsx
          </Button>
        </div>
        <Layout.Content>
          <div>{this._renderFilters()}</div>
          {this._renderOrdersTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }
  /* private UI */
  _renderFilters() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div style={{ marginBottom: '16px' }}>
          <Typography.Text strong>Date Range: </Typography.Text>
          <DatePicker.RangePicker onChange={this.handleDateChange} style={{ width: '400px' }} />
        </div>
        <div style={{ marginLeft: '20px', marginBottom: '16px' }}>
          <Typography.Text strong>Status: </Typography.Text>
          <Select allowClear style={{ width: '260px' }} placeholder="Status" onChange={this.handleSelectStatus}>
            <Select.Option
              value={Globals.Product_Order.COMPLETED}
              key={Globals.Product_Order.COMPLETED}
            ></Select.Option>
            <Select.Option value={Globals.Product_Order.FAILED} key={Globals.Product_Order.FAILED}></Select.Option>
            <Select.Option
              value={Globals.Product_Order.PENDING_SHIPPING}
              key={Globals.Product_Order.PENDING_SHIPPING}
            ></Select.Option>
            <Select.Option
              value={Globals.Product_Order.CANCELLED}
              key={Globals.Product_Order.CANCELLED}
            ></Select.Option>
            <Select.Option value={Globals.Product_Order.REFUNDED} key={Globals.Product_Order.REFUNDED}></Select.Option>
          </Select>
        </div>
        <div style={{ marginLeft: '20px', marginBottom: '16px' }}>
          <Button type="primary" icon={<SearchOutlined />} onClick={this.handleSearch}>
            Search
          </Button>
        </div>
      </div>
    );
  }
  _renderOrdersTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Date',
        key: 'orderDate',
        dataIndex: 'orderDate',
        width: '20%',
        render: (orderDate) => (orderDate ? Utils.getDateOnUIFormatByTimestamp(orderDate) : ''),
        sorter: (a, b) => a.orderDate - b.orderDate,
        sortOrder: sortedInfo.columnKey === 'orderDate' && sortedInfo.order,
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        width: '20%',
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: '20%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: (status) => `${Utils.formatLabel(status)}`,

      },
      {
        title: 'Total',
        key: 'orderValueTotal',
        dataIndex: 'orderValueTotal',
        width: '20%',
        sorter: (a, b) => a.orderValueTotal.localeCompare(b.orderValueTotal),
        sortOrder: sortedInfo.columnKey === 'orderValueTotal' && sortedInfo.order,
        render: (orderValueTotal) => `$${Utils.toCurrencyFormat(orderValueTotal)}`,
      },
      {
        title: 'Actions',
        width: '15%',
        key: 'Actions',
        render: (text, record) => (
          <span className="tableButtonContainer">
            <Tooltip placement="bottomLeft" title="View">
              <Button
                variant="none"
                icon={<EyeOutlined />}
                onClick={() => this.handleProductOrdersDetails(record)}
                shape="circle"
              />
            </Tooltip>
            <Tooltip placement="bottomLeft" title="Download">
              <Button
                icon={<DownloadOutlined />}
                style={{ marginRight: 6 }}
                shape="circle"
                onClick={() => this.handleDownloadOrder(record)}
              />
            </Tooltip>
          </span>
        ),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={this.state.orders}
        rowKey="id"
        loading={this.state.isLoading}
        onChange={this.handleFilterChange}
        pagination={{
          pageSize: Globals.Table_PagingItemsPerPage,
          hideOnSinglePage: true,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
        locale={{ emptyText: 'No orders found!' }}
      />
    );
  }
  /* private API */
  async _loadAll() {
    // const filters = { filters: { externalID: this.state.id } };
    // const resp = await this.props.app.license.productOrder.searchProductOrders(filters);
    // if (resp.statusCode == 200) {
    //   return { orders: resp.body.productOrders };
    // } else {
    //   this.props.app.alertController.showAPIErrorAlert(null, resp);
    // }

    let externalID = '';
    const needsUserFetch = this.props.app.isAdmin();
    //Choose what value to use as user ID
    if (this.props.app.isOrgManager() && this.isOrg) {
      externalID = this.props.app.urlManager.selectedOrg;
    } else if (this.props.app.isAdmin()) {
      externalID = this.props.match.params.id;
    } else {
      externalID = this.props.app.idm.session.authorization.getUserID();
    }

    let orders = null,
      user = null,
      org = null;
    //Load orders and user in parallel
    const filters = { filters: { externalID } };
    await Utils.execRequests(
      [
        await this._fetchProductOrders(filters),
        await (this.isOrg ? this._fetchOrganization(externalID) : this._fetchUser(externalID, needsUserFetch)),
      ],
      null,
      (resp, index) => {
        if (index == 0) {
          //orders
          orders = (resp?.body?.productOrders || []).sort((a, b) => b.createdOn - a.createdOn);
        } else if (index == 1) {
          //user
          if (this.isOrg) org = resp.body;
          else user = resp.body;
        }
      }
    );
    //Set state but also check if purchase modal came back as is waiting data to be loaded to show up
    const purchaseModalRequiredAttention = this.state.purchaseModalRequiresAttention;
    this.setState({ isLoading: false, orders, user, org, purchaseModalRequiresAttention: false }, () => {
      if (purchaseModalRequiredAttention) this.handleBuyProduct();
    });
  }
  async _fetchProductOrders(filters) {
    const resp = await this.props.app.license.productOrder.searchProductOrders(filters);
    if (resp.statusCode != 200 || !resp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    return resp;
  }
  async _fetchUser(externalID, needsUserFetch) {
    if (needsUserFetch) {
      const resp = await this.props.app.api.user.getByID(externalID);
      if (resp.statusCode != 200 || !resp.body) {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      }
      return resp;
    } else {
      return { statusCode: 200, body: this.props.app.sharedCache().getProgramUser() };
    }
  }
  async _fetchOrganization(orgID) {
    const resp = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);
    if (resp.statusCode != 200 || !resp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    return resp;
  }
  async _searchProductOrders() {
    const { from, to, status, id } = this.state;
    const filters = { filters: { externalID: id, dateFrom: from, dateTo: to, status } };
    const resp = await this.props.app.license.productOrder.searchProductOrders(filters);
    this.setState({ isLoading: true });
    if (resp.statusCode === 200) {
      this.setState({ orders: resp.body.productOrders });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }
  async _downloadOrder(externalID, orderID) {
    this.startLoading();
    const resp = await this.props.app.license.productOrder.getProductOrderReceipt(externalID, orderID);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${orderID}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.stopLoading();
  }
}
