import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Row, Col, Tooltip, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonSessionInvitationDrawer from '../Drawers/CommonSessionInvitationDrawer';
//props are: session, app, onDelete, onInvite, failures
export default class CommonSessionInvitationsForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  handleInviteStudents() {
    this.invitationDrawer.show();
  }
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
      },
      {
        title: 'Is Reserved Seat?',
        key: 'isReservedSeat',
        width: '15%',
        render: (props) => (props.isReservedSeat ? 'Reserved' : 'Not reserved'),
        sorter: (a, b) =>
          (a.isReservedSeat ? 'Reserved' : 'Not reserved').localeCompare(
            b.isReservedSeat ? 'Reserved' : 'Not reserved'
          ),
        sortOrder: sortedInfo.columnKey === 'isReservedSeat' && sortedInfo.order,
      },
      {
        title: '# of Uses',
        dataIndex: 'useCount',
        key: 'useCount',
        sorter: (a, b) => a.useCount.localeCompare(b.useCount),
        sortOrder: sortedInfo.columnKey === 'useCount' && sortedInfo.order,
      },
      {
        title: 'Created on',
        key: 'createdOn',
        width: '15%',
        render: (props) => (props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete this invitation from this session?`}
                  onConfirm={this.props.onDelete.bind(this, props.createdOn)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];

    const props = {
      rowKey: 'invitationCode',
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No invitations assigned to this session!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    const invitations = this.props.session.invitations;

    return (
      <>
        <Row type="flex" justify="end">
          <Col>
            <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleInviteStudents}>
              {' '}
              Invite Students{' '}
            </Button>
          </Col>
        </Row>
        <Table className="commonInvitationsTable" columns={columns} dataSource={invitations || []} {...props} />
        <CommonSessionInvitationDrawer
          {...Utils.propagateRef(this, 'invitationDrawer')}
          session={this.props.session}
          app={this.props.app}
          onInvite={this.props.onInvite}
          failures={this.props.failures}
        />
      </>
    );
  }
}
