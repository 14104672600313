import React from 'react';

import '../../assets/stylesheets/WhiteBox.less';

export default function WhiteBox({ children, isSecondary, style, title }) {
  return (
    <div className={`whiteBox ${isSecondary ? 'secondary' : ''}`} {...(style ? { style } : {})}>
      {title && (
        <header>
          <strong>{title}</strong>
        </header>
      )}
      {children}
    </div>
  );
}
