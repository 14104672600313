import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';
import { Result, Row, Divider, Button } from 'antd';
//
//props are: error, isLoading, onNext
export default class CommonProductPurchaseModalStep_ResultView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    let content = {
      status: 'success',
      title: this.props.isInvoice ? 'Order Submitted' : 'Order Completed',
      subTitle: this.props.isInvoice
        ? `Your order was successfully submitted. Please wait for ${this.props.app.themeManager.theme.config.applicationName} administrator to review and process.`
        : 'Your purchase was completed. Check your inbox for the payment receipt.',
    };
    if (this.props.error)
      content = {
        status: 'error',
        title: 'Order error',
        subTitle: this._extractError(),
      };
    return (
      <>
        <div className={this.props.error ? 'ant-result ant-result-error' : 'ant-result ant-result-success'}>
          {this.props.error ? (
            <div className="ant-result-icon">
              <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="close-circle"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                </svg>
              </span>
            </div>
          ) : (
            <div className="ant-result-icon">
              <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="check-circle"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" />
                </svg>
              </span>
            </div>
          )}
          <h1 style={{ fontSize: 18, textAlign: 'center' }}>{content.subTitle}</h1>
          <div className="ant-result-title">{content.title}</div>
        </div>
        {this._renderButtonsFooter()}
      </>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        {this.props.error && (
          <Button
            className="purchaseModalCancelButton"
            key="back"
            disabled={this.props.isLoading}
            onClick={this.props.onCancel}
          >
            {' '}
            Cancel{' '}
          </Button>
        )}
        <Button
          form="summary"
          htmlType="submit"
          key="submit"
          type="primary"
          loading={this.props.isLoading}
          disabled={this.props.isLoading}
          onClick={this.props.onNext}
          className="purchaseModalConfirmationButton"
        >
          {' '}
          {this.props.error ? 'Retry' : 'OK'}{' '}
        </Button>
      </Row>
    );
  }
  _extractError() {
    if (this.props.error && this.props.error.err) return this.props.error.err;
    //40x codes
    else if (this.props.error && this.props.error.message) return this.props.error.message;
    return JSON.stringify(this.props.error);
  }
}
