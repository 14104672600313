import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Table, Tag } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import TabViewController from '../../commonComponents/TabViewController';
import CommonWeeklySessionsView from '../../commonComponents/Calendar/CommonWeeklySessionsView';
import CommonMonthlySessionsView from '../../commonComponents/Calendar/CommonMonthlySessionsView';
import moment from 'moment';
//
import CommonSessionsDateRangeSelector from '../../commonComponents/CommonSessionsDateRangeSelector';
//
export default class AdminInstructorCalendarView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      from: null,
      to: null,
      dateType: 1,
      isLoading: false,
      sessions: [],
      calendars: [],
      sortedInfo: { columnKey: 'internalName', order: 'ascend' },
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    this.tabs = [
      {
        component: () => {
          return (
            <CommonWeeklySessionsView
              app={this.props.app}
              sessions={this._getFilteredSessions()}
              calendars={this.state.calendars}
              from={this.state.from ? moment(this.state.from) : moment()}
              to={this.state.to ? moment(this.state.to) : moment()}
              onSelect={this.handleEditSession.bind(this)}
            />
          );
        },
      },
      {
        component: () => {
          return (
            <CommonMonthlySessionsView
              app={this.props.app}
              sessions={this._getFilteredSessions()}
              calendars={this.state.calendars}
              from={this.state.from ? moment(this.state.from) : moment()}
              to={this.state.to ? moment(this.state.to) : moment()}
              onSelect={this.handleEditSession.bind(this)}
            />
          );
        },
      },
    ];
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  handleEditSession(sessionID) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.session, null, sessionID);
  }
  handleDateChange(from, to, dateType) {
    this.setState({ from, to, dateType }, this._fetchData);
  }
  async _fetchData() {
    this.setState({ isLoading: true });
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.setState({ isLoading: false });
  }
  async _loadAll() {
    const userID = this.props.userID;
    const resp = await Promise.all([
      this.props.app.classroom.instructor.getInstructorEvents(userID, {
        startTime: this.state.from,
        endTime: this.state.to,
        expandSessions: true,
      }),
    ]);

    if (!(resp[0].statusCode === 200 && resp[0].body)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }

    const events = resp[0].body.instructorCalendarEvents
      .map((event) => ({
        ...event,
        isAvailabilityEvent: !event.sessionID,
        session: resp[0].body.sessions?.find((session) => session?.id === event.sessionID),
      }))
      .filter((event) => event.isAvailabilityEvent);

    return { sessions: resp[0].body.sessions || [], calendars: events || [] };
  }

  _getFilteredSessions() {
    return this.state.sessions.filter((s) => {
      return s !== null;
    });
  }
  //UI
  render() {
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonSessionsDateRangeSelector
          anyRangeMode={false}
          app={this.props.app}
          onDateChange={this.handleDateChange.bind(this)}
          onTimeEntryAdded={this._fetchData}
          onTimeEntryEdited={this._fetchData}
          {...Utils.propagateRef(this, 'sessionsDateRangeSelector')}
        />
        <WhiteBox>
          <TabViewController
            selectedIndex={(this.state.dateType || 1) - 1}
            config={this.tabs}
            parent={this}
            app={this.props.app}
          />
        </WhiteBox>
        {this._renderSessionsTable()}
      </>
    );
  }

  _renderSessionsTable() {
    const courses = this.props.app.sharedCache().getAllUniqueCourses();
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Internal Name',
        key: 'internalName',
        dataIndex: 'internalName',
        sorter: (a, b) => a.internalName.localeCompare(b.internalName),
        sortOrder: sortedInfo.columnKey === 'internalName' && sortedInfo.order,
      },
      {
        title: 'Course',
        key: 'courseID',
        sorter: (a, b) =>
          (courses.find((c) => c.id == a.courseID)?.description || '').localeCompare(
            courses.find((c) => c.id == b.courseID)?.description || ''
          ),
        sortOrder: sortedInfo.columnKey === 'courseID' && sortedInfo.order,
        render: (props) => courses.find((c) => c.id == props.courseID)?.description || '',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (props) => Globals.getTemplateTypeIcon(props, true),
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.state.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'state' && sortedInfo.order,
        render: (props) => {
          return <Tag color={Globals.getColorBySessionState(props)}>{Globals.getTextBySessionState(props)}</Tag>;
        },
      },
      {
        title: 'Dates',
        key: 'dates',
        render: (props) => {
          if (props.startDate && props.startDate.length > 0)
            return props.startDate.map((s) => Utils.getDateAndTimeOnUIFormatByTimestamp(s)).join(', ');
          else return '-';
        },
        sorter: (a, b) => (a?.startDate?.[0] || 0) - (b?.startDate?.[0] || 0),
        sortOrder: sortedInfo.columnKey === 'dates' && sortedInfo.order,
      },
      {
        title: 'Students',
        dataIndex: 'enrolmentCount',
        key: 'enrolmentCount',
        sorter: (a, b) => (a.enrolmentCount || 0) - (b.enrolmentCount || 0),
        sortOrder: sortedInfo.columnKey === 'enrolmentCount' && sortedInfo.order,
        render: (props) => `${props || 0} Students`,
      },
      {
        title: 'Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
        sorter: (a, b) => (a.capacity || 0) - (b.capacity || 0),
        sortOrder: sortedInfo.columnKey === 'capacity' && sortedInfo.order,
        render: (props) => (props < 0 ? 'Unlimited' : props),
      },
      ...(this.props.app.isSysAdmin()
        ? [
            {
              title: 'Online Setup',
              key: 'onlineSetup',
              render: (props) => {
                return props.onlineCourseID && props.onlineGradeID
                  ? 'Configured'
                  : props.onlineSetupID
                    ? 'Configuring'
                    : 'Not Configured';
              },
              sorter: (a, b) => a.createdOn - b.createdOn,
              sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
            },
          ]
        : []),
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange.bind(this),
      locale: { emptyText: 'No sessions found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Layout.Content>
        <Table
          className="adminSessionsTable"
          columns={columns}
          dataSource={this._getFilteredSessions()}
          {...props}
          style={{ marginBottom: 50 }}
        />
      </Layout.Content>
    );
  }
}
