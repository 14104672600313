import React from 'react';
import autoBind from 'react-autobind';
import { Popover, Row, Col, Result, Input, Button, Divider, Table } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
//props are: app, onSelect, regions, instructorRegions
export default class CommonInstructorRegionPopover extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, searchTerm: '' };
  }
  //Actions
  //Search actions
  handleChange(e) {
    this.setState({ searchTerm: e.target.value });
  }
  //Table actions
  handleSelectItem(item) {
    this.popover.onClick(); //hacky way of dismissing non controller popovers.. Blame Gabriel :p
    if (this.props.onSelect) this.props.onSelect(item);
  }
  //UI
  render() {
    const columns = [
      { title: 'Region', key: 'name', dataIndex: 'name' },
      {
        title: '',
        key: 'select',
        align: 'right',
        render: (item) => (
          <Button
            type="primary"
            size="small"
            onClick={this.handleSelectItem.bind(this, item)}
            disabled={(this.props.instructorRegions || []).find((i) => i.regionID == item.id)}
          >
            Add to Instructor
          </Button>
        ),
      },
    ];
    const props = {
      rowKey: 'id',
      locale: { emptyText: 'No regions found!' },
      pagination: { pageSize: 8, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom' },
    };
    return (
      <Popover
        title="Filter and select a region for this instructor"
        trigger={['click']}
        placement="bottomRight"
        {...Utils.propagateRef(this, 'popover')}
        content={
          <div style={{ minWidth: '600px', maxWidth: '1200px' }}>
            <Row>
              <Col span={24}>
                <Input
                  prefix={<SearchOutlined />}
                  size="large"
                  placeholder="Filter..."
                  value={this.state.searchTerm}
                  onChange={this.handleChange}
                  autoFocus
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                {this.props.regions && (
                  <Table
                    columns={columns}
                    dataSource={this.props.regions.filter((c) => c.name.includes(this.state.searchTerm || ''))}
                    {...props}
                  />
                )}
              </Col>
            </Row>
          </div>
        }
      >
        {this.props.children}
      </Popover>
    );
  }
}
