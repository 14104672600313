import React from 'react';
import CommonCertificationCard from '../commonComponents/CommonCertificationCard';
import '../../assets/stylesheets/CommonCertificationProcess.less';

export default class CommonCertificationCollectionView extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <div className="wrapper">
        <div className="certification-container">
          {this.props.certifications.map((certification) =>
            certification.ui?.hideFromUI ? null : (
              <CommonCertificationCard
                app={this.props.app}
                customUser={this.props.customUser}
                handleStartCertification={this.props.handleStartCertification}
                key={certification.id}
                certification={certification}
              />
            )
          )}
        </div>
      </div>
    );
  }
}
