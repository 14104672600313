import React from 'react';
import autoBind from 'react-autobind';
import { Form, Row, Col, Switch, Button, Popover, message } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import Utils from '../../../components/Utils';
import WhiteBox from '../WhiteBox';
//
export default class CommonNotificationsSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  resetFields() {
    this.form.resetFields();
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({ ...data });
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return formData;
  }

  //Life cycle
  componentDidUpdate(prevProps) {
    const data = this.props.data;
    if (prevProps.data != data && data) this.setFieldsValue(data);
  }

  componentDidMount() {
    const data = this.props.data;
    this.setFieldsValue(data);
  }

  //Actions
  async handleSave() {
    const data = await this.form.validateFields();
    if (!data) return;
    const orgID = this.props.orgID;
    const body = {
      orgMgrNotifications: {
        licenseConsumed: data.licenseConsumed,
        courseCompletion: data.courseCompletion,
        invitationExpired: data.invitationExpired,
        courseFailure: data.courseFailure,
        courseExpiration: data.courseExpiration,
      },
    };
    this._updateSettings(orgID, body);
  }

  //UI
  render() {
    return (
      <WhiteBox title="Notifications" style={{ height: 300 }}>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row gutter={16} justify="space-between">
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                <Button type="primary" onClick={this.handleSave}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name="licenseConsumed" valuePropName="checked" label="License consumed">
                  <Switch />
                </Form.Item>
                <Popover
                  title="License consumed"
                  content="When this setting is ON, managers  of the organization will receive email notifications when students consume an org assigned licenses."
                  placement="topRight"
                >
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10, marginBottom: 52 }} />
                </Popover>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name="invitationExpired" valuePropName="checked" label="Invitation expired">
                  <Switch />
                </Form.Item>
                <Popover
                  title="Invitation expired"
                  content="When this setting is ON, managers  of the organization will receive email notifications when invitations sent to students expire without being accepted."
                  placement="topRight"
                >
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10, marginBottom: 52 }} />
                </Popover>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name="courseCompletion" valuePropName="checked" label="Student course completion">
                  <Switch />
                </Form.Item>
                <Popover
                  title="Student course completion"
                  content="When this setting is ON, managers  of the organization will receive email notifications when students complete a course or certification."
                  placement="topRight"
                >
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10, marginBottom: 52 }} />
                </Popover>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name="courseFailure" valuePropName="checked" label="Student failed course">
                  <Switch />
                </Form.Item>
                <Popover
                  title="Student failed course"
                  content="When this setting is ON, managers  of the organization will receive email notifications when students fail a course."
                  placement="topRight"
                >
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10, marginBottom: 52 }} />
                </Popover>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ display: 'flex' }}>
                <Form.Item name="courseExpiration" valuePropName="checked" label="Student course expiration">
                  <Switch />
                </Form.Item>
                <Popover
                  title="Student course expiration"
                  content="When this setting is ON, managers  of the organization will receive email notifications  sent to students concerning the expiration of a course or certification."
                  placement="topRight"
                >
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10, marginBottom: 52 }} />
                </Popover>
              </div>
            </Col>
          </Row>
        </Form>
      </WhiteBox>
    );
  }

  //API call
  async _updateSettings(orgID, body) {
    const resp = await this.props.app.organization.organizationApp.updateOrganizationAppSettings(orgID, body);
    if (resp.statusCode == 200) {
      message.success('Updated with success!');
      return true;
    }
    this.props.app.alertController.showAPIErrorAlert(null, resp);
    return false;
  }
}
