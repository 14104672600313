import React from 'react';
import autoBind from 'react-autobind';
import { Table, DatePicker, message, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

class CommonSessionDatesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { dates: [] };
  }
  handleChangeDate = (value, field, id) => {
    if (field === 'sessionDate') {
      const dateAlreadyAdded = this.state.dates.find((date) => date.sessionDate?.isSame(value, 'day'));

      if (dateAlreadyAdded) {
        message.error('This date has already been added to the list.');
        return;
      }
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        dates: prevState.dates.map((date) => (date.id === id ? { ...date, [field]: value } : date)),
      }),
      () => {
        this.props.onDateSelection(
          this.state.dates.filter((date) => date.sessionDate !== null).map((date) => date.sessionDate.valueOf())
        );
      }
    );
  };

  handleAddDate = () => {
    if (this.state.dates.length < 10) {
      const newDate = {
        id: new Date().getTime(),
        sessionDate: null,
      };

      this.setState((prevState) => {
        const updatedDates = [...prevState.dates, newDate];

        const newSessionDates = updatedDates
          .filter((date) => date.sessionDate !== null)
          .map((date) => date.sessionDate.valueOf());

        this.props.onDateSelection(newSessionDates);

        return { dates: updatedDates };
      });
    } else {
      message.warning('You have reached the limit of 10 dates.');
    }
  };

  handleDeleteDate = (id) => {
    this.setState(
      (prevState) => {
        const updatedDates = prevState.dates.filter((date) => date.id !== id);
        const formattedDates = updatedDates.map((date) => {
          if (date.sessionDate) {
            return date.sessionDate.valueOf();
          }
          return null;
        });
        return { dates: updatedDates, formattedDates };
      },
      () => {
        this.props.onDateSelection(this.state.formattedDates);
      }
    );
  };

  render() {
    const columns = [
      {
        title: 'Date',
        key: 'date',
        render: (props) => {
          const date = this.state.dates.find((date) => date.id === props.id) || {};
          return (
            <>
              <DatePicker
                placeholder="Date"
                onChange={(value) => this.handleChangeDate(value, 'sessionDate', props.id)}
                value={date.sessionDate || null}
                format="YYYY-MM-DD"
                style={{ marginRight: '10px' }}
              />{' '}
              <Button icon={<DeleteOutlined />} shape="circle" onClick={() => this.handleDeleteDate(props.id)} />
            </>
          );
        },
      },
    ];

    return (
      <>
        {this.state.dates.length > 0 && (
          <Table
            style={{ width: '460px' }}
            className="sessionDatesTable"
            columns={columns}
            dataSource={this.state.dates}
            rowKey="id"
            pagination={{
              pageSize: 5,
              hideOnSinglePage: true,
              showSizeChanger: false,
              position: ['bottomCenter'],
            }}
            locale={{ emptyText: 'No dates added to this session yet!' }}
          />
        )}
        <Button type="primary" onClick={this.handleAddDate}>
          Add date
        </Button>
      </>
    );
  }
}

export default CommonSessionDatesTable;
