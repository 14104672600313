import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Row, Col, Button } from 'antd';
//
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import CommonSessionDraftForm from '../../commonComponents/Forms/CommonSessionDraftForm';
import CommonTemplateSelectionModal from '../../commonComponents/Modals/CommonTemplateSelectionModal';
import WhiteBox from '../../commonComponents/WhiteBox';
//
const RequestMask = { TEMPLATES: 2, VENUES: 4, CITIES: 8, ALL: 2 | 4 | 8 };
//
export default class AdminSessionCreateView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      venues: null,
      courses: null,
      templates: [],
      templateID: null,
      template: null,
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    await this.fetchData(RequestMask.ALL);
    this.selectionModal.show();
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask);
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
  async handleCreateSession() {
    const formData = await this.sessionForm.validateFields();
    if (!formData) return;
    this.startLoading();
    await this._createSession(formData);
    this.stopLoading();
  }
  handleTemplateSelection(templateID) {
    const template = (this.state.templates || []).find((t) => t.id == templateID);
    this.setState({ templateID, template }, () => {
      this.sessionForm.setFieldsValueFromTemplate(template);
    });
  }

  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader
            className="pageHeader"
            title={'Create Session'}
            onBack={() => this.props.app.urlManager.pushBack()}
          />
          <WhiteBox>
            <CommonTemplateSelectionModal
              app={this.props.app}
              templates={this.state.templates}
              courses={this.state.courses}
              onSelect={this.handleTemplateSelection}
              {...Utils.propagateRef(this, 'selectionModal')}
            />
            <Layout.Content>
              <Row type="flex" justify="end">
                {' '}
                <Col>
                  {' '}
                  <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateSession}>
                    {' '}
                    Create Session
                  </Button>{' '}
                </Col>{' '}
              </Row>
              <CommonSessionDraftForm
                {...Utils.propagateRef(this, 'sessionForm')}
                app={this.props.app}
                courses={this.state.courses}
                venues={this.state.venues}
              />
            </Layout.Content>
          </WhiteBox>
        </Layout.Content>
      </>
    );
  }

  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.TEMPLATES) === RequestMask.TEMPLATES
        ? this.props.app.classroom.template.getTemplates()
        : null,
      (mask & RequestMask.VENUES) === RequestMask.VENUES ? this.props.app.sharedCache().getVenues() : null,
      (mask & RequestMask.CITIES) === RequestMask.CITIES ? this.props.app.sharedCache().getCities() : null,
    ]);
    if (
      (mask & RequestMask.TEMPLATES) === RequestMask.TEMPLATES &&
      !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.templates)
    ) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if ((mask & RequestMask.VENUES) === RequestMask.VENUES && !resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load venues!');
      return false;
    }
    if ((mask & RequestMask.CITIES) === RequestMask.CITIES && !resp[2]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    return {
      ...((mask & RequestMask.TEMPLATES) === RequestMask.TEMPLATES ? { templates: resp[0].body.templates } : {}),
      ...((mask & RequestMask.VENUES) === RequestMask.VENUES ? { venues: resp[1] } : {}),
      courses: this.props.app.sharedCache().getAllUniqueCourses(),
    };
  }
  async _createSession(sessionData) {
    if (!this._isMounted) return;
    const resp = await this.props.app.classroom.session.createSession({
      ...sessionData,
      capacity: sessionData.capacity || -1, //auto-unlimited if not set
      isPrivate: !!sessionData.isPrivate,
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Session created with success!');
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.session, null, resp.body.id);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
