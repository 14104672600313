import React from 'react';
import autoBind from 'react-autobind';

import Utils from '../../../../../components/Utils';
import CommonSessionResultModal from '../../../../commonComponents/Modals/CommonSessionResultModal';
import CommonCoursePurchaseModal from '../../../../commonComponents/Modals/CommonCoursePurchaseModal';
import CommonCourseLicenseRedeemModal from '../../../../commonComponents/Modals/CommonCourseLicenseRedeemModal';
import CommonCourseScheduleModal from '../../../../commonComponents/Modals/CommonCourseScheduleModal';
import CommonProductPurchaseModal from '../../../../commonComponents/Modals/ProductPurchaseModal/CommonProductPurchaseModal';

export default class CourseTabViewModals extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isPurchaseModalVisible: false,
      isProductPurchaseModalVisible: false,
      currentCourse: null,
      isActivation: false,
      requestProductPurchaseModal: null,
    };
  }
  componentDidUpdate() {
    if (this.state.requestProductPurchaseModal?.productID) {
      this.openProductPurchaseModal(
        this.state.requestProductPurchaseModal?.productID,
        this.state.requestProductPurchaseModal?.sessionID
      );
    }
  }
  /***** Public *****/
  openPurchaseLicenseModal(courseSpecs, sessionID, requestProductPurchaseModal) {
    if (!this.state.isPurchaseModalVisible) this.purchaseModal.loadModalInfoWithCourseSpec(courseSpecs);
    if (sessionID) this.purchaseModal.handleSessionSelection(sessionID);
    this.setState(
      (prevState) => ({ isPurchaseModalVisible: !prevState.isPurchaseModalVisible, requestProductPurchaseModal }),
      () => {
        if (!this.state.isPurchaseModalVisible) this.props.onUpdate();
      }
    );
  }
  openAddResultModal(currentCourse) {
    this.setState({ currentCourse }, () => {
      this.resultModal.show(currentCourse.sessionID, currentCourse.courseID, this.props.user);
    });
  }
  openRedeemModal(currentCourse, isActivation) {
    this.setState({ currentCourse, isActivation }, () => this.redeemModal.show());
  }
  openScheduleModal(currentCourse) {
    this.setState({ currentCourse }, () => this.scheduleModal.show());
  }
  openProductPurchaseModal(productID, sessionID) {
    if (sessionID) this.productPurchaseModal.setSessionID(sessionID);
    if (!this.state.isVisibleProductPurchase) this.productPurchaseModal.loadModalInfo(productID);
    this.setState(
      { isVisibleProductPurchase: !this.state.isVisibleProductPurchase, requestProductPurchaseModal: null },
      () => {
        if (!this.state.isVisibleProductPurchase) this.props.onUpdate();
      }
    );
  }
  /***** Actions *****/
  handleChangeResultModal(isCancel) {
    this.setState({ currentCourse: null });
    if (!isCancel) this.props.onUpdate();
  }
  handleChangeRedeemModal(isCancel, requestProductPurchaseModal) {
    this.setState({
      requestProductPurchaseModal: !isCancel ? requestProductPurchaseModal : null,
      currentCourse: null,
      isActivation: false,
    });
    if (!isCancel) this.props.onUpdate();
  }
  handleChangeScheduleModal(isCancel) {
    this.setState({ currentCourse: null });
    if (!isCancel) this.props.onUpdate();
  }

  //UI
  render() {
    return (
      <>
        <CommonCourseLicenseRedeemModal
          {...Utils.propagateRef(this, 'redeemModal')}
          app={this.props.app}
          isActivation={this.state.isActivation}
          course={this.state.currentCourse}
          onChange={this.handleChangeRedeemModal}
          user={this.props.user}
        />
        <CommonProductPurchaseModal
          {...Utils.propagateRef(this, 'productPurchaseModal')}
          app={this.props.app}
          isVisible={this.state.isVisibleProductPurchase}
          onChange={this.openProductPurchaseModal}
          user={this.props.user}
          onRequiresAttention={(modal) => {
            this.productPurchaseModal = modal;
            this.openProductPurchaseModal();
          }}
        />
        <CommonCoursePurchaseModal
          {...Utils.propagateRef(this, 'purchaseModal')}
          user={this.props.user}
          certification={this.props.certificationProcess}
          isVisible={this.state.isPurchaseModalVisible}
          autoRedeem
          fixedQuantity
          invitationCode={this.props.invitationCode}
          onChange={this.openPurchaseLicenseModal}
          app={this.props.app}
          onRequiresAttention={(modal) => {
            this.purchaseModal = modal;
            this.openPurchaseLicenseModal();
          }}
        />
        <CommonSessionResultModal
          {...Utils.propagateRef(this, 'resultModal')}
          app={this.props.app}
          certification={this.props.certificationProcess}
          course={this.state.currentCourse}
          onChange={this.handleChangeResultModal}
        />
        <CommonCourseScheduleModal
          {...Utils.propagateRef(this, 'scheduleModal')}
          app={this.props.app}
          course={this.state.currentCourse}
          onChange={this.handleChangeScheduleModal}
          user={this.props.user}
        />
      </>
    );
  }
}
