import React from 'react';
import autoBind from 'react-autobind';
import { Form, Divider, Col, Row, InputNumber, Button, message, Steps, Spin, Modal, Result, Popover } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLicenseOrderPreview from '../../commonComponents/CommonLicenseOrderPreview';
import CommonPopoversContent from '../../commonComponents/Forms/_CommonPopoversContent';
//
import Utils from '../../../components/Utils';
//
const styleHidden = { style: { display: 'none' } };
const STEPS = { INPUT: 0, PROCESSING: 1, SUCCESS: 2 };
//props are: app, onChange, isVisible
export default class CommonLicenseRefundModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = {
      isLoading: false,
      stepper: { current: STEPS.INPUT, status: 'waiting', error: null },
      licenseOrderObj: {},
      refundAmount: 0,
      originalValue: 0,
      refundTax: 0,
      refundTotal: 0,
      product: {},
    };
  }
  //
  loadModalInfo(licOrder, cancelPolicy) {
    //Calculate transaction values
    const refundAmount = licOrder.value;
    const productSpecs = this.props.app.sharedCache().getProductByID(licOrder.productID);
    this.setState(
      {
        licenseOrderObj: licOrder,
        product: productSpecs,
        originalValue: refundAmount,
        refundAmount,
        cancelPolicy,
        disableAmount: !!cancelPolicy,
      },
      () => {
        const amount = this.handleChange();
        this.form.setFieldsValue({ refundAmount: amount });
      }
    );
  }

  //Actions
  handleCancel() {
    this.props.onChange();
  }
  handleAfterClose() {
    this.setState({
      isLoading: false,
      stepper: { current: STEPS.INPUT, status: 'waiting', error: null },
      licenseOrderObj: {},
      refundAmount: 0,
      originalValue: 0,
      refundTax: 0,
      refundTotal: 0,
      product: {},
    });
  }
  async handleSubmit() {
    if (this.state.stepper.current == STEPS.INPUT) {
      const resp = await this.form.validateFields(['refundAmount']);
      if (resp) this._refundOrder({ ...resp, refundTotal: this.state.refundTotal });
    } else if (this.state.stepper.current == STEPS.PROCESSING) {
      //error! - try again
      this.form.resetFields();
      this.setState({ ...this.state, isLoading: false, stepper: { current: STEPS.INPUT, status: 'waiting' } }, () => {
        this.loadModalInfo(this.state.licenseOrderObj);
      });
    } else if (this.state.stepper.current == STEPS.SUCCESS) {
      //completed! - done
      this.props.onChange();
    }
  }
  //form change
  handleChange(value, id) {
    let amount = this.state.refundAmount;
    //Check for the amount to be set
    if (id == 'amount') amount = value;
    //Recalculate transaction value
    let refundAmount = Utils.safelyFixCurrency(amount);
    const taxRate = this._getTaxPercentage();
    let refundTax = Utils.safelyFixCurrency(refundAmount * taxRate); //calculate tax amount
    if (isNaN(refundTax)) refundTax = 0;
    let refundTotal = Utils.safelyFixCurrency(refundAmount + refundTax); //Calculate total amount (amount + taxes)
    if (isNaN(refundTotal)) refundTotal = 0;
    //keep it reflected on state for security purposes (element values are easier to change)
    this.setState({ refundAmount: refundAmount, refundTax, refundTotal });
    return refundAmount;
  }
  render() {
    return (
      <Modal
        maskClosable={false}
        title={'Refund License Order'}
        afterClose={this.handleAfterClose}
        open={this.props.isVisible}
        confirmLoading={this.state.isLoading}
        closable={false}
        footer={null}
      >
        <Steps
          {...this.state.stepper}
          size="small"
          direction={isMobile ? 'vertical' : 'horizontal'}
          className="paymentSteps"
        >
          <Steps.Step title="Refund Info" />
          <Steps.Step title="Processing Refund" />
          <Steps.Step title="Completed" />
        </Steps>
        {this._renderInputForm()}
        {this._renderFinalStepsView()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderInputForm() {
    const visible = this.state.stepper.current == STEPS.INPUT;
    return (
      <Form {...Utils.propagateRef(this, 'form')} {...(visible ? {} : styleHidden)}>
        <CommonLicenseOrderPreview
          app={this.props.app}
          licenseOrder={this.state.licenseOrderObj}
          product={this.state.product}
        />
        <Divider orientation="left">Refund Information</Divider>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 8}>
            <Form.Item
              label={
                <>
                  Refund Value
                  {this.state.cancelPolicy && (
                    <Popover
                      title="Refund Value"
                      content={CommonPopoversContent.renderCancelPolicyDescriptionPopoverContent(
                        this.state.cancelPolicy
                      )}
                    >
                      <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                    </Popover>
                  )}
                </>
              }
              name="refundAmount"
              rules={[
                { required: true, message: 'Please, inform the refund amount!' },
                { type: 'number', min: 0, message: 'Refund amount must be greater than 0!' },
                {
                  type: 'number',
                  max: this.state.licenseOrderObj.value,
                  message: 'Refund amount can not exceed original transaction value!',
                },
              ]}
              initialValue={this.state.refundAmount}
            >
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled={this.state.disableAmount}
                formatter={Utils.defaultCurrenyInputFormatter}
                min={0}
                max={this.state.licenseOrderObj ? this.state.licenseOrderObj.value : 0}
                parser={Utils.defaultCurrentInputParser}
                onChange={(val) => this.handleChange(val, 'amount')}
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label={`Refund Tax`}>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                value={this.state.refundTax}
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 7} offset={isMobile ? 0 : 1}>
            <Form.Item label={`Total Refund Amount`}>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                value={this.state.refundTotal}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderFinalStepsView() {
    const visible = this.state.stepper.current != STEPS.INPUT;
    //if error on state, show error, if last step, success!
    let result = {};
    if (this.state.stepper.error) {
      result = { status: 'error', title: `Refund error.`, subTitle: this.state.stepper.error };
    } else if (this.state.stepper.current == STEPS.SUCCESS) {
      result = {
        status: 'success',
        title: `Refund completed.`,
        subTitle: `Refund completed.\n Check your inbox for the refund receipt.`,
      };
    } else {
      result = { status: 'info', title: 'Processing.', subTitle: `Processing refund.`, extra: <Spin spinning> </Spin> };
    }
    return <Result {...(visible ? {} : styleHidden)} {...result} />;
  }
  _renderButtonsFooter() {
    let title = 'Done';
    if (this.state.stepper.current == STEPS.INPUT) title = `Submit Refund`;
    else if (this.state.stepper.current == STEPS.PROCESSING) {
      if (this.state.stepper.error) title = 'Try again!';
      else title = '...'; //probably it's loading
    }
    if (!this.props.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button
          disabled={this.state.isLoading || this.state.stepper.current == STEPS.SUCCESS}
          className="purchaseModalCancelButton"
          key="back"
          onClick={this.handleCancel}
        >
          {' '}
          Cancel{' '}
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={this.state.isLoading}
          className="purchaseModalConfirmationButton"
          onClick={this.handleSubmit}
          disabled={this.state.isLoading}
        >
          {' '}
          {title}{' '}
        </Button>
      </Row>
    );
  }

  /* private methods */
  _getTaxPercentage() {
    const selectedObject = this.props.app.sharedCache().getProductRelatedObjectByProductID(this.state.product.id);
    if (selectedObject.isApplication && selectedObject.application?.taxRate != undefined)
      return selectedObject.application?.taxRate;
    if (selectedObject.isRenewal && selectedObject.renewal?.taxRate != undefined)
      return selectedObject.renewal?.taxRate;
    return this.props.app.sharedCache().getTenantConfig().taxRate;
  }
  async _refundOrder(data) {
    this.setState({ stepper: { current: STEPS.PROCESSING, status: 'process', error: null }, isLoading: true });
    const provider = this.props.app.license.order.getProviderFromProduct(this.state.product);
    const t = this.props.app.sharedCache().getTenantConfig();
    const refundResp = await this.props.app.license.order.refundOrder(
      {
        user: { id: this.state.licenseOrderObj.externalID },
        orderID: this.state.licenseOrderObj.id,
        refundAmount: data.refundAmount,
        refundTotal: data.refundTotal,
        allowConsumedLicRefund: this.state.cancelPolicy ? true : false,
      },
      provider
    );
    if (!this._isMounted) return;
    console.debug('Refund resp:', refundResp, data);
    if (refundResp.statusCode == 200 && refundResp.body) {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.SUCCESS, status: 'finish', error: null },
        isLoading: false,
      });
      message.success(`Refund Completed!`);
      this.props.onChange(null, null, true);
    } else {
      this.setState({
        stepper: {
          ...this.state.stepper,
          current: STEPS.PROCESSING,
          status: 'error',
          error: refundResp.error || refundResp.body.err,
        },
        isLoading: false,
      });
      this.props.app.alertController.showAPIErrorAlert(null, refundResp);
    }
  }
}
