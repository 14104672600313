import React from 'react';
import autoBind from 'react-autobind';
import {
  PageHeader,
  message,
  Divider,
  Layout,
  Select,
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Popconfirm,
  Tag,
  Modal,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  BookOutlined,
  CopyOutlined,
  CheckOutlined,
  ExportOutlined,
  BookFilled,
} from '@ant-design/icons';
import moment from 'moment';
import scrollIntoView from 'scroll-into-view';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import TabViewController from '../../commonComponents/TabViewController';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CommonDailySessionView from '../../commonComponents/Calendar/CommonDailySessionView';
import CommonWeeklySessionsView from '../../commonComponents/Calendar/CommonWeeklySessionsView';
import CommonMonthlySessionsView from '../../commonComponents/Calendar/CommonMonthlySessionsView';
import CommonSessionsDateRangeSelector from '../../commonComponents/CommonSessionsDateRangeSelector';
import CommonSessionDatesTable from '../../commonComponents/CommonSessionDatesTable';
import CommonCertificationViewUserHeader from '../../commonSubviews/CommonCertificationView/CommonCertificationViewUserHeader';

//
export default class AdminSessionsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sessionIDCopied: false,
      dateType: parseInt(localStorage.getItem('preferredDateType'), 10) || 1,
      from: null,
      to: null,
      sessions: [],
      calendars: [],
      cities: [],
      courses: [],
      sortedInfo: { columnKey: 'internalName', order: 'ascend' },
      ...this._getInitialState(),
      newSessionDates: [],
    };
  }
  async componentDidMount() {
    super.componentDidMount();
    //Load calendar view if needed
    if (this.homepageMode) {
      //Build tab children
      this.tabChildren = [
        {
          component: () => {
            return (
              <CommonWeeklySessionsView
                app={this.props.app}
                sessions={this._getFilteredSessions()}
                calendars={this.state.calendars}
                from={this.state.from ? moment(this.state.from) : moment()}
                to={this.state.to ? moment(this.state.to) : moment()}
                onSelect={this.handleEditSession.bind(this)}
              />
            );
          },
        },
        {
          component: () => {
            return (
              <CommonMonthlySessionsView
                app={this.props.app}
                sessions={this._getFilteredSessions()}
                calendars={this.state.calendars}
                from={this.state.from ? moment(this.state.from) : moment()}
                to={this.state.to ? moment(this.state.to) : moment()}
                onSelect={this.handleEditSession.bind(this)}
              />
            );
          },
        },
        {
          component: () => {
            return (
              <CommonDailySessionView
                app={this.props.app}
                sessions={this._getFilteredSessions()}
                calendars={this.state.calendars}
                from={this.state.from ? moment(this.state.from) : moment()}
                to={this.state.to ? moment(this.state.to) : moment()}
                onSelect={this.handleEditSession.bind(this)}
              />
            );
          },
        },
      ];
    }
    this._scrollToTop();
    this.setState(this._getInitialState(), () => {
      this.fetchData();
    });
  }

  handleReloadList = () => {
    this.fetchData();
  };

  async fetchData() {
    this.startLoading();
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
    this._reloadURLParams();
  }

  //Actions
  //Date
  handleDateChange(from, to, dateType) {
    if (from == to) to = from + 24 * 60 * 60 * 1000;
    this.setState({ from, to, dateType }, this.fetchData);
  }
  handleSesssionIDCopy() {
    this.setState({ sessionIDCopied: true }, () => {
      message.success('Copied to clipboard');
      setTimeout(() => {
        if (this._isMounted) this.setState({ sessionIDCopied: false });
      }, 3500);
    });
  }

  handleCloseModal() {
    this.setState({ isModalVisible: false });
  }

  //Filter
  handleFilterSessionTypes(types) {
    this.setState({ selectedSessionTypes: types }, () => {
      this.fetchData();
    });
  }
  handleFilterCityChange(cities) {
    this.setState({ selectedCities: cities }, () => {
      this.fetchData();
    });
  }
  handleFilterCourseChange(courses) {
    this.setState({ selectedCourses: courses }, () => {
      this.fetchData();
    });
  }
  handleCostChange(value) {
    if (!value || value === Globals.Session_Cost.ALL) {
      this.setState({ selectedCost: [Globals.Session_Cost.ALL] }, () => {
        this.fetchData();
      });
    } else {
      const filteredSessions = this.state.sessions.filter((session) => session.isEligibleForNoCost === true);
      this.setState({ selectedCost: value, filteredSessions }, () => {
        this.fetchData();
      });
    }
  }

  //Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Table row actions
  handleEditSession(sessionID) {
    this.props.app.isAdmin()
      ? this.props.app.urlManager.pushPage(config.ApplicationRoutes.session, null, sessionID)
      : this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage, { sessionID });
  }
  handleAddSession = () => {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.sessionCreate, null);
  };
  async handleDeleteSession(sessionID) {
    //Only sysadmins
    /* removed as per client request
    if (!this.props.app.isSysAdmin()) {
      this.props.alertController.showErrorAlert('Ops..', 'You are not allowed to delete sessions. If you really need to delete a session, please, contact the system administrator.');
      return;
    } */
    //
    this.startLoading();
    const resp = await this._deleteSession(sessionID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  async handleShipMaterial(sessionID, shipFlag) {
    this.startLoading();
    const resp = await this._shipMaterial(sessionID, shipFlag);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    const user = this.props.app.sharedCache().getProgramUser(); //get current logged user
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          className="pageHeader"
          title="Sessions"
          subTitle={
            <Tooltip title="Refresh">
              <Button icon={<ReloadOutlined />} onClick={this.handleReloadList} />
            </Tooltip>
          }
          {...(!this.homepageMode ? { onBack: () => this.props.app.urlManager.pushBack() } : {})}
        />
        {/* Content */}
        {!this.props.app.isAdmin() && (
          <CommonCertificationViewUserHeader app={this.props.app} user={user} showBackButton={true} />
        )}
        <br />
        <CommonSessionsDateRangeSelector
          anyRangeMode={false}
          app={this.props.app}
          onDateChange={this.handleDateChange.bind(this)}
          daily={true}
          defaultDateType={2}
        />
        <Layout.Content>
          {this.homepageMode && (
            <WhiteBox style={{ width: '100%' }}>
              {this._renderFilters()}
              <TabViewController
                selectedIndex={(this.state.dateType || 1) - 1}
                config={this.tabChildren}
                parent={this}
                app={this.props.app}
              />
            </WhiteBox>
          )}
          {this.homepageMode && <Divider />}
          {this.props.app.isAdmin() && (
            <Row type="flex" justify="end">
              <Col>
                {' '}
                <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleAddSession}>
                  {' '}
                  Add Session{' '}
                </Button>{' '}
              </Col>
            </Row>
          )}
          {!this.homepageMode && this._renderFilters()}
          {this._renderSessionsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderSessionsTable() {
    const courses = this.props.app.sharedCache().getAllUniqueCourses();
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Internal Name',
        key: 'internalName',
        dataIndex: 'internalName',
        sorter: (a, b) => a.internalName.localeCompare(b.internalName),
        sortOrder: sortedInfo.columnKey === 'internalName' && sortedInfo.order,
      },
      {
        title: 'Course',
        key: 'courseID',
        sorter: (a, b) =>
          (courses.find((c) => c.id == a.courseID)?.description || '').localeCompare(
            courses.find((c) => c.id == b.courseID)?.description || ''
          ),
        sortOrder: sortedInfo.columnKey === 'courseID' && sortedInfo.order,
        render: (props) => courses.find((c) => c.id == props.courseID)?.description || '',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (props) => Globals.getTemplateTypeIcon(props, true),
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.state.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'state' && sortedInfo.order,
        render: (props) => {
          return <Tag color={Globals.getColorBySessionState(props)}>{Globals.getTextBySessionState(props)}</Tag>;
        },
      },
      {
        title: 'Dates',
        key: 'dates',
        render: (props) => {
          if (props.startDate && props.startDate.length > 0)
            return props.startDate.map((s) => Utils.getDateAndTimeOnUIFormatByTimestamp(s)).join(', ');
          else return '-';
        },
        sorter: (a, b) => (a?.startDate?.[0] || 0) - (b?.startDate?.[0] || 0),
        sortOrder: sortedInfo.columnKey === 'dates' && sortedInfo.order,
      },
      {
        title: 'Students',
        dataIndex: 'enrolmentCount',
        key: 'enrolmentCount',
        sorter: (a, b) => (a.enrolmentCount || 0) - (b.enrolmentCount || 0),
        sortOrder: sortedInfo.columnKey === 'enrolmentCount' && sortedInfo.order,
        render: (props) => `${props || 0} Students`,
      },
      {
        title: 'Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
        sorter: (a, b) => (a.capacity || 0) - (b.capacity || 0),
        sortOrder: sortedInfo.columnKey === 'capacity' && sortedInfo.order,
        render: (props) => (props < 0 ? 'Unlimited' : props),
      },
      ...(this.props.app.isSysAdmin()
        ? [
            {
              title: 'Online Setup',
              key: 'onlineSetup',
              render: (props) => {
                return props.onlineCourseID && props.onlineGradeID
                  ? 'Configured'
                  : props.onlineSetupID
                    ? 'Configuring'
                    : 'Not Configured';
              },
              sorter: (a, b) => a.createdOn - b.createdOn,
              sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
            },
          ]
        : []),
      ...(this.props.app.isAdmin()
        ? [
            {
              title: 'Actions',
              width: 160,
              key: 'Actions',
              align: 'center',
              render: (props) => {
                return (
                  <Row gutter={8} className="tableButtonContainer scrollHere" justify="space-around">
                    {props.hasMaterial != Globals.Session_HasMaterial.NONE && (
                      <Col span={4}>
                        {!props.isMaterialSent && (
                          <Tooltip placement="bottomLeft" title="Set Course Material as Shipped">
                            <Popconfirm
                              placement="left"
                              title={
                                'Course material for this session was not shipped yet, would you like to mark it as shipped?'
                              }
                              onConfirm={this.handleShipMaterial.bind(this, props.id, true)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button style={{ marginLeft: 4 }} variant="none" icon={<BookOutlined />} shape="circle" />
                            </Popconfirm>
                          </Tooltip>
                        )}
                        {props.isMaterialSent && (
                          <Tooltip placement="bottomLeft" title="Set Course Material as not Shipped">
                            <Popconfirm
                              placement="left"
                              title={
                                'Course material for this session was already shipped, would you like to mark it as not shipped?'
                              }
                              onConfirm={this.handleShipMaterial.bind(this, props.id, false)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                style={{ marginLeft: 4, borderColor: '#af3947' }}
                                variant="none"
                                icon={<BookFilled style={{ color: '#af3947' }} />}
                                shape="circle"
                              />
                            </Popconfirm>
                          </Tooltip>
                        )}
                      </Col>
                    )}
                    <Col span={4}>
                      <Tooltip placement="bottomLeft" title="Copy ID">
                        <CopyToClipboard text={props.id} onCopy={this.handleSesssionIDCopy.bind(this, props.id)}>
                          <Button style={{ marginLeft: 5 }} variant="none" shape="circle">
                            {!this.state.sessionIDCopied ? <CopyOutlined /> : <CheckOutlined />}
                          </Button>
                        </CopyToClipboard>
                      </Tooltip>
                    </Col>
                    <Col span={4}>
                      <Tooltip placement="bottomLeft" title="Edit">
                        <Button
                          style={{ marginLeft: 5 }}
                          variant="none"
                          icon={<EditOutlined />}
                          shape="circle"
                          onClick={this.handleEditSession.bind(this, props.id)}
                        />
                      </Tooltip>
                    </Col>
                    <Col span={4}>
                      <Tooltip placement="bottomLeft" title="Duplicate">
                        <Button
                          style={{ marginLeft: 5 }}
                          variant="none"
                          disabled={props.type == Globals.Session_Type.ONLINE_EXT}
                          icon={<ExportOutlined />}
                          shape="circle"
                          onClick={() => this._renderDuplicateSessionModal(props.id)}
                        />
                      </Tooltip>
                    </Col>
                    <Col span={4}>
                      <Tooltip placement="bottomLeft" title="Delete">
                        <Popconfirm
                          placement="top"
                          title={`Are you sure that you want to delete the session '${props.internalName}'?`}
                          onConfirm={this.handleDeleteSession.bind(this, props.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                        </Popconfirm>
                      </Tooltip>
                    </Col>
                  </Row>
                );
              },
            },
          ]
        : []),
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange.bind(this),
      locale: { emptyText: 'No sessions found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Layout.Content>
        <Table className="adminSessionsTable" columns={columns} dataSource={this._getFilteredSessions()} {...props} />
      </Layout.Content>
    );
  }
  _renderFilters() {
    return (
      <Row type="flex" justify="center">
        <Col style={{ borderRadius: '2*6px', padding: '10px 0 10px 0', marginRight: '20px' }}>
          Course:{' '}
          <Select
            mode="tags"
            placeholder="All Courses"
            style={{ marginLeft: '5px', minWidth: '300px', marginRight: '0px' }}
            allowClear
            optionFilterProp="label"
            onChange={this.handleFilterCourseChange.bind(this)}
            value={this.state.selectedCourses}
          >
            {this.state.courses.map((c) => {
              return (
                <Select.Option value={c.id} key={c.id}>
                  {c.description}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col style={{ borderRadius: '2*6px', padding: '10px 0 10px 0', marginRight: '20px' }}>
          City:{' '}
          <Select
            mode="tags"
            placeholder="All Cities"
            style={{ marginLeft: '5px', minWidth: '300px', marginRight: '0px' }}
            allowClear
            optionFilterProp="label"
            onChange={this.handleFilterCityChange.bind(this)}
            value={this.state.selectedCities}
          >
            {this.state.cities
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => {
                return (
                  <Select.Option value={c.id} key={c.id}>
                    {c.name} ({c.province})
                  </Select.Option>
                );
              })}
          </Select>
        </Col>
        <Col style={{ borderRadius: '2*6px', padding: '10px 0 10px 0', marginRight: '20px' }}>
          Type:{' '}
          <Select
            mode="tags"
            placeholder="All types"
            style={{ marginLeft: '5px', minWidth: '300px', marginRight: '0px' }}
            allowClear
            optionFilterProp="label"
            value={this.state.selectedSessionTypes}
            onChange={this.handleFilterSessionTypes.bind(this)}
          >
            <Select.Option
              value={Globals.Session_Type.SCHEDULED_ONLINE_EXT}
              key={Globals.Session_Type.SCHEDULED_ONLINE_EXT}
            >
              {Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_ONLINE_EXT, true)}
            </Select.Option>
            <Select.Option
              value={Globals.Session_Type.SCHEDULED_PRESENTIAL}
              key={Globals.Session_Type.SCHEDULED_PRESENTIAL}
            >
              {Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_PRESENTIAL, true)}
            </Select.Option>
            <Select.Option value={Globals.Session_Type.SCHEDULED_VILT} key={Globals.Session_Type.SCHEDULED_VILT}>
              {Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_VILT, true)}
            </Select.Option>
            <Select.Option value={Globals.Session_Type.SCHEDULED_WEBINAR} key={Globals.Session_Type.SCHEDULED_WEBINAR}>
              {Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_WEBINAR, true)}
            </Select.Option>
            <Select.Option value={Globals.Session_Type.ONLINE_EXT} key={Globals.Session_Type.ONLINE_EXT}>
              {Globals.getTemplateTypeIcon(Globals.Session_Type.ONLINE_EXT, true)}
            </Select.Option>
            <Select.Option value={Globals.Session_Type.ONLINE} key={Globals.Session_Type.ONLINE}>
              {Globals.getTemplateTypeIcon(Globals.Session_Type.ONLINE, true)}
            </Select.Option>
          </Select>
        </Col>
        <Col style={{ borderRadius: '2*6px', padding: '10px 0 10px 0', marginRight: '20px' }}>
          Cost:{' '}
          <Select
            mode="tags"
            placeholder="All costs"
            style={{ marginLeft: '5px', minWidth: '300px', marginRight: '0px' }}
            allowClear
            optionFilterProp="label"
            value={this.state.selectedCost}
            onChange={this.handleCostChange.bind(this)}
          >
            <Select.Option value={Globals.Session_Cost.ALL} key={Globals.Session_Cost.ALL}></Select.Option>
            <Select.Option
              value={Globals.Session_Cost.ELIGIBLE_FOR_NO_COST}
              key={Globals.Session_Cost.ELIGIBLE_FOR_NO_COST}
            ></Select.Option>
          </Select>
        </Col>
      </Row>
    );
  }

  _renderDuplicateSessionModal(sessionID) {
    this.setState({ isModalVisible: true });
    Modal.confirm({
      title: 'Select dates to duplicate session',
      content: (
        <div style={{ width: '460px' }}>
          <CommonSessionDatesTable
            weekDayMode={false}
            selectedDates={this.state.newSessionDates}
            onDateSelection={this.handleDateSelection}
          />
        </div>
      ),
      onOk: () => {
        const numSelectedDates = this.state.newSessionDates?.newSessionDates?.length ?? 0;
        if (numSelectedDates === 0) {
          Modal.error({
            title: 'You must select at least one date',
          });
        } else {
          Modal.confirm({
            title: `Confirm the duplication? This operation will create ${numSelectedDates} new sessions`,
            onOk: () => {
              this.handleOkDuplicate(sessionID);
              this.handleCloseModal();
            },
            onCancel: () => {},
          });
        }
      },
      onCancel: () => {
        this.handleCloseModal();
      },
    });
  }

  handleDateSelection = (date) => {
    this.setState({ newSessionDates: { newSessionDates: date } });
  };

  handleOkDuplicate(sessionID) {
    const { newSessionDates } = this.state;

    this.startLoading();
    this.duplicateSession(sessionID, newSessionDates)
      .then((success) => {
        if (success) {
          message.success('Session Duplicated with success');
        }
        this.stopLoading();
        this.handleCloseModal();
      })
      .catch((error) => {
        console.error('error ', error);
        this.stopLoading();
        this.handleCloseModal();
      });
  }

  /* private helpers */
  _scrollToTop() {
    const element = document.querySelector('.scrollHere');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }
  //Filters and URL support
  _getInitialState() {
    const currentPage = parseInt(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Page) || 1);
    const selectedSessionTypes = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Type) || [];
    const selectedCities = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_City) || [];
    const selectedCourses = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Course) || [];
    const selectedCost = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Cost) || [];

    return {
      currentPage,
      selectedSessionTypes,
      selectedCities,
      selectedCourses,
      selectedCost,
    };
  }

  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Type, this.state.selectedSessionTypes);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_City, this.state.selectedCities);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Course, this.state.selectedCourses);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Cost, this.state.selectedCost);
  }

  /* private API */
  async _loadAll() {
    const sessionListPromise = !this.props.app.isSysAdmin()
      ? this.props.app.classroom.session.getSessionList(this.state.from, this.state.to, null, true)
      : this.props.app.classroom.session.getSessionList(this.state.from, this.state.to);

    const resp = await Promise.all([
      sessionListPromise,
      this.props.app.sharedCache().getCities(),
      this.props.app.sharedCache().getVenues(),
      this.props.app.sharedCache().getAllUniqueCourses(),
    ]);

    if (!(resp[0].statusCode == 200 && resp[0].body && resp[0].body.sessions)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if (!resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    if (!resp[3]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load courses!');
      return false;
    }
    return {
      sessions: resp[0].body.sessions,
      calendars: resp[0].body.calendars || [],
      cities: resp[1],
      courses: resp[3],
    };
  }
  async _deleteSession(sessionID) {
    const resp = await this.props.app.classroom.session.deleteSession(sessionID);
    if (resp.statusCode == 200) {
      message.success('Session deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _shipMaterial(sessionID, shipFlag) {
    const resp = await this.props.app.classroom.session.updateSessionMaterialShipStatus(sessionID, shipFlag);
    if (resp.statusCode == 200) {
      message.success('Session material shipped status updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }

  async duplicateSession(sessionID, newSessionDates) {
    const response = await this.props.app.classroom.session.duplicateSession(sessionID, newSessionDates);
    if (response.statusCode === 200) {
      message.success('Session duplicated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, response);
      return false;
    }
  }

  _getFilteredSessions() {
    return this.state.sessions.filter((session) => {
      const isEligibleForNoCostFilter =
        this.state.selectedCost.includes(Globals.Session_Cost.ELIGIBLE_FOR_NO_COST) && session.isEligibleForNoCost;
      const allCost =
        this.state.selectedCost.length === 0 || this.state.selectedCost.includes(Globals.Session_Cost.ALL);
      const venue = this.props.app.sharedCache().getVenueByID(session.venueID);
      return (
        (!this.state.selectedCities?.length || this.state.selectedCities.includes(venue?.cityID)) &&
        (!this.state.selectedCourses?.length || this.state.selectedCourses.includes(session.courseID)) &&
        (!this.state.selectedSessionTypes?.length || this.state.selectedSessionTypes.includes(session.type)) &&
        (allCost || isEligibleForNoCostFilter)
      );
    });
  }
}
//
export class AdminSessionsHomeView extends AdminSessionsView {
  constructor(props) {
    super(props);
    autoBind(this);
    this.homepageMode = true;
  }
}
