import React from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm, message, Modal, Form, Input } from 'antd';
import { DownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import Globals from '../../../../config/Globals';
import Utils from '../../../../components/Utils';
//
import CourseTabWrapper from './CourseTabWrapper';
import CommonResultCard from '../../../commonComponents/CommonResultCard';

//props are: app, currentCourse
export default class CourseTabResultsTab extends CourseTabWrapper {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      editingRow: null,
      newGrade: null,
    };
    autoBind(this);
  }

  // Actions
  async handleUpdate() {
    await this.props.onUpdate();
  }

  handleDelete(row) {
    this._delete(row);
  }

  showEditModal(row) {
    this.setState({
      isModalVisible: true,
      editingRow: row,
      newGrade: null,
    });
  }

  handleModalOk() {
    const { editingRow, newGrade } = this.state;
    if (newGrade === null || isNaN(newGrade)) {
      message.error('Please enter a valid numeric grade.');
      return;
    }

    const grade = parseFloat(newGrade).toFixed(2);
    this._editGrade(editingRow, grade)
      .then(() => {
        this.setState({ isModalVisible: false });
      })
      .catch((err) => {
        message.error('Failed to update the grade. Please try again.');
      });
  }

  handleModalCancel() {
    this.setState({ isModalVisible: false });
  }

  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return <></>;
    //
    let courseSpecs = { passUiTerm: 'Passed', failUiTerm: 'Failed', revokedUiTerm: 'Revoked' };
    if (this.props.currentCourse) {
      courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.currentCourse.courseID);
    }
    console.log('*-*- rendering attemps courseSpecs', courseSpecs);
    const columns = [
      {
        title: 'Status',
        dataIndex: 'state',
        render: (state) => {
          const passed = state == Globals.Course_State.PASS;
          const revoked = state == Globals.Course_State.REVOKED;
          const statusText = passed
            ? courseSpecs.passUiTerm || 'Passed'
            : revoked
              ? 'Revoked'
              : courseSpecs.failUiTerm || 'Failed';

          return (
            <div className="value">
              <div style={{ display: 'inline-block' }} className={`status-dot ${passed ? 'green' : 'red'}`} />
              <span> {statusText} </span>
            </div>
          );
        },
      },
      {
        title: 'Grade',
        render: (props) => parseInt(props.courseResult?.resultGrade || props.courseResultV1?.grade || 0),
      },
      {
        title: 'Result Date',
        render: (props) =>
          Utils.getDateAndTimeOnUIFormatByTimestamp(props.courseResult?.resultDate || props.courseResultV1?.resultDate),
      },
      {
        title: 'Actions',
        width: '10%',
        key: 'Actions',
        render: (props) => {
          console.log('*-*- rendering attempts props', props, courseSpecs);

          return (
            <span className="tableButtonContainer">
              {props.courseResult?.resultStatus === 'PASS' &&
                courseSpecs.courseCertificateType === Globals.CertificationProcess_CertificationPrintType.CERT && (
                  <Tooltip placement="bottomLeft" title="Download a copy of the certificate">
                    <Button variant="none" icon={<DownloadOutlined />} shape="circle" onClick={this.props.onDownload} />
                  </Tooltip>
                )}
              {this.props.app.isAdmin() && (
                <>
                  <Tooltip placement="bottomLeft" title="Edit">
                    <Button
                      variant="none"
                      icon={<EditOutlined />}
                      shape="circle"
                      onClick={() => this.showEditModal(props)}
                    />
                  </Tooltip>
                  <Popconfirm
                    placement="top"
                    okText="Yes"
                    cancelText="No"
                    title={'Confirm removal of this attempt?'}
                    onConfirm={() => this.handleDelete(props)}
                  >
                    <Button style={{ marginLeft: '4px' }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                  </Popconfirm>
                </>
              )}
            </span>
          );
        },
      },
    ];

    const filteredRows = this.props.resultsHistory.filter(
      (row) =>
        row.state == Globals.Course_State.PASS ||
        row.state == Globals.Course_State.FAIL ||
        row.state == Globals.Course_State.REVOKED
    );

    return (
      <>
        {super.render(
          <Table
            rowKey="id"
            columns={columns}
            dataSource={filteredRows}
            expandable={{ expandedRowRender: this._renderExpandedRow }}
            pagination={{ hideOnSinglePage: true, pageSize: 5 }}
            scroll={{ x: true }}
          />
        )}
        {this._renderEditModal()}
      </>
    );
  }

  _renderExpandedRow(row) {
    return <CommonResultCard app={this.props.app} currentCourse={row} />;
  }

  _renderEditModal() {
    const { isModalVisible, newGrade } = this.state;
    return (
      <Modal
        title="Edit Result"
        open={isModalVisible}
        okText="Save"
        onOk={() => this.handleModalOk()}
        onCancel={() => this.handleModalCancel()}
      >
        <Form layout="vertical" style={{ width: 400 }}>
          <Form.Item label="Grade" required>
            <Input
              type="number"
              min={0}
              value={newGrade}
              onChange={(e) => this.setState({ newGrade: e.target.value })}
              placeholder="Enter the new grade"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  //private API
  async _delete(row) {
    this.startLoading();

    const { userID, certProcID, id: courseID } = row;
    const resp = await this.props.app.api.course.delete(userID, certProcID, courseID);

    if (resp.statusCode == 200) {
      message.success('Course successfully deleted.');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _editGrade(row, newGrade) {
    const { userID, certProcID, id: courseID } = row;
    const content = { resultGrade: parseFloat(newGrade) };

    const resp = await this.props.app.api.course.overrideResult(userID, certProcID, courseID, content);

    if (resp.statusCode === 200) {
      message.success('Grade updated successfully.');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      throw new Error('Failed to update grade.');
    }
  }
}
