import React from 'react';
import autoBind from 'react-autobind';
import { message, Alert } from 'antd';
import { InfoCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';

import '../../../assets/stylesheets/ApplicationView.less';
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';

import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';

// Super tabs
import CommonCertificationApplicationTabController from './CommonCertificationApplicationTabController';
import CommonCertificationViewCourseTabView from './CommonCertificationViewCourseTabView';
import CommonCertificationViewResultTabView from './CommonCertificationViewResultTabView';
import CommonCertificationViewCooldownTabView from './CommonCertificationViewCooldownTabView';
import CommonCertificationViewLockCourseTabView from './CommonCertificationViewLockCourseTabView';

import CommonCertificationViewUserHeader from './CommonCertificationViewUserHeader';

export default class CommonCertificationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //
    this.certID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    this.userID = this.props.match.params[Globals.URL_Path_ID2_Placeholder];
    //
    this.state = {
      visibleTabs: [],
      currentTab: Globals.CommonCertificationViewTabs.COURSE,
      isLoading: true,
      certificationProcess: null,
      certification: null,
      user: null,
      firstLoad: true,
    };
    //tabs controller
    this.tabsInstances = {};
  }
  //Life cycle
  async componentDidMount() {
    await this._fetch();
    this.tabsInstances = {}; //reset and request redraw
    this.setState({ firstLoad: false });
  }

  //Actions
  handleChangeTab(tabIndex) {
    this.setState({ currentTab: tabIndex });
  }
  // User Header actions
  handleToogleWaiveStatus(waived, optionalComments) {
    this._toogleCertificationWaiveStatus(waived, optionalComments);
  }

  //UI
  render() {
    const { certificationSpecs } = this.state;
    return (
      <>
        <div className="application-wrapper">
          {this.state.certificationSpecs?.ui?.banner && (
            <div>
              {certificationSpecs.ui.banner.type === Globals.Certification_BannerTypes.INFO && (
                <Alert
                  description={
                    <ReactMarkdown>{Utils.sanitizeMarkdownContent(certificationSpecs.ui.banner.content)}</ReactMarkdown>
                  }
                  type="info"
                  showIcon
                />
              )}
              {certificationSpecs.ui.banner.type === Globals.Certification_BannerTypes.WARNING && (
                <Alert
                  description={
                    <ReactMarkdown>{Utils.sanitizeMarkdownContent(certificationSpecs.ui.banner.content)}</ReactMarkdown>
                  }
                  type="warning"
                  showIcon
                />
              )}
              {certificationSpecs.ui.banner.type === Globals.Certification_BannerTypes.CRITICAL && (
                <Alert
                  description={
                    <ReactMarkdown>{Utils.sanitizeMarkdownContent(certificationSpecs.ui.banner.content)}</ReactMarkdown>
                  }
                  type="error"
                  showIcon
                />
              )}
            </div>
          )}

          <CommonLoadingView isLoading={this.state.isLoading} />
          <CommonCertificationViewUserHeader
            app={this.props.app}
            user={this.state.user}
            certificationSpecs={this.state.certificationSpecs}
            certificationProcess={this.state.certificationProcess}
            onWaiveToogle={this.handleToogleWaiveStatus}
            showBackButton={true}
          />
          {this._renderTabController()}
        </div>
      </>
    );
  }

  /* Private UI */
  async _toogleCertificationWaiveStatus(waived, optionalComments) {
    this.startLoading();
    const resp = await this.props.app.api.certification.toogleWaiveStatus(
      this.state.certificationProcess.userID,
      this.state.certificationProcess.id,
      optionalComments,
      waived
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Waived status updated with success!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  _getCurrentTabStates(certificationSpecs, certificationProcess) {
    const state = { currentTab: 0, visibleTabs: [] };
    //Has application? push new application
    if (certificationSpecs.application) {
      state.visibleTabs.push(Globals.CommonCertificationViewTabs.NEW_APPLICATION);
    }
    //Cert application is not pending and application is approved OR does not have application
    if (certificationProcess.state != Globals.CertificationProcess_State.APPLICATION_PENDING) {
      const isApplicationApproved = certificationProcess.applications.find(
        (application) =>
          application.reviewAcknowledge &&
          application.reviewAcknowledge != -1 &&
          application.state == Globals.ApplicationState.APPROVED &&
          (application.type == Globals.ApplicationTypes.NEW || application.type == Globals.ApplicationTypes.RENEWAL)
      );
      if (!certificationSpecs.application || isApplicationApproved)
        state.visibleTabs.push(Globals.CommonCertificationViewTabs.COURSE);
    }
    //Cert if completed, failed or expired, show certification result
    if (
      [
        Globals.CertificationProcess_State.COMPLETED,
        Globals.CertificationProcess_State.FAILED,
        Globals.CertificationProcess_State.EXPIRED,
      ].includes(certificationProcess.state)
    ) {
      state.visibleTabs.push(Globals.CommonCertificationViewTabs.RESULT);
    }
    //Cert is on cooldown, show certification cooldown
    if (certificationProcess.state == Globals.CertificationProcess_State.COOLDOWN) {
      state.visibleTabs.push(Globals.CommonCertificationViewTabs.COOLDOWN);
    }
    //Cert is locked, show locked tab
    if (certificationProcess.state == Globals.CertificationProcess_State.COURSE_LOCKED) {
      state.visibleTabs.push(Globals.CommonCertificationViewTabs.LOCKED);
    }
    // We only push renewal application tab when the renewal is already
    // started by the certification completed tab BUT not fully completed && ACKed by user && APPROVED
    if (
      certificationSpecs.renewal?.application &&
      certificationProcess.applications?.find(
        (a) =>
          a.type == Globals.ApplicationTypes.RENEWAL &&
          (!a.reviewAcknowledge || a.reviewAcknowledge < 0 || a.state != Globals.ApplicationState.APPROVED)
      )
    ) {
      state.visibleTabs.push(Globals.CommonCertificationViewTabs.RENEW_APPLICATION);
    }
    //Visible tab is always the last pushed!
    state.currentTab = state.visibleTabs[state.visibleTabs.length - 1];
    //
    return state;
  }
  //Tab controller
  _renderTabController() {
    const { currentTab, isLoading, firstLoad, visibleTabs, certificationSpecs, certificationProcess } = this.state;
    const hideContentOnFirstLoad = firstLoad && isLoading;
    //silly optmization :p
    if (!this._courseTabTitle)
      this._courseTabTitle = CommonCertificationViewCourseTabView.GetTabTitleView(
        certificationSpecs,
        certificationProcess,
        this.props.app
      );
    //
    const tabs = [
      {
        key: Globals.CommonCertificationViewTabs.NEW_APPLICATION,
        title: 'Application',
        render: this._renderNewApplicationTab,
        hidden: hideContentOnFirstLoad || !visibleTabs.includes(Globals.CommonCertificationViewTabs.NEW_APPLICATION),
      },
      {
        key: Globals.CommonCertificationViewTabs.COURSE,
        title: this._courseTabTitle,
        render: this._renderCourseTab,
        hidden: hideContentOnFirstLoad || !visibleTabs.includes(Globals.CommonCertificationViewTabs.COURSE),
      },
      {
        key: Globals.CommonCertificationViewTabs.RESULT,
        title: CommonCertificationViewResultTabView.GetTabTitleView(certificationSpecs, certificationProcess),
        render: this._renderResultTab,
        hidden: hideContentOnFirstLoad || !visibleTabs.includes(Globals.CommonCertificationViewTabs.RESULT),
      },
      {
        key: Globals.CommonCertificationViewTabs.COOLDOWN,
        title: CommonCertificationViewCooldownTabView.GetTabTitleView(certificationSpecs, certificationProcess),
        render: this._renderCooldownTab,
        hidden: hideContentOnFirstLoad || !visibleTabs.includes(Globals.CommonCertificationViewTabs.COOLDOWN),
      },
      {
        key: Globals.CommonCertificationViewTabs.RENEW_APPLICATION,
        title: 'Renew Application',
        render: this._renderRenewApplicationTab,
        hidden: hideContentOnFirstLoad || !visibleTabs.includes(Globals.CommonCertificationViewTabs.RENEW_APPLICATION),
      },
      {
        key: Globals.CommonCertificationViewTabs.LOCKED,
        title: CommonCertificationViewLockCourseTabView.GetTabTitleView(certificationSpecs, certificationProcess),
        render: this._renderLockedTab,
        hidden: hideContentOnFirstLoad || !visibleTabs.includes(Globals.CommonCertificationViewTabs.LOCKED),
      },
    ].sort((a, b) => a.key - b.key);
    //
    if (hideContentOnFirstLoad) return <></>;
    return (
      <>
        <div className="application-container">
          <header className="tabs-header">
            {tabs.map((tab) =>
              tab.hidden ? null : (
                <button
                  key={tab.title}
                  type="button"
                  className={`tab ${tab.key === currentTab ? 'active' : ''}`}
                  onClick={() => this.handleChangeTab(tab.key)}
                >
                  {tab.title}
                </button>
              )
            )}
          </header>
          <div className="application-content">
            {tabs.map((tab) => tab.render(!tab.hidden && tab.key === currentTab))}
          </div>
        </div>
      </>
    );
  }
  //Tabs
  _renderNewApplicationTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CommonCertificationViewTabs.NEW_APPLICATION])
      return <div key="10"></div>;
    this.tabsInstances[Globals.CommonCertificationViewTabs.NEW_APPLICATION] = true;
    const { certificationProcess, certificationSpecs } = this.state;
    //Whenever renewal is completed and ACKed, we don't show the renewal tab, and therefore we use the NEW application tab
    //as the renewal application to allow user to download the latest submitted application
    let renewalCompleted = false;
    if (
      certificationSpecs.renewal?.application &&
      certificationProcess.applications?.find((a) => {
        return (
          a.type == Globals.ApplicationTypes.RENEWAL &&
          a.reviewAcknowledge > 0 &&
          a.state == Globals.ApplicationState.APPROVED
        );
      })
    )
      renewalCompleted = true;
    //
    return (
      <CommonCertificationApplicationTabController
        key={Globals.CommonCertificationViewTabs.NEW_APPLICATION}
        app={this.props.app}
        isVisible={visible}
        applicationType={Globals.ApplicationTypes[renewalCompleted ? 'RENEWAL' : 'NEW']}
        certificationProcess={certificationProcess}
        certificationSpecs={certificationSpecs}
        user={this.state.user}
        onUpdate={this._fetch}
      />
    );
  }
  _renderCourseTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CommonCertificationViewTabs.COURSE]) return <div key="20"></div>;
    this.tabsInstances[Globals.CommonCertificationViewTabs.COURSE] = true;
    //
    return (
      <CommonCertificationViewCourseTabView
        key={Globals.CommonCertificationViewTabs.COURSE}
        app={this.props.app}
        isVisible={visible}
        certificationProcess={this.state.certificationProcess}
        certificationSpecs={this.state.certificationSpecs}
        user={this.state.user}
        onUpdate={this._fetch}
        invitation={this.state.invitation}
        renewalInProgress={
          this.state.visibleTabs.includes(Globals.CommonCertificationViewTabs.RENEW_APPLICATION) ||
          this.state.visibleTabs.includes(Globals.CommonCertificationViewTabs.RESULT)
        }
      />
    );
  }
  _renderResultTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CommonCertificationViewTabs.RESULT]) return <div key="30"></div>;
    this.tabsInstances[Globals.CommonCertificationViewTabs.RESULT] = true;
    //
    return (
      <CommonCertificationViewResultTabView
        key={Globals.CommonCertificationViewTabs.RESULT}
        certificationProcess={this.state.certificationProcess}
        certificationSpecs={this.state.certificationSpecs}
        isVisible={visible}
        onUpdate={this._fetch}
        app={this.props.app}
      />
    );
  }
  _renderCooldownTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CommonCertificationViewTabs.COOLDOWN]) return <div key="40"></div>;
    this.tabsInstances[Globals.CommonCertificationViewTabs.COOLDOWN] = true;
    //
    return (
      <CommonCertificationViewCooldownTabView
        key={Globals.CommonCertificationViewTabs.COOLDOWN}
        certificationProcess={this.state.certificationProcess}
        certificationSpecs={this.state.certificationSpecs}
        isVisible={visible}
        onUpdate={this._fetch}
        app={this.props.app}
      />
    );
  }
  _renderLockedTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CommonCertificationViewTabs.LOCKED]) return <div key="45"></div>;
    this.tabsInstances[Globals.CommonCertificationViewTabs.LOCKED] = true;
    //
    return (
      <CommonCertificationViewLockCourseTabView
        key={Globals.CommonCertificationViewTabs.LOCKED}
        app={this.props.app}
        isVisible={visible}
        certificationProcess={this.state.certificationProcess}
        certificationSpecs={this.state.certificationSpecs}
        onUpdate={this._fetch}
      />
    );
  }
  _renderRenewApplicationTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CommonCertificationViewTabs.RENEW_APPLICATION])
      return <div key="50"></div>;
    this.tabsInstances[Globals.CommonCertificationViewTabs.RENEW_APPLICATION] = true;
    //
    return (
      <CommonCertificationApplicationTabController
        key={Globals.CommonCertificationViewTabs.RENEW_APPLICATION}
        app={this.props.app}
        isVisible={visible}
        applicationType={Globals.ApplicationTypes.RENEWAL}
        certificationProcess={this.state.certificationProcess}
        certificationSpecs={this.state.certificationSpecs}
        user={this.state.user}
        onUpdate={this._fetch}
      />
    );
  }

  /* Private API */
  async _fetch() {
    this.setState({ isLoading: true });
    // Get user
    let user = null;
    if (this.props.app.isAdmin() || this.props.app.isOrgManager()) {
      const userRequest = await this.props.app.api.user.getByID(this.userID);
      if (!this._isMounted) return;
      if (userRequest.statusCode != 200 || !userRequest.body) {
        this.setState({ isLoading: false });
        this.props.app.alertController.showAPIErrorAlert(null, userRequest);
        return;
      }
      user = userRequest.body;
    } else user = this.props.app.sharedCache().getProgramUser();

    // Get cert
    const certificationProcess = await this.props.app.api.certification.getByUserIDAndCertID(user.id, this.certID);
    if (!this._isMounted) return;
    if (certificationProcess.statusCode != 200 || !certificationProcess.body) {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, certificationProcess);
      return;
    }
    const certificationSpecs = this.props.app
      .sharedCache()
      .getCertificationByID(certificationProcess.body.certificationID);
    const currentTabStates = this._getCurrentTabStates(certificationSpecs, certificationProcess.body);

    if (this.state.firstLoad) await this.handleInvitation(user, certificationProcess.body);

    this.setState({
      isLoading: false,
      certificationProcess: certificationProcess.body,
      certificationSpecs,
      user,
      ...currentTabStates,
    });
  }

  /* Session invitation */
  async handleInvitation(user, certificationProcess) {
    // Get invitation information
    const paramInvitationCode = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_InvitationCode);
    const paramSessionID = this.props.app.urlManager.getQueryParam(Globals.URL_Path_LicensePurchase_SessionID);
    let sessionInvitation = null;
    if (paramInvitationCode) {
      sessionInvitation = await this.props.app.classroom.sessionInvitation.validateSessionInvitation(
        paramInvitationCode
      );
      if (sessionInvitation.statusCode == 200 && sessionInvitation.body && sessionInvitation.body.invitation)
        sessionInvitation = sessionInvitation.body.invitation;
      else return null;
      if (
        !certificationProcess.state == Globals.CertificationProcess_State.LICENSE_PENDING &&
        certificationProcess.courses?.find(
          (c) =>
            c.courseID == sessionInvitation.courseID &&
            (c.state == Globals.Course_State.PENDING || Globals.Course_State.PASS)
        )
      )
        return null;
      if (sessionInvitation.activationCode) {
        let session = null;
        if (sessionInvitation.sessionID)
          session = await this.props.app.classroom.session.getSession(sessionInvitation.sessionID, false);
        const redeemResp = await this.props.app.api.certification.redeemLicense({
          userID: user.id,
          certID: certificationProcess.id,
          courseID: sessionInvitation.courseID,
          activationCode: sessionInvitation.activationCode,
          sessionID: sessionInvitation.sessionID,
          invitationCode: sessionInvitation.invitationCode,
          autoEnrolSessionID: session?.autoEnrolOnSession || null,
        });
        if (!this._isMounted) return null;
        //Response
        console.debug('License redeem resp: ', redeemResp);
        if (redeemResp.statusCode == 200 && redeemResp.body) message.success('License Redeemed!');
        else this.props.app.alertController.showAPIErrorAlert(null, redeemResp);
      }
    } else if (paramSessionID) {
      const sessionResp = await this.props.app.classroom.session.getSession(paramSessionID, false);
      console.debug('Session resp:', sessionResp);
      if (sessionResp.statusCode == 200 && sessionResp.body) {
        if (
          !certificationProcess.state == Globals.CertificationProcess_State.LICENSE_PENDING &&
          certificationProcess.courses?.find(
            (c) =>
              c.courseID == sessionResp.body.courseID &&
              (c.state == Globals.Course_State.PENDING || Globals.Course_State.PASS)
          )
        )
          return null;
        sessionInvitation = {
          courseID: sessionResp.body.courseID,
          sessionID: paramSessionID,
        };
      }
    }
    this.props.app.urlManager.clearQueryStringParam();
    this.setState({ invitation: sessionInvitation });
  }
}
