import React from 'react';
import autoBind from 'react-autobind';
import { Table, Button, Layout, Tooltip, Popconfirm, Drawer, message, DatePicker, Spin } from 'antd';
import { EyeOutlined, CheckOutlined, DownloadOutlined, CloseOutlined, UndoOutlined } from '@ant-design/icons';
import moment from 'moment';
//
import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
import config from '@/config/config';
import Globals from '@/config/Globals';
//

export default class CommonProductOrdersTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sortedInfo: { columnKey: 'orderDate', order: 'descend' },
      orders: [],
      sessions: [],
      drawerVisible: false,
      selectedOrder: null,
      shippingDate: null,
      allowRefundStatuses: [Globals.Product_Order.PENDING_SHIPPING, Globals.Product_Order.COMPLETED],
    };
  }

  async componentDidMount() {
    if (this.props.orders && this.props.orders.length > 0) {
      await this._searchSessions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orders !== this.props.orders) {
      this._searchSessions();
    }
  }

  showDrawer(order) {
    this.setState({ drawerVisible: true, selectedOrder: order });
  }

  closeDrawer() {
    this.setState({ drawerVisible: false, selectedOrder: null, shippingDate: null });
  }

  onShippingDateChange(date) {
    this.setState({ shippingDate: date });
  }

  async _searchSessions() {
    const { orders } = this.props;
    const sessionIDs = orders?.reduce((acc, order) => {
      if (order.sessionID && !acc.includes(order.sessionID)) acc.push(order.sessionID);
      return acc;
    }, []);

    if (!sessionIDs.length) {
      this.setState({ sessions: [], orders: orders.map((order) => ({ ...order, name: '-' })) });
      return;
    }

    try {
      const response = await this.props.app.classroom.session.searchSessionsByIDs(sessionIDs);
      if (response.statusCode === 200) {
        const sessions = response.body.sessions.map((s) => s._source);
        const ordersWithNames = orders.map((order) => {
          const session = sessions.find((s) => s.id === order.sessionID);
          return { ...order, name: session ? session.name : '-' };
        });
        this.setState({ sessions, orders: ordersWithNames });
      } else {
        this.setState({ sessions: [], orders: orders.map((order) => ({ ...order, name: '-' })) });
        this.props.app.alertController.showAPIErrorAlert(null, response);
      }
    } catch (error) {
      console.error('Error fetching sessions:', error);
      this.setState({ sessions: [], orders: orders.map((order) => ({ ...order, name: '-' })) });
    }
  }

  submitMarkShipped = () => {
    const { shippingDate } = this.state;
    const { id, externalID } = this.state.selectedOrder;
    const shippingTimestamp = shippingDate ? shippingDate.valueOf() : null;

    if (!shippingDate) {
      message.error('Please select a shipping date!');
      return;
    }
    if (this.props.handleMarkShipped) {
      this.props.handleMarkShipped(externalID, id, { shippingDate: shippingTimestamp });
    }
    this.closeDrawer();
  };

  //Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter, filters });
  }

  getTableRowProps(record) {
    return {
      onClick: () => this.props.onRow && this.props.onRow(record),
    };
  }

  handleProductOrdersDetails(row) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.productOrdersDetails, null, row.externalID, row.id);
  }
  _handleNavigateToSessions(sessionID) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.session, null, sessionID);
  }

  render() {
    let { sortedInfo, sessions } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Date',
        key: 'orderDate',
        dataIndex: 'orderDate',
        width: '15%',
        render: (orderDate) => (orderDate ? Utils.getDateOnUIFormatByTimestamp(orderDate) : ''),
        sorter: (a, b) => a.orderDate - b.orderDate,
        sortOrder: sortedInfo.columnKey === 'orderDate' && sortedInfo.order,
      },
      {
        title: 'Purchaser',
        key: 'name',
        dataIndex: 'name',
        width: '15%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        width: '15%',
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: '15%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: (status) => `${Utils.formatLabel(status)}`,
      },
      {
        title: 'Session',
        key: 'session',
        dataIndex: 'sessionID',
        width: '15%',
        render: (sessionID) => {
          const { sessions } = this.state;
          if (!sessions) {
            return <Spin size="small" />;
          }
          const session = sessions.find((s) => s.id === sessionID);
          if (!session) {
            return '-';
          }
          let sessionDate = '';
          if (Array.isArray(session.startDate) && session.startDate.length > 0) {
            sessionDate = `- ${Utils.getDateOnUIFormatByTimestamp(session.startDate[0])}`;
          }
          return <a onClick={() => this._handleNavigateToSessions(session.id)}>{`${session.name} ${sessionDate}`}</a>;
        },
      },
      {
        title: 'Total',
        key: 'orderValueTotal',
        dataIndex: 'orderValueTotal',
        width: '15%',
        sorter: (a, b) => a.orderValueTotal.localeCompare(b.orderValueTotal),
        sortOrder: sortedInfo.columnKey === 'orderValueTotal' && sortedInfo.order,
        render: (orderValueTotal) => `$${Utils.toCurrencyFormat(orderValueTotal)}`,
      },
      {
        title: 'Actions',
        width: '15%',
        key: 'Actions',
        align: 'middle',
        render: (props) => (
          <span className="tableButtonContainer">
            <Tooltip placement="bottomLeft" title="Marks order as shipped">
              <Button
                icon={<CheckOutlined />}
                onClick={() => this.showDrawer(props)}
                shape="circle"
                style={{ marginRight: 6 }}
                disabled={props.status !== Globals.Product_Order.PENDING_SHIPPING}
              />
            </Tooltip>
            <Tooltip placement="bottomLeft" title="View">
              <Button
                icon={<EyeOutlined />}
                onClick={() => this.handleProductOrdersDetails(props)}
                shape="circle"
                style={{ marginRight: 6 }}
              />
            </Tooltip>
            <Tooltip placement="bottomLeft" title="Download">
              <Button
                icon={<DownloadOutlined />}
                style={{ marginRight: 6 }}
                shape="circle"
                onClick={() => this.props.onDownload(props)}
              />
            </Tooltip>
            <Tooltip placement="bottomLeft" title="Refund">
              <Popconfirm
                placement="top"
                title="Are you sure you want to refund this order?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.props.onRefund(props)}
                disabled={!this.state.allowRefundStatuses.includes(props.status)}
              >
                <Button
                  icon={<UndoOutlined />}
                  shape="circle"
                  style={{ marginRight: 6 }}
                  disabled={!this.state.allowRefundStatuses.includes(props.status)}
                />
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No orders found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <Layout.Content>
        <Table columns={columns} dataSource={this.props.orders} {...props} />
        <Drawer
          title="Mark Order as Shipped"
          open={this.state.drawerVisible}
          onClose={this.closeDrawer}
          footer={
            <Button type="primary" onClick={this.submitMarkShipped}>
              Submit
            </Button>
          }
        >
          <p>Please select the shipping date:</p>
          <DatePicker onChange={this.onShippingDateChange} value={this.state.shippingDate} />
        </Drawer>
      </Layout.Content>
    );
  }
}
