import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tag, Typography, Button, Tooltip } from 'antd';
import * as ExcelJS from 'exceljs';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
import { DownloadOutlined } from '@ant-design/icons';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminRevenuePerPeriodReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      orders: [],
      sortedInfo: null,
      firstLoad: true,
      reportDates: [],
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  //Actions
  onRowSelection(val) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.affiliateComissionDetails, {}, val.id);
  }

  handleRowClick({ externalID, licenseID }) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.licenseConsumption, null, externalID, licenseID);
  }

  async handleFilter(dates) {
    let [from, to] = dates || [];
    if (!from || !to) {
      this.setState({ orders: [] });
      return;
    }
    //Start loading
    this.setState({ orders: [], isLoading: true, reportDates: dates });
    from = from.format('YYYY-MM-DD');
    to = to.format('YYYY-MM-DD');
    //Make request
    const resp = await this.props.app.license.order.getOrdersFromToTimestamp(from, to);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.orders) {
      let orders = resp.body.orders;
      //Inject users
      const users = await this._fetchUserNames(orders.map((o) => o.externalID));
      orders = orders.map((o) => {
        o.user = users ? users.find((u) => o.externalID == u._source.id) : null;
        return o;
      });
      //Generate Total row
      const subtotal = orders.reduce((acc, curr) => acc + ((curr.value || 0) - (curr.refundAmount || 0)), 0);
      const taxes = orders.reduce((acc, curr) => acc + (curr.valueTax || 0), 0);
      const total = orders.reduce((acc, curr) => acc + ((curr.valueTotal || 0) - (curr.refundAmount || 0)), 0);
      orders.push({
        id: String(Math.random()),
        value: subtotal,
        valueTax: taxes,
        valueTotal: total,
        name: 'Total',
      });

      this.setState({ orders, isLoading: false, firstLoad: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ orders: [], isLoading: false, firstLoad: false });
    }
  }

  async generateReportXLSX() {
    if (this.state.orders.length < 1) {
      return;
    }

    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    let columns = [
      'Date',
      'Name',
      'Employer',
      'CUs',
      'Quantity',
      'Product',
      'Type',
      'Status',
      'Value pre tax ($)',
      'Refunded Value pre tax ($)',
      'Tax ($)',
      'Total ($)',
      'Refunded Total ($)',
    ];

    const showEmployerColumns = this._showEmployerColumns();

    if (!showEmployerColumns) {
      columns = columns.filter((e) => e !== 'Employer' && e !== 'CUs');
    }

    // Generate XLSX header
    ws.addRow(columns);

    // Generate XLSX rows
    this.state.orders.forEach((order) => {
      const valuePreTax =
        order.status == Globals.LicenseOrder_State.REFUNDED
          ? Number(Utils.toCurrencyFormat(order.value - (order.refundAmount || 0))) || 0
          : Number(Utils.toCurrencyFormat(order.value)) || 0;
      const refundedValuePreTax =
        order.status == Globals.LicenseOrder_State.REFUNDED ? Number(Utils.toCurrencyFormat(order.value)) || 0 : '';
      const valueTotal =
        order.status == Globals.LicenseOrder_State.REFUNDED
          ? Number(
              Utils.toCurrencyFormat(
                order.valueTotal -
                  (order.refundAmount || 0) -
                  ((order.refundAmount || 0) / order.value) * order.valueTax
              )
            ) || 0
          : Number(Utils.toCurrencyFormat(order.valueTotal)) || 0;
      const refundedValueTotal =
        order.status == Globals.LicenseOrder_State.REFUNDED
          ? Number(Utils.toCurrencyFormat(order.valueTotal)) || 0
          : '';

      let row = [];

      row.push(
        order.createdOn ? Utils.getDateOnUIFormatByTimestamp(order.createdOn) : '',
        this._getNameFromObject(order)
      );

      if (showEmployerColumns) {
        row.push(order.employerName, order.employerCUs ? order.employerCUs.join(', ') : '');
      }

      row.push(
        order.quantity,
        order.productName,
        order.type,
        order.status,
        valuePreTax,
        refundedValuePreTax,
        Number(Utils.toCurrencyFormat(order.valueTax)) || 0,
        valueTotal,
        refundedValueTotal
      );

      ws.addRow(row);
    });

    const buffer = await wb.xlsx.writeBuffer();

    let [from, to] = this.state.reportDates;
    from = from.format('YYYY-MM-DD');
    to = to.format('YYYY-MM-DD');
    Utils.downloadArrayBuffer(buffer, `revenue-report-${from}-to-${to}`, 'xlsx');
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '13%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : ''),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Name',
        key: 'name',
        width: '20%',
        render: (prop) => {
          const name = this._getNameFromObject(prop);
          if (name == 'Total') return <strong>Total</strong>;
          else return name;
        },
        sorter: (a, b) => {
          const nameA = this._getNameFromObject(a);
          const nameB = this._getNameFromObject(b);
          if (!nameA || nameB == 'Total') return false;
          return nameA.localeCompare(nameB);
        },
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },

      {
        title: 'Employer',
        dataIndex: 'employerName',
        key: 'employerName',
        width: '7%',
        sorter: (a, b) => a.employerName - b.employerName,
        sortOrder: sortedInfo.columnKey === 'employerName' && sortedInfo.order,
        show: !this._showEmployerColumns(),
      },
      {
        title: 'CUs',
        dataIndex: 'employerCUs',
        key: 'employerCUs',
        width: '7%',
        render: (employerCUs) => (employerCUs ? employerCUs.join(', ') : ''),
        show: !this._showEmployerColumns(),
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '7%',
        sorter: (a, b) => a.quantity - b.quantity,
        sortOrder: sortedInfo.columnKey === 'quantity' && sortedInfo.order,
      },
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
        width: '15%',
        sorter: (a, b) => a.productName.localeCompare(b.productName),
        sortOrder: sortedInfo.columnKey === 'productName' && sortedInfo.order,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '15%',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (type) => {
          return (
            <Tag icon={Globals.getIconByOrderType(type)} color={Globals.getColorByOrderType(type)}>
              {type}
            </Tag>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '8%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: (status) => {
          return <Tag color={Globals.getColorByOrderState(status)}>{status}</Tag>;
        },
      },
      {
        title: 'Value pre tax ($)',
        key: 'value',
        width: '15%',
        align: 'right',
        render: (order) => {
          if (order.status == Globals.LicenseOrder_State.REFUNDED) {
            return (
              <div>
                <Typography.Text type="danger" delete>
                  ${Utils.toCurrencyFormat(order.value)}
                </Typography.Text>
                <Typography.Text style={{ marginLeft: 4 }}>
                  ${Utils.toCurrencyFormat(order.value - (order.refundAmount || 0))}
                </Typography.Text>
              </div>
            );
          } else return order.value != undefined ? `$${Utils.toCurrencyFormat(order.value)}` : '-';
        },
        sorter: (a, b) => a.value - b.value,
        sortOrder: sortedInfo.columnKey === 'value' && sortedInfo.order,
      },
      {
        title: 'Tax ($)',
        dataIndex: 'valueTax',
        key: 'valueTax',
        width: '15%',
        align: 'right',
        render: (amount) => (amount ? `$${Utils.toCurrencyFormat(amount)}` : ''),
        sorter: (a, b) => a.valueTax - b.valueTax,
        sortOrder: sortedInfo.columnKey === 'valueTax' && sortedInfo.order,
      },
      {
        title: 'Total ($)',
        key: 'valueTotal',
        width: '15%',
        align: 'right',
        render: (order) => {
          if (order.status == Globals.LicenseOrder_State.REFUNDED) {
            return (
              <div>
                <Typography.Text type="danger" delete>
                  ${Utils.toCurrencyFormat(order.valueTotal)}
                </Typography.Text>
                <Typography.Text style={{ marginLeft: 4 }}>
                  $
                  {Utils.toCurrencyFormat(
                    order.valueTotal -
                      (order.refundAmount || 0) -
                      ((order.refundAmount || 0) / order.value) * order.valueTax
                  )}
                </Typography.Text>
              </div>
            );
          } else return order.valueTotal != undefined ? `$${Utils.toCurrencyFormat(order.valueTotal)}` : '';
        },
      },
    ].filter((item) => !item.show);

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: this.state.firstLoad ? 'Search orders' : 'No orders found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Revenue per Period" />
          <Layout.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <DateRangeFilter buttonLabel="Run Report" onFilter={this.handleFilter} />
              <Button
                type="primary"
                style={{ marginLeft: 4 }}
                icon={<DownloadOutlined />}
                onClick={this.generateReportXLSX}
                disabled={this.state.orders.length < 1}
              >
                Export to xlsx
              </Button>
            </div>
            <Table
              className="adminSearchUsersTable"
              columns={columns}
              dataSource={this.state.orders}
              components={{ body: { row: this._renderTableRow } }}
              onRow={(record) => ({
                onClick: this.handleRowClick.bind(this, record),
              })}
              {...props}
            />
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  //Private
  _renderTableRow(props) {
    if (this.state.orders.length < 1) {
      return <tr {...props} />;
    }

    const email = props.children?.[0]?.props?.record?.email;

    return (
      <Tooltip title={email}>
        <tr {...props} />
      </Tooltip>
    );
  }

  _getNameFromObject(prop) {
    if (prop.user && prop.name != 'Total') {
      return `${prop.user._source.firstName} ${prop.user._source.lastName}`;
    } else if (prop.name == 'Total') return `Total`;
    else return prop.name;
  }
  async _fetchUserNames(usersIDs) {
    const resp = await this.props.app.api.user.searchUsersByIDs(usersIDs);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) {
      return resp.body.users;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    return null;
  }

  _showEmployerColumns() {
    const { settingsDisplayOptions } = this.props.app.sharedCache().getTenantConfig();
    return settingsDisplayOptions?.showEmployerInRevenueReport;
  }
}
