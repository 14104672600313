import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Table, Drawer, Row, Tooltip, Col, Popconfirm, Button, message, Divider } from 'antd';
import { CloudUploadOutlined, RocketOutlined, GlobalOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import scrollIntoView from 'scroll-into-view';
//
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//
import CommonRequirementForm from '../Forms/CommonRequirementForm';
import CommonRequirementAcceptedProviderModal from '../Modals/CommonRequirementAcceptedProviderModal';
import Globals from '../../../config/Globals';
//props are: app, onUpdate
export default class CommonRequirementDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      isVisible: false,
      requirementID: null,
      requirementData: null,
      trainingProviders: null,
      requirements: [],
      sortedInfo: null,
    };
  }
  //Public
  show(requirementID, trainingProviders, requirements) {
    this._scrollToTop();
    this.setState(
      { isVisible: true, requirementID, requirementData: null, trainingProviders, requirements },
      this._fetchRequirement
    );
  }
  //Private actions
  handleClose() {
    if (this.props.onUpdate) this.props.onUpdate();
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      requirementID: null,
      requirementData: null,
      trainingProviders: null,
      requirements: null,
      sortedInfo: null,
    });
  }
  //Requirement
  async handleSubmitRequirement() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.requirementID) this._updateRequirement(formData);
    else this._createRequirement(formData);
  }
  //Accepted Providers Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Accepted Providers
  handleAddProvider() {
    this.providerModal.show(this.state.requirementData, null);
  }
  handleEditProvider(optionID) {
    this.providerModal.show(this.state.requirementData, optionID);
  }
  handleDeleteProvider(optionID) {
    const requirement = this.state.requirementData;
    requirement.acceptedProviders.splice(
      requirement.acceptedProviders.indexOf(requirement.acceptedProviders.find((o) => o.id == optionID)),
      1
    );
    this._saveRequirementLocallyAndWarn(requirement); //save
  }
  handleProviderOptionModal(data, optionID) {
    const requirement = this.state.requirementData;
    if (optionID)
      requirement.acceptedProviders = requirement.acceptedProviders.map((o) =>
        o.id == optionID ? { ...o, ...data } : o
      );
    else
      requirement.acceptedProviders = requirement.acceptedProviders
        ? requirement.acceptedProviders.concat([data])
        : [data];
    //Save
    this._saveRequirementLocallyAndWarn(requirement);
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.requirementID ? 'Edit' : 'Create'} Requirement`}
        placement="right"
        onClose={this.handleClose}
        width={1000}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <CommonRequirementAcceptedProviderModal
          app={this.props.app}
          trainingProviders={this.state.trainingProviders}
          onSubmit={this.handleProviderOptionModal}
          {...Utils.propagateRef(this, 'providerModal')}
        />
        <Row type="flex" justify="end" className="scrollHere">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleSubmitRequirement}>
              {this.state.requirementID ? 'Save' : 'Create'} Requirement
            </Button>{' '}
          </Col>
        </Row>
        <CommonRequirementForm
          app={this.props.app}
          {...Utils.propagateRef(this, 'form')}
          isEdit={!!this.state.requirementID}
          trainingProviders={this.state.trainingProviders}
          requirements={(this.state.requirements || []).filter((r) => r.id != this.state.requirementID)}
        />
        {this.state.requirementID && (
          <>
            <Divider>Providers</Divider>
            <Row type="flex" justify="end">
              {' '}
              <Col>
                {' '}
                <Button type="primary" onClick={this.handleAddProvider}>
                  Add Provider
                </Button>{' '}
              </Col>{' '}
            </Row>
            {this._renderProvidersTable()}
          </>
        )}
      </Drawer>
    );
  }

  /* private UI */
  _renderProvidersTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (e) => {
          if (e == Globals.Providers_Types.FILE)
            return (
              <>
                <CloudUploadOutlined style={{ marginRight: 7 }} />
                File
              </>
            );
          else if (e == Globals.Providers_Types.EXTERNAL)
            return (
              <>
                <GlobalOutlined style={{ marginRight: 7 }} />
                External
              </>
            );
          else if (e == Globals.Providers_Types.INTERNAL)
            return (
              <>
                <RocketOutlined style={{ marginRight: 7 }} />
                Internal
              </>
            );
          else return '';
        },
      },
      {
        title: 'Training Provider',
        key: 'trainingProviderID',
        dataIndex: 'trainingProviderID',
        sorter: (a, b) =>
          ((this.state.trainingProviders || []).find((p) => p.id == a.trainingProviderID)?.name || '').localeCompare(
            (this.state.trainingProviders || []).find((p) => p.id == b.trainingProviderID)?.name || ''
          ),
        render: (props) => (this.state.trainingProviders || []).find((p) => p.id == props)?.name,
        sortOrder: sortedInfo.columnKey === 'trainingProviderID' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditProvider.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete the Provider '${(this.state.trainingProviders || []).find((p) => p.id == props.trainingProviderID)?.name}' from the accepted providers of this requirement?`}
                  onConfirm={this.handleDeleteProvider.bind(this, props.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No training providers accepetd on this requirement!' },
      onRow: this._onRow,
      pagination: { pageSize: 5, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return (
      <Layout.Content style={{ marginTop: 15 }}>
        <Table
          className="adminAcceptedProvidersTable"
          columns={columns}
          {...props}
          dataSource={this.state.requirementData?.acceptedProviders || []}
        />
      </Layout.Content>
    );
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.handleEditCity(record.id);
      }, // click row
      onDoubleClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.handleEditCity(record.id);
      }, // double click row
    };
  }

  //private helpers
  _saveRequirementLocallyAndWarn(requirementData) {
    message.warning('Please, save this requirement in order to save this change to the accepted providers.');
    this.setState({ requirementData });
  }
  _scrollToTop() {
    const element = document.querySelector('.scrollHere');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }

  // API Calls
  //Requirement
  async _fetchRequirement() {
    if (!this.state.requirementID) return; //avoid loading empty requirements
    this.setState({ isLoading: true });
    const resp = await this.props.app.config.requirement.getRequirement(this.state.requirementID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ requirementData: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _updateRequirement(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.config.requirement.updateRequirement({
      id: this.state.requirementData.id,
      customerID: this.state.requirementData.customerID,
      acceptedProviders: this.state.requirementData.acceptedProviders || [],
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Requirement successfully updated!');
      this._fetchRequirement();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _createRequirement(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const requirement = {
      ...data,
      customerID: this.props.app.sharedCache().getCustomerID(),
      acceptedProviders: [],
      ...(this.props.app.isSysAdmin() && data.id
        ? {
            id: data.id,
          }
        : {
            id: `Requirement-${this.props.app.sharedCache().getCustomerID()}-${this.props.app.sharedCache().getTenantConfig().id}-${config.Stage}-${data.internalName.toLowerCase().replace(/[ ?&/]/g, '')}-${Date.now()}`,
          }),
    };
    const resp = await this.props.app.config.requirement.createRequirement(requirement);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Requirement successfully created!');
      this.show(requirement.id, this.state.trainingProviders, this.state.requirements);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
