import React from 'react';
import autoBind from 'react-autobind';
import CommonDynamicForm from '../../Forms/CommonDynamicForm';
import Utils from '../../../../components/Utils';
import { Button, Checkbox, Col, Divider, Form, Input, Result, Row, Spin } from 'antd';
import Globals from '../../../../config/Globals';
import moment from 'moment';

export default class CommonLicensePurchaseModalStep_InfoConfirmation extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      selectedOrg: null,
      isLoading: false,
      useDifferentShippingAddress: false,
      isSubmitting: false,
      hasChanged: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });

      const user = await this._getUser();

      this.setState({ selectedOrg: user?.employementInfo?.org, isLoading: false }, async () => {
        this.form.setFieldsValue(user);
        await this.form.validateFields();
      });
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }

  handleOrgChange(orgObj) {
    this.setState({ selectedOrg: orgObj }, this.handleValidation);
  }

  handleToggleDifferentShippingAddress(event) {
    this.setState({ useDifferentShippingAddress: event.target.checked });
  }

  async handleValidation() {
    try {
      if (!this.state.hasChanged) {
        this.setState({ hasChanged: true });
      }

      const resp = await this.form.validateFields();

      return { ...resp, ...this.state };
    } catch (err) {
      console.error('error ', err);

      return null;
    }
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    let materialShippingInfo;

    if (this.state.useDifferentShippingAddress) {
      materialShippingInfo = await this.materialShippingInfoForm.validateFields();
      materialShippingInfo.shipToMainAddress = false;
    } else if (formData.shipToMainAddress) {
      materialShippingInfo = {
        unit: formData['address.unit'],
        street0: formData['address.street.0'],
        street1: formData['address.street.1'],
        city: formData['address.city'],
        province: formData['address.province'],
        country: formData['address.country'],
        postalCode: formData['address.postalCode'],
        shipToMainAddress: true,
      };
    } else {
      materialShippingInfo = {
        unit: formData['shipping.address.unit'],
        street0: formData['shipping.address.street.0'],
        street1: formData['shipping.address.street.1'],
        city: formData['shipping.address.city'],
        province: formData['shipping.address.province'],
        country: formData['shipping.address.country'],
        postalCode: formData['shipping.address.postalCode'],
        shipToMainAddress: false,
      };
    }

    if (this.state.hasChanged) {
      this.setState({ isSubmitting: true });
      this.updateUserPartitions(formData, () => {
        this.props.onNext({ materialShippingInfo });
      });
    } else {
      this.props.onNext({ materialShippingInfo });
    }
  }

  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')} style={{ marginTop: 20 }}>
        {this.state.isLoading && (
          <Result status="info" title="Loading" subTitle="Loading your account data..." extra={<Spin />} />
        )}

        {!this.state.isLoading && (
          <>
            <CommonDynamicForm
              isDesktop
              app={this.props.app}
              selectedOrg={this.state.selectedOrg}
              onChange={this.handleValidation}
              additionalInputs={this.props.app.sharedCache().getTenantRegistrationConfig()}
              onOrgChange={this.handleOrgChange}
            />

            {this._renderDifferentShippingAddress()}

            <Row justify="end" style={{ marginTop: 24 }}>
              <Col>
                <Button
                  className="purchaseModalCancelButton"
                  disabled={this.state.isSubmitting}
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>

                <Button disabled={this.state.isSubmitting} onClick={this.props.onPrevious}>
                  Previous
                </Button>

                <Button
                  className="purchaseModalConfirmationButton"
                  type="primary"
                  onClick={this.handleSubmit}
                  loading={this.state.isSubmitting}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }

  _renderDifferentShippingAddress() {
    const { selectedSession } = this.props;

    if (selectedSession?.hasMaterial !== Globals.Session_HasMaterial.INDIVIDUAL) {
      return null;
    }

    return (
      <>
        <Divider orientation="left">
          <Checkbox
            onChange={this.handleToggleDifferentShippingAddress}
            checked={this.state.useDifferentShippingAddress}
          >
            Use a different shipping address
          </Checkbox>
        </Divider>

        {this.state.useDifferentShippingAddress && (
          <Form layout="vertical" {...Utils.propagateRef(this, 'materialShippingInfoForm')}>
            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col offset={1} span={11}>
                <Form.Item name="unit" label="Apt, Suite or Unit">
                  <Input />
                </Form.Item>
              </Col>
              <Col offset={1} span={11}>
                <Form.Item
                  name="street0"
                  label="Mailing Address or PO Box"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col offset={1} span={5}>
                <Form.Item name="city" label="City" rules={[{ required: true, message: 'This field is required!' }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={5}>
                <Form.Item
                  name="province"
                  label="Province"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={5}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col offset={1} span={5}>
                <Form.Item
                  name="postalCode"
                  label="Postal Code"
                  rules={[{ required: true, message: 'This field is required!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </>
    );
  }

  /* API */
  async _getUser() {
    let userID = null;
    if (this.props.user?.id) userID = this.props.user.id;
    else userID = this.props.app.getAuthorizedUserID();
    const promises = [];
    //get user and check for failure
    let userObj = null;
    promises.push(
      new Promise(async (resolve) => {
        userObj = await this.props.app.idm.api.user.read(userID);
        if (userObj.statusCode == 200) {
          userObj = userObj.body;
          resolve(true);
        } else {
          this.props.app.alertController.showAPIErrorAlert(null, userObj);
          resolve(false);
        }
      })
    );

    //Load employment info
    let employementInfo = null;
    const additionalInputs = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
    if (Object.values(additionalInputs || {}).find((f) => f.type == Globals.RegOrgEmploymentSelectionType)) {
      promises.push(
        new Promise(async (resolve) => {
          let employmentResp = await this.props.app.organization.employee.getEmployeeByEmployeeID(userID);
          if (employmentResp.statusCode == 200) employementInfo = employmentResp.body;
          resolve(true);
        })
      );
    }

    //get partitions data
    const wantedParts = this._getAvailablePartitions(true);
    let partData = null;
    if (wantedParts.length > 0) {
      promises.push(
        new Promise(async (resolve) => {
          partData = await this.props.app.idm.api.userPartition.readSome(wantedParts, userID);
          partData = partData?.body?.parts || {};
          resolve(true);
        })
      );
    }

    //Resolve alll, continue if all true
    const resolveAll = await Promise.all(promises);
    if (!resolveAll || resolveAll.find((r) => !r)) return;
    //Return parsed info
    return this._parseUserResponses(userObj, partData, employementInfo);
  }

  _parseUserResponses(userObj, partData, employementInfo) {
    //
    try {
      //Buildup form data
      const formData = {};
      const fields = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
      for (let fieldKey of Object.keys(fields || {})) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0) {
          const part = partData.find((part) => part.partID == field.partitionID);
          formData[field.id] = Utils.getNestedObject(part ? part.value : null, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (field.saveOnUser && !formData[field.id]) {
          formData[field.id] = Utils.getNestedObject(userObj, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (!formData[field.id]) {
          formData[field.id] = ''; /* unknown source */
        }
        if (field.type == 'boolean' || field.type == 'switch') formData[field.id] = !!formData[field.id];
      }
      //Set ship to main address
      const partition = this.props.app.sharedCache().getTenantRegistrationConfig().fields
        ?.shipToMainAddress?.partitionID;
      const shipToMainAddress = !!partition
        ? !!partData?.find((part) => part.partID == partition).value?.shipToMainAddress
        : false;
      //Set form data
      return { ...formData, ...userObj, partData, employementInfo, shipToMainAddress };
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  _getAvailablePartitions(noSanitize) {
    let parts = [];
    const additionalInputs = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
    for (let input of Object.values(additionalInputs || {})) {
      const partID = noSanitize ? input.partitionID : input.partitionID.replace(/\./g, '_');
      if (parts.indexOf(partID) == -1) parts.push(partID);
    }
    return parts;
  }

  async updateUserPartitions(data, onSuccess) {
    //Get all unique partitions in parallel
    const { fields } = this.props.app.sharedCache().getTenantRegistrationConfig();
    const uniqueValidParts = Object.keys(fields || {})
      .filter((f) => fields[f].partitionID && fields[f].partitionID.length > 0)
      .reduce((acc, curr) => {
        if (acc.indexOf(fields[curr].partitionID) == -1) acc.push(fields[curr].partitionID);
        return acc;
      }, []);
    const partsPool = await Promise.all(
      uniqueValidParts.map((p) => this.props.app.idm.session.getPartitionByID(p, true))
    );
    //Buildup part data
    const partData = {};
    //For each unique part
    for (let partitionID of uniqueValidParts) {
      let partition = partsPool.find((p) => p && p.partID == partitionID);
      if (!partition || !partition.partID) partition = { partID: partitionID };
      //Get fields that needs to be saved on this partition
      const fieldsData = {};
      for (let fieldKey of Object.keys(fields || {})) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0 && field.partitionID == partition.partID) {
          if (data[field.id] && field.type == 'date') {
            Utils.setNestedObject(fieldsData, field.id, moment(data[field.id]).format(field.format));
          } else {
            Utils.setNestedObject(fieldsData, field.id, data[field.id]);
          }
        }
      }
      //Build part from old values + overrides
      partData[partition.partID] = { value: { ...(partition.value || {}), ...fieldsData } };
    }
    // Get current logged user ID from session authorization
    let userID = null;
    if (this.props.user?.id) userID = this.props.user.id;
    else userID = await this.props.app.idm.session.authorization.getUserID();
    // Send API request to IDM, setting paritition for user X on partition Y with value Z
    const resp = await this.props.app.idm.api.userPartition.setMultiple({ parts: partData }, userID);
    //Invalidate old partition values
    await Promise.all(uniqueValidParts.map((p) => this.props.app.idm.session.invalidatePartitionByID(p)));
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('update user partitions with success');
      onSuccess();
    } else {
      // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
      // Other status codes are not expected, 500 means de role API is down and it should be
      // handled as a unknown error for it rarity
      console.log('ERROR while updating user partitions');
      this.props.app.alertController.showAPIErrorAlert('Error', resp);
      return false;
    }
    return true;
  }
}
