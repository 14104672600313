import React from 'react';
import autoBind from 'react-autobind';

import { Layout, PageHeader, Table, Button, Tooltip, Tag } from 'antd';
import { EditOutlined, ReloadOutlined } from '@ant-design/icons';

import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';

import Utils from '../../components/Utils';
import config from '../../config/config';

export default class AdminPendingStudentsRequestsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const approvals = this.props.app.sharedCache().getPendingApprovals();

    this.state = {
      isLoading: false,
      approvals,
    };
  }

  handleReview(id) {
    this.props.app.urlManager.pushPageWithThirdID(config.ApplicationRoutes.pendingApprovals, null, id);
  }

  async handleReloadList() {
    this.setState({ isLoading: true });
    await this.props.app.reloadPendingApprovals();
    const approvals = this.props.app.sharedCache().getPendingApprovals();
    this.setState({ isLoading: false, approvals });
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Certification',
        dataIndex: 'certTitle',
        render: (certTitle) => (certTitle ? certTitle : '-'),
      },
      {
        title: 'Course',
        dataIndex: 'displayName',
        render: (displayName) => (displayName ? displayName : '-'),
      },
      {
        title: 'Request date',
        dataIndex: 'updatedOn',
        render: (date) => Utils.getDateOnUIFormatByTimestamp(date),
      },
      {
        title: '',
        align: 'right',
        render: (approval) => (
          <Tooltip placement="bottomLeft" title="Review">
            <Button
              variant="none"
              icon={<EditOutlined />}
              shape="circle"
              onClick={() => this.handleReview(approval.id)}
            />
          </Tooltip>
        ),
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />

        <PageHeader
          className="pageHeader"
          title="Students Requests"
          subTitle={
            <Tooltip title="Refresh">
              <Button icon={<ReloadOutlined />} onClick={this.handleReloadList} />
            </Tooltip>
          }
        />
        <Layout.Content>
          <Table
            rowKey="id"
            dataSource={this.state.approvals}
            loading={this.state.isLoading}
            columns={columns}
            pagination={{
              hideOnSinglePage: true,
            }}
            onRow={(approval) => ({
              onClick: this.handleReview.bind(this, approval.id),
            })}
          />
        </Layout.Content>
      </Layout.Content>
    );
  }
}
