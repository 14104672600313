import React from 'react';
import autoBind from 'react-autobind';
import { isMobile } from 'react-device-detect';
import ReactMarkdown from 'react-markdown';
import { Form, Input, Divider, Col, Row, Select, InputNumber, Button, Tooltip, Typography, Alert, Popover } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
//
import Utils from '../../../../../components/Utils';
//
const styleHidden = { style: { display: 'none' } };
//
export default class CommonProductPurchaseModalStep_OverviewView extends React.Component {
  /**
   * Creates an instance of CommonProductPurchaseModalStep_OverviewView.
   *
   * @constructor
   * @param { {
   * product
   * products
   * app
   * isLoading
   * selectedObjectIndex
   * quantity
   * taxAmount
   * totalAmount
   * discountAmount
   * chargingAmount
   * shippingAmount
   * fixedQuantity
   * onPrevious
   * hasPrevious
   * onChange
   * onCancel
   * onNext
   * onVoucherApply
   * selectedReferral
   * applingVoucher
   * validatedVoucher
   * isHiddenVoucher
   * disableProductSelection
   * } } props
   */
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { referralOrgs: [], selectedReferral: null };
  }
  componentDidMount() {}
  handleChangeReferral(value) {
    const org = this.state.referralOrgs.find((org) => org.id == value);

    if (!org) return;

    this.props.onChange({ id: org.id, name: org.name }, 'selectedReferral');
  }
  //Public
  async validateFields() {
    return this.form.validateFields();
  }
  //Actions
  async handleVoucherApply() {
    const resp = await this.form.validateFields(['voucherCode']);
    if (resp) this.props.onVoucherApply(resp.voucherCode);
  }
  //UI
  render() {
    const discount = this.props.discountAmount > 0 ? this.props.discountAmount * this.props.quantity * -1 : 0;
    const productLabel = Utils.capitalizeString(
      this.props.products && this.props.products[0] //you can hate Gabriel for that :p
        ? this.props.products.reduce((p, c) => {
            const term = 'Product';
            if (this.props.products?.length == 1) return term;
            return p != term ? p : 'Product';
          }, '')
        : `Product`
    );
    return (
      <Form key="product" {...Utils.propagateRef(this, 'form')}>
        <Divider orientation="left">Product Details</Divider>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={productLabel} rules={[{ required: true, message: `Please, select an option!` }]}>
              <Select
                onChange={(val) => this.props.onChange(val, 'object')}
                value={this.props.selectedObjectIndex}
                disabled={this.props.disableProductSelection}
              >
                {this.props.products &&
                  this.props.products.map((item, index) => {
                    const productSpecs = item;
                    return (
                      <Select.Option value={index} key={index}>
                        {productSpecs.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Popover
              title="Optional purchase"
              content={
                <ReactMarkdown
                  children={this.props.app.sharedCache().getTenantConfig().licModPurchaseMessage}
                  linkTarget="_blank"
                />
              }
            >
              <span style={{ marginLeft: 10, color: 'red' }}>Optional purchase</span>
              <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10, marginTop: 5 }} />
            </Popover>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 10}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Product </Typography.Text>
            </div>
            <Form.Item>
              <Input value={this.props.product?.name} disabled className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Price </Typography.Text>
            </div>
            <Form.Item>
              <Tooltip title={'The original price of the product.'} placement="topLeft">
                <InputNumber
                  value={this.props.chargingAmount}
                  decimalSeparator="."
                  precision={2}
                  disabled={!this.props.app.isAdmin()}
                  className={!this.props.app.isAdmin() ? 'purchaseModalInputBoderless' : ''}
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  onChange={(val) => this.props.onChange(val, 'amount')}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 2}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Quantity </Typography.Text>
            </div>
            <Form.Item name="quantity" rules={[{ min: 1, message: 'Please, inform the desired quantity amount!' }]}>
              <Tooltip title={'The number of licenses (activations) you are purchasing.'} placement="topLeft">
                <InputNumber
                  precision={0}
                  step={1}
                  min={1}
                  value={this.props.quantity}
                  disabled={this.props.fixedQuantity}
                  onChange={(val) => this.props.onChange(val, 'quantity')}
                  max={
                    this.props.validatedVoucher && this.props.validatedVoucher.maxQuantity > 0
                      ? this.props.validatedVoucher.maxQuantity
                      : 99999
                  }
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Shipping </Typography.Text>
            </div>
            <Form.Item
              name="shippingAmount"
              rules={[{ min: 1, message: 'Please, inform the desired shipping price!' }]}
            >
              <Tooltip title={'The shipping price for the product'} placement="topLeft">
                <InputNumber
                  value={this.props.shippingAmount}
                  decimalSeparator="."
                  precision={2}
                  disabled={!this.props.app.isAdmin() || !this.props.product?.requiresShipping}
                  className={
                    !this.props.app.isAdmin() || !this.props.product?.requiresShipping
                      ? 'purchaseModalInputBoderless'
                      : ''
                  }
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  onChange={(val) => this.props.onChange(val, 'shippingAmount')}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 3}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Subtotal </Typography.Text>
            </div>
            <Form.Item>
              <InputNumber
                disabled
                precision={2}
                decimalSeparator="."
                value={(this.props.chargingAmount + this.props.shippingAmount) * (this.props.quantity || 0)}
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <Tooltip title={'Discounts can be applied by using cupom codes.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                  value={discount}
                />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                value={this.props.taxAmount}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={this.props.totalAmount}
              />
            </Form.Item>
          </Col>
        </Row>
        {this._renderVoucherRow()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }

  /* Private UI */
  _renderVoucherRow() {
    //isHiddenVoucher validatedVoucher applingVoucher
    //{valid discPercent discAmount}
    let validationStatus = null;
    if (this.props.applingVoucher) validationStatus = 'validating';
    if (this.props.validatedVoucher) {
      if (this.props.validatedVoucher.valid == true) validationStatus = 'success';
      else validationStatus = 'error';
    }
    //
    return (
      <Row type="flex" justify="end" {...(this.props.isHiddenVoucher ? styleHidden : {})}>
        <Col span={7}>
          <Form.Item
            label="Voucher Code"
            name="voucherCode"
            {...(validationStatus ? { validateStatus: validationStatus, hasFeedback: true } : {})}
          >
            <Input id="voucherCode" allowClear />
          </Form.Item>
        </Col>
        <Col>
          <Button
            className="fixedInputButton_Voucher"
            loading={this.props.applingVoucher || this.props.isLoading}
            onClick={this.handleVoucherApply}
            disabled={this.props.applingVoucher || this.props.isLoading}
            type="primary"
          >
            Apply
          </Button>
        </Col>
      </Row>
    );
  }
  _renderButtonsFooter() {
    return (
      <>
        <Row type="flex" justify="end">
          <Divider />
          <Button
            className="purchaseModalCancelButton"
            disabled={this.props.isLoading || this.props.applingVoucher}
            key="back"
            onClick={this.props.onCancel}
          >
            {' '}
            Cancel{' '}
          </Button>
          {this.props.hasPrevious && (
            <Button disabled={this.props.isLoading} onClick={this.props.onPrevious}>
              {' '}
              Previous{' '}
            </Button>
          )}
          <Button
            key="submit"
            type="primary"
            loading={this.props.isLoading || this.props.applingVoucher}
            onClick={this.props.onNext}
            disabled={this.props.isLoading || this.props.applingVoucher || !this.props.quantity}
            className="purchaseModalConfirmationButton"
          >
            {' '}
            Next{' '}
          </Button>
        </Row>
      </>
    );
  }
}
