import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Row, Col, Tooltip, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import CommonInstructorCoursePopover from '../Popovers/CommonInstructorCoursePopover';
//props are: instructorCourses, courses, app, onDelete, onAdd
export default class CommonInstructorCoursesForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    console.log(this.props.courses);
    const columns = [
      {
        title: 'Course',
        key: 'name',
        sorter: (a, b) =>
          (this.props.courses.find((r) => r.id == a.courseID)?.description || '').localeCompare(
            this.props.courses.find((r) => r.id == b.courseID)?.description || ''
          ),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: (props) => this.props.courses.find((r) => r.id == props.courseID)?.description,
      },
      {
        title: 'Added On',
        key: 'createdOn',
        width: '15%',
        render: (props) => (props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete the course '${this.props.courses.find((r) => r.id == props.courseID)?.displayName}' from this instructor?`}
                  onConfirm={this.props.onDelete.bind(this, props.courseID)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];

    const props = {
      rowKey: 'courseID',
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No courses related to this instructor!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <>
        <Row type="flex" justify="end">
          <Col>
            <CommonInstructorCoursePopover
              app={this.props.app}
              onSelect={this.props.onSelect}
              courses={this.props.courses}
              instructorCourses={this.props.instructorCourses}
            >
              <Button style={{ marginBottom: 20 }} type="primary">
                {' '}
                Add Course{' '}
              </Button>
            </CommonInstructorCoursePopover>
          </Col>
        </Row>
        <Table
          className="commonInstructorCoursesTable"
          columns={columns}
          dataSource={this.props.instructorCourses || []}
          {...props}
        />
      </>
    );
  }
}
