import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Alert, InputNumber, Button, Tooltip, Typography, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import { loadStripe } from '@stripe/stripe-js';
//
import { PAYMENT_TYPE } from './CommonProductPurchaseModalStep_PaymentView_Federated';
import Utils from '../../../../../components/Utils';
//
const styleHidden = { style: { opacity: 0 } };
export default class CommonProductPurchaseModalStep_SummaryView_Stripe extends React.Component {
  /**
   * Creates an instance of CommonProductPurchaseModalStep_SummaryView_Stripe.
   *
   * @constructor
   * @param {{
   * onCancel
   * onPrevious
   * onNext
   * app
   * isLoading
   * payload
   * product
   * quantity
   * taxAmount
   * totalAmount
   * chargingAmount
   * user
   * discountAmount
   * }} props
   */
  constructor(props) {
    super(props);
    autoBind(this);
    this.stripeAPI = null;
    this.stripeElements = null;
    this.state = {
      firstName: '',
      lastName: '',
      purchaserName: 'loading...',
      purchaseMethod: '',
      paymentMethod: PAYMENT_TYPE.CARD,
      loadingStripe: false,
      paymentMethodID: null,
    };
  }
  setSensitiveInfo(data) {
    this.setState(
      {
        purchaserName: `${data.firstName} ${data.lastName}`,
        firstName: data.firstName,
        lastName: data.lastName,
        paymentMethod: data.paymentMethod,
        purchaseMethod: data.paymentMethod == PAYMENT_TYPE.CARD ? '(Credit Card)' : 'Invoice',
        loadingStripe: true,
      },
      () => {
        //load stripe
        if (data.paymentMethod == PAYMENT_TYPE.CARD) this._loadStripeSDK();
      }
    );
  }
  //Validation
  async validateFields() {
    return this.form.validateFields();
  }
  //Actions
  async handleSubmit() {
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) {
      await this._createStripePaymentMethod();
      if (this.state.paymentMethodID) {
        await this.props.onNext(this.state);
      }
    }
  }
  async handlePreviousAction() {
    const resp = await this.form.validateFields();
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) this.checkoutGateway.closeCheckout();
    if (resp) this.props.onPrevious({ ...resp, ...this.state });
  }
  //UI
  render() {
    return (
      <Form {...Utils.propagateRef(this, 'form')} key="summary" id="summary">
        {this.state.paymentMethod == PAYMENT_TYPE.CARD ? this._renderStripeCheckout() : this._renderInvoiceSummary()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }
  /* Private UI */
  _renderStripeCheckout() {
    return (
      <Row type="flex" align="center">
        <div style={{ width: '90%', height: this.state.loadingStripe ? 250 : 450, marginTop: 20 }}>
          {this.state.loadingStripe && (
            <Row type="flex" justify="center" style={{ marginTop: '130px' }}>
              <Col>
                {' '}
                <Spin spinning size="large"></Spin>{' '}
              </Col>
            </Row>
          )}
          <div
            {...(this.state.loadingStripe ? styleHidden : { style: { opacity: 1 } })}
            id="paymentProcessorContainer"
          ></div>
        </div>
      </Row>
    );
  }
  _renderInvoiceSummary() {
    const discount = this.props.discountAmount > 0 ? this.props.discountAmount * this.props.quantity * -1 : 0;
    return (
      <>
        <Divider orientation="left">Order Summary</Divider>
        <Row type="flex" justify="start">
          <Typography.Text strong>Please, confirm the order information carefully:</Typography.Text>
        </Row>
        <Divider orientation="left" className="subdividers">
          Order
        </Divider>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 8}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Product </Typography.Text>
            </div>
            <Form.Item>
              <Input value={this.props.product.name} disabled className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Price </Typography.Text>
            </div>
            <Form.Item>
              <Tooltip title={'The original price of the product.'} placement="topLeft">
                <InputNumber
                  name="amount"
                  value={this.props.chargingAmount}
                  decimalSeparator="."
                  precision={2}
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 2}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 2}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Quantity </Typography.Text>
            </div>
            <Form.Item name="quantity">
              <Tooltip title={'The number of licenses (activations) you are purchasing.'} placement="topLeft">
                <InputNumber
                  precision={0}
                  step={1}
                  min={0}
                  value={this.props.quantity}
                  disabled
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 6}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              <Typography.Text> Subtotal </Typography.Text>
            </div>
            <Form.Item>
              <InputNumber
                disabled
                precision={2}
                decimalSeparator="."
                value={this.props.chargingAmount * this.props.quantity}
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <Tooltip title={'Discounts can be applied by using cupom codes.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  value={discount}
                  className="purchaseModalInputBoderless"
                />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                value={this.props.taxAmount}
                className="purchaseModalInputBoderless"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 3}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={this.props.totalAmount}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" className="subdividers">
          Billing
        </Divider>
        <Row type="flex" justify="start">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text>Payment method: {this.state.purchaseMethod}</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text>Billing name: {this.state.purchaserName}</Typography.Text>
          </Col>
        </Row>
      </>
    );
  }
  _renderButtonsFooter() {
    const isCard = this.state.paymentMethod == PAYMENT_TYPE.CARD;
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button
          className="purchaseModalCancelButton"
          disabled={this.props.isLoading}
          key="back"
          onClick={this.props.onCancel}
        >
          {' '}
          Cancel{' '}
        </Button>
        {!isCard && (
          <Button disabled={this.props.isLoading} onClick={this.handlePreviousAction}>
            {' '}
            Previous{' '}
          </Button>
        )}
        <Button
          form="summary"
          key="submit"
          type="primary"
          loading={this.props.isLoading}
          disabled={this.props.isLoading}
          onClick={this.handleSubmit}
          className="purchaseModalConfirmationButton"
        >
          {' '}
          Submit{' '}
        </Button>
      </Row>
    );
  }
  /* Private Stripe */
  async _loadStripeSDK() {
    this.stripeAPI = await loadStripe(this.props.payload?.providerEnv); //TODO
    //
    const options = { wallets: { applePay: 'never', googlePay: 'never' } };
    this.stripeElements = this.stripeAPI.elements({
      clientSecret: this.props.payload?.ticketID,
      paymentMethodCreation: 'manual',
    });
    const paymentElement = this.stripeElements.create('payment', options);
    paymentElement.mount('#paymentProcessorContainer');
    this.setState({ loadingStripe: false });
  }
  async _createStripePaymentMethod() {
    if (!this.stripeAPI || !this.stripeElements) {
      return;
    }
    this.setState({ isProcessing: true });
    // Create payment method
    const sumbmit = await this.stripeElements.submit();
    if (sumbmit?.error) {
      this.setState({ isProcessing: false });
      return;
    }
    const result = await this.stripeAPI.createPaymentMethod({
      //`Elements` instance that was used to create the Payment Element
      elements: this.stripeElements,
    });
    if (result?.error) {
      this.setState({ message: `Payment method collection failed: ${result?.error?.message}`, isProcessing: false });
      return;
    }
    this.setState({ paymentMethodID: result?.paymentMethod?.id, isProcessing: false });
  }
}
