const DropInSDK = require('@ikonintegration/braintree-web');
const SDKVaultLocalization = require('../config/brainTreeVaultLocalization');
require('@ikonintegration/braintree-web/dropin.css');
//
export default class PaymentManager {
  constructor(auth) {
    this.auth = auth;
    this.isLoading = false;
  }
  /* BrainTree Drop-In SDK integration */
  async initializeVaultSDKOnContainer(containerID, nonce) {
    return new Promise((resolve, reject) => {
      DropInSDK.create(
        {
          authorization: nonce,
          container: containerID,
          translations: SDKVaultLocalization,
          vaultManager: { preventDeletingPaymentMethodsWithSubscriptions: true },
          card: { cardholderName: { required: true } },
          showDefaultPaymentMethodFirst: true,
          preselectVaultedPaymentMethod: true,
        },
        (err, dropinInstance) => {
          if (err) reject(err);
          else resolve(dropinInstance);
        }
      );
    });
  }
  async initializePaymentFlowSDKOnContainer(containerID, nonce) {
    return new Promise((resolve, reject) => {
      DropInSDK.create(
        {
          authorization: nonce,
          container: containerID,
          translations: SDKVaultLocalization,
          vaultManager: { preventDeletingPaymentMethodsWithSubscriptions: true },
          card: { cardholderName: { required: true } },
          showDefaultPaymentMethodFirst: true,
          preselectVaultedPaymentMethod: true,
        },
        (err, dropinInstance) => {
          if (err) reject(err);
          else resolve(dropinInstance);
        }
      );
    });
  }
}
