import { CheckCircleFilled } from '@ant-design/icons';
import { CloseCircleFilled } from '@ant-design/icons';

import Globals from '../../../../../config/Globals';

import PaymentRequired from '../OverallStatuses/PaymentRequired';
import Completed from '../OverallStatuses/Completed';
import CourseAvailable from '../OverallStatuses/CourseAvailable';
import Failed from '../OverallStatuses/Failed';
import Cooldown from '../OverallStatuses/Cooldown';
import Locked from '../OverallStatuses/Locked';
import LockedCourse from '../OverallStatuses/LockedCourse';
import Expired from '../OverallStatuses/Expired';
import Scheduled from '../OverallStatuses/Scheduled';
import ReadyToSchedule from '../OverallStatuses/ReadyToSchedule';
import Revoked from '../OverallStatuses/Revoked';
import MissingRequirements from '../OverallStatuses/MissingRequirements';
import WaitingResultRelease from '../OverallStatuses/WaitingResultRelease';
import Invalidated from '../OverallStatuses/Invalidated';
import AccessDenied from '../OverallStatuses/AccessDenied';
import CheckingStatus from '../OverallStatuses/CheckingStatus';

export default function getCardStatusHeaderComponent(status, courseSpecs) {
  const headersByStatus = {
    [Globals.CourseStatuses.PAYMENT_REQUIRED]: <PaymentRequired.CardStatusHeader courseSpecs={courseSpecs} />,
    [Globals.CourseStatuses.COMPLETED]: <Completed.CardStatusHeader courseSpecs={courseSpecs} />,
    [Globals.CourseStatuses.COURSE_AVAILABLE]: <CourseAvailable.CardStatusHeader />,
    [Globals.CourseStatuses.FAILED]: <Failed.CardStatusHeader courseSpecs={courseSpecs} />,
    [Globals.CourseStatuses.COOLDOWN]: <Cooldown.CardStatusHeader />,
    [Globals.CourseStatuses.LOCKED]: <Locked.CardStatusHeader />,
    [Globals.CourseStatuses.LOCKED_COURSE]: <LockedCourse.CardStatusHeader />,
    [Globals.CourseStatuses.EXPIRED]: <Expired.CardStatusHeader />,
    [Globals.CourseStatuses.SCHEDULED]: <Scheduled.CardStatusHeader />,
    [Globals.CourseStatuses.READY_TO_SCHEDULE]: <ReadyToSchedule.CardStatusHeader courseSpecs={courseSpecs} />,
    [Globals.CourseStatuses.REVOKED]: <Revoked.CardStatusHeader />,
    [Globals.CourseStatuses.MISSING_REQUIREMENTS]: <MissingRequirements.CardStatusHeader />,
    [Globals.CourseStatuses.WAITING_RESULT_RELEASE]: <WaitingResultRelease.CardStatusHeader />,
    [Globals.CourseStatuses.INVALIDATED]: <Invalidated.CardStatusHeader courseSpecs={courseSpecs} />,
    [Globals.CourseStatuses.ACCESS_DENIED]: <AccessDenied.CardStatusHeader />,
    [Globals.CourseStatuses.CHECKING_STATUS]: <CheckingStatus.CardStatusHeader />,
    PassUiTerm: (
      <span className="success">
        <CheckCircleFilled /> {courseSpecs.passUiTerm}
      </span>
    ),
    FailUiTerm: (
      <span className="danger">
        <CloseCircleFilled /> {courseSpecs.failUiTerm}
      </span>
    ),
  };

  return headersByStatus[status] || null;
}
