import React from 'react';
import autoBind from 'react-autobind';
import { Alert, Row, Col, Layout, PageHeader, message } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonOrgForm from '../commonComponents/Forms/CommonOrgForm';
//
import config from '../../config/config';
import Utils from '../../components/Utils';
//
export default class AdminRegisterOrgView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }

  async handleSubmit(data) {
    this.startLoading();
    const { selectedOrg, ...ds } = data;
    //Generate voucherID
    if (!this.props.app.isUser() && !this.props.app.isOrgManager()) {
      const voucherID = await this._createVoucher(ds, selectedOrg);
      if (!voucherID) {
        this.stopLoading();
        return; //stop, error is displayed already
      }
      data.metadata.voucherID = voucherID; //assign voucher ID
    }
    //Register Org
    const orgID = await this._registerOrganization(ds, selectedOrg);
    if (!orgID) {
      this.stopLoading();
      return; //stop, error is displayed already
    }
    //Send to org view
    const isAdmins = !this.props.app.isUser() && !this.props.app.isOrgManager();
    if (isAdmins) this.props.app.urlManager.replacePage(config.ApplicationRoutes.editOrg, null, orgID);
    else {
      message.loading('Please, allow a few seconds to have your organization configured!', 30);
      await Utils.sleep(30000);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage);
      window.location.reload();
    }
  }

  render() {
    const isFullForm = !this.props.app.isUser() && !this.props.app.isOrgManager();
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader
            className="pageHeader"
            title="Register an Organization"
            onBack={() => this.props.app.urlManager.pushBack()}
          />
          <Layout.Content>
            {!isFullForm && this._renderUserSelfRegisterInformation()}
            <CommonOrgForm
              onSubmit={this.handleSubmit}
              isLoading={this.state.isLoading}
              app={this.props.app}
              {...Utils.propagateRef(this, 'form')}
            />
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* private UI */
  _renderUserSelfRegisterInformation() {
    return (
      <Row type="flex" justify="center" style={{ marginBottom: 20 }}>
        <Col span={16}>
          <Alert
            type="warning"
            showIcon
            title="Organization self register operation!"
            message="Attention, this operation will only be allowed if your organization is already added into our database but is not registered within this program yet! By doing so you confirm you are responsible for the organization and will become its manager when the registration operation is completed."
          />
        </Col>
      </Row>
    );
  }

  /* private API calls */
  async _createVoucher(data, org) {
    const resp = await this.props.app.license.voucher.createVoucher({
      description: `Org: ${org.name} Voucher - Auto generated on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(
        Date.now()
      )}`,
      productID: this.props.app.sharedCache().getAllProductsID(),
      discAmount: data.metadata?.discAmount || 0,
      discPercent: data.metadata?.discPercent || 0,
      userIDRestriction: [org.id],
      isPrivate: true,
      includeAllProducts: true,
    });
    if (resp.statusCode == 200 && resp.body && resp.body.voucherID) {
      return resp.body.voucherID;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  async _registerOrganization({ metadata, isAffiliate, notes }, org) {
    const resp = await this.props.app.organization.organizationApp.createOrganizationApp(
      metadata,
      isAffiliate,
      org.id,
      org.hashedWorksafeID || '',
      notes
    );
    if (resp.statusCode == 200 && resp.body && resp.body.id) {
      const tenantConfig = this.props.app.sharedCache().getTenantConfig();
      message.success(`Org successfully registered to ${tenantConfig.organizationName}`);
      return resp.body.id;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
}
