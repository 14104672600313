import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Typography, Tag, Tooltip, Button, Popconfirm } from 'antd';
import { EditOutlined, UndoOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import CommonLicenseInvoiceApprovalModal from './Modals/CommonLicenseInvoiceApprovalModal';
//
export default class CommonLicenseOrdersTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      sortedInfo: null,
      isApproveRejectModalOpened: false,
      sortedInfo: { columnKey: 'createdOn', order: 'descend' },
    };
  }

  //Actions
  //Approve Reject
  handleApproveOrRejectModal(record) {
    if (!this.state.isApproveRejectModalOpened && record) this.approvalModal.loadModalInfo(record);
    this.setState({ isApproveRejectModalOpened: !this.state.isApproveRejectModalOpened }, () => {
      if (!this.state.isApproveRejectModalOpened) this.props.onApproval();
    });
  }
  //Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  //UI
  render() {
    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No orders found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <>
        <CommonLicenseInvoiceApprovalModal
          app={this.props.app}
          onChange={this.handleApproveOrRejectModal}
          isVisible={this.state.isApproveRejectModalOpened}
          {...Utils.propagateRef(this, 'approvalModal')}
        />
        <Table
          className="adminSearchUsersTable"
          onRow={this._onRow}
          columns={this._getTableColumns()}
          dataSource={this.props.orders}
          scroll={{ x: true }}
          sticky
          {...props}
        />
      </>
    );
  }

  // private ui
  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    return [
      {
        title: 'Date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: '10%',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '--'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      this.props.purchaserVisible
        ? {
            title: 'Purchaser',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
          }
        : null,
      {
        title: 'License ID',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: 'Product',
        dataIndex: 'productID',
        key: 'productID',
        width: '15%',
        sorter: (a, b) => a.productID.localeCompare(b.productID),
        sortOrder: sortedInfo.columnKey === 'productID' && sortedInfo.order,
      },
      {
        title: 'Licenses',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '10%',
        sorter: (a, b) => a.quantity.localeCompare(b.quantity),
        sortOrder: sortedInfo.columnKey === 'quantity' && sortedInfo.order,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '10%',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        render: (type) => {
          return (
            <Tag icon={Globals.getIconByOrderType(type)} color={Globals.getColorByOrderType(type)}>
              {type || ''}
            </Tag>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: (status) => {
          return <Tag color={Globals.getColorByOrderState(status)}>{status}</Tag>;
        },
      },
      {
        title: 'Voucher ID',
        key: 'voucherID',
        width: '15%',
        render: (order) => (order.voucherID && !order.isVoucherPrivate ? order.voucherID : '--'),
        sorter: (a, b) => a.voucherID.localeCompare(b.voucherID),
        sortOrder: sortedInfo.columnKey === 'voucherID' && sortedInfo.order,
      },
      {
        title: 'Total ($)',
        key: 'value',
        width: '10%',
        // render: value => `$${Utils.toCurrencyFormat(value)}`,
        render: (order) => {
          if (order.status == Globals.LicenseOrder_State.REFUNDED) {
            return (
              <div>
                <Typography.Text type="danger" delete>
                  ${Utils.toCurrencyFormat(order.value)}
                </Typography.Text>
                <Typography.Text style={{ marginLeft: 4 }}>
                  ${Utils.toCurrencyFormat(order.value - (order.refundAmount || 0))}
                </Typography.Text>
              </div>
            );
          } else return order.value != undefined ? `$${Utils.toCurrencyFormat(order.value)}` : '';
        },
        sorter: (a, b) => a.value - b.value,
        sortOrder: sortedInfo.columnKey === 'value' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: '100px',
        key: 'Actions',
        align: 'middle',
        render: (props) => {
          console.log(props);
          return (
            <span className="tableButtonContainer">
              {props.providerTransactionID &&
                Object.values(Globals.LicenseOrder_ReceiptEnabled_Providers).includes(props.providerID) && (
                  <Tooltip placement="bottomLeft" title="Download Receipt">
                    <Button
                      variant="none"
                      icon={<DownloadOutlined />}
                      style={{ marginRight: 6 }}
                      shape="circle"
                      onClick={() => this.props.onReceiptDownload(props)}
                    />
                  </Tooltip>
                )}
              {props.status == Globals.LicenseOrder_State.INITIATED && props.type == Globals.LicenseOrder_Type.CARD && (
                <Tooltip placement="bottomLeft" title="Cancel">
                  <Popconfirm
                    placement="top"
                    title="Are you sure that you want to cancel this license order?"
                    onConfirm={() => this.props.onCancel(props)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button variant="none" icon={<CloseOutlined />} shape="circle" />
                  </Popconfirm>
                </Tooltip>
              )}
              {props.status == Globals.LicenseOrder_State.COMPLETED &&
                props.type == Globals.LicenseOrder_Type.CARD &&
                this.props.app.isAdmin() && (
                  <Tooltip placement="bottomLeft" title="Refund">
                    <Button
                      variant="none"
                      icon={<UndoOutlined />}
                      shape="circle"
                      onClick={() => this.props.onRefund(props)}
                    />
                  </Tooltip>
                )}
              {props.status == Globals.LicenseOrder_State.PENDING &&
                props.type == Globals.LicenseOrder_Type.INVOICE &&
                this.props.app.isAdmin() && (
                  <Tooltip placement="bottomLeft" title="Approve/Reject">
                    <Button
                      variant="none"
                      icon={<EditOutlined />}
                      shape="circle"
                      onClick={this.handleApproveOrRejectModal.bind(this, props)}
                    />
                  </Tooltip>
                )}
            </span>
          );
        },
      },
    ].filter(Boolean); // filter to remove null positions
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        if (this.props.onSelectRow) this.props.onSelectRow(record);
      },
      onDoubleClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        if (this.props.onSelectRow) this.props.onSelectRow(record);
      },
    };
  }
}
