import React from 'react';
import autoBind from 'react-autobind';
import { Form, Row, Radio, Tooltip, Divider, Col, Input, InputNumber, Alert } from 'antd';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//props: app, isEdit
export default class CommonCourseProviderForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { type: Globals.CourseProvider_Type.D2L };
  }
  //Public
  resetFields() {
    this.form.resetFields();
    this.setState({ type: Globals.CourseProvider_Type.D2L });
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({
      ...data,
      ...(data?.provider || {}),
      ...(data?.provider.SSO || {}),
      type: data.type || Globals.CourseProvider_Type.D2L,
      ...(data.provider?.PublicKey
        ? { PublicKey: Buffer.from(data.provider?.PublicKey, 'base64').toString('ascii') }
        : {}),
    });
    this.setState({ type: data.type || Globals.CourseProvider_Type.D2L });
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return {
      id: formData.id,
      type: this.state.type,
      onlineTemplateIDs: [],
      ...(this.state.type == Globals.CourseProvider_Type.D2L
        ? {
            provider: {
              orgID: formData.orgID,
              APIEndpoint: formData.APIEndpoint,
              APIPort: formData.APIPort,
              APIID: formData.APIID,
              APIKey: formData.APIKey,
              APIUserID: formData.APIUserID,
              APIUserKey: formData.APIUserKey,
              learnerRole: formData.learnerRole,
              SSO: { iCode: formData.iCode, key: formData.key },
            },
          }
        : {
            provider: {
              APIEndpoint: formData.APIEndpoint,
              APIKey: formData.APIKey,
              launchURL: formData.launchURL,
              AccessKey: formData.AccessKey,
              SecretKey: formData.SecretKey,
              PublicKey: Buffer.from(formData.PublicKey || '').toString('base64'),
            },
          }),
    };
  }
  async handleTypeChange(type) {
    this.setState({ type: type.target.value });
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={24}>
            <Form.Item label="Unique ID" name="id" rules={[{ validator: Utils.validateUniqueID }, { required: false }]}>
              <Input disabled={this.props.isEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {this._renderDisabledTooltip(
              <Form.Item label="Provider Type" name="type" initialValue={this.state.type}>
                <Radio.Group
                  onChange={this.handleTypeChange}
                  value={this.state.type}
                  disable={this.props.isEdit}
                  optionType="button"
                  buttonStyle="solid"
                  options={[
                    { value: Globals.CourseProvider_Type.D2L, label: 'D2L - Brightspace' },
                    { value: Globals.CourseProvider_Type.TCP, label: 'TCP - Deejays' },
                  ]}
                />
              </Form.Item>,
              this.props.isEdit
            )}
          </Col>
        </Row>
        {this.state.type == Globals.CourseProvider_Type.D2L && this._renderD2LForm()}
        {this.state.type == Globals.CourseProvider_Type.TCP && this._renderTCPForm()}
      </Form>
    );
  }

  /* D2L form */
  _renderD2LForm() {
    return (
      <>
        <Divider>D2L - Brightspace Configuration</Divider>
        <Row>
          <Col span={15}>
            <Form.Item
              label="Endpoint"
              name="APIEndpoint"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={3}>
            <Form.Item
              label="Port"
              name="APIPort"
              rules={[{ required: true, message: 'This field is required! (normally it should be 443)' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col offset={1} span={4}>
            <Form.Item label="Org ID" name="orgID" rules={[{ required: true, message: 'This field is required!' }]}>
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="API ID"
              name="APIID"
              rules={[{ required: true, message: 'This field is required!' }]}
              extra="Can be retrieved on the D2L UI. Options UI > Extensibility Management > Register App"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={11}>
            <Form.Item label="API Key" name="APIKey" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="API User ID"
              name="APIUserID"
              rules={[{ required: true, message: 'This field is required!' }]}
              extra="Can be generated on https://apitesttool.desire2learnvalence.com"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={11}>
            <Form.Item
              label="API User Key"
              name="APIUserKey"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Alert
          title="Attention"
          type="warning"
          description={
            <>
              Please, make sure <strong>d2l.Security.Api.TokenTimeout</strong> is <strong>-1</strong> on the provider
              configuration environments!
            </>
          }
        />
        <br></br>
        <Row>
          <Col span={4}>
            <Form.Item
              label="Learner role"
              name="learnerRole"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item label="SSO ICode" name="iCode" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item label="SSO Key" name="key" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderTCPForm() {
    return (
      <>
        <Divider>TCP (Deejays) - Configuration</Divider>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Endpoint"
              name="APIEndpoint"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1} span={11}>
            <Form.Item
              label="API Access Key"
              name="AccessKey"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={1} span={12}>
            <Form.Item
              label="API Secret Key"
              name="SecretKey"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="API Public Key"
              name="PublicKey"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="UI Launch URL"
              name="launchURL"
              rules={[{ required: true, message: 'This field is required!' }]}
              help="Enter the URL to be launched in a new browser tab when the user clicks the Launch button."
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  /* private UI helpers */
  _renderDisabledTooltip(children, conditional) {
    if (!conditional) return children;
    return <Tooltip title="This option is disabled for updates.">{children}</Tooltip>;
  }
}
