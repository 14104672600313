import React from 'react';
import autoBind from 'react-autobind';
import { Form, Row, Col, Button, Table, DatePicker, TimePicker, message, Tooltip, Select } from 'antd';
//
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
//
export default class CommonSessionDraftFormDatesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = { dates: [] };
  }

  // Imperative actions
  setDates(dates) {
    this.setState({ dates });
  }

  getStartAndEndDates() {
    const sortedDates = this.state.dates.sort(
      (a, b) => Utils.getTimestampFromMoment(a?.startDate) - Utils.getTimestampFromMoment(b?.startDate)
    );
    const startDate = sortedDates.map((d) =>
      d.startDate.hour(d.startTime.hours()).minute(d.startTime.minutes()).second(0).toDate().getTime()
    );
    const endDate = sortedDates.map((d) =>
      d.startDate.hour(d.endTime.hours()).minute(d.endTime.minutes()).second(0).toDate().getTime()
    );

    return { startDate, endDate };
  }

  getWeekdays() {
    return this.state.dates.map((date) => ({
      dayOfWeek: Number(date.dayOfWeek),
      startTime: date.startTime.format('HH:mm'),
      endTime: date.endTime.format('HH:mm'),
    }));
  }

  // Actions
  handleChangeDate(value, field, id) {
    const currentEditingDate = this.state.dates.find((date) => date.id == id);
    if (field == 'startTime' && currentEditingDate.endTime && !value.isSameOrBefore(currentEditingDate.endTime)) {
      message.error('The start time should be inferior to the end time.');
      return;
    }

    if (field == 'endTime' && currentEditingDate.startTime && !value.isSameOrAfter(currentEditingDate.startTime)) {
      message.error('The end time should be greater than the start time.');
      return;
    }

    if (field == 'startDate') {
      const dateAlreadyAdded = this.state.dates.find(
        (date) => date.startDate?.format('YYYY-MM-DD') == value.format('YYYY-MM-DD')
      );

      if (dateAlreadyAdded) {
        message.error('This date is already added to the list.');
        return;
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      dates: prevState.dates.map((date) => (date.id == id ? { ...date, [field]: value } : date)),
    }));
  }

  handleAddDate() {
    this.setState((prevState) => ({
      ...prevState,
      dates: [...prevState.dates, { id: new Date().getTime(), dayOfWeek: null, startDate: null, endDate: null }],
    }));
  }

  handleDeleteDate(id) {
    this.setState((prevState) => ({ ...prevState, dates: prevState.dates.filter((date) => date.id != id) }));
  }

  // UI
  render() {
    const { weekDayMode } = this.props;

    const columns = [
      {
        title: 'Date',
        key: 'date',
        render: (props) => {
          const date = this.state.dates.find((date) => date.id == props.id) || {};

          return (
            <>
              {!weekDayMode && (
                <DatePicker
                  placeholder="Date"
                  onChange={(value) => this.handleChangeDate(value, 'startDate', props.id)}
                  value={date.startDate ?? null}
                />
              )}
              {weekDayMode && (
                <Select
                  placeholder="Week day"
                  onChange={(value) => this.handleChangeDate(value, 'dayOfWeek', props.id)}
                  value={date.dayOfWeek ?? null}
                  style={{ width: 150 }}
                >
                  <Select.Option value="0">Sunday</Select.Option>
                  <Select.Option value="1">Monday</Select.Option>
                  <Select.Option value="2">Tuesday</Select.Option>
                  <Select.Option value="3">Wednesday</Select.Option>
                  <Select.Option value="4">Thursday</Select.Option>
                  <Select.Option value="5">Friday</Select.Option>
                  <Select.Option value="6">Saturday</Select.Option>
                </Select>
              )}{' '}
              <TimePicker
                allowClear={false}
                minuteStep={15}
                value={date.startTime ?? null}
                onSelect={(value) => this.handleChangeDate(value, 'startTime', props.id)}
                placeholder="Start time"
                use12Hours
                format="HH:mm"
              />{' '}
              <TimePicker
                allowClear={false}
                minuteStep={15}
                value={date.endTime ?? null}
                onSelect={(value) => this.handleChangeDate(value, 'endTime', props.id)}
                placeholder="End time"
                use12Hours
                format="HH:mm"
              />{' '}
            </>
          );
        },
      },
      {
        title: '',
        width: 'auto',
        key: 'Actions',
        align: 'right',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Delete">
                <Button icon={<DeleteOutlined />} shape="circle" onClick={this.handleDeleteDate.bind(this, props.id)} />
              </Tooltip>
            </span>
          );
        },
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No dates added to this session yet!' },
      pagination: { pageSize: 5, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };

    return (
      <Form.Item name="dates_" label="Dates" rules={[{ required: false, validator: this._validator }]}>
        <>
          {this.state.dates.length > 0 && (
            <Row>
              <Col>
                <Table
                  className="sessionDatesTable"
                  columns={columns}
                  dataSource={this.state.dates.sort((a, b) => {
                    const dateA = a.startDate ? Utils.getTimestampFromMoment(a.startDate) : 0;
                    const dateB = b.startDate ? Utils.getTimestampFromMoment(b.startDate) : 0;

                    return dateA - dateB;
                  })}
                  {...props}
                />
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: this.state.dates.length > 0 ? 16 : 0 }}>
            <Col>
              <Button type="primary" onClick={this.handleAddDate}>
                Add date
              </Button>
            </Col>
          </Row>
        </>
      </Form.Item>
    );
  }

  // Private methods
  _validator() {
    return new Promise((resolve, reject) => {
      if (
        this.props.isRequired &&
        (this.state.dates.length < 1 || this.state.dates.find((d) => !d.startDate || !d.startTime || !d.endTime))
      ) {
        reject('One ore more dates are required!');
      } else {
        resolve();
      }
    });
  }
}
