import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import CommonProductCategoriesDrawer from '../../commonComponents/Drawers/CommonProductCategoriesDrawer';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
//
export default class AdminProductCategoriesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      categories: [],
      sortedInfo: { columnKey: 'name', order: 'ascend' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
  handleCreateCategory() {
    this.categoryDrawer.show(null);
  }
  //Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Table row actions
  handleEditCategory(categoryID) {
    this.categoryDrawer.show(categoryID);
  }
  async handleDeleteCategory(categoryID) {
    this.startLoading();
    const resp = await this._deleteCategory(categoryID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          className="pageHeader"
          title="Product Categories"
          onBack={() => this.props.app.urlManager.pushBack()}
        />
        <CommonProductCategoriesDrawer
          {...Utils.propagateRef(this, 'categoryDrawer')}
          onUpdate={this.fetchData}
          app={this.props.app}
        />
        <Layout.Content>
          <Row type="flex" justify="end">
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateCategory}>
                {' '}
                Add Category{' '}
              </Button>
            </Col>
          </Row>
          {this._renderCategoriesTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderCategoriesTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: '20%',
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: '20%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditCategory.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete the category '${props.name}'?`}
                  onConfirm={this.handleDeleteCategory.bind(this, props.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No categories found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Layout.Content>
        <Table columns={columns} dataSource={this.state.categories} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadAll() {
    const resp = await this.props.app.license.category.getAllClientCategories();
    if (resp.statusCode == 200) {
      return { categories: resp.body.categories };
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  async _deleteCategory(categoryID) {
    const resp = await this.props.app.license.category.deleteCategory(categoryID);
    if (resp.statusCode == 200) {
      message.success('Category deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
