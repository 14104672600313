import React from 'react';
import autoBind from 'react-autobind';
import { Modal, Form, Input, message } from 'antd';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
//
import '../../../assets/stylesheets/AdminFaq.less';
//
import Utils from '../../../components/Utils';
import CommonFaqView from '../../commonSubviews/CommonFaqView';
//
export default class AdminFaqView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      scenario: '', // create | update
      isFormModalOpen: false,
      answer: MarkdownEditor.createEmptyValue(),
      faqBeingUpdated: null,
    };
  }

  handleAddNewQuestion() {
    this.setState({
      scenario: 'create',
      isFormModalOpen: true,
    });
  }

  handleUpdateQuestion(faq) {
    this.setState(
      {
        scenario: 'update',
        isFormModalOpen: true,
        answer: MarkdownEditor.createValueFromString(faq.answer || '', 'markdown'),
        faqBeingUpdated: faq,
      },
      () => {
        this.form.setFieldsValue({ question: faq.question });
      }
    );
  }

  handleCloseFormModal() {
    this.setState(
      {
        scenario: '',
        isFormModalOpen: false,
        answer: MarkdownEditor.createEmptyValue(),
        faqBeingUpdated: null,
      },
      () => {
        if (this.form) {
          this.form.setFieldsValue({ question: '' });
        }
      }
    );
  }

  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;

    const payload = {
      question: formData.question,
      answer: this.state.answer.toString('markdown'),
      section: 'FAQ',
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
    };

    const { scenario } = this.state;

    if (scenario === 'create') this._createFaq(payload);
    if (scenario === 'update') this._updateFaq(payload);
  }

  handleDeleteQuestion(questionID) {
    this._deleteQuestion(questionID);
  }

  //UI
  render() {
    const { scenario } = this.state;

    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />

        <CommonFaqView
          {...this.props}
          {...Utils.propagateRef(this, 'faqView')}
          isAdminView
          onAddNewQuestion={this.handleAddNewQuestion}
          onUpdateQuestion={this.handleUpdateQuestion}
          onDeleteQuestion={this.handleDeleteQuestion}
        />

        <Modal
          open={this.state.isFormModalOpen}
          title="Add new FAQ"
          onCancel={this.handleCloseFormModal}
          okText={scenario === 'create' ? 'Add' : 'Save'}
          onOk={this.handleSubmit}
        >
          <Form layout="vertical" style={{ width: 500 }} {...Utils.propagateRef(this, 'form')}>
            <Form.Item
              label="Question"
              name="question"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Answer" name="answer" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.answer}
                onChange={(answer) => this.setState({ answer })}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }

  async _createFaq(payload) {
    this.startLoading();

    const resp = await this.props.app.config.faq.createQuestion(payload);
    if (resp.statusCode == 200) {
      await this.faqView.reloadFaqs();
      this.handleCloseFormModal();
      message.success('Question created with success!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _updateFaq(payload) {
    this.startLoading();

    const resp = await this.props.app.config.faq.updateQuestion({
      id: this.state.faqBeingUpdated.id,
      ...payload,
    });
    if (resp.statusCode == 200) {
      await this.faqView.reloadFaqs();
      this.handleCloseFormModal();
      message.success('Question updated with success!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _deleteQuestion(questionID) {
    this.startLoading();

    const resp = await this.props.app.config.faq.deleteQuestion(questionID);
    if (resp.statusCode == 200) {
      await this.faqView.reloadFaqs();
      this.handleCloseFormModal();
      message.success('Question deleted with success!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
}
