import React from 'react';
import autoBind from 'react-autobind';
import { Form, Divider, Col, Row, Select, Button, Alert, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
//
import creditCard from '../../../../assets/images/credit-card.png';
import stripe from '../../../../assets/images/stripe.svg';
import mastercard from '../../../../assets/images/mastercard.svg';
import visa from '../../../../assets/images/visa.svg';
//
import Utils from '../../../../components/Utils';
import { PAYMENT_TYPE } from './CommonLicensePurchaseModalStep_PaymentView_Federated';
//
import 'react-credit-cards/lib/styles.scss';
//props are: app, onCancel, onNext, onPrevious, isLoading, totalAmount, isNormalUser, product
export default class CommonLicensePurchaseModalStep_PaymentView_Moneris extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    const paymentType =
      this.props.app.isAdmin() && !this.props.isNormalUser && !props.product.invoiceIsDisabled
        ? PAYMENT_TYPE.INVOICE
        : PAYMENT_TYPE.CARD;
    this.state = {
      paymentMethod: paymentType,
      name: `${this.props.user.firstName} ${this.props.user.lastName}`,
    };
  }
  //Validation
  async validateFields() {
    return {
      paymentMethod: this.state.paymentMethod,
      firstName: this.state.name.split(' ')[0].trim(),
      lastName: this.state.name.replace(this.state.name.split(' ')[0].trim(), ''),
    };
  }
  //Public
  setInfo(data) {
    const pData = { paymentMethod: data.paymentMethod };
    this.setState(pData, () => {
      this.form.setFieldsValue({ ...pData });
    });
  }
  //Actions
  handlePaymentTypeChange(type) {
    this.setState({ paymentMethod: type });
  }
  //UI
  render() {
    return (
      <Form key="product" {...Utils.propagateRef(this, 'form')} data-private>
        <Divider orientation="left">Payment Selection</Divider>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 12}>
            <Form.Item
              name="paymentMethod"
              label={'Payment method'}
              rules={[{ required: true, message: 'Please, select a payment method!' }]}
              initialValue={this.state.paymentMethod}
            >
              <Select onChange={(val) => this.handlePaymentTypeChange(val)}>
                {(this.props.app.isOrgManager() || this.props.isNormalUser || this.props.product.invoiceIsDisabled) && (
                  <Select.Option
                    value={PAYMENT_TYPE.CARD}
                    key={PAYMENT_TYPE.CARD}
                    disabled={this.props.totalAmount <= 0}
                  >
                    Credit Card
                  </Select.Option>
                )}
                {((this.props.app.isAdmin() && !this.props.isNormalUser && !this.props.product.invoiceIsDisabled) ||
                  (this.props.app.isOrgManager() && !this.props.product.invoiceIsDisabled)) && (
                  <Select.Option value={PAYMENT_TYPE.INVOICE} key={PAYMENT_TYPE.INVOICE}>
                    Invoice
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          {this.state.paymentMethod === PAYMENT_TYPE.CARD ? (
            <Col style={{ marginLeft: 15 }} span={isMobile ? 24 : 11}>
              <img src={mastercard} width="40" alt="mastercard" />
              <img src={visa} width="40" alt="visa" style={{ marginLeft: 10 }} />
              <Typography.Text style={{ fontSize: 10, marginLeft: 10 }}>and others..</Typography.Text>
            </Col>
          ) : (
            <Col span={11} />
          )}
        </Row>
        <Divider />
        {this.state.paymentMethod == PAYMENT_TYPE.CARD ? this._renderCardRow() : this._renderInvoiceRow()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button
          className="purchaseModalCancelButton"
          disabled={this.props.isLoading}
          key="back"
          onClick={this.props.onCancel}
        >
          {' '}
          Cancel{' '}
        </Button>
        <Button disabled={this.props.isLoading} onClick={this.props.onPrevious}>
          {' '}
          Previous{' '}
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={this.props.isLoading}
          onClick={this.props.onNext}
          disabled={this.props.isLoading}
          className="purchaseModalConfirmationButton"
        >
          {' '}
          Next{' '}
        </Button>
      </Row>
    );
  }
  //Card
  _renderCardRow() {
    return (
      <>
        <Row type="flex" justify="center">
          {' '}
          <img src={creditCard} width="200" alt="stripe" />{' '}
        </Row>
        <Row type="flex" justify="center" style={{ marginLeft: 300, marginTop: -60 }}>
          {' '}
          <img src={stripe} width="100" alt="stripe" />{' '}
        </Row>

        <Row type="flex" justify="center">
          <Typography.Paragraph style={{ fontSize: 16 }}>
            Your payment information will be processed securely.
          </Typography.Paragraph>
        </Row>
        <Row type="flex" justify="center">
          <Typography.Paragraph underline style={{ fontSize: 18 }}>
            Please, advance to the next step and have your payment information ready.
          </Typography.Paragraph>
        </Row>
      </>
    );
  }
  _renderInvoiceRow() {
    const text = `When you complete this transaction, {ORG_NAME}’s training department will be notified and will issue your invoice.
    Your license(s) will become available once {ORG_NAME} has received payment and the {APP_NAME} administrator approves your order.`;
    return (
      <Row type="flex" justify="center">
        <Alert
          message="Invoice order details"
          description={Utils.replacePlaceholders(text, this.props.app.themeManager.theme)}
          type="info"
          showIcon
        />
      </Row>
    );
  }
}
