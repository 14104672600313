import React from 'react';
import autoBind from 'react-autobind';
import { Popover, Row, Col, Result, Input, Button, Divider, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
import Utils from '../../../components/Utils';
const ItemsPerPage = 6;
//props are: app, onSelect, existingInstructors
export default class CommonInstructorSelectionPopover extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      isVisible: false,
      currentPage: 1,
      searchTerm: '',
      data: null,
    };
  }
  //Actions
  //Search actions
  handleChange(e) {
    this.setState({ searchTerm: e.target.value });
  }
  handleSearch() {
    this.setState({ currentPage: 1 }, () => this._search());
  }
  //Table actions
  handleSelectItem(item) {
    this.popover.onClick(); //hacky way of dismissing non controller popovers.. Blame Gabriel :p
    if (this.props.onSelect) this.props.onSelect(item);
  }
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => this._search());
  }
  //UI
  render() {
    const columns = [
      { title: 'Name', key: 'fullName.keyword', dataIndex: 'fullName', width: '35%' },
      { title: 'Email', key: 'email', dataIndex: 'email', width: '37%' },
      {
        title: 'Confirmed',
        render: (d) => (d.confirmationDate > 0 ? 'Yes' : 'No'),
        key: 'confirmationDate',
        width: '8%',
      },
      {
        title: '',
        key: 'select',
        align: 'right',
        width: '20%',
        render: (item) => (
          <Button
            type="primary"
            size="small"
            onClick={this.handleSelectItem.bind(this, item)}
            disabled={(this.props.existingInstructors || []).find((i) => i.id == item.id)}
          >
            Add as Instructor
          </Button>
        ),
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      locale: {
        emptyText: this.state.firstLoad ? 'Search users' : this.state.isLoading ? 'Loading...' : 'No users found!',
      },
      pagination: {
        pageSize: ItemsPerPage,
        showSizeChanger: false,
        hideOnSinglePage: true,
        position: 'bottom',
        total: this.state.total,
        onChange: this.handlePagination,
        current: this.state.currentPage,
      },
    };
    return (
      <Popover
        title="Search and select a user to become instructor"
        trigger={['click']}
        placement="bottomRight"
        {...Utils.propagateRef(this, 'popover')}
        content={
          <div style={{ minWidth: '600px', maxWidth: '1200px' }}>
            <Row>
              <Col span={19}>
                <Input
                  prefix={<SearchOutlined />}
                  size="large"
                  onPressEnter={this.handleSearch}
                  placeholder="Search..."
                  value={this.state.searchTerm}
                  onChange={this.handleChange}
                  autoFocus
                />
              </Col>
              <Col offset={1}>
                {' '}
                <Button size="large" type="primary" onClick={this.handleSearch}>
                  Search
                </Button>{' '}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                {this.state.data && <Table columns={columns} dataSource={this.state.data} {...props} />}
                {!this.state.data && (
                  <Result
                    icon={<SearchOutlined style={{ color: this.props.app.themeManager.primaryColor }} />}
                    title="Please, search for a user to add as instructor!"
                  />
                )}
              </Col>
            </Row>
          </div>
        }
      >
        {this.props.children}
      </Popover>
    );
  }

  // API Calls
  _getSearchFilter() {
    const from = ItemsPerPage * (this.state.currentPage - 1);
    //return { from, sortField: 'fullName.keyword', sortOrder: 'asc', itemsPerPage: ItemsPerPage }; // removed due to making it more difficult to find instructors
    return { from, itemsPerPage: ItemsPerPage };
  }
  async _search() {
    if (!this._isMounted) return;
    //
    this.setState({ data: [], isLoading: true });
    //request
    const resp = await this.props.app.idm.api.user.searchUsersByTerm(this.state.searchTerm, this._getSearchFilter());
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.users) {
      const users = resp.body.users.map((user) => user._source);
      this.setState({ data: users, total: resp.body.total, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}
