import React from 'react';
import autoBind from 'react-autobind';
import { Form, Select, Divider, Col, Row, Button, Modal, Descriptions, Typography } from 'antd';
import { ImInsertTemplate } from 'react-icons/im';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//props: app, onSelect, templates, courses
export default class CommonTemplateSelectionModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { templateID: null, isVisible: false };
  }
  //Public
  show() {
    this.setState({ templateID: null, isVisible: true });
  }

  //Actions
  handleAfterClose() {
    this.setState({ templateID: null, isVisible: false }, () => this.form.resetFields());
  }
  async handleSubmit() {
    const resp = await this.form.validateFields();
    if (resp) {
      this.props.onSelect(this.state.templateID);
      this.setState({ isVisible: false }, this.handleAfterClose);
    }
  }
  handleOnChange(templateID) {
    this.setState({ templateID });
  }
  handleCancel() {
    this.props.app.urlManager.pushBack();
  }
  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        title={'Select a template to create a session:'}
        afterClose={this.handleAfterClose}
        open={this.state.isVisible}
        closable={false}
        footer={null}
      >
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }
  /* subforms */
  _renderForm() {
    const template = this.state.templateID ? this.props.templates.find((t) => t.id == this.state.templateID) : null;
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row type="flex" align="middle" style={{ marginBottom: 20 }}>
          <Col>
            <ImInsertTemplate style={{ fontSize: 40 }} />
          </Col>
          <Col style={{ maxWidth: 400, marginLeft: 30 }}>
            <Typography.Text>
              Please, select a template that you want to create a session from. You will be able to change most of the
              values from a template,{' '}
              <strong>
                but make sure you are selecting a template from the course you want to have a session from.
              </strong>
            </Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={24}>
            <Form.Item
              label="Template"
              name="templateID"
              rules={[{ required: true, message: `Please, select a template!` }]}
            >
              <Select onChange={this.handleOnChange}>
                {this.props.templates &&
                  this.props.templates
                    .sort((a, b) => a.internalName.localeCompare(b.internalName))
                    .map((item) => {
                      const course = (this.props.courses || []).find((c) => c.id === item.courseID);
                      return (
                        <Select.Option value={item.id} key={item.id}>
                          {' '}
                          {Globals.getTemplateTypeIcon(item.type)} {item.internalName} - (Course: {course?.description}){' '}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {this.state.templateID && template && (
          <Row type="flex" style={{ maxWidth: 400 }}>
            <Descriptions size="small">
              <Descriptions.Item span={3} label="Internal Name">
                {template?.internalName}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Name">
                {template?.name}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Course">
                {(this.props.courses || []).find((c) => c.id == template?.courseID)?.description || ''}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Type">
                {Globals.getTemplateTypeIcon(template?.type, true)}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        )}
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button onClick={this.handleCancel} style={{ border: 0, marginLeft: 20 }}>
          {' '}
          Cancel and Go Back{' '}
        </Button>
        <Button
          disabled={!this.state.templateID}
          style={{ border: 0, marginLeft: 8 }}
          type="primary"
          onClick={this.handleSubmit}
        >
          {' '}
          Select{' '}
        </Button>
      </Row>
    );
  }
}
