import React from 'react';
import autoBind from 'react-autobind';
import { isMobile } from 'react-device-detect';
//
import { Col, Row, Form, Typography, Divider, Button, Tooltip, Tag, message, InputNumber, Spin } from 'antd';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//props are: product, productOrder, invoiceMode
export default class CommonProductOrderPreview extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = { productOwner: null, downloadingReceipt: false };
  }
  //Life cycle
  componentDidUpdate() {
    if (this.props.productOrder?.externalID && !this.state.productOwner) this._fetchProductOwner();
  }
  //Actions
  handleReceiptDownload() {
    this._downloadReceipt();
  }
  //UI
  render() {
    console.log('product order', this.props.productOrder);
    //subValue equals itemsValue plus shippingChargesValue, guaranteeing that the value is always a number
    const subValue = this.props.productOrder.itemsValue + this.props.productOrder.shippingChargesValue;
    const discountValue =
      this.props.productOrder.itemsValueDiscount > 0 ? this.props.productOrder.itemsValueDiscount * -1 : 0;
    return (
      <>
        <Divider orientation="left">Order Information</Divider>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 6}>
            <Form.Item label="Order #">{this.props.productOrder && this.props.productOrder.id}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Status">
              {this.props.productOrder && this.props.productOrder.status && (
                <Tag color={Globals.getColorByOrderState(this.props.productOrder.status)}>
                  {this.props.productOrder.status}
                </Tag>
              )}
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 7} offset={isMobile ? 0 : 1}>
            {this.props.invoiceMode ? (
              <Form.Item label="Created On">
                {' '}
                {Utils.getDateAndTimeOnUIFormatByTimestamp(this.props.productOrder.createdOn)}{' '}
              </Form.Item>
            ) : (
              <Form.Item label="Completed On">
                {' '}
                {Utils.getDateAndTimeOnUIFormatByTimestamp(this.props.productOrder.completedOn)}{' '}
              </Form.Item>
            )}
          </Col>
          {this.props.productOrder?.providerTransactionID &&
            Object.values(Globals.LicenseOrder_ReceiptEnabled_Providers).includes(
              this.props.productOrder?.providerID
            ) && (
              <Col span={isMobile ? 24 : 1} offset={isMobile ? 0 : 1}>
                <Tooltip placement="bottomLeft" title="Download Receipt">
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={this.state.downloadingReceipt}
                    onClick={this.handleReceiptDownload}
                  />
                </Tooltip>
              </Col>
            )}
        </Row>
        <Row>
          <Col span={isMobile ? 24 : 7} offset={1}>
            <Form.Item label="Purchaser">
              {this.props.productOrder.name}
              <Tooltip title={`Purchaser email(s): ${this.props.productOrder.email}`} placement="topLeft">
                <InfoCircleOutlined style={{ marginLeft: 10 }} />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Form.Item label="Product Owner">
              {this.state.productOwner ?? (
                <>
                  <Spin size="small" /> Loading...
                </>
              )}
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 8} />
        </Row>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 6}>
            <Form.Item label="Transaction ID">
              {this.props.productOrder && this.props.productOrder.providerTransactionID}
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 6} offset={isMobile ? 0 : 1}>
            {this.props.productOrder &&
              this.props.productOrder.voucherID &&
              !this.props.productOrder.isVoucherPrivate && (
                <Form.Item label="Voucher">
                  {' '}
                  <Tag>{this.props.productOrder.voucherID}</Tag>{' '}
                </Form.Item>
              )}
          </Col>
          <Col span={isMobile ? 0 : 8} offset={isMobile ? 0 : 1}></Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={isMobile ? 24 : 7}>
            <div className="purchaseModalOverviewTableHeader">
              {' '}
              <Typography.Text> Order Description </Typography.Text>{' '}
            </div>
            <Form.Item>{this.props.productOrder.description}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              {' '}
              <Typography.Text> Price </Typography.Text>{' '}
            </div>
            <Form.Item>
              <Tooltip title={'The original price of the order.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                  value={this.props.productOrder.itemsValue}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 2}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              {' '}
              <Typography.Text> Shipping </Typography.Text>{' '}
            </div>
            <Form.Item>
              <Tooltip title={'The original shipping value of the order.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                  value={this.props.productOrder.shippingChargesValue}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 0 : 4}>
            <div className="purchaseModalOverviewTableHeader"> &nbsp; </div>
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <div className="purchaseModalOverviewTableHeader">
              {' '}
              <Typography.Text> Subtotal </Typography.Text>{' '}
            </div>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={subValue}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 4}>
            <Form.Item>
              <Tooltip title={'Discounts can be applied by using cupom codes.'} placement="topLeft">
                <InputNumber
                  precision={2}
                  decimalSeparator="."
                  disabled
                  formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser}
                  className="purchaseModalInputBoderless"
                  value={discountValue}
                />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 4}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={this.props.productOrder.orderValueTax}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={isMobile ? 12 : 2} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={isMobile ? 12 : 4}>
            <Form.Item>
              <InputNumber
                precision={2}
                decimalSeparator="."
                disabled
                formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser}
                className="purchaseModalInputBoderless"
                value={this.props.productOrder.orderValueTotal}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }

  // Private methods
  async _fetchProductOwner() {
    const { externalID } = this.props.productOrder;
    let productOwner = 'N/A';

    const idmResp = await this.props.app.idm.api.user.read(externalID);
    if (idmResp.statusCode == 200 && idmResp.body) {
      productOwner = `${idmResp.body.firstName} ${idmResp.body.lastName}`;
    } else {
      const orgResp = await this.props.app.organization.organizationApp.getOrganizationApp(externalID);
      if (orgResp.statusCode == 200 && orgResp.body) {
        productOwner = orgResp.body.name;
      }
    }
    this.setState({ productOwner });
  }
  async _downloadReceipt() {
    this.setState({ downloadingReceipt: true });
    const resp = await this.props.app.license.productOrder.getProductOrderReceipt(
      this.props.productOrder?.externalID,
      this.props.productOrder?.id
    );
    if (resp.statusCode == 200)
      Utils.downloadArrayBuffer(resp.body.data, `receipt-${this.props.productOrder?.id}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.setState({ downloadingReceipt: false });
  }
}
