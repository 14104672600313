import React from 'react';
import autoBind from 'react-autobind';
import {
  Form,
  Input,
  Divider,
  Col,
  Select,
  Row,
  InputNumber,
  Button,
  message,
  Modal,
  Checkbox,
  Tooltip,
  DatePicker,
} from 'antd';
//
import CustomComponent from '../../../components/CustomComponent';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import '../../../assets/stylesheets/CommonSessionResultModal.less';
//required props are: app, onChange
//This modal has 2 modes: 1 is launched from sessions and other is specific to certification view
//props for mode 1: session -- show(sessionID, courseSpecsID, enrolment, optionalResult, optionalAttendance)
//props for mode 2: certification, course -- show(sessionID, courseSpecsID, userObj, optionalResult, optionalAttendance)
export default class CommonSessionResultModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      courseSpecs: null,
      sessionID: null,
      student: null,
      session: null,
      isVisible: false,
      attendance: null,
      result: null,
      enrolmentID: null,
      enableSetResultDate: false,
      overrideResultDate: false,
    };
  }
  //
  show(sessionID, courseSpecsID, userObj /* can be user or enrolment */, result, attendance) {
    const courseSpecs = this.props.app.sharedCache().getCourseByID(courseSpecsID);
    const enrolment = this.props.session?.enrolments.find((e) => e.userID === userObj.userID);
    const enrolmentID = enrolment?.id.split('::')[2];

    this.setState(
      {
        courseSpecs,
        result,
        attendance,
        isVisible: true,
        student: userObj,
        enrolmentID,
        sessionID: sessionID || this.props.session.id,
        session: this.props.session || null,
        isLoading: false,
        enableSetResultDate: false,
        overrideResultDate: false,
      },
      async () => {
        //Load session
        if (!this.props.session) await this._loadSession(sessionID);
        //reset fields
        if (result || attendance) {
          this.form.setFieldsValue({
            attendanceStatus: attendance?.attendanceStatus || null,
            additionalNotes: attendance?.additionalNotes || '',
            resultStatus: result?.resultStatus || null,
            resultGrade: result?.resultGrade || 0,
            resultNotes: result?.resultNotes || '',
            ...(result?.resultDate
              ? { resultDate: Utils.getDateOnMomentFormatByTimestamp(result.resultDate) }
              : { resultDate: null }),
            retainAcessToCourse: result?.retainAcessToCourse,
          });
        } else this.form.resetFields();
      }
    );
  }

  //Actions
  handleCancel() {
    this.setState({ isVisible: false }, () => this.props.onChange(true));
  }
  handleAfterClose() {
    this.setState({
      isLoading: false,
      courseSpecs: null,
      sessionID: null,
      student: null,
      session: null,
      isVisible: false,
      attendance: null,
      result: null,
      enableSetResultDate: false,
      overrideResultDate: false,
    });
    this.form.resetFields();
  }
  handleEnableSetResultDate() {
    this.setState({
      enableSetResultDate: !this.state.enableSetResultDate,
      overrideResultDate: !this.state.overrideResultDate,
    });
  }
  async handleSubmit() {
    const isUpdate = this.state.attendance || this.state.result;
    let formData = await this.form.validateFields();
    if (formData) {
      let resp = true;
      this.startLoading();
      if (isUpdate) {
        //Attendance upsert
        if (this.state.attendance) resp = await this._updateAttendance(formData);
        else if (formData.attendanceStatus) resp = await this._addAttendance(formData);
        //Result upsert
        if (resp && this.state.result) resp = await this._updateResult(formData);
        else if (resp && formData.resultStatus) resp = await this._addResult(formData);
      } else {
        if (formData.attendanceStatus) resp = await this._addAttendance(formData);
        if (resp && formData.resultStatus) resp = await this._addResult(formData);
      }
      if (resp) {
        this.setState({ isLoading: false, isVisible: false }, this.props.onChange);
        if (isUpdate) message.success('Result updated with success!');
        else message.success('Result added with success!');
      } else this.stopLoading();
    }
  }
  //UI
  render() {
    const courseType = this.state.session ? Globals.getTemplateTypeLabel(this.state.session.type) : '';
    let certSpecs = null;
    if (this.props.certification) {
      certSpecs = this.props.app.sharedCache().getCertificationByID(this.props.certification.certificationID);
    }
    const isUpdate = this.state.attendance || this.state.result;
    return (
      <Modal
        maskClosable={false}
        afterClose={this.handleAfterClose}
        open={this.state.isVisible}
        confirmLoading={this.state.isLoading}
        closable={false}
        footer={null}
        className="sessionResultModal"
        title={`${isUpdate ? 'Update' : 'Add'} Result to ${this.state.courseSpecs?.uiTerm}`}
      >
        {this._renderResultForm(certSpecs)}
        {this._renderButtonsFooter(isUpdate)}
      </Modal>
    );
  }
  /* subforms */
  _renderResultForm(certSpecs) {
    const isUpdateResult = this.state.result;
    return (
      <Form key="result" {...Utils.propagateRef(this, 'form')} layout="vertical">
        {certSpecs && (
          <Row type="flex" justify="start">
            <Col span={12}>
              <Form.Item label="Certification">
                {' '}
                <span>{certSpecs?.description || ''}</span>{' '}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={`${Utils.capitalizeString(this.state.courseSpecs?.uiTerm || '')} Description`}>
                {' '}
                <span>{this.state.courseSpecs?.description}</span>{' '}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row type="flex" justify="start">
          <Col span={12}>
            <Form.Item label={`${Utils.capitalizeString(this.state.courseSpecs?.uiTerm || '')} Session`}>
              {' '}
              <span>{this.state.session?.name || ''}</span>{' '}
            </Form.Item>
          </Col>
          {this.props.showEnrolment && (
            <Col span={12}>
              <Form.Item label="Enrolment ID">
                {' '}
                <span>{this.state.enrolmentID || ''}</span>{' '}
              </Form.Item>
            </Col>
          )}

          {this.state.session && this.state.session?.startDate && this.state.session?.startDate?.length > 0 && (
            <Col span={12}>
              <Form.Item label={`${Utils.capitalizeString(this.state.courseSpecs?.uiTerm || '')} Date(s)`}>
                <span>
                  {this.state.session?.startDate?.map((s) => Utils.getDateAndTimeOnUIFormatByTimestamp(s)).join(', ') ||
                    ''}
                </span>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row type="flex" justify="start">
          <Col span={12}>
            <Form.Item label={'Student Name'}>
              {' '}
              <span>{`${this.state.student?.firstName} ${this.state.student?.lastName}`}</span>{' '}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Student Email'}>
              {' '}
              <span>{`${this.state.student?.email}`}</span>{' '}
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">{'Result'}</Divider>
        <Row type="flex" justify="start">
          <Col span={6}>
            <Form.Item
              name="attendanceStatus"
              rules={[{ required: !!this.state?.attendance?.attendanceStatus, message: 'This field is required!' }]}
              label="Student Attended?"
            >
              <Select mode="single" showSearch>
                <Select.Option value={Globals.Attendance_Status.ATTENDED} key={Globals.Attendance_Status.ATTENDED}>
                  Attended
                </Select.Option>
                <Select.Option value={Globals.Attendance_Status.NO_SHOW} key={Globals.Attendance_Status.NO_SHOW}>
                  No Show
                </Select.Option>
                <Select.Option value={Globals.Attendance_Status.INCOMPLETE} key={Globals.Attendance_Status.INCOMPLETE}>
                  Incomplete
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item
              name="resultStatus"
              rules={[{ required: true, message: 'This field is required!' }]}
              label="Final Result"
            >
              <Select mode="single" showSearch>
                <Select.Option value={Globals.Result_Status.PASS} key={Globals.Result_Status.PASS}>
                  Pass
                </Select.Option>
                <Select.Option value={Globals.Result_Status.FAIL} key={Globals.Result_Status.FAIL}>
                  Fail
                </Select.Option>
                <Select.Option value={Globals.Result_Status.INCOMPLETE} key={Globals.Result_Status.INCOMPLETE}>
                  Incomplete
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item
              label={'Grade'}
              name="resultGrade"
              rules={[{ required: true, message: `Please, inform the ${this.state.courseSpecs?.uiTerm} grade!` }]}
              initialValue={0}
            >
              <InputNumber id="grade" min={0} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            {true && (
              <Form.Item
                label="Result Date"
                name="resultDate"
                rules={[{ required: this.state.enableSetResultDate, message: 'Please, choose a new date!' }]}
              >
                <DatePicker format={Globals.DefaultUIBirthDateFormat} disabled={!this.state.enableSetResultDate} />
              </Form.Item>
            )}
          </Col>

          <Col>
            <Tooltip
              title={
                'If you check this option, the student will still be able to access the course in the training provider.'
              }
              placement="topRight"
            >
              <Form.Item
                name="retainAcessToCourse"
                initialValue={false}
                label={'Keep student in the session '}
                valuePropName="checked"
              >
                <Checkbox id="retainAcessToCourse" />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col offset={2}>
            {true && (
              <Tooltip title={'If you check this option, you will be able to set a result date.'} placement="topRight">
                <Form.Item initialValue={false} label={'Override result date'} valuePropName="checked">
                  <Checkbox checked={this.state.overrideResultDate} onChange={this.handleEnableSetResultDate} />
                </Form.Item>
              </Tooltip>
            )}
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={11}>
            <Form.Item
              name="additionalNotes"
              label={'Students Notes'}
              rules={[{ required: false }]}
              extra="Please, add attendance if using this field."
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item name="resultNotes" label={'Result Notes'} rules={[{ required: false }]}>
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  _renderButtonsFooter(isUpdate) {
    if (!this.state.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          style={{ border: 0, marginLeft: 20 }}
          key="submit"
          type="primary"
          loading={this.state.isLoading}
          onClick={this.handleSubmit}
          disabled={this.state.isLoading}
          className="paymentConfirmButton"
        >
          {isUpdate ? 'Update' : 'Add'} Result{' '}
        </Button>
      </Row>
    );
  }

  /* private methods */
  async _loadSession() {
    this.startLoading();
    const sessionResp = await this.props.app.classroom.session.getSession(this.state.sessionID, false);
    if (!this._isMounted) return;
    console.debug('Session resp:', sessionResp);
    if (sessionResp.statusCode == 200 && sessionResp.body) {
      this.setState({ isLoading: false, session: sessionResp.body });
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert(null, sessionResp);
    }
  }
  async _updateAttendance(data) {
    const userID = this.state.student.userID || this.state.student.id;
    const externalID = this.state.student.externalID || this.props.course.id;
    const attendanceResp = await this.props.app.classroom.sessionAttendance.updateSessionAttendance(
      this.state.sessionID,
      userID,
      externalID,
      {
        attendanceStatus: data.attendanceStatus,
        additionalNotes: data.additionalNotes,
      }
    );
    if (!this._isMounted) return;
    console.debug('Attendance update result resp:', attendanceResp, data);
    if (attendanceResp.statusCode == 200 && attendanceResp.body) {
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, attendanceResp);
      return false;
    }
  }
  async _addAttendance(data) {
    const userID = this.state.student.userID || this.state.student.id;
    const externalID = this.state.student.externalID || this.props.course.id;
    const attendanceResp = await this.props.app.classroom.sessionAttendance.createSessionAttendance(
      this.state.sessionID,
      userID,
      externalID,
      {
        attendanceStatus: data.attendanceStatus,
        additionalNotes: data.additionalNotes,
      }
    );
    if (!this._isMounted) return;
    console.debug('Attendance add result resp:', attendanceResp, data);
    if (attendanceResp.statusCode == 200 && attendanceResp.body) {
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, attendanceResp);
      return false;
    }
  }
  async _updateResult(data) {
    const userID = this.state.student.userID || this.state.student.id;
    const externalID = this.state.student.externalID || this.props.course.id;
    const resultData = {
      resultGrade: data.resultGrade || 0,
      resultNotes: data.resultNotes || '',
      resultStatus: data.resultStatus,
      retainAcessToCourse: data.retainAcessToCourse,
    };
    if (this.state.enableSetResultDate) {
      resultData.resultOverrideDate = data.resultDate ? Utils.getTimestampFromMoment(data.resultDate) : null;
    }
    const resultResp = await this.props.app.classroom.sessionResult.updateSessionResult(
      this.state.sessionID,
      userID,
      externalID,
      resultData
    );
    if (!this._isMounted) return;
    console.debug('Session update result resp:', resultResp, data);
    if (resultResp.statusCode == 200 && resultResp.body) {
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resultResp);
      return false;
    }
  }
  async _addResult(data) {
    const userID = this.state.student.userID || this.state.student.id;
    const externalID = this.state.student.externalID || this.props.course.id;
    const resultData = {
      resultGrade: data.resultGrade || 0,
      resultNotes: data.resultNotes || '',
      resultStatus: data.resultStatus,
      retainAcessToCourse: data.retainAcessToCourse,
    };
    if (this.state.enableSetResultDate) {
      resultData.resultOverrideDate = data.resultDate ? Utils.getTimestampFromMoment(data.resultDate) : null;
    }
    const resultResp = await this.props.app.classroom.sessionResult.createSessionResult(
      this.state.sessionID,
      userID,
      externalID,
      resultData
    );
    if (!this._isMounted) return;
    console.debug('Session add result resp:', resultResp, data, this.state);
    if (resultResp.statusCode == 200 && resultResp.body) {
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resultResp);
      return false;
    }
  }
}
