import React from 'react';
import autoBind from 'react-autobind';
import EventWrapper from 'react-big-calendar/lib/addons/dragAndDrop/EventWrapper';
import { Typography, Row, Col } from 'antd';
import moment from 'moment';
import { FaLock } from 'react-icons/fa';
//
import Globals from '../../../config/Globals';
//
import CommonSessionPopover from '../Popovers/CommonSessionPopover';
//
export default class CommonStudentSessionCalendarEvent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  onSelect() {
    if (this.props?.event?.onSelect) this.props.event.onSelect(this.props.event.metadata.id);
  }
  //UI
  render() {
    const { app, metadata: session, isMonthlyView } = this.props.event;
    const isLocked = session.state == Globals.Session_State.LOCKED;
    const course = app.sharedCache().getCourseByID(session?.courseID);
    const isSessionCancelled = session.state === Globals.Session_State.CANCELLED;
    const backgroundColor = isSessionCancelled ? '#B4B4B4' : this.props.event.backgroundColor;

    //type != than date means show more popover visualization
    const isMonthly = isMonthlyView && this.props.resizable != undefined;
    return (
      <EventWrapper {...this.props}>
        <CommonSessionPopover app={app} session={session} onSelect={this.onSelect} studentMode>
          <div
            className="rbc-event cleanEventContainer"
            style={this._getStyle()}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
          >
            <div className="customSessionEvent" style={{ height: '100%', backgroundColor: backgroundColor }}>
              <Row type="flex" justify="space-between">
                <Col>
                  <Typography.Text className={isMonthly ? 'titleLabel' : 'dateLabel'}>
                    {isLocked && (
                      <>
                        <FaLock style={{ marginRight: 7 }} />
                        <span style={{ marginRight: 7 }}>{'Locked'}</span>
                      </>
                    )}
                    {isMonthly && Globals.getTemplateTypeIcon(session?.type, false)}
                    {moment(this.props.event.start, 'hh:mm A').format('hh:mm A')}
                    {' - '}
                    {moment(this.props.event.end, 'hh:mm A').format('hh:mm A')}
                    {isMonthly && ` ${session?.name || ''}`}
                  </Typography.Text>
                </Col>
              </Row>
              {!isMonthly && (
                <Row type="flex" className="secondRow">
                  {Globals.getTemplateTypeIcon(session?.type, false)}
                  <Typography.Text className="sessionName">
                    {session?.name || ''} ({course?.displayName || ''})
                  </Typography.Text>
                </Row>
              )}
            </div>
          </div>
        </CommonSessionPopover>
      </EventWrapper>
    );
  }
  /* private UI */
  _getStyle() {
    if (!this.props.style) return {};
    return {
      top: `${this.props.style.top}%`,
      left: `${this.props.style.xOffset}%`,
      width: `${this.props.style.width}%`,
      height: `${this.props.style.height}%`,
    };
  }
}
