import React from 'react';
import moment from 'moment';

import autoBind from 'react-autobind';
import { PageHeader, Layout, Table } from 'antd';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CustomComponent from '../../components/CustomComponent';
//
export default class CommonMyUpcomingCoursesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      upComingCourses: [],
      isLoading: false,
      sortedInfo: { columnKey: 'updatedOn', order: 'descend' },
    };
  }

  async componentDidMount() {
    this._fetchUpcomingCoursesByUserID();
  }

  //Actions
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader
          className="pageHeader"
          title="Upcoming Courses"
          onBack={() => this.props.app.urlManager.pushBack()}
        />

        <Layout.Content>{this._renderTable()}</Layout.Content>
      </Layout.Content>
    );
  }

  //Private UI
  _renderTable() {
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No courses found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };

    return (
      <>
        <Table
          className="adminSearchUsersTable"
          onRow={this._onRow}
          columns={this._getTableColumns()}
          dataSource={this.state.upComingCourses}
          scroll={{ x: true }}
          sticky
          {...props}
        />
      </>
    );
  }

  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    return [
      {
        title: 'Certification / Course',
        key: 'title',
        width: '10%',
        sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
        render: (text, record) => record.title || '',
      },
      {
        title: 'Name',
        key: 'description',
        width: '10%',
        sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
        render: (text, record) => record.description || '',
      },
      {
        title: 'Type',
        key: 'type',
        width: '15%',
        render: (text, record) => (record.type ? Globals.getTemplateTypeIcon(record.type, true) : '--'),
      },
      {
        title: 'Location',
        key: 'location',
        width: '10%',
        render: (text, record) => record.location || '-',
      },
      {
        title: 'Dates',
        key: 'dates',
        width: '10%',
        render: (text, record) => record.dates || '-',
      },
      {
        title: 'Last update',
        key: 'updatedOn',
        width: '10%',
        render: (text, record) => (record.updatedOn ? Utils.getDateOnUIFormatByTimestamp(record.updatedOn) : '--'),
        sorter: (a, b) => (a.updatedOn || 0) - (b.updatedOn || 0),
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
      },
    ];
  }

  async _getLocation(sessions) {
    // load on demand cache if its not loaded already
    await this.props.app.sharedCache().getVenues();
    await this.props.app.sharedCache().getCities();
    const venue = this.props.app.sharedCache().getVenueByID(sessions.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    return `${venue?.address?.street1 || ''}${city ? `, ${city.name}` : ''}`;
  }

  _getDates(sessions) {
    return sessions?.startDate?.map((startDate, i) => {
      const endDate = sessions.endDate[i];
      return (
        <div key={i}>
          {moment(new Date(startDate)).format(`${Globals.DefaultUIBirthDateFormat} hh:mm A`)} -{' '}
          {moment(new Date(endDate)).format('hh:mm A')}
        </div>
      );
    });
  }

  //API call
  async _fetchUpcomingCoursesByUserID() {
    this.startLoading();

    const userID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    const resp = await this.props.app.api.certification.getUpcomingCoursesByUserID(userID);
    if (resp.statusCode === 200) {
      const { courses, certificationProcesses, sessions } = resp.body.procs;
      const upcomingCoursesData = [];
      if (Array.isArray(certificationProcesses) && certificationProcesses.length > 0) {
        for (const certProcess of certificationProcesses) {
          const course = courses.find((course) => course.certProcID === certProcess.id);
          if (course) {
            const certificationID = certProcess.certificationID;
            const certSpecs = this.props.app.sharedCache().getCertificationByID(certificationID);
            const courseID = course.courseID;
            const courseSpecs = this.props.app.sharedCache().getCourseByID(courseID);
            const session = sessions.find((session) => session.id === course.sessionID);

            const sessionType = session ? session.type : 'Invalid Session';

            let location = null;
            if (session.venueID) location = await this._getLocation(session);

            const dates = this._getDates(session);

            const courseData = {
              title: certSpecs.title,
              description: courseSpecs.description,
              updatedOn: course.updatedOn,
              type: sessionType,
              location: location,
              dates: dates,
              id: session.id,
            };
            upcomingCoursesData.push(courseData);
          }
        }
      }

      this.setState({ upComingCourses: upcomingCoursesData, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
}
