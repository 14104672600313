import React from 'react';
import autoBind from 'react-autobind';
import { Drawer, Row, Table, Input, Divider, Col, Button, Tag } from 'antd';
//
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
import { SearchOutlined } from '@ant-design/icons';
import CustomComponent from '../../../components/CustomComponent';
//
const NEW_STATE = { isLoading: false, isVisible: false, searchTerm: '', data: null, selectedContact: null };
//props are: app, onAdd
export default class CommonOrgContactDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = NEW_STATE;
  }
  //Public
  show() {
    this.setState({ ...NEW_STATE, isVisible: true });
  }
  //Actions
  handleChange(e) {
    this.setState({ searchTerm: e.target.value }, this.handleSearch);
  }
  handleClose() {
    this.setState({ isVisible: false, selectedContact: null });
  }
  handleSearch() {
    if (this.state.searchTerm?.length > 0) this._scheduleSearch();
    else this.setState({ data: null });
  }
  //Table actions
  handleSelectContact(item) {
    this.setState({ selectedContact: item }, () => {
      if (this.props.onSelection) this.props.onSelection(this.state.selectedContact);
      this.handleClose();
    });
  }

  //UI
  render() {
    const columns = [
      { title: 'Name', key: 'name', render: (props) => `${props.firstName} ${props.lastName}` },
      { title: 'Email', key: 'email', render: (props) => props.email || '' },
      {
        title: 'Phone',
        key: 'phone',
        render: (props) =>
          props.phone?.[0]?.label ? `${props.phone?.[0]?.label || ''} - ${props.phone?.[0]?.number || ''}` : '-',
      },
      {
        title: '',
        key: 'select',
        align: 'right',
        render: (item) => {
          return (
            <Button type="primary" size="small" onClick={this.handleSelectContact.bind(this, item)}>
              Select
            </Button>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      scroll: { x: true },
      locale: {
        emptyText: this.state.data ? 'No contacts found!' : 'Please, type a contact name, email and/or phone above!',
      },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Drawer
        open={this.state.isVisible}
        title={'Add an Organization Contact'}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        {/* Search bar */}
        <Row type="flex">
          <Col span={24}>
            <Input
              prefix={<SearchOutlined />}
              size="large"
              onPressEnter={this.handleSearch}
              placeholder="Search by contact name, phone and/or email"
              value={this.state.searchTerm}
              onChange={this.handleChange}
              autoFocus
            />
          </Col>
        </Row>
        {/* Table section */}
        <Divider />
        <Row type="flex">
          {' '}
          <Col span={24}>
            {' '}
            <Table columns={columns} dataSource={this.state.data || []} {...props} />{' '}
          </Col>{' '}
        </Row>
      </Drawer>
    );
  }
  /* private - debounces */
  _scheduleSearch() {
    if (this.state.isLoading) return;
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this._search();
      this.debounce = null;
    }, 250);
  }
  /* private - API */
  async _search() {
    if (!this._isMounted) return;
    this.setState({ data: [], isLoading: true });
    //request
    const resp = await this.props.app.organization.contact.searchContactsByTerm(this.state.searchTerm || '', {
      from: 0,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp?.body?.contacts) {
      const contacts = resp.body.contacts;
      this.setState({ data: contacts, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: null, isLoading: false });
    }
  }
}
