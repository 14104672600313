import React from 'react';
import autoBind from 'react-autobind';
import { Menu, Avatar, Dropdown, Tag, Typography } from 'antd';
import { UserOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';

export default class IDMUserBadge extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  // Actions
  handleSelect({ key }) {
    if (key === 'changepassword') this.props.onChangePassword();
    else if (key === 'logout') this.props.onLogout();
    else if (key === 'register-org') this.props.onRegisterOrg();
    else if (key === 'profile') this.props.onProfile();
  }

  // Render Dropdown Menu
  _renderDropdownItems() {
    const { orgModAllowOrgSelfRegistration } = this.props.app.sharedCache().getTenantConfig();
    const shouldShowRegisterOrg =
      orgModAllowOrgSelfRegistration && this.props.app.isUser() && !this.props.app.isOrgAdmin();

    const items = [
      {
        key: 'name',
        label: `${this.props.user?.firstName} ${this.props.user?.lastName}`,
        disabled: true,
        icon: <UserOutlined />,
      },
      ...(shouldShowRegisterOrg
        ? [
            { type: 'divider' },
            {
              key: 'register-org',
              label: 'Register an organization',
              icon: <PlusOutlined />,
            },
            { type: 'divider' },
          ]
        : []),
      ...(!this.props.app.isUser()
        ? [
            {
              key: 'profile',
              label: 'Profile',
            },
          ]
        : []),
      {
        key: 'changepassword',
        label: 'Change Password',
      },
      {
        key: 'logout',
        label: 'Logout',
        icon: <ExportOutlined />,
      },
    ];

    return items;
  }

  // UI
  render() {
    const menuItems = this._renderDropdownItems();

    return (
      <Dropdown
        menu={{
          items: menuItems,
          onClick: this.handleSelect,
        }}
        className="-leave"
      >
        <Tag className="idmBadgeContainer">
          <Avatar justify="end" size={36} className="idmBadgeUserIcon">
            <Typography.Text className="idmBadgeUserText">{this.props.user?.firstName?.substr(0, 1)}</Typography.Text>
          </Avatar>
        </Tag>
      </Dropdown>
    );
  }
}
