import React from 'react';
import { Row, Col } from 'antd';
import { LockFilled } from '@ant-design/icons';
//
import CourseCardActions from '../Subcomponents/CourseCardActions';
//
export default class Locked extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess, session } = this.props;
    return (
      <>
        <Row justify="center">
          <Col span={14} style={{ textAlign: 'center' }}>
            {courseSpecs.description} is locked and needs to be released by the administrator.
          </Col>
        </Row>
        <CourseCardActions
          app={app}
          disableActions={this.props.disableActions}
          courseSpecs={courseSpecs}
          certificationProcess={certificationProcess}
          session={session}
          actionUnlockSession
          actionCancelCourse
          actionRescheduleCourse
        />
      </>
    );
  }
}
Locked.CardStatusHeader = function () {
  return (
    <span className="danger">
      <LockFilled /> Locked
    </span>
  );
};
