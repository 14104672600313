import React from 'react';
import autoBind from 'react-autobind';
//
import { Layout, Tabs, Row } from 'antd';
//
import '../../assets/stylesheets/TabViewController.less';
//
const _TabPrefix = 'tab_';
const _TabComponentPrefix = 'tab_content_';
//
export default class TabViewController extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.cache = [];
  }
  //UI
  render() {
    return (
      <Layout.Content className="tabContainer">
        <Tabs type="card" animated activeKey={`${_TabPrefix}${this.props.selectedIndex}`} className="tabView">
          {(this.props.config || []).map((item, i) => {
            if (!this.cache[i]) this.cache[i] = item.component;
            const Component = this.cache[i];
            return (
              <Tabs.TabPane tab={this._tabTagForItem(item)} key={`${_TabPrefix}${i}`}>
                <Component key={`${_TabComponentPrefix}${i}`} app={this.props.app} parent={this.props.parent} />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Layout.Content>
    );
  }
  _tabTagForItem(item) {
    return <Row className="tabTag" type="flex" justify="center" align="middle"></Row>;
  }
}
