import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import Globals from '../../config/Globals';
//props are: locations, onDelete, onEdit, isLoading
export default class CommonOrgLocationsTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
      },
      {
        title: 'Address',
        key: 'address',
        sorter: (a, b) => (a.address?.street?.join(', ') || '').localeCompare(b.address?.street?.join(', ') || ''),
        render: (props) => props.address?.street?.join(', ') || '',
        sortOrder: sortedInfo.columnKey === 'address' && sortedInfo.order,
      },
      {
        title: 'City',
        key: 'city',
        render: (props) => props.address?.city || '',
        sorter: (a, b) => (a.address?.city || '').localeCompare(b.address?.city || ''),
        sortOrder: sortedInfo.columnKey === 'city' && sortedInfo.order,
      },
      {
        title: 'Phone',
        key: 'phone',
        render: (props) =>
          props.phone?.[0]?.label ? `${props.phone?.[0]?.label || ''} - ${props.phone?.[0]?.number || ''}` : '-',
        sorter: false,
      },
      {
        title: 'Actions',
        width: '10%',
        key: 'Actions',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  style={{ marginLeft: 10 }}
                  onClick={() => this.props.onEdit(props)}
                  shape="circle"
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure you want to remove the ${props?.description} location from this organization?`}
                  onConfirm={() => this.props.onDelete(props)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" style={{ marginLeft: 6 }} />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No locations found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return <Table className="adminOrgLocationsTable" columns={columns} dataSource={this.props.locations} {...props} />;
  }
}
