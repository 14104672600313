/* eslint-disable no-useless-escape */
/* eslint-disable one-var */
import URLQuery from 'query-string-manipulator';
//
import config from '../config/config';
import Globals from '../config/Globals';
import { isIOS } from 'react-device-detect';
//
const urlParse = require('url-parse');
//
export default class URLManager {
  constructor(app) {
    this.app = app;
  }

  /* Organization manager support */
  get selectedOrg() {
    const cachedOrgID = localStorage.getItem(Globals.selectedOrgStorageKey);
    const userObj = this.app.sharedCache().getProgramUser();
    const userOrgs = userObj?.managerOf || [];
    if (cachedOrgID) {
      const orgID = JSON.parse(cachedOrgID);
      if (userOrgs.includes(orgID)) return orgID;
    }
    const orgID = userOrgs[0] || null;
    if (orgID) localStorage.setItem(Globals.selectedOrgStorageKey, JSON.stringify(orgID));
    return orgID;
  }
  switchOrg(orgID) {
    localStorage.setItem(Globals.selectedOrgStorageKey, JSON.stringify(orgID));
    setTimeout(() => window.location.reload(), 300);
  }
  /* Self urls */
  appURLWithPath(path, args) {
    return URLQuery(this.appURL + path, { set: args || {} });
  }
  get appURL() {
    return urlParse(window.location.href).origin;
  }
  getLoginPage() {
    const affiliateID = this.app.idm.urlmanager.getParam(Globals.URL_Path_Affiliate_ID);
    return this.appURLWithPath(config.ApplicationRoutes.login, {
      ...(this.affiliateID || affiliateID ? { [Globals.URL_Path_Affiliate_ID]: this.affiliateID || affiliateID } : {}),
    });
  }
  getRegisterPageByAffiliateID(affiliateID) {
    return this.appURLWithPath(config.ApplicationRoutes.register, {
      [Globals.URL_Path_Affiliate_ID]: affiliateID,
    });
  }
  /* Portal support */
  async redirectToPortal(path) {
    const redirectURL = await this.app.idm.urlmanager.getRedirectURL(
      this.app.sharedCache().getPortalDomain(),
      path,
      null,
      null,
      null,
      this.app.tenantID ? { tenantID: this.app.tenantID } : null,
      true
    );
    this.openExternalPage(redirectURL);
  }

  //Params
  getQueryParam(paramName) {
    return this.app.idm.urlmanager.getParam(paramName);
  }
  getPathParam(paramName, ref) {
    return ref?.props?.match?.params[paramName];
  }

  //Navigation stack
  parseUrl(pagePath, args, id, id2, id3) {
    //replace id
    if (id3 != undefined) pagePath = pagePath.replace(':' + Globals.URL_Path_ID3_Placeholder, id3);
    if (id2 != undefined) pagePath = pagePath.replace(':' + Globals.URL_Path_ID2_Placeholder, id2);
    if (id != undefined) pagePath = pagePath.replace(':' + Globals.URL_Path_ID_Placeholder, id);
    //build url
    const reqURL = URLQuery(pagePath, { set: args || {} });
    return reqURL;
  }
  replacePage(pagePath, args, id, id2, state, id3) {
    const reqURL = this.parseUrl(pagePath, args, id, id2, id3);
    if (state) {
      console.debug('Replacing page with state', reqURL);
      this.app.props.history.replace(reqURL, state);
    } else {
      console.debug('Replacing page', reqURL);
      this.app.props.history.replace(reqURL);
    }
  }
  pushPage(pagePath, args, id, id2, state, id3) {
    const reqURL = this.parseUrl(pagePath, args, id, id2, id3);
    if (state) {
      console.debug('Pushing page with state', reqURL);
      this.app.props.history.push(reqURL, state);
    } else {
      console.debug('Pushing page', reqURL);
      this.app.props.history.push(reqURL);
    }
  }
  pushPageWithThirdID(pagePath, args, id, id2, id3, state) {
    this.pushPage(pagePath, args, id, id2, state, id3);
  }
  pushBack() {
    this.app.props.history.goBack();
  }
  // openPage(page) { -- not in use
  //   console.debug("Open page", page);
  //   window.location.assign(page);
  // }
  openExternalPage(url, appendProtocol = false) {
    console.debug('Open external page', url);
    if (appendProtocol) {
      const curr = urlParse(window.location.href);
      url = `${curr.protocol.replace(':', '')}://${url}`;
    }
    //
    if (isIOS) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
      window.focus();
    }
  }
  openExternalPageWithParams(pagePath, args, id, id2, id3) {
    const reqURL = this.parseUrl(pagePath, args, id, id2, id3);
    this.openExternalPage(reqURL);
  }

  //URL
  updateQueryStringParam(key, value) {
    // - https://gist.github.com/excalq/2961415
    console.debug('Update query string', key, value);
    let baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
      urlQueryString = document.location.search,
      newParam = key + '=' + value,
      params = '?' + newParam;
    // If the "search" string exists, then build params from it
    if (urlQueryString) {
      let updateRegex = new RegExp('([\?&])' + key + '[^&]*');
      let removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
      if (typeof value == 'undefined' || value == null || value == undefined || value == '') {
        // Remove param if value is empty
        params = urlQueryString.replace(removeRegex, '$1');
        params = params.replace(/[&;]$/, '');
      } else if (urlQueryString.match(updateRegex) !== null) {
        // If param exists already, update it
        params = urlQueryString.replace(updateRegex, '$1' + newParam);
      } else {
        // Otherwise, add it to end of query string
        params = urlQueryString + '&' + newParam;
      }
    } else if (!value) return; //nothing to add
    // no parameter was set so we don't need the question mark
    params = params == '?' ? '' : params;
    window.history.replaceState({}, '', baseUrl + params);
  }
  clearQueryStringParam() {
    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
    window.history.replaceState({}, '', baseUrl);
  }
}
