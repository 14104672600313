import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Empty } from 'antd';

export default class ExamsChart extends React.Component {
  render() {
    const { exams } = this.props;

    const data = (exams || []).map((data) => ({
      id: data.exam.displayName,
      label: data.exam.displayName,
      value: data.total,
    }));

    const allEmpty = data.every((item) => item.value === 0);
    if (allEmpty) {
      return <Empty description="No data to be displayed here" style={{ marginTop: 56 }} />;
    }

    return (
      <ResponsivePie
        data={data}
        margin={{ top: 24, right: 0, bottom: 100, left: 0 }}
        activeOuterRadiusOffset={8}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        theme={{
          labels: { text: { fontSize: 13 } },
        }}
      />
    );
  }
}
