import { ResponsiveBar } from '@nivo/bar';
import { Empty } from 'antd';
import React from 'react';

export default class PassFailRateChart extends React.Component {
  render() {
    const { exams } = this.props;

    const data = exams.map((data) => ({
      id: data.exam.displayName,
      label: data.exam.displayName,
      value: data.total,
      Passed: data.pass,
      Failed: data.fail,
      PassedColor: '#39AF68',
      FailedColor: '#af3947',
    }));

    const allEmpty = data.every((item) => item.Passed === 0 && item.Failed === 0);
    if (allEmpty) {
      return <Empty description="No data to be displayed here" style={{ marginTop: 56 }} />;
    }

    return (
      <ResponsiveBar
        data={data}
        keys={['Passed', 'Failed']}
        layout="horizontal"
        colors={({ data, id }) => data[`${id}Color`]}
        labelTextColor="#fff"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        margin={{ top: 0, right: 0, bottom: 70, left: 170 }}
        axisLeft={{ tickSize: 0, tickPadding: 20, tickRotation: 0 }}
        enableGridY={false}
        theme={{
          legends: { text: { fontSize: 13 } },
          axis: { ticks: { text: { fontSize: 13 } } },
          labels: { text: { fontSize: 13 } },
        }}
      />
    );
  }
}
