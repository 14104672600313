import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Button, Checkbox, message, Modal } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import '../../assets/stylesheets/CommonContactModal.less';
import Utils from '../../components/Utils';
//props handleSubmissionComplete, handleCancel
export default class CommonSetPasswordModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //stepper possible statuses - wait process finish error
    this.state = { isLoading: false, userID: null };
  }
  loadInfo(userID) {
    this.setState({ userID });
  }
  //Actions
  handleCancel() {
    this.props.handleCancel();
  }
  async handleSubmit() {
    const resp = await this.form.validateFields();
    if (resp) {
      if (resp.confirmationPassword != resp.newPassword) {
        this.props.app.alertController.showErrorAlert('Error!', "Passwords don't match!");
        return;
      }
      resp.isPermanent = !resp.userMustChange;
      delete resp.userMustChange;

      this._submitSetPassword(resp);
    }
  }
  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        closable={true}
        title="Set new password"
        footer={null}
        className="contactUsModal"
        open={this.props.isVisible}
        onCancel={this.handleCancel}
        confirmLoading={this.state.isLoading}
      >
        {this._renderForm()}
        {this._renderButtonsFooter()}
      </Modal>
    );
  }

  /* subcomponents */
  _renderForm() {
    const layout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
    };

    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    return (
      <Form {...layout} key="contactUs" className="contactUsForm" {...Utils.propagateRef(this, 'form')}>
        <Form.Item
          label="New password"
          name="newPassword"
          rules={[{ required: true, message: 'Please, type a new password!' }]}
        >
          <Input.Password id="newPassword" allowClear data-dd-privacy="mask" />
        </Form.Item>
        <Form.Item
          label="Verify"
          name="confirmationPassword"
          rules={[{ required: true, message: 'Please, type a new password again!' }]}
        >
          <Input.Password id="confirmationPassword" allowClear data-dd-privacy="mask" />
        </Form.Item>
        <Form.Item label="User must change password" name="userMustChange" valuePropName="checked">
          <Checkbox id="userMustChange" allowClear />
        </Form.Item>
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={this.handleCancel}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          style={{ border: 0 }}
          key="submit"
          type="primary"
          loading={this.state.isLoading}
          onClick={this.handleSubmit}
          disabled={this.state.isLoading}
          className="contactUsSubmitButton"
        >
          {' '}
          Set password{' '}
        </Button>
      </Row>
    );
  }
  /* private methods */
  async _submitSetPassword(data) {
    this.setState({ isLoading: true });
    const changeResp = await this.props.app.idm.api.password.update(
      {
        newPassword: data.newPassword,
        isPermanent: data.isPermanent,
      },
      this.state.userID
    );
    if (!this._isMounted) return;
    console.debug('Set new pass resp:', changeResp, data);
    if (changeResp.statusCode == 200) {
      this.setState({ isLoading: false });
      message.success('New password was set with success!');
      this.form.resetFields();
      this.props.handleSubmissionComplete();
    } else {
      this.setState({ isLoading: false });
      this.props.app.alertController.showAPIErrorAlert(null, changeResp);
    }
  }
}
