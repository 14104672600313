import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Table, Drawer, Row, Tooltip, Col, Popconfirm, Button, message, Divider } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../CommonLoadingView';
//
import CommonRegionForm from '../Forms/CommonRegionForm';
import CommonCityForm from '../Forms/CommonCityForm';
//props are: app, onUpdate
export default class CommonLocationDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      isVisible: false,
      regionID: null,
      regionData: null,
      cities: null,
      cityID: null,
      cityData: null,
      sortedInfo: null,
    };
  }
  //Public
  show(regionID, cities) {
    this.setState(
      { isVisible: true, regionID, regionData: null, cities, cityID: null, cityData: null, sortedInfo: null },
      this._fetchRegion
    );
  }
  //Private actions
  handleClose() {
    if (this.props.onUpdate) this.props.onUpdate();
    this.form.resetFields();
    this.setState({
      isLoading: false,
      isVisible: false,
      regionID: null,
      regionData: null,
      cities: null,
      cityID: null,
      cityData: null,
      sortedInfo: null,
    });
  }
  //Region
  async handleSubmitRegion() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.regionID) this._updateRegion(formData);
    else this._createRegion(formData);
  }
  //Cities Table
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  //Cities
  async handleSubmitCity() {
    const formData = await this.cityForm.validateFields();
    if (!formData) return;
    if (this.state.cityID) this._updateCity(formData);
    else this._createCity(formData);
  }
  handleClearCity() {
    this.setState({ cityID: null, cityData: null });
    this.cityForm.resetFields();
  }
  async handleDeleteCity(cityID) {
    this._deleteCity(cityID);
  }
  async handleEditCity(cityID) {
    this.setState({ cityID, cityData: (this.state.cities || []).find((c) => c.id == cityID) || {} }, () => {
      this.cityForm.setFieldsValue(this.state.cityData);
    });
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`${this.state.regionID ? 'Edit' : 'Create'} Location`}
        placement="right"
        onClose={this.handleClose}
        width={800}
      >
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Divider>Region</Divider>
        <Row type="flex" justify="end">
          <Col>
            {' '}
            <Button type="primary" onClick={this.handleSubmitRegion}>
              {this.state.regionID ? 'Save' : 'Create'} Region
            </Button>{' '}
          </Col>
        </Row>
        <CommonRegionForm app={this.props.app} {...Utils.propagateRef(this, 'form')} />
        {this.state.regionID && (
          <>
            <Divider>Cities</Divider>
            <Row type="flex" justify="end">
              <Col>
                <Button type="primary" onClick={this.handleSubmitCity}>
                  {this.state.cityID ? 'Save' : 'Add'} City
                </Button>
                {this.state.cityID && (
                  <Button style={{ marginLeft: '10px' }} onClick={this.handleClearCity}>
                    {' '}
                    Cancel{' '}
                  </Button>
                )}
              </Col>
            </Row>
            <CommonCityForm app={this.props.app} {...Utils.propagateRef(this, 'cityForm')} />
            {this._renderCitiesTable()}
          </>
        )}
      </Drawer>
    );
  }

  /* private UI */
  _renderCitiesTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: '30%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Province',
        key: 'province',
        dataIndex: 'province',
        width: '30%',
        sorter: (a, b) => a.province.localeCompare(b.province),
        sortOrder: sortedInfo.columnKey === 'province' && sortedInfo.order,
      },
      {
        title: 'Created On',
        key: 'createdOn',
        width: '15%',
        render: (props) => (props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Actions',
        width: 'auto',
        key: 'Actions',
        width: '15%',
        render: (props) => {
          return (
            <span className="tableButtonContainer">
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.handleEditCity.bind(this, props.id)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm
                  placement="top"
                  title={`Are you sure that you want to delete the City '${props.name}'?`}
                  onConfirm={this.handleDeleteCity.bind(this, props.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
    ];
    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No cities found on this region!' },
      onRow: this._onRow,
      pagination: { pageSize: 5, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return (
      <Layout.Content>
        <Table
          className="adminCitiesTable"
          columns={columns}
          {...props}
          dataSource={(this.state.cities || []).filter((c) => c.regionID == this.state.regionID)}
        />
      </Layout.Content>
    );
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.handleEditCity(record.id);
      }, // click row
      onDoubleClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.handleEditCity(record.id);
      }, // double click row
    };
  }

  // API Calls
  //Region
  async _fetchRegion() {
    if (!this.state.regionID) return; //avoid loading empty regions
    this.setState({ isLoading: true });
    const resp = await this.props.app.classroom.region.getRegion(this.state.regionID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ regionData: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _updateRegion(data) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateRegions();
    const resp = await this.props.app.classroom.region.updateRegion({
      id: this.state.regionData.id,
      customerID: this.state.regionData.customerID,
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Region successfully updated!');
      this._fetchRegion();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _createRegion(data) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateRegions();
    const resp = await this.props.app.classroom.region.createRegion({
      ...data,
      customerID: this.props.app.sharedCache().getCustomerID(),
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Region successfully created!');
      this.show(resp.body.id);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  //City
  async _fetchCities() {
    if (!this.state.regionID) return; //avoid loading empty regions
    this.setState({ isLoading: true });
    const resp = await this.props.app.sharedCache().getCities();
    if (!this._isMounted) return;
    if (resp) {
      this.setState({ cities: resp, cityID: null, cityData: null });
      this.cityForm.resetFields();
    } else this.props.app.alertController.showErrorAlert(null, 'Error while loading cities!');
    this.setState({ isLoading: false });
  }
  async _updateCity(data) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateCities();
    const resp = await this.props.app.classroom.city.updateCity({
      id: this.state.cityData.id,
      customerID: this.state.cityData.customerID,
      regionID: this.state.cityData.regionID,
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('City successfully updated!');
      this._fetchCities();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _createCity(data) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateCities();
    const resp = await this.props.app.classroom.city.createCity({
      customerID: this.state.regionData.customerID,
      regionID: this.state.regionID,
      ...data,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('City successfully created!');
      this._fetchCities();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _deleteCity(cityID) {
    if (!this._isMounted) return;
    this.startLoading();
    this.props.app.sharedCache().invalidateCities();
    const resp = await this.props.app.classroom.city.deleteCity(cityID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('City successfully deleted!');
      this._fetchCities();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
}
