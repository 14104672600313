import React from 'react';
import autoBind from 'react-autobind';
import { Modal, DatePicker, Checkbox, Row, Col, Alert, Result, Select } from 'antd';
//
import CustomComponent from '../../../components/CustomComponent';
import Utils from '../../../components/Utils';
//
export default class AdminUsersReportModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      isVisible: false,
      dateType: '',
      dates: [],
      columns: [],
      success: null,
    };
  }

  show() {
    this.setState({ isVisible: true });
  }

  handleCancel() {
    this.setState({
      isLoading: false,
      isVisible: false,
      dateType: '',
      dates: [],
      columns: [],
      success: null,
    });
  }

  handleDateChange(dates) {
    this.setState({ dates });
  }

  handleChangeChekboxes(checkedValues) {
    this.setState({ columns: checkedValues });
  }

  handleTryAgain() {
    this.setState({ success: null });
  }

  handleDateTypeChange(value) {
    this.setState({ dateType: value });
  }

  async handleGenerateReport() {
    this.setState({ isLoading: true });

    let [from, to] = this.state.dates;

    if (!from || !to) {
      return;
    }

    let { columns } = this.state;
    columns.sort();
    columns = columns.map((column) => column.substring(2));

    const resp = await this.props.app.api.user.usersAdvancedReport(
      Utils.getZeroTimestampFromMomentDate(from),
      Utils.getEndOfDayTimestampFromMomentDate(to),
      columns,
      this.state.dateType
    );

    if (resp.statusCode == 200 && resp.body) {
      from = from.format('YYYY-MM-DD');
      to = to.format('YYYY-MM-DD');

      Utils.downloadBlob(resp.body, `users-report-${from}-to-${to}`, 'xlsx');
      this.setState({ isLoading: false, success: true });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }

  //UI
  render() {
    const { dates, columns, isLoading, isVisible, success, dateType } = this.state;

    return (
      <Modal
        title="Student Data Export"
        open={isVisible}
        onCancel={this.handleCancel}
        okText="Generate Report"
        cancelText="Close"
        style={{ maxWidth: '650px' }}
        okButtonProps={{ disabled: !dates || dates.length < 1 || columns.length < 1 || !!success || !dateType }}
        onOk={this.handleGenerateReport}
        confirmLoading={isLoading}
      >
        {success === null && (
          <>
            <Alert
              showIcon
              type="warning"
              message="Student data export will generate an Excel spreadsheet (XLSX) containing student data. Please select the desired date range and what date type should be used for the filter. This report can take a few minutes if a large number of students is selected."
            />
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, paddingRight: 15 }}>
                <strong style={{ display: 'block', marginTop: 20 }}>
                  <label>Filter by</label>
                </strong>
                <Select
                  defaultValue="select"
                  value={dateType || 'select'}
                  style={{ width: '100%' }}
                  onChange={this.handleDateTypeChange}
                >
                  <Select.Option disabled value="select">
                    Select
                  </Select.Option>
                  <Select.Option value="completionDate">Completion Date</Select.Option>
                  <Select.Option value="signin">Registration Date</Select.Option>
                </Select>
              </div>

              <div style={{ flex: 1 }}>
                <strong style={{ display: 'block', marginTop: 20 }}>
                  <label>Period</label>
                </strong>
                <DatePicker.RangePicker value={dates} onChange={this.handleDateChange} />
              </div>
            </div>

            <strong style={{ display: 'block', marginTop: 15 }}>
              <label>Columns</label>
            </strong>
            <Checkbox.Group value={columns} style={{ width: '100%' }} onChange={this.handleChangeChekboxes}>
              <Row>
                <Col span={8}>
                  <Checkbox value="01firstName" style={{ display: 'flex', marginLeft: 8 }}>
                    First name
                  </Checkbox>
                  <Checkbox value="02lastName" style={{ display: 'flex' }}>
                    Last name
                  </Checkbox>
                  <Checkbox value="03email" style={{ display: 'flex' }}>
                    Email
                  </Checkbox>
                  <Checkbox value="04streetAddress" style={{ display: 'flex' }}>
                    Street address
                  </Checkbox>
                  <Checkbox value="05streetAddress2" style={{ display: 'flex' }}>
                    Street address 2
                  </Checkbox>
                  <Checkbox value="06city" style={{ display: 'flex' }}>
                    City
                  </Checkbox>
                  <Checkbox value="07province" style={{ display: 'flex' }}>
                    Province
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="08postalCode" style={{ display: 'flex', marginLeft: 8 }}>
                    Postal code
                  </Checkbox>
                  <Checkbox value="09country" style={{ display: 'flex' }}>
                    Country
                  </Checkbox>
                  <Checkbox value="10mobilePhoneNumber" style={{ display: 'flex' }}>
                    Mobile number
                  </Checkbox>
                  <Checkbox value="11dob" style={{ display: 'flex' }}>
                    Date of birth
                  </Checkbox>
                  <Checkbox value="12employer" style={{ display: 'flex' }}>
                    Employer
                  </Checkbox>
                  <Checkbox value="13registrationDate" style={{ display: 'flex' }}>
                    Registration date
                  </Checkbox>
                  <Checkbox value="14completionDate" style={{ display: 'flex' }}>
                    Completion date
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="15certNumber" style={{ display: 'flex', marginLeft: 8 }}>
                    Certificate number
                  </Checkbox>
                  <Checkbox value="16nameOnCertificate" style={{ display: 'flex' }}>
                    Name on certificate
                  </Checkbox>
                  <Checkbox value="17loginDate" style={{ display: 'flex' }}>
                    Last login date
                  </Checkbox>
                  <Checkbox value="18sendGift" style={{ display: 'flex', marginLeft: 8 }}>
                    Send Gift
                  </Checkbox>
                  <Checkbox value="19itaNumber" style={{ display: 'flex' }}>
                    Ita Number
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </>
        )}

        {!!success && (
          <>
            <Result
              status="success"
              title="Success!"
              subTitle={
                <strong>
                  Your file should download automatically. If it doesn't,{' '}
                  <a onClick={this.handleTryAgain} href="javascript:;">
                    please try again
                  </a>
                  .
                </strong>
              }
            />
          </>
        )}
      </Modal>
    );
  }
}
