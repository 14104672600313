import { CloseCircleFilled } from '@ant-design/icons';
import React from 'react';
//
import Globals from '../../../../../config/Globals';
import CommonResultCard from '../../../../commonComponents/CommonResultCard';
import CourseCardActions from '../Subcomponents/CourseCardActions';
//
export default class Failed extends React.Component {
  render() {
    const { courseSpecs, currentCourse, certificationProcess, app, session } = this.props;
    const isWaived = !!certificationProcess.waivedBy;
    return (
      <>
        <CommonResultCard
          label={`You have failed the ${courseSpecs.description}.`}
          app={app}
          currentCourse={currentCourse}
        />

        {courseSpecs.courseType === Globals.Course_Types_Keys.EXTERNALREQUIREMENT && (
          <CourseCardActions
            app={app}
            courseSpecs={courseSpecs}
            certificationProcess={certificationProcess}
            session={session}
            actionForceCheck
          />
        )}

        {courseSpecs.courseType !== Globals.Course_Types_Keys.EXTERNALREQUIREMENT && (
          <CourseCardActions
            app={app}
            courseSpecs={courseSpecs}
            certificationProcess={certificationProcess}
            availableKeys={this.props.availableKeys}
            session={session}
            actionPurchase
            actionRedeem
            actionActivate
            disableActions={this.props.disableActions}
            message={
              isWaived
                ? `To take the ${this.props.courseSpecs.description} you need to activate this ${this.props.courseSpecs.uiTerm}. Please click continue the activation.`
                : `To take the ${this.props.courseSpecs.description} please click the buttons below to make a purchase or enter a license key.`
            }
          />
        )}
      </>
    );
  }
}
Failed.CardStatusHeader = function (courseSpecs) {
  return (
    <span className="danger">
      <CloseCircleFilled /> {courseSpecs.failUiTerm ? courseSpecs.failUiTerm : 'Failed'}{' '}
    </span>
  );
};
