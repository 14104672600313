import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Empty } from 'antd';

export default class CertificationsChart extends React.Component {
  render() {
    const data = [
      {
        id: 'New',
        label: 'New',
        Total: this.props.isV2 ? this.props.issued : this.props.issued - this.props.recertifications,
      },
      { id: 'Recertifications', label: 'Recertifications', Total: this.props.recertifications },
    ];

    const allEmpty = data.every((item) => item.Total === 0);
    if (allEmpty) {
      return <Empty description="No data to be displayed here" style={{ marginTop: 56 }} />;
    }

    return (
      <ResponsiveBar
        data={data}
        keys={['Total']}
        layout="horizontal"
        colors={{ scheme: 'orange_red' }}
        colorBy="id"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        margin={{ top: 0, right: 0, bottom: 70, left: 114 }}
        axisLeft={{ tickSize: 0, tickPadding: 20, tickRotation: 0 }}
        enableGridY={false}
        theme={{
          legends: { text: { fontSize: 13 } },
          axis: { ticks: { text: { fontSize: 13 } } },
          labels: { text: { fontSize: 13 } },
        }}
      />
    );
  }
}
