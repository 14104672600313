import React from 'react';
import autoBind from 'react-autobind';

import CourseCard from './CommonCertificationViewCourseTabView/CourseCard';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';

import CourseTabViewModals from './CommonCertificationViewCourseTabView/Subcomponents/CourseTabViewModals';

export default class CommonCertificationViewCourseTabView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //Life cycle
  async componentDidMount() {
    if (this.props.invitation && !this.props.invitation.activationCode) {
      const courseCardIx = this.props.certificationSpecs?.requirementsIDs.findIndex((req) =>
        req.includes(this.props.invitation?.courseID)
      );
      const courseStatus = this[`courseCard${courseCardIx}`]._getCurrentCourseStatus();
      if (courseCardIx != -1 && Globals.PurchasableCourseStatuses.includes(courseStatus)) {
        const courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.invitation.courseID);
        this.courseTabModals.openPurchaseLicenseModal(courseSpecs, this.props.invitation.sessionID);
      }
    } else if (this.props.invitation && this.props.invitation.activationCode) {
      this.handleUpdateAndRefreshCardState();
    }
  }

  //Actions
  async handleUpdateAndRefreshCardState() {
    await this.props.onUpdate();
    for (const ii in this.props.certificationSpecs?.requirementsIDs || []) this[`courseCard${ii}`]?.reloadState();
  }
  // Purchase modals actions
  handleLicensePurchase(courseSpecs) {
    this.courseTabModals.openPurchaseLicenseModal(courseSpecs);
  }
  handleLicenseRedeem(courseSpecs, isActivation, availableKeys) {
    this.courseTabModals.openRedeemModal(
      {
        courseID: courseSpecs.id,
        userID: this.props.certificationProcess.userID,
        certProcID: this.props.certificationProcess.id,
        availableKeys,
      },
      isActivation
    );
  }
  // Result modal actions
  handleAddResult(currentCourse) {
    this.courseTabModals.openAddResultModal(currentCourse);
  }
  // Schedule modal actions
  handleSchedule(currentCourse) {
    this.courseTabModals.openScheduleModal(currentCourse);
  }

  //UI
  render() {
    const { certificationProcess, certificationSpecs, user } = this.props;
    return (
      <div style={!this.props.isVisible ? { display: 'none' } : {}}>
        {certificationSpecs?.requirementsIDs.map((coursesIDs, i) => (
          <CourseCard
            {...Utils.propagateRef(this, `courseCard${i}`)}
            key={!Array.isArray(coursesIDs) ? coursesIDs : coursesIDs[0]}
            app={this.props.app}
            certificationProcess={certificationProcess}
            certificationSpecs={certificationSpecs}
            coursesIDs={!Array.isArray(coursesIDs) ? [coursesIDs] : coursesIDs}
            user={user}
            onUpdate={this.handleUpdateAndRefreshCardState}
            onLicensePurchase={this.handleLicensePurchase}
            onLicenseRedeem={this.handleLicenseRedeem}
            onAddResult={this.handleAddResult}
            onSchedule={this.handleSchedule}
            disableActions={this.props.renewalInProgress}
          />
        ))}
        {certificationProcess && (
          <CourseTabViewModals
            {...Utils.propagateRef(this, 'courseTabModals')}
            app={this.props.app}
            certificationProcess={certificationProcess}
            user={user}
            invitationCode={this.props.invitation?.invitationCode}
            onUpdate={this.handleUpdateAndRefreshCardState}
          />
        )}
      </div>
    );
  }
}

CommonCertificationViewCourseTabView.GetTabTitleView = function (certSpecs, certProc, app) {
  if (!certSpecs || !certProc) return '';
  if (certSpecs.ui?.courseTabLabel) return certSpecs.ui?.courseTabLabel;
  //Get first req.
  const isPlural = certSpecs?.requirementsIDs.length > 0;
  const firstReqID = Array.isArray(certSpecs?.requirementsIDs[0])
    ? certSpecs?.requirementsIDs[0][0]
    : certSpecs?.requirementsIDs[0];
  const courseSpecs = app.sharedCache().getCourseByID(firstReqID);
  if (!courseSpecs) return '-';
  return `${Utils.camelizeString(courseSpecs.uiTerm)}` + (isPlural ? 's' : '');
};
